import React from 'react';
import PropTypes from 'prop-types';

import { parseString, formatDate, openInTab } from '../../../AppConstants';
import blog_thumb_img from '../../../../assets/images/blog-thumb-1.png';

const BlogVerticalCard = ({ content, outerClassName }) => {
  return (
    <div
      //   key={`${Date.now()}-blg-${content?.id}`}
      className={`mb-4 bg-white md:mb-4  card-shadow-custom   rounded-xl  lg:rounded-lg ${outerClassName} `}
    >
      <div className="flex flex-col h-full">
        <img
          onClick={
            content?.attributes?.blog_url
              ? () => openInTab(content?.attributes?.blog_url)
              : null
          }
          className="w-full h-70 object-cover rounded-t-lg mb-4 cursor-pointer"
          src={
            content?.attributes?.blog_image?.data?.attributes?.url
              ? content?.attributes?.blog_image?.data?.attributes?.url
              : blog_thumb_img
          }
          alt={
            content?.attributes?.blog_image?.data?.attributes?.alternativeText
              ? content?.attributes?.blog_image?.data?.attributes
                  ?.alternativeText
              : ''
          }
        />

        <div className="text-left p-3 flex  flex-col h-full justify-between">
          {content?.attributes?.blog_title && (
            <div className="text-left text-sm md:text-base lg:text-lg line-clamp-custom">
              {parseString(content?.attributes?.blog_title)}
            </div>
          )}

          <div className="">
            {content?.attributes?.blog_author && (
              <div className="text-neutral-BLACK-DARKER pt-3  text-l text-sm md:text-base font-bold">
                {parseString(content?.attributes?.blog_author)}
              </div>
            )}

            <div className="bg-transparent pt-3 flex items-center justify-start">
              {content?.attributes?.blog_types?.data?.[0]?.attributes
                ?.blog_type_name && (
                <button className="blog-btn-color border border-custom-color text-black-500 signin-btn-hover text-xxs rounded-full px-2 py-1">
                  {
                    content?.attributes?.blog_types?.data?.[0]?.attributes
                      ?.blog_type_name
                  }
                </button>
              )}

              {content?.attributes?.blog_date && (
                <span className="ml-1 text-xxs text-BLACK">
                  {formatDate(content?.attributes?.blog_date)}
                </span>
              )}
              {content?.attributes?.blog_read_time &&
                content?.attributes?.time && (
                  <p className="ml-5 text-xxs text-BLACK relative">
                    <span className="before-dot text-xxs"></span>{' '}
                    {content?.attributes?.blog_read_time}
                    {content?.attributes?.time} Read
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogVerticalCard.propTypes = {
  content: PropTypes.object || undefined,
};

export default BlogVerticalCard;
