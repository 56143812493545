import React from 'react';
import PropTypes from 'prop-types';

// import linkedin_button from '../../../assets/images/linkedin-button.svg';
import avatar_dummy from '../../../assets/images/avatar-dummy.png';
import { parseString } from '../../AppConstants';

const CardCopyUser = ({
  contents,
  isModalOpen,
  onImageClick = () => { },
  setModalContent = () => { },
}) => {
  const handleImageClick = (index) => {
    if (index != null) {
      onImageClick(!isModalOpen);
      setModalContent(contents?.leaders_list?.data[index]);
    }
  };

  return (
    <>
      {contents?.leadership_title && (
        <div className="w-full mt-6 md:mt-14">
          <h3 className="text-primary-BLACK text-lg md:text-xl lg:text-2xl ml-2 md:ml-0 lg:ml-0 font-bold text-left mb-4 md:mb-8">
            {parseString(contents?.leadership_title)}
          </h3>
        </div>
      )}
      {contents?.leaders_list?.data?.length && (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {contents?.leaders_list?.data?.map((object, index) => (
            <div key={`cuc-ldlt-${index}`} className="w-full flex flex-col">
              <div className="flex-1">
                <div className="bg-white h-full mx-0 md:mx-0 lg:mx-0 p-8 rounded-lg shadow-md ">
                  <figure className="flex justify-center">
                    <div className="relative rounded-full overflow-hidden"
                      onClick={() => handleImageClick(index)}>
                      <div className="absolute flex items-center justify-center
                       left-0 top-0 w-full h-full bg-BLACK bg-opacity-60 
                       cursor-pointer
                       opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out">
                        <span className="font-material-icons no-underline text-white text-4xl cursor-pointer">
                          visibility
                        </span>
                      </div>
                      {object?.attributes?.leader_photo?.data?.attributes?.url ? (
                        <img
                          className=" text-center w-44 h-44 rounded-full object-cover cursor-pointer"
                          src={
                            object?.attributes?.leader_photo?.data?.attributes
                              ?.url
                          }
                        />
                      ) : (
                        <img
                          className=" text-center w-44 h-44 rounded-full object-cover cursor-pointer"
                          src={avatar_dummy}
                        />
                      )}
                    </div>
                  </figure>
                  <div className="text-center mt-4">
                    <h4 className=" text-lg lg:text-xl font-bold text-black">
                      {parseString(object?.attributes?.leader_name)}
                    </h4>
                    <div className="text-base text-neutral-GRAY-300">
                      {parseString(object?.attributes?.leader_position)}
                    </div>
                  </div>
                  {object?.attributes?.social_media_link && (
                    <div className="flex justify-center mt-4">
                      <a href={object?.attributes?.social_media_link}>
                        <img
                          src={
                            object?.attributes?.social_icon?.data?.attributes
                              ?.media?.data?.attributes?.url
                          }
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

CardCopyUser.propTypes = {
  contents: PropTypes.object || undefined,
  iconHeadStartClass: PropTypes.string || undefined,
};
export default CardCopyUser;
