import React from 'react';

import plus from '../../../assets/images/plusicon.svg';
import minus from '../../../assets/images/minusicon.svg';
import { useEffect } from 'react';

const SelectPlusMinus = ({ selected, setSelection = () => { }, options, defaultVal }) => {


    useEffect(() => {
        if (defaultVal) {
            setSelection(defaultVal);
        }
    }, [])

    const getIndex = (item) => {
        if (item) {
            let itemIndex = 0;
            options?.map((object, index) => {
                if (object?.value == item) {
                    itemIndex = index;
                }
            })
            return itemIndex;
        }
    };

    const changePlus = () => {
        let active = getIndex(selected?.value);
        if (active + 1 < options?.length) {
            active = active + 1;
            setSelection(options[active]);
        }
    };

    const changeMinus = () => {
        //let active = IsEmpty(selected) ? 0 : selected;
        let active = getIndex(selected?.value);
        if (active)
            active = active - 1;

        if (active <= 0)
            active = 0;

        setSelection(options[active]);
    };

    return (
        <div className="flex items-start justify-start border-b-[2px] border-black mt-5 p-5 w-full   ">
            <button onClick={() => changeMinus()}>
                <img className=" " src={minus} alt="Mobile Screen Image" />
            </button>
            <label className="text-xl
                font-bold  text-[20px] text-primary-black  md:text-[26px] w-[7rem] md:w-[10rem] text-center bg-sky-blue " >{selected?.text || ''}</label>
            <button onClick={() => changePlus()}>
                <img className=" " src={plus} alt="Mobile Screen Image" />
            </button>
        </div>
    )

};

export default SelectPlusMinus;