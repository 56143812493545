import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// images
import blog_line_bg from '../../../assets/images/blog-line-bg.png';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_blog_category } from '../../layout/breadcrumb-data';

const BannerBlogsCategory = ({ content }) => {
  return (
    <div className=" mx-auto p-8 flex flex-col items-center mt-20 xl:mt-28 relative">
      <div className="absolute top-0 right-0">
        <img className="w-20 md:w-40" src={blog_line_bg} />
      </div>

      <div className="container mx-auto p-0  self-start">
        <div className="mb-4">
          <BreadCrumb items={bc_blog_category} />
        </div>
      </div>

      <div className="container mx-auto lg:pt-0 lg:px-8 text md:px-8 sm:p-2 flex flex-col mt-5 md:mt-10 ">
        <div className="grid grid-cols-1 ">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_blogs_cat_content_${index}`}>
                {toLower(item?.position) == 'center' && (
                  <div key={`content_center_${index}`} className="w-full ">
                    {item?.banner_heading && (
                      <h1 className="text-2xl md:text-4xl font-bold mb-2 mt-3 md:mt-0 lg:mb-4 text-primary text-center ">
                        {parseString(item?.banner_heading)}
                      </h1>
                    )}
                    {item?.banner_description && (
                      <div className="text-center text-base md:text-lg">
                        {parseString(item?.banner_description)}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerBlogsCategory;
