
export const initialState = {
  //Common
  access_token: null,
  refresh_token: null,
  access_token_reset: null,
  access_token_expired: false,
  refresh_token_expired: false,
  msgSnackbar: null,
  pageLoader: false,

  //File Upload
  fileProgress: {
    // format will be like below
    // 'abc.jpg': {
    //   name: 'abc.jpg',
    //   file,
    //   progress: 0,
    //   cancelSource: source,
    //   status: 0,
    //   data: [],
    //   message: '',
    // },
  },
  deleteFileProgress: null,
  delete_file_id: null,
  delete_file_type_id: null,
  getFileProgress: null,

  //Page
  home_contents: null,
  aboutus_contents: null,
  aboutnps_contents: null,
  faq_contents: null,
  nps_retail_contents: null,
  nps_corp_contents: null,
  nps_gov_contents: null,
  sitemap_contents: null,
  blog_contents: null,
  blog_list_contents: null,
  blog_data_contents: null,
  aboutnps_table: null,

  // template
  header_footer_contents: null,
  calculator_contents: null,
  calculator_data_contents: null,

  //Public Disclosure
  pubdisc_content: null,
  latest_nav_contents: null,
  nav_contents: null,
  proxy_voting_contents: null,
  half_year_contents: null,
  home_calculator: null,
  privacy_contents: null,
  comm_files: null,
  policy_files: null,
  policy_comp_files: null,
  risk_meter_files: null,
  regular_circulars_files: null,
  group_company_files: null,
  ann_report_company_files: null,
  ann_report_scheme_files: null,
  ann_return_other_files: null,
  latest_nav_files: null,
  portfolio_files: null,
  fragm_files: null,
  fragm_other_files: null,
  pd_portfolio_files: null,
};
