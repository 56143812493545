import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '../../common/layout/Menu';
import Footer from '../../common/layout/Footer';

// import icons_3 from '../../assets/images/icons-3.svg';
// import icons_4 from '../../assets/images/icons-4.svg';

import ContenSliderCopy from '../../common/components/Sliders/ContenSliderCopy';
import FooterBanner from '../../common/components/Banners/FooterBanner';

// images
import avatar_dummy from '../../assets/images/avatar-dummy.png';

// redux
import { fetchBlog } from '../../redux/actions/PageAction';
import {
  resetPageLoader,
  showPageLoader,
} from '../../redux/actions/CommonAction';

// app constants
import { parseString, formatDate } from '../../common/AppConstants';
// import BlogSlider from '../../common/components/Sliders/BlogSlider';
import BlogVerticalCard from '../../common/components/Cards/Blog/BlogVerticalCard';
// import StyledButton from '../../common/components/StyledButton/Index';
import BlogVerticalSlider from '../../common/components/Sliders/BlogVerticalSlider';
import BlogSwiperSlider from '../../common/components/Sliders/BlogSwiperSlider';
import BannerBlogs from '../../common/components/Banners/BannerBlogs';
import StyledButton from '../../common/components/StyledButton/Index';

const Blog = () => {
  const [body, setBody] = useState({});

  // Redux
  const dispatch = useDispatch();
  const { blog_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (blog_contents == null) {
      dispatch(fetchBlog({}));
      dispatch(showPageLoader());
    }
  }, [blog_contents, body]);

  useEffect(() => {
    if (blog_contents) {
      setBody(
        blog_contents?.data[0]?.attributes?.page_body_elements?.data
          ? blog_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
      dispatch(resetPageLoader());
    }
  }, [blog_contents]);

  return (
    <>
      {/* NAVBAR SECTION */}
      <Menu key="blogs-menu" />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {blog_contents?.data[0]?.attributes?.page_banners && (
        <BannerBlogs
          content={blog_contents?.data[0]?.attributes?.page_banners}
        />
      )}

      {/* Page Banner */}

      {/* BODY SECTION */}
      {body?.attributes?.page_body_cmp &&
        body?.attributes?.page_body_cmp?.map((object, index) => (
          <div key={`blogs_page_body_${index}`}>
            {/*             
            {object?.internal_identifier == 'article' && (
              <div
                key={`${Date.now()}-art-${index}`}
                className="bg-primary-light-orange  mx-auto p-8 h-90 md:h-96 flex flex-col items-center mt-20 xl:mt-28 relative"
              >
                <div className="absolute top-0 right-0">
                  <img src={blog_bg_light} />
                </div>

                <div className="container mx-auto w-full align-start p-4">
                  <BreadCrumb items={bc_blog} />
                </div>

                <div>
                  {object?.marketing_cmp_heading && (
                    <h1 className="text-2xl md:text-4xl font-bold lg:mb-4 text-primary text-center ">
                      {parseString(object?.marketing_cmp_heading)}
                    </h1>
                  )}
                  {object?.marketing_cmp_description && (
                    <div className="text-center text-base md:text-lg">
                      {parseString(object?.marketing_cmp_description)}
                    </div>
                  )}
                </div>
              </div>
            )} */}

            {object?.internal_identifier == 'ceo_title' && (
              <div className="top-banner relative w-full flex flex-col justify-center items-center text-center py-4 lg:py-12 ">
                <div className=" ">
                  <img
                    className="mx-auto max-w-[250px] md:max-w-[550px] w-80 h-80 rounded-full -mt-52"
                    src={
                      object?.leaders_list?.data?.[0]?.attributes?.leader_photo
                        ?.data?.attributes?.url || avatar_dummy
                    }
                  />

                  <div className="text-center mt-0 md:mt-4">
                    {object?.leaders_list?.data?.[0]?.attributes
                      ?.leader_name && (
                      <h4 className="text-black text-lg md:text-xl lg:text-2xl font-bold">
                        {parseString(
                          object?.leaders_list?.data?.[0]?.attributes
                            ?.leader_name
                        )}
                      </h4>
                    )}

                    {object?.leaders_list?.data?.[0]?.attributes
                      ?.leader_position && (
                      <div className="text-base md:text-lg">
                        {parseString(
                          object?.leaders_list?.data?.[0]?.attributes
                            ?.leader_position
                        )}
                      </div>
                    )}

                    {object?.description && (
                      <div className="container  px-10 lg:px-44">
                        <div className="text-primary-BLACK text-xs md:text-base lg:text-xl mt-7 custom-quote relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="inline -mt-4 mr-1"
                          >
                            <path
                              d="M13.875 13.2985H20.25C20.4489 13.2985 20.6397 13.2206 20.7803 13.0821C20.921 12.9435 21 12.7556 21 12.5597V6.64921C21 6.45326 20.921 6.26534 20.7803 6.12679C20.6397 5.98824 20.4489 5.9104 20.25 5.9104H14.625C14.4261 5.9104 14.2353 5.98824 14.0947 6.12679C13.954 6.26534 13.875 6.45326 13.875 6.64921V14.7761C13.875 15.7558 14.2701 16.6954 14.9733 17.3881C15.6766 18.0809 16.6304 18.4701 17.625 18.4701"
                              stroke="#003DAD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3 13.2985H9.375C9.57391 13.2985 9.76468 13.2206 9.90533 13.0821C10.046 12.9435 10.125 12.7556 10.125 12.5597V6.64921C10.125 6.45326 10.046 6.26534 9.90533 6.12679C9.76468 5.98824 9.57391 5.9104 9.375 5.9104H3.75C3.55109 5.9104 3.36032 5.98824 3.21967 6.12679C3.07902 6.26534 3 6.45326 3 6.64921V14.7761C3 15.7558 3.39509 16.6954 4.09835 17.3881C4.80161 18.0809 5.75544 18.4701 6.75 18.4701"
                              stroke="#003DAD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {parseString(object?.description, {
                            class: 'inline',
                          })}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className="inline mt-0 md:-mt-3 ml-1"
                          >
                            <path
                              d="M10.125 13.806H3.75C3.55109 13.806 3.36032 13.7282 3.21967 13.5896C3.07902 13.4511 3 13.2632 3 13.0672V7.15677C3 6.96083 3.07902 6.77291 3.21967 6.63436C3.36032 6.49581 3.55109 6.41797 3.75 6.41797H9.375C9.57391 6.41797 9.76468 6.49581 9.90533 6.63436C10.046 6.77291 10.125 6.96083 10.125 7.15677V15.2836C10.125 16.2634 9.72991 17.2029 9.02665 17.8957C8.32339 18.5885 7.36956 18.9777 6.375 18.9777"
                              stroke="#003DAD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21 13.806H14.625C14.4261 13.806 14.2353 13.7282 14.0947 13.5896C13.954 13.4511 13.875 13.2632 13.875 13.0672V7.15677C13.875 6.96083 13.954 6.77291 14.0947 6.63436C14.2353 6.49581 14.4261 6.41797 14.625 6.41797H20.25C20.4489 6.41797 20.6397 6.49581 20.7803 6.63436C20.921 6.77291 21 6.96083 21 7.15677V15.2836C21 16.2634 20.6049 17.2029 19.9017 17.8957C19.1984 18.5885 18.2446 18.9777 17.25 18.9777"
                              stroke="#003DAD"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {object?.internal_identifier == 'from_our_management' && (
              <div className="container mx-auto  mt-2 lg:mt-8 px-5 md:px-10  pb-0 lg:pb-16 ">
                <div className="w-full">
                  {object?.blog_cmp_heading && (
                    <h3 className="text-primary-BLACK text-base md:text-xl lg:text-2xl ml-0 font-bold text-left  md:mb-8 flex items-center">
                      {parseString(object?.blog_cmp_heading, {
                        class: 'inline',
                      })}
                    </h3>
                  )}
                </div>
                {/* <BlogSlider slides={object} /> */}
                <BlogSwiperSlider slides={object} />
              </div>
            )}

            {object?.internal_identifier == 'popular_reads' && (
              <div className="container mx-auto  mt-5 md:mt-10 lg:mt-8 px-5 md:px-10  pb-5 lg:pb-16 ">
                <div className="w-full mt-0 lg:mt-5">
                  {object?.blog_cmp_heading && (
                    <h3 className="text-primary-BLACK text-base md:text-xl lg:text-2xl sm:ml-0 md:ml-0 lg:ml-0 font-bold text-left mb-4 md:mb-8 flex items-center">
                      {parseString(object?.blog_cmp_heading, {
                        class: 'inline',
                      })}
                    </h3>
                  )}
                </div>

                {object?.blogs?.data.length == 0 &&
                object?.featured_blog?.data == null ? (
                  <div className="flex items-center justify-center min-h-[80px] md:min-h-[150px]">
                    <div className="text-xl font-bold text-primary">
                      {parseString(object?.blog_cmp_description)}
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-12  gap-4">
                    <div className="col-span-12 lg:col-span-7">
                      {object?.featured_blog?.data?.attributes && (
                        <div className=" bg-white  mb-0 md:mb-4  card-shadow-custom   rounded-xl  lg:rounded-lg ">
                          <div className="flex flex-col h-full ">
                            {object?.featured_blog?.data?.attributes?.blog_image
                              ?.data?.attributes?.url && (
                              <img
                                className="w-full h-96 object-cover rounded-t-lg mb-4"
                                src={
                                  object?.featured_blog?.data?.attributes
                                    ?.blog_image?.data?.attributes?.url
                                }
                                alt={
                                  object?.featured_blog?.data?.attributes
                                    ?.blog_image?.data?.attributes
                                    ?.alternativeText
                                }
                              />
                            )}

                            <div className="text-left p-3 ">
                              {object?.featured_blog?.data?.attributes
                                ?.blog_title && (
                                <h2 className=" text-lg lg:text-3xl font-normal line-clamp-custom">
                                  {parseString(
                                    object?.featured_blog?.data?.attributes
                                      ?.blog_title
                                  )}
                                </h2>
                              )}

                              {object?.featured_blog?.data?.attributes
                                ?.blog_description && (
                                <div className="pt-3 text-left text-sm lg:text-lg line-clamp-custom1">
                                  {parseString(
                                    object?.featured_blog?.data?.attributes
                                      ?.blog_description
                                  )}
                                </div>
                              )}

                              {object?.featured_blog?.data?.attributes
                                ?.blog_author && (
                                <div className="pt-3 text-neutral-BLACK-DARKER  text-sm md:text-base font-bold">
                                  {parseString(
                                    object?.featured_blog?.data?.attributes
                                      ?.blog_author
                                  )}
                                </div>
                              )}

                              <div className="bg-transparent pt-3 flex flex-wrap items-center justify-start">
                                {object?.featured_blog?.data?.attributes
                                  ?.blog_categories?.data?.[0]?.attributes
                                  ?.blog_category_name && (
                                  <button className="blog-btn-color border border-custom-color text-black-500 signin-btn-hover text-xxs rounded-full px-2 py-1 mr-1">
                                    {
                                      object?.featured_blog?.data?.attributes
                                        ?.blog_categories?.data?.[0]?.attributes
                                        ?.blog_category_name
                                    }
                                  </button>
                                )}

                                {object?.featured_blog?.data?.attributes
                                  ?.blog_date && (
                                  <span className="ml-1 text-xxs text-BLACK">
                                    {formatDate(
                                      object?.featured_blog?.data?.attributes
                                        ?.blog_date
                                    )}
                                  </span>
                                )}
                                {object?.featured_blog?.data?.attributes
                                  ?.blog_read_time &&
                                  object?.featured_blog?.data?.attributes
                                    ?.time && (
                                    <p className="ml-5 text-xxs  text-BLACK relative">
                                      <span className="before-dot text-xxs"></span>
                                      {
                                        object?.featured_blog?.data?.attributes
                                          ?.blog_read_time
                                      }{' '}
                                      {
                                        object?.featured_blog?.data?.attributes
                                          ?.time
                                      }{' '}
                                      read
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-span-12 lg:col-span-5">
                      {object?.blogs?.data && (
                        <BlogVerticalSlider contents={object?.blogs?.data} />
                      )}
                    </div>
                  </div>
                )}

                {object?.blog_cta?.data && (
                  <div className="text-center mt-4 md:mt-10 lg:mt-12 lg:text-left flex gap-6 justify-center">
                    {/* <a
                      href="/blogs/popular-reads"
                      className=" flex flex-row items-center justify-center border border-custom-color text-BLACK rounded-full font-bold text-sm md:text-base lg:text-lg leading-6 px-4 md:px-8 py-3 bg-white "
                    >
                      View All
                      <img src={icons_3} className="w-4-important" />
                    </a> */}
                    <StyledButton
                      cta={object?.blog_cta}
                      // url="/blogs/popular-reads"
                    />
                  </div>
                )}
              </div>
            )}

            {object?.internal_identifier == 'pension_perspective' && (
              <div className="bg-primary-light-orange">
                <div className="container mx-auto mt-4 md:mt-8 px-5 md:px-10  pb-10 lg:pb-20">
                  <div className="mx-auto pt-5 md:pt-10 lg:pt-20 py-8 lg:py-14 text-center ">
                    {object?.blog_cmp_heading && (
                      <h1 className="text-xl md:text-4xl md:!leading-0 font-bold black-h-text lg:mb-4 md:mb-0 flex items-center justify-center">
                        {parseString(object?.blog_cmp_heading)}
                      </h1>
                    )}
                    {object?.blog_cmp_description && (
                      <div className='class="  text-sm  md:text-base lg:text-lg text-center text-gray-600 mb-0 md:mb-4 px-2 md:px-10 mt-2 font-normal"'>
                        {parseString(object?.blog_cmp_description)}
                      </div>
                    )}
                  </div>

                  <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 md:gap-5">
                    {object?.blogs?.data &&
                      object?.blogs?.data.map((item, idx) => (
                        <BlogVerticalCard
                          content={item}
                          key={`${Date.now()}-blg-crd-${idx}`}
                        />
                      ))}
                  </div>

                  {object?.blog_cta?.data && (
                    <div className="text-center mt-4 md:mt-10 lg:mt-12 lg:text-left flex justify-center">
                      {/* <a href="/blogs/pension-perspective">
                        <button className=" flex flex-row items-center justify-center border border-custom-color text-white rounded-full font-bold text-sm md:text-base lg:text-lg leading-6 px-4 md:px-8 py-3 bg-primary  ">
                          View All{' '}
                          <img src={icons_4} className="w-4-important" />
                        </button>
                      </a> */}
                      <StyledButton
                        cta={object?.blog_cta}
                        // url="/blogs/pension-perspective"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {object?.internal_identifier == 'sales_and_success_stories' && (
              <div className="container mx-auto">
                <ContenSliderCopy contents={object} />
              </div>
            )}
          </div>
        ))}
      {/* BODY SECTION */}

      {/* FOOTER BANNER */}
      {body?.attributes?.footer_banner && (
        <FooterBanner contents={body?.attributes?.footer_banner} />
      )}
      {/* FOOTER BANNER */}

      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default Blog;
