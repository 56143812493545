import { initialState } from '../initialStates';

const PageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HOME':
      return {
        ...state,
        home_contents: action.payload,
      };
    case 'ABOUTNPS':
      return {
        ...state,
        aboutnps_contents: action.payload,
      };

    case 'FAQ':
      return {
        ...state,
        faq_contents: action.payload,
      };
    case 'NPSRETAIL':
      return {
        ...state,
        nps_retail_contents: action.payload,
      };
    case 'NPSCORP':
      return {
        ...state,
        nps_corp_contents: action.payload,
      };
    case 'NPSGOV':
      return {
        ...state,
        nps_gov_contents: action.payload,
      };
    case 'SITEMAP':
      return {
        ...state,
        sitemap_contents: action.payload,
      };
    case 'ABOUTUS':
      return {
        ...state,
        aboutus_contents: action.payload,
      };
    case 'BLOG':
      return {
        ...state,
        blog_contents: action.payload,
      };
    case 'BLOG_LIST':
      return {
        ...state,
        blog_list_contents: action.payload,
      };
    case 'BLOG_DATA':
      return {
        ...state,
        blog_data_contents: action.payload,
      };
    case 'ABOUTNPSTABLE':
      return {
        ...state,
        aboutnps_table: action.payload,
      };
    case 'HEADER_FOOTER_DATA':
      return {
        ...state,
        header_footer_contents: action.payload,
      };
    case 'CALCULATOR_PAGE':
      return {
        ...state,
        calculator_contents: action.payload,
      };
    case 'CALCULATOR_DATA':
      return {
        ...state,
        calculator_data_contents: action.payload,
      };
    case 'PUBLICDISC_DATA':
      return {
        ...state,
        pubdisc_content: action.payload,
      };
    case 'NAV_LATEST_DATA':
      return {
        ...state,
        latest_nav_contents: action.payload,
      };
    case 'NAV_DATA':
      return {
        ...state,
        nav_contents: action.payload,
      };
    case 'PROXY_VOTING_DATA':
      return {
        ...state,
        proxy_voting_contents: action.payload,
      };
    case 'HALF_YEAR_DATA':
      return {
        ...state,
        half_year_contents: action.payload,
      };
    case 'HOME_CALCULATOR':
      return {
        ...state,
        home_calculator: action.payload,
      };
    case 'PRIVACY':
      return {
        ...state,
        privacy_contents: action.payload,
      };
    case 'DFILE':
      return {
        ...state,
        comm_files: action.payload,
      };
    case 'POLICYFILE':
      return {
        ...state,
        policy_files: action.payload,
      };
    case 'CMPSTEPOL':
      return {
        ...state,
        policy_comp_files: action.payload,
      };
    case 'RSKMFLE':
      return {
        ...state,
        risk_meter_files: action.payload,
      };
    case 'RGCFLE':
      return {
        ...state,
        regular_circulars_files: action.payload,
      };
    case 'GRPCMFLE':
      return {
        ...state,
        group_company_files: action.payload,
      };
    case 'ANRPTFLE':
      return {
        ...state,
        ann_report_company_files: action.payload,
      };
    case 'ANSCHFLE':
      return {
        ...state,
        ann_report_scheme_files: action.payload,
      };
    case 'ANRTNOTFLE':
      return {
        ...state,
        ann_return_other_files: action.payload,
      };
    case 'LTNVFLE':
      return {
        ...state,
        latest_nav_files: action.payload,
      };
    case 'PTFLFLO':
      return {
        ...state,
        portfolio_files: action.payload,
      };
    case 'AGMF':
      return {
        ...state,
        fragm_files: action.payload,
      };
    case 'AGMFO':
      return {
        ...state,
        fragm_other_files: action.payload,
      };
    case 'PUBPTF':
      return {
        ...state,
        pd_portfolio_files: action.payload,
      };
    default:
      return state;
  }
};

export default PageReducer;
