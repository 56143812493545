import { combineReducers } from "redux";

import CommonReducer from "../reducers/CommonReducer";
import PageReducer from "../reducers/PageReducer";

const rootReducer = combineReducers({
    commonState: CommonReducer,
    pageState:PageReducer
});

export default rootReducer;