import React, { useState, useEffect } from 'react';

// import MapPinLine from '../../assets/images/mappinline.svg';

// import apple_store from '../../assets/images/apple-store.png';
// import google_play from '../../assets/images/google-play.png';
// import qr_code_footer from '../../assets/images/qr_code.svg';

// constants
import { IsEmpty, getFileExt, getFilenameUrl, parseString } from '../AppConstants';
import { useSelector } from 'react-redux';
import FooterDaForms from './FooterDaForms';

const Footer = () => {
  const [footerContents, setFooterContents] = useState({});
  const { header_footer_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (header_footer_contents) {
      setFooterContents(
        header_footer_contents ? header_footer_contents?.footerdata : {}
      );
    }
  }, [header_footer_contents]);

  const handleFileClick = (item) => {
    if (item) {

      let fileName = getFilenameUrl(item?.attributes?.redirect_to) || '';
      let ext = getFileExt(item?.attributes?.redirect_to) || 'pdf';
      fileName = `${fileName}.${ext}`;
      fileName = IsEmpty(fileName) ? `${Date.now()}.${ext}` : fileName;

      fetch(item?.attributes?.redirect_to)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName || 'downloaded-file';
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error fetching the file:', error);
        });
    }
  };

  return (
    <footer className="bg-gray-800 px-10 pt-16 pb-10 mt-auto">
      <div className="container mx-auto grid grid-cols-2  md:grid-cols-4 lg:grid-cols-6 gap-4">
        {/* <!-- Column 1 Address --> */}
        {footerContents?.data?.attributes && (
          <div className="text-white mb-4 lg:mb-0 hidden lg:block">
            <div className="flex flex-row items-center pb-3  ">
              {footerContents?.data?.attributes?.reg_addr_icon?.data?.attributes
                ?.media?.data?.attributes?.url && (
                  <img
                    className="pr-2 w-10 h-10"
                    src={
                      footerContents?.data?.attributes?.reg_addr_icon?.data
                        ?.attributes?.media?.data?.attributes?.url
                    }
                    alt={
                      footerContents?.data?.attributes?.reg_addr_icon?.data
                        ?.attributes?.media?.data?.attributes?.alternative_text
                    }
                  />
                )}
              {footerContents?.data?.attributes?.reg_addr_heading && (
                <span className="text-white text-xl font-bold">
                  {footerContents?.data?.attributes?.reg_addr_heading}
                </span>
              )}
            </div>
            {footerContents?.data?.attributes?.reg_addr && (
              <div className="text-white font-medium text-lg">
                {parseString(footerContents?.data?.attributes?.reg_addr)}
              </div>
            )}

            {footerContents?.data?.attributes?.social_media_icons && (
              <div className="flex flex-row items-center justify-start pt-5">
                {footerContents?.data?.attributes?.social_media_icons?.data?.map(
                  (item, index) => (
                    <a
                      key={`social_media_icons_${index}`}
                      target="blank"
                      href={
                        item?.attributes?.ctas?.data?.[0]?.attributes
                          ?.cta_external_link
                      }
                      className="cursor-pointer"
                    >
                      <img
                        className="pr-2  h-10"
                        src={item?.attributes?.media?.data?.attributes?.url}
                        alt={
                          item?.attributes?.media?.data?.attributes
                            ?.alternative_text
                        }
                      />
                    </a>
                  )
                )}
              </div>
            )}
          </div>
        )}

        {/* Column 2,3,4,5 */}
        {footerContents?.data?.attributes?.footer_link_groups?.data?.length &&
          footerContents?.data?.attributes?.footer_link_groups?.data?.map(
            (item, index) => (
              <div
                className="text-white mb-4 lg:mb-0 p-4 py-0"
                key={`footer_link_groups_${index}`}
              >
                {item?.attributes?.link_heading && (
                  <h2 className="text-white text-lg font-semibold mb-4">
                    {parseString(item?.attributes?.link_heading)}
                  </h2>
                )}

                {item?.attributes?.link_set?.data &&
                  item?.attributes?.link_set?.data?.map((link, idx) => (
                    <div
                      className="px-0 py-2 font-normal	 text-base"
                      key={`footer_link_${idx}`}
                    >
                      {link?.attributes?.link_mode == 'Download' ? (
                        <a
                          className="text-neutral-GRAY-100 hover:text-white font-normal text-base cursor-pointer"
                          onClick={() => handleFileClick(link)}
                        >
                          {parseString(link?.attributes?.link_text, { class: 'hover:text-white' })}
                        </a>
                      ) : (
                        <a
                          className="text-neutral-GRAY-100 hover:text-white font-normal text-base cursor-pointer"
                          href={
                            link?.attributes?.url ||
                            link?.attributes?.redirect_to
                          }
                          target={
                            link?.attributes?.open_in_new_window ? 'blank' : ''
                          }
                          rel="noreferrer"
                        >
                          {parseString(link?.attributes?.link_text, { class: 'hover:text-white' })}
                        </a>
                      )}
                    </div>
                  ))}

                {
                  item?.attributes?.footer_inner_titles?.data &&
                  < FooterDaForms contents={item?.attributes?.footer_inner_titles?.data} />
                }


              </div>)
          )}
      </div>

      {footerContents?.data?.attributes && (
        <div className="container mx-auto grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="text-white mb-4 lg:mb-0 lg:hidden">
            <div className="flex flex-row pb-3 items-center  ">
              {footerContents?.data?.attributes?.reg_addr_icon?.data?.attributes
                ?.media?.data?.attributes?.url && (
                  <img
                    className="pr-2 w-10 h-10"
                    src={
                      footerContents?.data?.attributes?.reg_addr_icon?.data
                        ?.attributes?.media?.data?.attributes?.url
                    }
                    alt={
                      footerContents?.data?.attributes?.reg_addr_icon?.data
                        ?.attributes?.media?.data?.attributes?.alternative_text
                    }
                  />
                )}
              {footerContents?.data?.attributes?.reg_addr_heading && (
                <span className="text-white text-xl font-bold">
                  {footerContents?.data?.attributes?.reg_addr_heading}
                </span>
              )}
            </div>
            {footerContents?.data?.attributes?.reg_addr && (
              <div className="text-white">
                {parseString(footerContents?.data?.attributes?.reg_addr)}
              </div>
            )}
            {footerContents?.data?.attributes?.social_media_icons && (
              <div className="flex flex-row items-center justify-start pt-5">
                {footerContents?.data?.attributes?.social_media_icons?.data?.map(
                  (item, index) => (
                    <a
                      key={`social_media_icons_bottom_${index}`}
                      target="blank"
                      href={
                        item?.attributes?.ctas?.data?.[0]?.attributes
                          ?.cta_external_link
                      }
                      className="cursor-pointer"
                    >
                      <img
                        className="pr-2 h-10"
                        src={item?.attributes?.media?.data?.attributes?.url}
                        alt={
                          item?.attributes?.media?.data?.attributes
                            ?.alternative_text
                        }
                      />
                    </a>
                  )
                )}
              </div>
            )}
          </div>

          {footerContents?.data?.attributes?.app_icons && (
            <div>
              {footerContents?.data?.attributes?.app_section_heading && (
                <h2 className="text-white text-lg font-semibold mb-4">
                  {parseString(
                    footerContents?.data?.attributes?.app_section_heading
                  )}
                </h2>
              )}

              {footerContents?.data?.attributes?.app_icons?.data && (
                <div className="flex flex-row items-center flex-wrap">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                    {footerContents?.data?.attributes?.app_icons?.data?.map(
                      (icon, index) => (
                        <a key={`app_icon_${index}`} className="cursor-pointer">
                          <img
                            className=""
                            src={icon?.attributes?.media?.data?.attributes?.url}
                            alt={
                              icon?.attributes?.media?.data?.attributes
                                ?.alternative_text
                            }
                          />
                        </a>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {footerContents?.data?.attributes?.footer_copyright_text && (
        <div className="container mx-auto text-center mt-8">
          <div className="text-white max-w-[1200px] mx-auto">
            {parseString(
              footerContents?.data?.attributes?.footer_copyright_text
            )}
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
