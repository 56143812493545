import React from 'react';
import PropTypes from 'prop-types';

// components
import BlogVerticalCard from './Blog/BlogVerticalCard';

// app constants
import { parseString } from '../../AppConstants';
import SecondaryButton from '../StyledButton/SecondaryButton';

const ArticleNewsCopy = ({ contents }) => {
  return (
    <>
      <div className="container main-bg mx-auto md:my-2 py-5 px-5 md:px-0 flex flex-col items-center text-center">
        {contents?.blog_cmp_heading && (
          <h1 className="  font-bold lg:mb-4 md:mb-0 black-h-text  text-xl md:text-2xl lg:text-4xl ">
            {parseString(contents?.blog_cmp_heading)}
          </h1>
        )}
        {contents?.blog_cmp_description && (
          <div className="text-sm md:text-lg mt-2  text-gray-600  lg:mb-2 md:mb-2 font-normal mb-5">
            {parseString(contents?.blog_cmp_description)}
          </div>
        )}
        <div className="container-fluid main-bg    mx-auto md:my-0 lg:my-2 px-0 md:px-8 py-8 flex flex-col items-center text-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5">
            {contents?.blogs?.data &&
              contents?.blogs?.data.map((item, idx) => (
                <BlogVerticalCard
                  key={`${Date.now()}-art-blg-crd-${idx}`}
                  content={item}
                />
              ))}
          </div>
          {contents?.blog_cta && (
            <div className="flex justify-center items-center mt-5">
              <SecondaryButton
                variant="button"
                cta={contents?.blog_cta}
                url="/blogs"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ArticleNewsCopy.propTypes = {
  contents: PropTypes.object || undefined,
};
export default ArticleNewsCopy;
