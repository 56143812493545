import { initialState } from "../initialStates";

const CommonReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'SET_SNACKBAR':
            return {
                ...state,
                msgSnackbar: {
                    ...state.msgSnackbar,
                    open: action.payload.open,
                    severity: action.payload.severity,
                    message: action.payload.message
                }
            };
        case 'RESET_SNACKBAR':
            return {
                ...state,
                msgSnackbar: {
                    ...state.msgSnackbar,
                    open: action.payload
                }
            };
        case 'RESET_DFILE':
            return {
                ...state,
                comm_files: null
            };
        case 'SET_PAGE_LOADER':
        case 'RESET_PAGE_LOADER':
            return {
                ...state,
                pageLoader: action.payload
            };
        case 'HOME':
            return {
                ...state,
                home_contents: action.payload
            };
        default:
            return state;
    }
};

export default CommonReducer;