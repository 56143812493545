import React, { useEffect, useState } from 'react';
import ComplianceStewardshipPolicy from './ComplianceStewardshipPolicy';
import AccordionCopy from '../Accordions/AccordionCopy';

const PolicyAccordion = ({ contents }) => {
  const [dataItem, setDataItem] = useState();

  const getContent = (key, data) => {
    if (key == "compliance_of_stewardship_policy")
      return <ComplianceStewardshipPolicy contents={data} />
  }
  
  useEffect(() => {
    if (contents) {
      let dataset = [];
      contents?.map((object, index) => {
        dataset.push({
          index: index,
          title: object?.tab_text,
          content: getContent(object?.internal_identifier, object?.page_body),
          key: object?.internal_identifier
        });
      });
      setDataItem(dataset);
    }
  }, [])

  return (
    <>
      <AccordionCopy items={dataItem} />
    </>
  );
};

export default PolicyAccordion;





