import React from 'react';
import PropTypes from 'prop-types';

// components
import BlogVerticalCard from '../Cards/Blog/BlogVerticalCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css';

const BlogSwiperSlider = ({ slides }) => {
  return (
    <>
      <div className="relative blogs-slider hidden md:block lg:block xl:block">
        <div
          className={` hidden md:flex navigation-btn-wrapper gradient-custom-left absolute z-10 -left-1 bottom-0 top-0 justify-center items-center`}
        >
          <button className="bss-button-prev swiper-button-prev bg-primary rounded-full !flex !justify-center !items-center !w-12 !h-12 after:hidden">
            <span className="font-material-icons material-symbols-outlined text-white">
              arrow_back_ios
            </span>
          </button>
        </div>

        <div
          className={` hidden md:block navigation-btn-wrapper gradient-custom absolute z-10 -right-1 bottom-0 top-0 justify-center items-center`}
        >
          <button className="bss-button-next swiper-button-next bg-primary !rounded-full !flex !justify-center !items-center !w-12 !h-12 after:hidden">
            <span className="font-material-icons material-symbols-outlined text-white">
              arrow_forward_ios
            </span>
          </button>
        </div>

        <div className="mt-3 md:mt-6 hidden md:block">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            modules={[Navigation]}
            navigation={{
              nextEl: '.bss-button-next',
              prevEl: '.bss-button-prev',
            }}
            // onSlideChange={handleSlideChange}
          >
            {slides?.blogs?.data &&
              slides?.blogs?.data?.map((item, idx) => (
                <SwiperSlide
                  className="!h-auto"
                  key={`${Date.now()}-blg-${idx}`}
                >
                  <BlogVerticalCard content={item} outerClassName="h-full" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>

      <div className="mt-3 md:mt-6 block md:hidden flex-nowrap gap-4 overflow-x-auto relative scrollbar-hide">
        {slides?.blogs?.data &&
          slides?.blogs?.data.map((item, idx) => (
            <div
              key={`${Date.now()}-blg-${idx}`}
              className="w-full  flex-shrink-0"
            >
              <BlogVerticalCard content={item} />
            </div>
          ))}
      </div>
    </>
  );
};

BlogSwiperSlider.propTypes = {
  slides: PropTypes.object || undefined,
};

export default BlogSwiperSlider;
