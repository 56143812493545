import React from "react";
import PropTypes from 'prop-types';


const ButtonPrimary = ({ title = '', url = '', image = '' }) => {
    return (
        <button><a href={url || '#'} className="px-4 py-2 border-custom-color text-sm flex justify-center items-center border text-white font-bold rounded-full bg-primary">
            {title} {image ? <img className="" src={image} /> : null}
        </a></button>
    )
}

ButtonPrimary.propTypes = {
    title: PropTypes.string || undefined,
    url: PropTypes.string || undefined,
    image: PropTypes.string || undefined,
};
export default ButtonPrimary;