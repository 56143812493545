import React from 'react';
import PropTypes from 'prop-types';
import { parseString } from '../../AppConstants';

const SitemapLinkGroup = ({ contents }) => {
  const handleFileClick = (item) => {
    if (item) {
      let fileName = `${Date.now()}.pdf`;
      fetch(item?.attributes?.redirect_to)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName || 'downloaded-file';
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error fetching the file:', error);
        });
    }
  };

  return (
    <div
      key={`${Date.now()}-smc${contents?.id}`}
      className="container px-6 mx-auto grid grid-cols-1 mt-12 mb-28 md:mb-32 lg:mb-36 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-10 lg:gap-6"
    >
      {contents?.link_groups?.data &&
        contents?.link_groups?.data?.map((object, index) => (
          <div key={`${Date.now()}-lg-${index}`} className="lg:mb-0 p-4 py-0">
            {object?.attributes?.link_heading && (
              <h2 className="text-lg md:text-xl font-bold mb-6 ">
                {parseString(object?.attributes?.link_heading)}
              </h2>
            )}

            {object?.attributes?.link_set.data &&
              object?.attributes?.link_set?.data?.map((link, index) => (
                <div
                  key={`${Date.now()}-link-${index}`}
                  className="px-0 py-1.5 font-semibold text-base lg:text-lg "
                >
                  {link?.attributes?.link_mode == 'Download' ? (
                    <a
                      className="text-primitive-BLUE-300 hover:text-primitive-BLUE-300 underline md:no-underline	 hover:underline decoration-1"
                      onClick={() => handleFileClick(link)}
                    >
                      {parseString(link?.attributes?.link_text)}
                    </a>
                  ) : (
                    <a
                      className="text-primitive-BLUE-300 hover:text-primitive-BLUE-300 underline md:no-underline	 hover:underline decoration-1"
                      href={
                        link?.attributes?.url || link?.attributes?.redirect_to
                      }
                    >
                      {parseString(link?.attributes?.link_text)}
                    </a>
                  )}
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};

SitemapLinkGroup.propTypes = {
  contents: PropTypes.object,
};

export default SitemapLinkGroup;
