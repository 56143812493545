import {
  getAboutNpsContent,
  getAboutUsContent,
  getFaqContent,
  getHomeContent,
  getNPSSolutionCorpContent,
  getNPSSolutionGovContent,
  getNPSSolutionRetailContent,
  getSiteMapContent,
  getBlogContent,
  getBlogListContent,
  getAboutNpsTable,
  getHeaderFooter,
  getCalcForward,
  getCalcInvest,
  getPubDisc,
  getNav,
  getProxyVoting,
  getHalfYear,
  getNavHistory,
  getHomeCalculator,
  getPrivacy,
  getPubFiles,
  getLatestNavFiles,
  getFilesAnnRepComp,
  getFilesAnnRepSche,
  getFilesAnnRetOth,
  getFilesGrpCmpLst,
  getFilesPolicy,
  getFilesRegCirc,
  getFilesRiskMete,
  getFilesPortFolio,
  getFilesCompStew,
  getCheck,
  getPortFolio
} from '../apis/CommonApi';

export const fetchHome = (data) => async (dispatch) => {
  try {
    const getResult = await getHomeContent(data);
    // const token_status = getResult?.tokenExpired || getResult.data?.tokenExpired;
    // if (token_status) {
    //     dispatch({ type: 'ACCESS_TOKEN_EXPIRED', payload: token_status });
    // }
    dispatch({ type: 'HOME', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAboutNPS = (data) => async (dispatch) => {
  try {
    const getResult = await getAboutNpsContent(data);
    dispatch({ type: 'ABOUTNPS', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchFaq = (data) => async (dispatch) => {
  try {
    const getResult = await getFaqContent(data);
    dispatch({ type: 'FAQ', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchNPSSolutionRetail = (data) => async (dispatch) => {
  try {
    const getResult = await getNPSSolutionRetailContent(data);
    dispatch({ type: 'NPSRETAIL', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchNPSSolutionCoporate = (data) => async (dispatch) => {
  try {
    const getResult = await getNPSSolutionCorpContent(data);
    dispatch({ type: 'NPSCORP', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchNPSSolutionGov = (data) => async (dispatch) => {
  try {
    const getResult = await getNPSSolutionGovContent(data);
    dispatch({ type: 'NPSGOV', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchSiteMap = (data) => async (dispatch) => {
  try {
    const getResult = await getSiteMapContent(data);
    dispatch({ type: 'SITEMAP', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAboutUs = (data) => async (dispatch) => {
  try {
    const getResult = await getAboutUsContent(data);
    dispatch({ type: 'ABOUTUS', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchBlog = (data) => async (dispatch) => {
  try {
    const getResult = await getBlogContent(data);
    dispatch({ type: 'BLOG', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchBlogList = (data) => async (dispatch) => {
  try {
    const getResult = await getBlogListContent(data);
    dispatch({ type: 'BLOG_LIST', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchBlogData = (data) => async (dispatch) => {
  try {
    const getResult = await getBlogContent(data);
    dispatch({ type: 'BLOG_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAboutNPSTable = (data) => async (dispatch) => {
  try {
    const getResult = await getAboutNpsTable(data);
    dispatch({ type: 'ABOUTNPSTABLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchHeaderFooterData = (data) => async (dispatch) => {
  try {
    const getResult = await getHeaderFooter(data);
    dispatch({ type: 'HEADER_FOOTER_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};


export const fetchCalcInvestData = (data) => async (dispatch) => {
  try {
    const getResult = await getCalcInvest(data);
    dispatch({ type: 'CALCULATOR_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchCalcForwardData = (data) => async (dispatch) => {
  try {
    const getResult = await getCalcForward(data);
    dispatch({ type: 'CALCULATOR_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchPubDiscData = (data) => async (dispatch) => {
  try {
    const getResult = await getPubDisc(data);
    dispatch({ type: 'PUBLICDISC_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchNavLatest = (data) => async (dispatch) => {
  try {
    const getResult = await getNav(data);
    dispatch({ type: 'NAV_LATEST_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchNavHistory = (data) => async (dispatch) => {
  try {
    const getResult = await getNavHistory(data);
    dispatch({ type: 'NAV_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchProxyVoting = (data) => async (dispatch) => {
  try {
    const getResult = await getProxyVoting(data);
    dispatch({ type: 'PROXY_VOTING_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchHalfYear = (data) => async (dispatch) => {
  try {
    const getResult = await getHalfYear(data);
    dispatch({ type: 'HALF_YEAR_DATA', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchHomeCalulator = (data) => async (dispatch) => {
  try {
    const getResult = await getHomeCalculator(data);
    dispatch({ type: 'HOME_CALCULATOR', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchPrivacy = (data) => async (dispatch) => {
  try {
    const getResult = await getPrivacy(data);
    dispatch({ type: 'PRIVACY', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchFile = (data) => async (dispatch) => {
  try {
    const getResult = await getPubFiles(data);
    dispatch({ type: 'DFILE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchPolicyFile = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesPolicy(data);
    dispatch({ type: 'POLICYFILE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchPolicyFileComp = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesCompStew(data);
    dispatch({ type: 'CMPSTEPOL', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchRiskMeterFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesRiskMete(data);
    dispatch({ type: 'RSKMFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchRegularCircularsFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesRegCirc(data);
    dispatch({ type: 'RGCFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchGroupCompanyFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesGrpCmpLst(data);
    dispatch({ type: 'GRPCMFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAnnualReportComFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesAnnRepComp(data);
    dispatch({ type: 'ANRPTFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAnnualReportSchFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesAnnRepSche(data);
    dispatch({ type: 'ANSCHFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAnnualReturnOthFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesAnnRetOth(data);
    dispatch({ type: 'ANRTNOTFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchLatestNavFile = (data) => async (dispatch) => {
  try {
    const getResult = await getLatestNavFiles(data);
    dispatch({ type: 'LTNVFLE', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchPortFolioFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getFilesPortFolio(data);
    dispatch({ type: 'PTFLFLO', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAGMFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getCheck(data);
    dispatch({ type: 'AGMF', payload: getResult.data });
  } catch (error) {
    return error;
  }
};

export const fetchAGMOFiles = (data) => async (dispatch) => {
  try {
    const getResult = await getCheck(data);
    dispatch({ type: 'AGMFO', payload: getResult.data });
  } catch (error) {
    return error;
  }
};


export const fetchPortfolio = (data) => async (dispatch) => {
  try {
    const getResult = await getPortFolio(data);
    dispatch({ type: 'PUBPTF', payload: getResult.data });
  } catch (error) {
    return error;
  }
};