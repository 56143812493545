import React from 'react';

// import logo from '../assets/images/icici-logo.svg';
// import search from '../assets/images/icons.svg';
import emoji from '../assets/images/emoji.svg';
import img4 from '../assets/images/404.svg';
import Menu from '../common/layout/Menu';
// import MobMenu from "../common/layout/MobMenu";

const NotFound = () => {
  return (
    <>
      <div className="bg-secondary">
        <Menu />

        <div className="container mt-36 mx-auto my-8 p-8 flex flex-col items-center">
          <div className="w-full lg:w-1/2  p-4 flex justify-center">
            <img src={img4} />
          </div>
          <h1 className="text-4xl font-bold mb-3 black-h-text text-center ">
            <span className="text-primary">OOPSY...</span>
            Something went wrong{' '}
          </h1>
          <p className="text-lg mt-0 text-center">
            We suggest you go to Home Page while we are fixing the problem{' '}
            <img className="pl-0.5 inline" src={emoji} />{' '}
          </p>
          <a className="no-underline" href="/">
            <button className="border border-custom-color text-white rounded-full px-6 py-3 mt-6 bg-primary no-underline">
              Back to Home Page
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
