import React from 'react';
import PropTypes from 'prop-types';
import { parseString, formatDate, openInTab } from '../../../AppConstants';

import blog_thumb_img from '../../../../assets/images/blog-thumb-1.png';

const BlogHorizontalCard = ({ content }) => {
  return (
    <div className="lg:mb-4 bg-white h-full card-shadow-custom rounded-xl lg:rounded-lg ">
      <div className="flex p-3">
        <img
          onClick={
            content?.attributes?.blog_url
              ? () => openInTab(content?.attributes?.blog_url)
              : null
          }
          className="w-28 h-24 lg:w-1/2 lg:h-70 object-cover rounded cursor-pointer"
          src={
            content?.attributes?.blog_image?.data?.attributes?.url
              ? content?.attributes?.blog_image?.data?.attributes?.url
              : blog_thumb_img
          }
          alt={
            content?.attributes?.blog_image?.data?.attributes?.alternativeText
              ? content?.attributes?.blog_image?.data?.attributes
                  ?.alternativeText
              : ''
          }
        />

        <div className=" w-full lg:w-1/2 text-left pl-3">
          {content?.attributes?.blog_title && (
            <div className="text-xs md:text-sm text-left  line-clamp-custom">
              {parseString(content?.attributes?.blog_title)}
            </div>
          )}

          {content?.attributes?.blog_author && (
            <div className="text-neutral-BLACK-DARKER pt-2  text-xs md:text-sm font-bold">
              {parseString(content?.attributes?.blog_author)}
            </div>
          )}

          <div className="bg-transparent  pt-2 flex flex-wrap justify-start items-center">
            {content?.attributes?.blog_types?.data?.[0]?.attributes
              ?.blog_type_name && (
              <button className="blog-btn-color border border-custom-color text-black-500 signin-btn-hover text-xxs rounded-full px-2 py-1">
                {
                  content?.attributes?.blog_types?.data?.[0]?.attributes
                    ?.blog_type_name
                }
              </button>
            )}

            {content?.attributes?.blog_date && (
              <span className="ml-1 mr-2 text-xxs text-BLACK">
                {formatDate(content?.attributes?.blog_date)}
              </span>
            )}

            {content?.attributes?.blog_read_time &&
              content?.attributes?.time && (
                <p className="ml-3 text-xxs text-BLACK relative">
                  <span className="before-dot text-xxs"></span>{' '}
                  {content?.attributes?.blog_read_time}{' '}
                  {content?.attributes?.time} read
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

BlogHorizontalCard.propTypes = {
  content: PropTypes.object || undefined,
};

export default BlogHorizontalCard;
