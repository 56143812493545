import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import timeline_dots from '../../assets/images/timelinedots.svg';

import CardCopy from "../../common/components/Cards/CardCopy";
import StyledButton from "../../common/components/StyledButton/Index";
import { getCardType } from "../../common/AppConstants";


const TabStepperComponent = ({ contents }) => {
    const [stepperItem, setStepper] = useState([]);
    useEffect(() => {
        if (contents?.marketing_units_groups?.data[0]?.attributes?.marketing_units) {
            setStepper(contents?.marketing_units_groups?.data[0]?.attributes?.marketing_units);
        }
    }, [contents])

    return (
        stepperItem &&
        <>
            {/* Mobile View */}
            <div className="block lg:hidden">
                <section className="items-center font-poppins">
                    <div className="justify-center max-w-6xl  py-4 mx-auto lg:py-8 md:px-6">
                        <div className="w-full mx-auto lg:max-w-3xl">
                            {
                                stepperItem?.map((object, index) => (
                                    <div key={`29-nstrs${index}`} className="relative flex justify-start">
                                        <div className="flex flex-col items-center w-10 mr-4 md:w-24">
                                            <div className="flex items-end justify-center  pl-3">
                                                <div
                                                    className="flex items-center justify-center w-8 h-8 bg-light-orange rounded-full dark:bg-gray-600">
                                                    <div className="w-4 h-4 bg-orange rounded-full dark:bg-orange-400"></div>
                                                </div>
                                                <span className="text-primary lg:bottom-20 text-xl md:text-2xl lg:font-52">{index + 1}</span>
                                            </div>
                                            <div className="w-1 h-full bg-blue-500 dark:bg-blue-600"></div>
                                        </div>

                                        <CardCopy key={`164-nstrs${index}`}
                                            content={object}
                                            cardType={getCardType(contents?.marketing_units_groups) || 'plain'}
                                            extraBoxClass="ml-30 mt-4 md:mt-10" />
                                    </div>
                                ))
                            }
                            {
                                contents?.ctas?.data?.length ?
                                    <div className="flex flex-row items-start justify-center  mt-10 ">
                                        <StyledButton cta={contents?.ctas} />
                                    </div> : null
                            }
                        </div>
                    </div>
                </section>
            </div>
            {/* Mobile View */}

            <div className="hidden lg:block mt-20">
                <ol className="items-center relative justify-center sm:flex mx-36">
                    {
                        stepperItem?.map((object, index) => (
                            <React.Fragment key={`tsc-${index}`}>
                                {index < stepperItem?.length && (
                                    <li key={object?.id} className="relative mb-6 sm:mb-0">
                                        <div className="flex items-center">
                                            <div className="z-10 flex items-center justify-center   rounded-full       shrink-0">
                                                <div className=" text-primary font-bold  absolute  bottom-8 lg:bottom-20 text-xl md:text-2xl font-52">{index + 1}</div>
                                                <img src={timeline_dots} className=" w-10 lg:w-14 " alt="Image" />
                                            </div>
                                            {
                                                (index + 1) != stepperItem?.length && (
                                                    <div className="hidden sm:flex w-52 bg-blue-500 h-2 dark:bg-blue-700"></div>
                                                )
                                            }
                                        </div>
                                    </li>)
                                }
                            </React.Fragment>
                        ))
                    }
                </ol>

                <div className="grid grid-cols-1 mx-5 md:mx-0 md:grid-cols-4  mt-5  gap-4">
                    {
                        stepperItem?.map((object, index) => (
                            <CardCopy key={`164-nstrs${index}`}
                                content={object}
                                cardType={getCardType(contents?.marketing_units_groups) || 'plain'} />
                        ))
                    }
                </div>
                {
                    contents?.ctas?.data?.length ?
                        <div className="flex flex-row items-start justify-center  mt-10 ">
                            <StyledButton cta={contents?.ctas} />
                        </div> : null
                }
            </div>
        </>)
}

TabStepperComponent.propTypes = {
    contents: PropTypes.object,
    cta: PropTypes.object
};
export default TabStepperComponent;