import React, { useEffect } from "react";
import DownloadCardDisclosureSecond from "../Downloaders/DownloadCardDisclosureSecond";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnnualReturnOthFiles } from "../../../redux/actions/PageAction";

const AnnualReturnsOthers = ({ contents }) => {
    // Redux
    const dispatch = useDispatch();
    const { ann_return_other_files } = useSelector((state) => state.pageState);

    useEffect(() => {
        dispatch(fetchAnnualReturnOthFiles('return-and-others'));
    }, [contents])

    return (
        <div className='px-[8px] py-[8px] bg-primary-LIGHT '>
            <div className="bg-primary-LIGHT grid grid-cols-1   
            sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3  md:gap-3 max-h-72 overflow-y-scroll">
                {
                    ann_return_other_files?.data?.data?.map((obj, index) => (
                        <DownloadCardDisclosureSecond key={`dlc-${index}`}
                            heading={obj?.attributes?.file_display_name}
                            contents={obj} />
                    ))
                }
            </div>
        </div>
    )
}

export default AnnualReturnsOthers;