import React, { useEffect, useState } from 'react';
import DisclosureCharts from '../Charts/DisclosureCharts';

import { useDispatch, useSelector } from 'react-redux';
import { fetchNavHistory } from '../../../redux/actions/PageAction';
import DisclosureDrop from '../DropDown/DisclosureDropdown';
import { IsEmpty, dateCustom, toSpecialDate, toUpper } from '../../AppConstants';
import CalenderDisclosure from '../Calendar/CalenderDisclosure';
import DatePickerView from '../Calendar/DatePickerView';

const NavHistoryDisclosure = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { nav_contents } = useSelector((state) => state.pageState);

  const [graph, setGraph] = useState();
  const [scheme_filter, setSchemeFilter] = useState();
  const [timefilter, setTimeFilter] = useState();
  const [timescale, setTimeScale] = useState();
  const [apiParam, setAPIParam] = useState('');

  const [scheme_filter_select, setScheme_filter_select] = useState();
  const [timefilter_select, setTimefilter] = useState();
  const [timescale_select, setTimescale] = useState();
  const [fromdate_select, setFromDate] = useState();
  const [todate_select, setToDate] = useState();

  const [pointData, setPointData] = useState([]);

  const [nav_year, setNavYear] = useState('');
  const [nav_date_cap, setNavCaption] = useState('');
  const [nav_value, setNavValue] = useState('');


  useEffect(() => {
    if (contents) {
      let ds = contents?.page_body?.data?.attributes?.page_body_cmp[0];
      if (ds) {
        let scheme_filter = ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.scheme_filter
        let timefilter = ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.timefilter;
        let timescale = ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.timescale;

        setGraph(ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.graph);
        setSchemeFilter(scheme_filter);
        setTimeFilter(timefilter);
        setTimeScale(timescale);

        setToDate(new Date());
        var currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        setFromDate(currentDate);


      }
    }
  }, [])

  useEffect(() => {
    let param = '';
    if (timefilter_select?.key == 'timehorizon') {
      if (timescale_select?.key) {
        var currentDate = new Date();
        if (timescale_select?.key == '1month') {
          currentDate.setMonth(currentDate.getMonth() - 1);
        }
        if (timescale_select?.key == '3months') {
          currentDate.setMonth(currentDate.getMonth() - 3);
        }
        if (timescale_select?.key == '6months') {
          currentDate.setMonth(currentDate.getMonth() - 6);
        }
        if (timescale_select?.key == '1year') {
          currentDate.setMonth(currentDate.getMonth() - 12);
        }
        let start = dateCustom(currentDate);
        let end = dateCustom(new Date());

        param = `${start}/${end}`;
      }
    }
    else if (timefilter_select?.key == 'custom') {
      let start = dateCustom(fromdate_select);
      let end = dateCustom(todate_select);

      param = `${start}/${end}`;
    }

    if (scheme_filter_select?.key) {
      if (IsEmpty(param))
        param = scheme_filter_select?.key;
      else
        param = `${param}/${scheme_filter_select?.key}`;
    }

    if (param)
      setAPIParam(param);

  }, [scheme_filter_select, timescale_select, fromdate_select, todate_select])

  useEffect(() => {
    if (apiParam) {
      dispatch(fetchNavHistory(apiParam));
    }
  }, [apiParam])

  const toLabelShort = (value) => {
    let date = new Date(value);
    let formatted = '';
    if (date) {
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      // let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      formatted = `${toUpper(month)} ${year.slice(-2)} `;
    }
    return formatted;
  };

  const toLabel = (value) => {
    let date = new Date(value);
    let formatted = '';
    if (date) {
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      formatted = `${day} ${toUpper(month)} ${year.slice(-2)} `;
    }
    return formatted;
  };

  useEffect(() => {
    if (nav_contents) {
      let param = scheme_filter_select?.key;
      const chartData = [];

      if (nav_contents?.data?.data) {
        nav_contents?.data?.data?.map((obj) => {
          let chartVal = '';
          let chartDate = '';

          Object.entries(obj?.attributes).forEach(([key, value]) => {
            if (key == param) {
              chartVal = value;
            }
            if (key == 'date') {
              chartDate = value;
            }
          });
          chartData.push({
            xLabel: toLabel(chartDate),
            xLabelShort: toLabelShort(chartDate),
            date: chartDate,
            value: chartVal
          });
        });
        let years_start = chartData[0]?.date;
        let years_end = chartData[chartData?.length - 1]?.date;
        let years = `${years_start?.split("-")[0]}-${years_end?.split("-")[0]}`
        setNavYear(years);
        setNavValue(nav_contents?.latest_data?.final_value || '--');
        setNavCaption(toSpecialDate(nav_contents?.latest_data?.date));
      }

      setPointData(chartData);
    }
  }, [nav_contents])

  return (
    <div className='p-[18px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>

      <div className="flex items-center md:flex ">
        <DisclosureDrop
          selected={scheme_filter_select}
          setSelection={setScheme_filter_select}
          options={scheme_filter}
          title={graph?.scheme_filter_title} titleAppend={true} />
        <span className="px-3">&</span>
        <DisclosureDrop
          selected={timefilter_select}
          setSelection={setTimefilter}
          options={timefilter}
          title={graph?.nav_filter_title} titleAppend={true} />
      </div>

      <div className="border border-gray-300 p-[24px] mt-5 rounded-[12px] min-h-80">
        <div className="flex items-center justify-between flex-row ">
          <div>
            <h4 className="font-normal text-[12px] md:text-[16px] lg:text-[18px] text-primary-DARK ">NAV History {nav_year}</h4>
            <div className="flex items-start md:items-center flex-col md:flex-row md:mt-3">
              <h2 className="text-[18px] md:text-[26px] lg:text-[32px] font-bold text-dark">{nav_value}</h2>
              <h5 className="font-normal md:ml-3 md:pt-[13px] text-[10px] md:text-[12px] lg:text-[14px] text-primary-DARK">{nav_date_cap}</h5>
            </div>
          </div>

          <div className="  flex items-end justify-center flex-col ">
            {timefilter_select?.key == 'timehorizon' && (
              <DisclosureDrop
                selected={timescale_select}
                setSelection={setTimescale}
                options={timescale} title="" />)
            }

            {timefilter_select?.key == 'custom' && (
              <div className=" flex  items-start lg:items-center justify-center mt-3 flex-col lg:flex-row">
                <h5 className="mr-4  text-[14px]  lg:text-[16px] ">From :</h5>
                <DatePickerView selectedDate={fromdate_select} setSelectedDate={setFromDate} maxDate={todate_select} />
                <h5 className="mr-4 ml-3 text-[14px]  lg:text-[16px]" >To :</h5>
                <DatePickerView selectedDate={todate_select} setSelectedDate={setToDate} maxDate={new Date()} />
              </div>)
            }
          </div>


        </div>

        <div className="mt-5 chart-wrapper min-h-96">
          {
            pointData && (<DisclosureCharts data={pointData} />)
          }
        </div>
      </div>
      {
        pointData && (<CalenderDisclosure data={pointData} />)
      }

    </div>
  );
};

export default NavHistoryDisclosure;





