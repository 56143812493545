import React from "react";
import icon4 from '../../../assets/images/icons-4.svg';
import { URI_JOIN_NPS } from "../../NavigationUri";

const ButtonJoinNps = () => {
    return (
        <a href={URI_JOIN_NPS} className="px-4 py-2 border-custom-color text-sm flex justify-center items-center border text-white font-bold rounded-full bg-primary">
            Join NPS <img className="" src={icon4} />
        </a>
    )
}
export default ButtonJoinNps;