import React from "react";


const Toast = ({ message, isOpen, onClose = () => { } }) => {
    // const [showToast, setShowToast] = useState(true);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowToast(false);
    //         onClose();
    //     }, 3000); // Adjust the duration as needed

    //     return () => clearTimeout(timer);
    // }, [onClose]);

    const togglePopup = () => {
        onClose(!isOpen);
    };

    return (
        <>
            {isOpen ? (
                <>
                    <div style={{ zIndex: 10000 }}
                        className="fixed bottom-0 right-0 mb-4 mr-4 flex bg-red-500 border 
                        border-gray-300 p-2 rounded shadow-md items-center popup">
                        <p className="flex-grow text-white">{message}</p>
                        <button onClick={togglePopup}
                            className="ml-4 text-white font-bold py-2 px-4 rounded cursor-pointer">
                            X
                        </button>
                    </div></>
            ) : null}
        </>
    );

};

export default Toast;