import React from 'react';

// constants
// import { URI_JOIN_NPS } from '../../NavigationUri';
import { parseString, toLower } from '../../AppConstants';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_nps_solution_retail } from '../../layout/breadcrumb-data';
import StyledButton from '../StyledButton/Index';

// images
import vector_nps from '../../../assets/images/vectornps.svg';

const BannerRetailSolution = ({ content }) => {
  return (
    <div className="bg-site-FFF0E3 ">
      <img src={vector_nps} className="absolute right-0  hidden  md:block" />
      <div className="container mx-auto pt-6  self-start pl-5">
        <BreadCrumb
          items={bc_nps_solution_retail}
          key="nps-solution-retail-breadcrumb"
        />
      </div>
      <div className="px-5 py-2 container mx-auto  banner-border-custom text md:py-8 flex flex-col lg:flex-row justify-between ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-3  md:gap-20">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_retail_content_${index}`}>
                {toLower(item?.position) == 'right' && (
                  <div
                    key={`img_right_${index}`}
                    className="w-full block md:hidden p-0 md:p-5 lg:overflow-y-auto mt-5 md:mt-0 order-first"
                  >
                    <img
                      className="w-full max-w-[340px] mx-auto"
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
                {toLower(item?.position) == 'left' && (
                  <div
                    key={`content_left_${index}`}
                    className="w-full flex flex-col lg:flex-row justify-center p-0 md:py-4 "
                  >
                    <div className="lg:pt-20 pt-sm-9  flex items-center justify-center flex-col md:items-start md:justify-start">
                      {item?.banner_heading && (
                        <h1 className=" text-center  text-2xl md:text-4xl lg:text-5xl  font-bold md:text-left ">
                          {parseString(item?.banner_heading)}
                        </h1>
                      )}
                      {item?.banner_description && (
                        <div className=" text-sm  lg:text-lg   text-center md:text-left lg:text-left text-primary-BLACK font-normal  tracking-wider	   mb-3 md:mb-6   mt-3 md:mt-6  ">
                          {parseString(item?.banner_description)}
                        </div>
                      )}
                      {item?.ctas && (
                        <div className="flex flex-row items-start justify-start mb-5 md:mb-auto  ">
                          <StyledButton cta={item?.ctas} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {toLower(item?.position) == 'right' && (
                  <div
                    key={`img_right_1${index}`}
                    className="w-full hidden md:block p-0 lg:overflow-y-auto"
                  >
                    <img
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      className="w-full max-w-[540px]"
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerRetailSolution;
