import React from 'react';
import BlogHorizontalCard from '../Cards/Blog/BlogHorizontalCard';

// images
import blog_blur_bg_slider from '../../../assets/images/blog-blur-bg-slider.png';

const BlogVerticalSlider = ({ contents }) => {
  return (
    <div className="relative">
      <div className="hidden lg:block absolute -bottom-1 w-full">
        <img src={blog_blur_bg_slider} className="w-full" />
      </div>
      <div className=" overflow-x-auto lg:overflow-y-auto flex lg:block max-h-38 scrollbar-hide gap-4 ">
        {contents?.map((item, index) => (
          <div
            key={`${Date.now()}-blg-crd-hrz-${index}`}
            className=" w-80 lg:w-full flex-shrink-0"
          >
            <BlogHorizontalCard content={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogVerticalSlider;
