import React, { useEffect, useState } from 'react';
import { getMonthName } from '../../AppConstants';
import DisclosureDrop from '../DropDown/DisclosureDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolio } from '../../../redux/actions/PageAction';

import DownloadCardDisclosureThird from '../Downloaders/DownloadCardDisclosureThird';

const AccordionPortfolio = () => {
  //const [activeIndex, setActiveIndex] = useState(null);

  const [selectedMonth, setMonthSelect] = useState();
  const [selectedYear, setYearSelect] = useState();
  const [monthOption, setOptionMonth] = useState();
  const [yearOption, setOptionYear] = useState();

  // const [dataItem, setDataItem] = useState();

  // Redux
  const dispatch = useDispatch();
  const { pd_portfolio_files } = useSelector((state) => state.pageState);

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();

    let defaultMonth = null;
    let defaultYear = null;

    if (month == 0) {
      month = 11;
      defaultYear = year - 1;
    }
    else {
      month = month - 1;
      defaultYear = year;
    }

    defaultMonth = getMonthName(month);

    let months = [{ key: 'jan', value: "January" },
    { key: 'feb', value: "February" },
    { key: 'mar', value: "March" },
    { key: 'apr', value: "April" },
    { key: 'may', value: "May" },
    { key: 'jun', value: "June" },
    { key: 'july', value: "July" },
    { key: 'aug', value: "August" },
    { key: 'sep', value: "September" },
    { key: 'oct', value: "October" },
    { key: 'nov', value: "November" },
    { key: 'dec', value: "December" }];

    setOptionMonth(months);

    let start = year;
    let end = 2018;

    let years = [];
    for (let i = start; i >= end; i--) {
      years.push({
        key: i, value: i
      })
    }
    setOptionYear(years);

    if (defaultMonth) {
      months.map((obj) => {
        if (defaultMonth == obj.value) {
          setMonthSelect(obj);
        }
      });
    }

    if (defaultYear) {
      setYearSelect({ key: defaultYear, value: defaultYear })
    }
  }, [])

  // const handleClick = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      let data = `${selectedMonth.key}/${selectedYear.key}`;
      dispatch(fetchPortfolio(data));
    }
  }, [selectedMonth, selectedYear])

  // useEffect(() => {
  //   if (pd_portfolio_files) {
  //     let dataset = [];
  //     pd_portfolio_files?.data?.map((obj, index) => {
  //       dataset.push({
  //         index: index,
  //         title: obj?.tier_heading,
  //         content: getContent(obj?.data),
  //         key: obj?.id
  //       });
  //     });
  //     setDataItem(dataset);
  //   }
  // }, [pd_portfolio_files])

  // const getContent = (data) => {
  //   return <TierSchemePortfolio contents={Object.values(data[0])} />
  // }

  // const UpArrowIcon = () => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     className="h-6 w-6"
  //     fill="none"
  //     viewBox="0 0 24 24"
  //     stroke="currentColor"
  //   >
  //     <path
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth={2}
  //       d="M5 15l7-7 7 7"
  //     />
  //   </svg>
  // );

  // const DownArrowIcon = () => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     className="h-6 w-6"
  //     fill="none"
  //     viewBox="0 0 24 24"
  //     stroke="currentColor"
  //   >
  //     <path
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth={2}
  //       d="M19 9l-7 7-7-7"
  //     />
  //   </svg>
  // );

  return (
    <div className="mt-4 container">
      <div className='p-[18px] '>
        <div className="flex items-center md:flex ">
          <DisclosureDrop
            selected={selectedMonth}
            setSelection={setMonthSelect}
            options={monthOption}
            title='Month' titleAppend={true}
          />
          <span className="px-3">&</span>
          <DisclosureDrop
            selected={selectedYear}
            setSelection={setYearSelect}
            options={yearOption}
            title='Year' titleAppend={true}
          />
        </div>
      </div>
      
      <div className='p-[8px] md:p-[15px] lg:p-[24px]'>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2   ">
          {
            pd_portfolio_files?.data?.map((obj, index) => (
                <DownloadCardDisclosureThird key={`dlc-${index}`}
                  heading={obj?.attributes?.Scheme_Details?.data?.attributes?.file_display_name}
                  contents={obj?.attributes?.Scheme_Details?.data} />
            ))
          }
        </div>
      </div>

      {/* {dataItem?.map((item, index) => (
        <div
          key={`acc-copy-${index}`}
          className="bg-primary-LIGHT rounded-lg w-500 mb-4 border border-gray-300 focus:outline-none transition ease-in-out delay-150 m-2"
        >
          <div className="cursor-pointer" onClick={() => handleClick(index)}>
            <div
              className={`sector flex justify-between items-center gap-4 px-[16px] py-[12px] rounded-t-xl ${activeIndex === index ? 'bg-primary-LIGHT' : ''
                }`}>
              <h2
                className={`  text-left text-[12px] md:text-[16px] lg:text-[18px] font-bold ${activeIndex === index ? 'text-BLACK' : ''
                  }`}>
                {parseString(item?.title)}
              </h2>
              <div className="w-4 h-3 transition-transform flex justify-center items-center">
                {activeIndex === index ? (
                  <UpArrowIcon />
                ) : (
                  <DownArrowIcon />
                )}
              </div>
            </div>
          </div>
          {activeIndex === index && (
            <div className={`accordion-content ${activeIndex === index ? 'active-acc' : ''}`}>
              {item?.content}
            </div>
          )}
        </div>
      ))} */}
    </div>
  );
};

export default AccordionPortfolio;
