import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrivacy } from '../redux/actions/PageAction';
import { showPageLoader, resetPageLoader } from '../redux/actions/CommonAction';
import Menu from '../common/layout/Menu';
import BannerCommon from '../common/components/Banners/BannerCommon';
import { parseString } from '../common/AppConstants';
import Footer from '../common/layout/Footer';

const Privacy = () => {
    const [body, setBody] = useState({});
    // Redux
    const dispatch = useDispatch();
    const { privacy_contents } = useSelector((state) => state.pageState);

    useEffect(() => {
        if (privacy_contents == null) {
            dispatch(fetchPrivacy({}));
            dispatch(showPageLoader());
        }
    }, [privacy_contents, body]);

    useEffect(() => {
        if (privacy_contents) {
            setBody(
                privacy_contents?.data[0]?.attributes?.page_body_elements?.data
                    ? privacy_contents?.data[0]?.attributes?.page_body_elements?.data[0]
                    : {}
            );

            dispatch(resetPageLoader());
        }
    }, [privacy_contents]);

    return (<>
        {/* NAVBAR SECTION */}
        <Menu key="faq-menu" />
        {/* NAVBAR SECTION */}

        {/* Page Banner */}
        {privacy_contents?.data[0]?.attributes?.page_banners && (
            <BannerCommon content={privacy_contents?.data[0]?.attributes?.page_banners} />
        )}
        {/* Page Banner */}


        {body?.attributes?.page_body_cmp &&
            body?.attributes?.page_body_cmp.map((object, index) => (
                <React.Fragment key={index}>
                    <div className=" container px-5 pb-2 md:pb-2 md:px-10 ">
                        {object?.marketing_cmp_heading && (
                            <div className="flex flex-row items-center mt-0 md:mt-5 text-start">
                                <h4 className="text-lg md:text-xl lg:text-2xl font-bold">
                                    {parseString(object?.marketing_cmp_heading)}
                                </h4>
                            </div>
                        )}
                        {object?.marketing_cmp_description && (
                            <div className="text-sm  md:text-base lg:text-lg py-4 font-normal">
                                {parseString(object?.marketing_cmp_description)}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            ))
        }

        {/* FOOTER */}
        <Footer key="home-footer" />
        {/* FOOTER */}
    </>)

};

export default Privacy;