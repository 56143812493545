import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import toparrow_icon from '../../../assets/images/toparrowicon.svg';
import bottomarrow_icon from '../../../assets/images/bottomarrowicon.svg';


const AgeFilter = ({ selected, minAge = 18, maxAge = 70, setSelection = () => { } }) => {
    const [age, setAge] = useState(parseInt(selected) || '');
    const [showDropdown, setMenuView] = useState(false);
    const [ageData, setAgeData] = useState([]);

    useEffect(() => {
        if (minAge > 0 && maxAge > 0) {
            var itrator = minAge;
            var ageArray = [];
            while (itrator <= maxAge) {
                ageArray.push(itrator);
                itrator++;
            }
            setAgeData(ageArray);
        }
    }, [minAge, maxAge])

    const handleAgeSelectClick = () => {
        setMenuView(!showDropdown);
    };

    const handleSelectClick = (index) => {
        if (index != null) {
            if (ageData[index]) {
                setAge(ageData[index]);
                setMenuView(false);
                setSelection(ageData[index]);
            }
        }
    };

    return (
        <div className="relative cursor-pointer">
            <div className="flex justify-between  items-center border-b-2 p-4 border-black">
                <span className="text-primary-BLACK  text-xl font-bold bg-transparent w-16 cursor-pointer"
                    onClick={handleAgeSelectClick}>{age || ''}</span>
                <div className="" onClick={handleAgeSelectClick}>
                    <a className="relative mb-2 top-0 cursor-pointer"><img src={toparrow_icon} /></a>
                    <a className="relative mt-5 top-2 cursor-pointer"><img src={bottomarrow_icon} /></a>
                </div>
            </div>
            <div className={`z-10 w-full mx-auto absolute top-14 ${showDropdown ? '' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700`}>
                {
                    ageData && (
                        <div className="max-h-36 overflow-y-auto w-full no-scrollbar">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                {
                                    ageData?.map((object, index) => (
                                        <li key={`al-${index}`} onClick={() => handleSelectClick(index)} className="cursor-pointer">
                                            <p className="text-xs text-left py-[0.25rem]">{object}</p>
                                            {
                                                index + 1 != ageData.length && <div className='border-acc-faq  h-[0.0625rem] my-[0.25rem]'></div>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>

    )
}
AgeFilter.propTypes = {
    selected: PropTypes.any || undefined,
};
export default AgeFilter;