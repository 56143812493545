import React, { useEffect } from 'react';
import DownloadCardDisclosureSecond from '../Downloaders/DownloadCardDisclosureSecond';
import PolicyAccordion from './PolicyAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPolicyFile } from '../../../redux/actions/PageAction';
import { resetCommFile } from '../../../redux/actions/CommonAction';

const Policy = ({ contents }) => {

  // Redux
  const dispatch = useDispatch();
  const { policy_files } = useSelector((state) => state.pageState);


  useEffect(() => {
    dispatch(resetCommFile());
  }, [])

  useEffect(() => {
    dispatch(resetCommFile());
    dispatch(fetchPolicyFile('policy'));
  }, [contents])

  return (
    <div className='p-[8px] md:p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      {
        contents?.page_body?.data?.attributes?.page_body_cmp?.map((object, index) => (
          <React.Fragment key={`policy-${index}`}>
            {
              object?.internal_identifier == 'policy' && (
                <div key={index} className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2 md:gap-3 max-h-72 overflow-y-scroll">
                  {
                    policy_files?.data?.data?.map((obj, index) => (
                      <DownloadCardDisclosureSecond key={`dlc-${index}`}
                        heading={obj?.attributes?.file_display_name}
                        contents={obj} />
                    ))
                  }
                </div>
              )
            }
            {
              object?.internal_identifier == 'compliance_of_stewardship_policy' && (
                <PolicyAccordion contents={object?.tab_element} />
              )
            }
          </React.Fragment>
        ))
      }

    </div>
  );
};

export default Policy;





