import React, { useEffect, useRef, useState } from 'react';

const Tabs = ({
  items,
  activeTab,
  handleTabClick = () => {},
  setActiveTab = () => {},
}) => {
  const [activeCurrentItem, setActiveItem] = useState(activeTab);
  const [totalTab, setTotalTab] = useState(0);
  const scrollContentRef = useRef(null);
  const buttonNextRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [trigger, setTrigger] = useState('');

  useEffect(() => {
    const handleResize = () => {
      // Update isMobile state based on the viewport width
      setIsMobile(window.innerWidth < 768); // You can adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (items) {
      setTotalTab(items?.length);
    }
  }, [items]);

  const handleMenuClick = (index) => {
    if (index != null) {
      handleTabClick(index);
      setActiveItem(index);
      if (index > 3) {
        setTrigger('next');
      }

      if (index == 0) {
        scrollContentRef.current.scrollLeft = 0;
      }
    }
  };

  const nextClick = (args) => {
    if (args != null) {
      if (activeCurrentItem < totalTab - 1) {
        let curTab = activeCurrentItem + 1;
        setActiveItem(curTab);
        setTrigger('next');
      }
    }
  };

  const prevClick = (args) => {
    if (args != null) {
      if (activeCurrentItem > 0) {
        let curTab = activeCurrentItem - 1;
        setActiveItem(curTab);
        setTrigger('prev');
      }
    }
  };

  useEffect(() => {
    setActiveTab(activeCurrentItem);

    if (trigger) {
      if (activeCurrentItem < totalTab) ScrollToLeft();
    }

    setTrigger('');
  }, [activeCurrentItem]);

  const ScrollToLeft = () => {
    let activeTabItem = document.getElementsByClassName('tab-active');

    if (activeTabItem.length > 0) {
      activeTabItem = activeTabItem[0];
    }

    if (activeTab) {
      const scrollAmount =
        activeTabItem.offsetLeft -
        scrollContentRef.current.offsetWidth / 2 +
        activeTabItem.offsetWidth / 2;
      scrollContentRef.current.scrollLeft = scrollAmount;
    }
  };

  return (
    <div className="bg-site-E5EDFC flex md:flex justify-between px-14 relative py-2 rounded-null md:rounded-full ">
      {activeCurrentItem > 0 && (
        <button
          onClick={(e) => prevClick(e)}
          className="bg-white flex justify-center items-center border text-black font-bold rounded-full pr-1 w-11 h-11 absolute left-2 top-1/2 -translate-y-1/2 shrink-0"
        >
          <span className="font-material-icons rotate-180">
            arrow_forward_ios
          </span>
        </button>
      )}

      <div
        ref={scrollContentRef}
        className="flex items-center overflow-x-auto no-scrollbar smooth w-full justify-between"
      >
        {items.map((item, index) => (
          <span
            onClick={() => handleMenuClick(index)}
            key={index}
            className={`tab-item ${
              activeCurrentItem == index ? 'tab-active ' : ''
            } whitespace-nowrap text-base leading-6 px-2 transform transition-transform duration-300 cursor-pointer `}
          >
            {item.title}
          </span>
        ))}
      </div>

      {(totalTab > 3 || isMobile) && (
        <button
          ref={buttonNextRef}
          onClick={(e) => nextClick(e)}
          className="bg-white flex justify-center items-center border text-sm text-black font-bold rounded-full absolute w-11 h-11 right-2 top-1/2 -translate-y-1/2 shrink-0"
        >
          <span className="font-material-icons">arrow_forward_ios</span>
        </button>
      )}
    </div>
  );
};

export default Tabs;
