export const bc_about_us = [
  { index: 0, title: 'Home', url: '/home' },
  { index: 1, title: 'About Us', url: '', isactive: true },
];

export const bc_about_nps = [
  { index: 0, title: 'Home', url: '/home' },
  { index: 1, title: 'About NPS', url: '/nps', isactive: true },
];

export const bc_sitemap = [
  { index: 0, title: 'Home', url: '/home' },
  { index: 1, title: 'Sitemap', url: '/sitemap', isactive: true },
];

export const bc_faq = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'Frequently Asked Questions',
    url: '/faq',
    isactive: true,
  },
];

export const bc_blog = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'Article',
    url: '/blogs',
    isactive: true,
  },
];

export const bc_blog_category = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'Blogs',
    url: '/blogs',
  },
  {
    index: 2,
    title: 'Popular Reads',
    url: '/blogs/category',
    isactive: true,
  },
];

export const bc_nps_solution_retail = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'NPS Solution',
    url: '/',
  },
  {
    index: 2,
    title: 'Retail Solution',
    url: '/solution/retail',
    isactive: true,
  },
];

export const bc_nps_solution_corporate = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'NPS Solution',
    url: '/',
  },
  {
    index: 2,
    title: 'Corporate Solution',
    url: '/solution/corporate',
    isactive: true,
  },
];

export const bc_nps_solution_government = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'NPS Solution',
    url: '/',
  },
  {
    index: 2,
    title: 'Government Solution',
    url: '/solution/government',
    isactive: true,
  },
];


export const bc_privacy = [
  { index: 0, title: 'Home', url: '/home' },
  {
    index: 1,
    title: 'Privacy',
    url: '/privacy',
    isactive: true,
  },
];
