import React, { useEffect, useState } from "react";
import './RangeSlider.css';

// type Props = {
//     min: number;
//     max: number;
//     value: number;
//     onChange: (value: number) => void;
// };

const RangeSlider = ({ min, max, inputVal = () => { }, setInput = () => { }, extraClass = "" }) => {

    const [value, setValue] = useState(inputVal || 0);

    useEffect(() => {
        if (inputVal != value) {
            setValue(inputVal);
        }
    }, [])

    const handleChange = (e) => {
        setValue(e.target.value || 0);
    };

    // useEffect(() => {
    //     if (value >= 0) {
    //         setTimeout(() => {
    //             setInput(value);
    //         }, 100)
    //     }
    // }, [value])

    const handleChangeCommitted = () => {
            if (value >= 0) {
                setTimeout(() => {
                    setInput(value);
                }, 100)
            }
    };



    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={value}
                onChange={handleChange}
                className={`${extraClass}`}
                onMouseUp={handleChangeCommitted}
                onTouchEnd={handleChangeCommitted}
            />

            {/* <Slider
                progress
                min={min}
                max={max}
                defaultValue={value || min}
                value={value}
                // onChange={setValue}
                onChangeCommitted={handleChangeCommitted}
                className={`${extraClass}`}
                valueLabelDisplay="auto"
                handleStyle={{
                    borderRadius: 10,
                    color: '#fff',
                    fontSize: 12,
                    width: 32,
                    height: 22
                }} /> */}
        </>
    )
}

export default RangeSlider;