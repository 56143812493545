import React, { useEffect, useState } from "react";

import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    Tooltip
} from 'chart.js';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    Tooltip
)



const DisclosureCharts = ({ data }) => {
    const [pointData, setData] = useState();

    const options = {
        plugins: {
            legend: false,
            tooltip: {
                enabled: true, // Ensure the tooltip is enabled
                callbacks: {
                    label: function (tooltipItem) {
                        return `NAV: ${tooltipItem.formattedValue}`;
                    },
                    // title: function (tooltipItem) {
                    //     return `${tooltipItem[0]?.raw?.tooltitle}`;
                    // },
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 8
                    }
                },
                title: {
                    display: true,
                    text: '  Period',
                    color: '#101C2D',
                    font: {
                        size: 18
                    },
                    padding: {
                        top: 20 // Adjust the value as per your requirement
                    }
                }
            },
            y: {
                ticks: {
                    stepSize: 0.5,
                    callback: (value) => value.toFixed(4),
                    font: {
                        size: 10
                    }
                },
                title: {
                    display: true,
                    text: 'Unit Values (in ₹)',
                    color: '#101C2D',
                    font: {
                        size: 16
                    },
                    padding: {
                        bottom: 20 // Adjust the value as per your requirement
                    }
                }
            }
        },
        backgroundColor: 'rgba(255, 0, 0, 0.2)' // Background shade color
    };

    useEffect(() => {
        if (data) {
            const labels = data?.map(point => point.xLabel) || [];
            const dataValues = data?.map(point => point.value) || [];
            // const dataValues = data?.map((value) => ({
            //     x: value?.xLabelShort,
            //     y: value?.value,
            //     tooltitle: value?.xLabel
            // }))

            const dataSet = {
                backgroundColor: 'red',
                fill: 'start',
                labels: labels || [],
                datasets: [
                    {
                        label: 'Value',
                        data: dataValues || [],
                        backgroundColor: 'rgba(0, 61, 173, 0.2)', // Background shade color
                        borderColor: '#003DAD',
                        pointBorderColor: 'transparent',
                        pointBorderWidth: 4,
                        tension: 0.5,
                        fill: true // Fill the area below the line
                    },
                ],
            };
            setData(dataSet);
        }
    }, [data])

    return (
        <>
            {pointData && <Line data={pointData} options={options} />}
        </>
    )
}

export default DisclosureCharts;
