import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IsEmpty, toLower } from '../../AppConstants';

const PrimaryButton = ({
  cta = {},
  variant = 'button',
  title = '',
  url = '',
  openTab = false,
  onClick = () => {},
  extraClass = '',
}) => {
  const [typeButton, setType] = useState(variant);
  const [link, setRedirect] = useState(url);
  const [btnText, setBtnText] = useState(title || '');

  useEffect(() => {
    if (cta) {
      if (IsEmpty(title)) {
        if (cta?.data?.attributes) {
          setBtnText(cta?.data?.attributes?.cta_title);
        } else if (cta?.attributes) {
          setBtnText(cta?.attributes?.cta_title);
        }
      }

      if (url == '') {
        if (cta?.attributes) {
          setType(toLower(cta?.attributes?.cta_type));

          if (cta?.attributes?.cta_external_link) {
            setType('link');
            setRedirect(cta?.attributes?.cta_external_link);
          }
        }
      } else setType('link');
    }
  }, [cta, url, title]);

  return (
    <>
      {typeButton == 'button' && (
        <button
          onClick={onClick}
          className={`flex flex-row items-center justify-center border 
                                    text-white 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-primary 
                                    hover:bg-primitive-ORANGE-400
                                    hover:text-white ${
                                      extraClass ? extraClass : ''
                                    } `}
        >
          {btnText || ''}
          {cta?.attributes?.cta_icon?.data?.attributes?.url && (
            <img
              className="mt-1"
              src={cta?.attributes?.cta_icon?.data?.attributes?.url}
            />
          )}
        </button>
      )}
      {typeButton == 'link' && (
        <>
          {openTab ? (
            <a
              href={link ? link : '#'}
              target="_blank"
              rel="noreferrer"
              className={`flex flex-row items-center justify-center border 
                                    text-white 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-primary 
                                    hover:bg-primitive-ORANGE-400
                                    hover:text-white ${
                                      extraClass ? extraClass : ''
                                    } `}
            >
              {btnText || ''}
              {cta?.attributes?.cta_icon?.data?.attributes?.url && (
                <img
                  className=""
                  src={cta?.attributes?.cta_icon?.data?.attributes?.url}
                />
              )}
            </a>
          ) : (
            <a
              href={link ? link : '#'}
              className={`flex flex-row items-center justify-center border 
                                    text-white 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-primary 
                                    hover:bg-primitive-ORANGE-400
                                    hover:text-white ${
                                      extraClass ? extraClass : ''
                                    } `}
            >
              {btnText || ''}
              {cta?.attributes?.cta_icon?.data?.attributes?.url && (
                <img
                  className=""
                  src={cta?.attributes?.cta_icon?.data?.attributes?.url}
                />
              )}
            </a>
          )}
        </>
      )}
    </>
  );
};

PrimaryButton.propTypes = {
  cta: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  openTab: PropTypes.bool,
  onClick: PropTypes.func,
  extraClass: PropTypes.string,
};

export default PrimaryButton;
