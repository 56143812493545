import React, { useEffect, useState } from "react";
import TierSchemePortfolio from "./TierSchemePortfolio";
import { resetCommFile } from "../../../redux/actions/CommonAction";
import { useDispatch } from "react-redux";
import AccordionPortfolio from "../Accordions/AccordionPortfolio";


const PortfolioDetails = ({ contents }) => {
  const [dataItem, setDataItem] = useState();
  const dispatch = useDispatch();

  const getContent = (data) => {
    return <TierSchemePortfolio contents={data} />
  }

  useEffect(() => {
    dispatch(resetCommFile());

    if (contents) {
      if (contents?.page_body?.data?.attributes?.page_body_cmp[0]?.tab_element) {
        let dataset = [];
        contents?.page_body?.data?.attributes?.page_body_cmp[0]?.tab_element?.map((object, index) => {
          dataset.push({
            index: index,
            title: object?.tab_text,
            content: getContent(object?.page_body),
            key: object?.internal_identifier
          });
        });
        setDataItem(dataset);
      }
    }
  }, [])

  return (
    <div className='p-[8px] md:p-[15px] lg:p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      <AccordionPortfolio items={dataItem} />
    </div>);
}

export default PortfolioDetails;