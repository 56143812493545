import React, { useEffect, useState } from "react";

// import toparrow_icon from '../../../assets/images/toparrowicon.svg';
import bottomarrow_icon from '../../../assets/images/bottomarrowicon.svg';

const SelectNormal = ({ selected, setSelection = () => { }, options, defaultVal }) => {
    const [showDropdown, setMenuView] = useState(false);

    useEffect(() => {
        if (defaultVal) {
            setSelection(defaultVal);
        }
    }, [])

    const handleDropClick = () => {
        setMenuView(!showDropdown);
    };

    const handleSelectClick = (index) => {
        if (index != null) {
            if (options[index]) {
                setSelection(options[index]);
                setMenuView(false);
            }
        }
    };

    return (
        <>
            {/* <div className="relative cursor-pointer w-full">
                <div onClick={() => handleDropClick()} className="flex justify-between items-center border-b-2 p-4 border-black w-full">
                    <span className="text-primary-BLACK  text-xl font-bold bg-transparent cursor-pointer">{selected?.value || ''}</span>
                    <div>
                        <a className="relative mb-2 top-0 cursor-pointer"><img src={toparrow_icon} /></a>
                        <a className="relative mt-5 top-2 cursor-pointer"><img src={bottomarrow_icon} /></a>
                    </div>
                </div>
                <div className={`z-10 w-full mx-auto absolute top-14 ${showDropdown ? '' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700`}>
                    {
                        options && (
                            <div className="max-h-36 overflow-y-auto w-full no-scrollbar">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    {
                                        options?.map((object, index) => (
                                            <li key={`al-${index}`} onClick={() => handleSelectClick(index)} className="cursor-pointer">
                                                <p className="text-xs text-left py-[0.25rem]">{object?.value}</p>
                                                {
                                                    index + 1 != options.length && <div className='border-acc-faq  h-[0.0625rem] my-[0.25rem]'></div>
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>
            </div> */}


            <div className="relative inline-block text-left w-full">
                <div>
                    <button type="button" onClick={() => handleDropClick()}
                        className="flex justify-between items-center border-b-2 p-4 border-black w-full"
                    >
                        <span className="text-primary-BLACK  text-xl font-bold bg-transparent cursor-pointer">{selected?.value || ''}</span>
                        <span className="relative cursor-pointer items-center justify-center">
                            <img src={bottomarrow_icon} />
                        </span>
                    </button>
                </div>

                <div className={`absolute w-full ${showDropdown ? '' : 'hidden'} transition ease-out duration-100 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    tabIndex="-1">
                    <div className="overflow-y-scroll h-48" role="none">
                        {
                            options && (
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    {
                                        options?.map((object, index) => (
                                            <li key={`al-${index}`} onClick={() => handleSelectClick(index)} className="cursor-pointer">
                                                <p className="text-gray-700 px-4 py-2 text-sm">{object?.value}</p>
                                                {
                                                    index + 1 != options.length && <div className='border-acc-faq  h-[0.0625rem] my-[0.25rem]'></div>
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    )

};

export default SelectNormal;