import React from "react";

const ButtonDownload = ({ text, icon = "", onClickEvent = () => { } }) => {
    return (
        <div onClick={() => onClickEvent()} className="flex flex-row items-center justify-between border border-custom-color bg-white p-2 rounded-lg cursor-pointer">
            {text && (<span className="text-[12px] md:text-[14px] text-neutral-BLACK font-normal">{text}</span>)}
            {icon && (<img src={icon} className="ml-11 md:w-8 md:h-5 cursor-pointer" alt="Download Icon" />)}
        </div>
    );
}
export default ButtonDownload;