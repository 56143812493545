import React from 'react';
import PropTypes from 'prop-types';

import { parseString } from '../../AppConstants';
import StyledButton from '../StyledButton/Index';

import hlt4 from '../../../assets/images/highlight-4.svg';

const CardCopy = ({
  content,
  iconHeadStartClass,
  extraBoxClass = '',
  cardType = '',
}) => {
  return (
    <div
      key={`cc_${Date.now}`}
      className={`bg-white w-full p-4 md:p-8 rounded-2xl z-50	 card-shadow-News-Blogs ${extraBoxClass} `}
    >
      {cardType == 'plain' ? (
        <>
          {content?.unit_heading && (
            <div key="cc_23" className="flex flex-row items-center">
              {content?.heading_icon?.data?.attributes?.url && (
                <img
                  className={
                    iconHeadStartClass ? iconHeadStartClass : 'pr-2 w-8 h-8'
                  }
                  src={content?.heading_icon?.data?.attributes?.url}
                  alt={
                    content?.heading_icon?.data?.attributes?.alternativeText ||
                    ''
                  }
                />
              )}
              <h3 className="text-neutral-BLACK-DARKER text-lg md:text-xl lg:text-2xl text-left">
                {parseString(content?.unit_heading)}
              </h3>
            </div>
          )}
          {content?.unit_description && (
            <div
              key="cc_42"
              className="pt-4 pl-0 text-sm lg:text-base text-left py-3"
            >
              {parseString(content?.unit_description)}
            </div>
          )}
        </>
      ) : cardType == 'description_bullets_blue_tick' ? (
        <>
          {content?.unit_heading && (
            <div key="cc_50" className="flex flex-row items-center">
              {content?.heading_icon?.data?.attributes?.url && (
                <img
                  className={
                    iconHeadStartClass ? iconHeadStartClass : 'pr-2 w-8 h-8'
                  }
                  src={content?.heading_icon?.data?.attributes?.url}
                  alt={
                    content?.heading_icon?.data?.attributes?.alternativeText ||
                    ''
                  }
                />
              )}
              <h3 className="text-neutral-BLACK-DARKER text-lg md:text-xl lg:text-2xl text-left">
                {parseString(content?.unit_heading)}
              </h3>
            </div>
          )}
          {content?.unit_description && (
            <div
              key="cc_69"
              className="pt-4 text-neutral-GRAY-300 text-sm lg:text-base text-left py-3"
            >
              {parseString(content?.unit_description, { class: 'list-disc' })}
            </div>
          )}
          {content?.unit_badge && (
            <div
              key="cc_74"
              className="w-fit bg-blue-50 text-center text-primary-BLUE-500 px-2 py-1 rounded-full text-xs font-semibold uppercase leading-normal"
            >
              {parseString(content?.unit_badge)}
            </div>
          )}
        </>
      ) : (
        <>
          {content?.attributes?.marketing_units?.length ? (
            <>
              {content?.attributes?.unit_heading && (
                <div key="cc_84" className="flex flex-row items-center">
                  {content?.attributes?.unit_common_icon?.data?.attributes
                    ?.url && (
                    <img
                      className={
                        iconHeadStartClass ? iconHeadStartClass : 'pr-2 w-8 h-8'
                      }
                      src={
                        content?.attributes?.unit_common_icon?.data?.attributes
                          ?.url
                      }
                      alt={
                        content?.attributes?.unit_common_icon?.data?.attributes
                          ?.alternativeText || ''
                      }
                    />
                  )}
                  <h3 className="text-neutral-BLACK-DARKER text-lg md:text-xl lg:text-2xl text-left">
                    {parseString(content?.attributes?.unit_heading)}
                  </h3>
                </div>
              )}

              {content?.attributes?.marketing_units?.map((obj) => (
                <div
                  key={obj?.id}
                  className="flex flex-row items-start text-left py-3"
                >
                  {obj?.unit_media?.data?.attributes?.url && (
                    <img
                      className="pr-2 w-6"
                      src={obj?.unit_media?.data?.attributes?.url}
                      alt={
                        obj?.unit_media?.data?.attributes?.alternativeText || ''
                      }
                    />
                  )}
                  {obj?.unit_heading && (
                    <div className="text-sm lg:text-lg">
                      {parseString(obj?.unit_heading)}
                    </div>
                  )}
                  {obj?.unit_description && (
                    <div className="text-sm lg:text-lg">
                      {parseString(obj?.unit_description)}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {content?.attributes?.marketing_heading && (
                <div key="cc_139" className="flex flex-row items-center">
                  {content?.attributes?.heading_icon?.data?.attributes?.url && (
                    <img
                      className={
                        iconHeadStartClass ? iconHeadStartClass : 'pr-2 w-8 h-8'
                      }
                      src={
                        content?.attributes?.heading_icon?.data?.attributes?.url
                      }
                      alt={
                        content?.attributes?.heading_icon?.data?.attributes
                          ?.alternativeText || ''
                      }
                    />
                  )}
                  <h3
                    key="cc_154"
                    className="text-neutral-BLACK-DARKER text-lg md:text-xl lg:text-2xl text-left"
                  >
                    {parseString(content?.attributes?.marketing_heading)}
                  </h3>
                </div>
              )}

              {content?.attributes?.marketing_description && (
                <div
                  key="cc_161"
                  className="pt-4 text-sm lg:text-base text-left py-3"
                >
                  {parseString(content?.attributes?.marketing_description)}
                </div>
              )}
              {content?.attributes?.marketing_cta?.data && (
                <div key="cc_166" className="flex flex-row items-center mt-3.5">
                  <StyledButton cta={content?.attributes?.marketing_cta} />
                </div>
              )}

              {content?.unit_heading && (
                <div
                  key="cc_172"
                  className="flex flex-row items-start text-left"
                >
                  <img className="pr-2 w-6 h-6  " src={hlt4} alt="Image" />
                  {content?.unit_heading && (
                    <div className=" text-xs	 lg:text-base font-medium text-neutral-GRAY-300 leading-[180%]">
                      {parseString(content?.unit_heading)}
                    </div>
                  )}
                </div>
              )}

              {content?.unit_description && (
                <div
                  key="cc_183"
                  className="flex flex-row items-start text-left"
                >
                  <img className="pr-2 w-6 h-6  " src={hlt4} alt="Image" />
                  {content?.unit_description && (
                    <div className="text-xs	 lg:text-base font-medium text-neutral-GRAY-300 leading-[180%]">
                      {parseString(content?.unit_description)}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

CardCopy.propTypes = {
  contents: PropTypes.object || undefined,
  iconHeadStartClass: PropTypes.string || undefined,
  extraBoxClass: PropTypes.string || undefined,
  cardType: PropTypes.string || undefined,
};
export default CardCopy;
