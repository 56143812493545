import React from "react";
import { parseString } from "../../AppConstants";

// import avatar_dummy from '../../../assets/images/avatar-dummy.png';

const ModalSponsers = ({ contents, openModal, onCloseClick = () => { } }) => {
    const handleCloseClick = () => {
        onCloseClick(false);
    };

    return (
        <div
            className={`fixed inset-0 z-[1000] ${!openModal ? 'hidden' : ''} overflow-auto bg-black bg-opacity-80`}>
            <div className="bg-white w-11/12 max-w-3xl mx-auto rounded shadow-lg py-4 mt-20">
                <div className="flex justify-end"
                    onClick={handleCloseClick}>
                    <button
                        className="text-gray-700 hover:text-gray-900 px-4 py-2">
                        <span className="font-material-icons material-symbols-outlined text-3xl">
                            close
                        </span>
                    </button>
                </div>

                <div className="text-left px-6 py-2">
                    <figure className="flex justify-center">
                        <div className="border-primary border-2 p-2">
                            {/* <img className=" text-center w-44 h-44 object-cover border-primary-MAROON-500 border-2 rounded-full "
                                src={
                                    contents?.attributes?.leader_photo?.data?.attributes
                                        ?.url || avatar_dummy
                                }
                            /> */}
                            {contents?.attributes?.images?.data[0]?.attributes?.url && (
                                <img
                                    className="text-center object-contain w-40"
                                    src={contents?.attributes?.images?.data[0]?.attributes?.url}
                                />
                            )}
                            {contents?.attributes?.award_image?.data?.attributes?.url && (
                                <img
                                    className="text-center object-contain w-40"
                                    src={contents?.attributes?.award_image?.data?.attributes?.url}
                                />
                            )}

                        </div>
                    </figure>

                    {contents?.attributes?.sponsor_title && (
                        <div className="text-center mt-4">
                            <h4 className="text-xl font-bold">{parseString(contents?.attributes?.sponsor_title)}</h4>
                        </div>)}

                    {contents?.attributes?.sponsor_description &&
                        <>
                            <div className="mt-6 mb-6">
                                <p className="h-[0.0625rem] mx-auto w-2/4 bg-neutral-GRAY-100"></p>
                            </div>
                            <div className="mt-4">{parseString(contents?.attributes?.sponsor_description)}</div>
                        </>}
                </div>
            </div>
        </div>
    )
};

export default ModalSponsers;