import React from 'react';
import PropTypes from 'prop-types';
import { parseString } from '../../AppConstants';
import CardCopyStatistics from './CardCopyStatistics';

const HomeInvestWhy = ({ contents }) => {
  return (
    <div
      key={`${Date.now()}-hinc${contents?.id}`}
      className="container mx-auto px-5 md:px-10 py-6 md:py-10 lg:py-12 flex flex-col items-center"
    >
      {/* <div className="mt-[3rem] md:mt-[5rem] lg:mt-[7.5rem]"> */}
      <div>
        {contents?.statistics_cmp_heading && (
          <h2 className="text-center text-darker text-xl md:text-2xl lg:text-4xl  font-bold">
            {parseString(contents?.statistics_cmp_heading)}
          </h2>
        )}
        {contents?.statistics_cmp_description && (
          <div className="text-neutral-GRAY text-center mt-4 text-sm md:text-lg">
            {parseString(contents?.statistics_cmp_description)}
          </div>
        )}
      </div>

      {contents?.statistics?.data?.length && (
        <div className="mt-6 md:mt-8 lg:mt-14 grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-4 gap-4 lg:gap-6 w-full">
          <CardCopyStatistics contents={contents?.statistics} />
        </div>
      )}
    </div>
  );
};

HomeInvestWhy.propTypes = {
  contents: PropTypes.object,
};
export default HomeInvestWhy;
