import React from "react";
import { parseString } from "../../AppConstants";
import pdf_icon from '../../../assets/images/pdficon.svg';
import dm_icon from '../../../assets/images/downlaodicon.svg';

const DownloadCardDisclosureSecond = ({ heading, contents }) => {

  const handleFileClick = (item) => {
    if (item) {
      let fileName = item?.name;
      fetch(item?.url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "downloaded-file";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    }
  };

  return (
    <div className="border border-custom-color bg-white p-4 rounded-lg   m-2">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <img src={pdf_icon} className="pr-2 w-8 h-5 md:w-10 md:h-7" alt="PDF Icon" />
          <span className="text-[12px] md:text-[14px] lg:text-[16px] text-neutral-BLACK font-medium	">
            {parseString(heading)}
          </span>
        </div>
        <img
          src={dm_icon}
          className=" w-8 h-5 md:w-10 md:h-7 cursor-pointer"
          onClick={() => handleFileClick(contents?.attributes?.file_upload?.data[0]?.attributes
            || contents?.attributes?.file_upload?.data?.attributes || contents?.attributes)}
          alt="Download Icon"
        />
      </div>
    </div>
  );
};

export default DownloadCardDisclosureSecond;
