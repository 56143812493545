import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tabs from '../../common/components/Tabs';
// import { retail_nps_solution } from './tabs';
import FaqCopy from '../../common/components/Accordions/FaqCopy';
// import Banner from '../../common/layout/Banner';
import { parseString } from '../../common/AppConstants';
import CardCopy from '../../common/components/Cards/CardCopy';
// import StyledButton from '../../common/components/StyledButton/Index';
import FooterBanner from '../../common/components/Banners/FooterBanner';

import ButtonJoinNps from '../../common/components/StyledButton/ButtonJoinNps';
import BannerRetailSolution from '../../common/components/Banners/BannerRetailSolution';

const TabRetail = ({ contents }) => {
  const [tabBody, setTabBody] = useState({});
  const [body, setBody] = useState({});
  const [all_tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (contents) {
      setBody(
        contents?.data[0]?.attributes?.page_body_elements?.data
          ? contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
    }
  }, [contents]);

  useEffect(() => {
    if (body) {
      if (body?.attributes?.page_body_cmp) {
        let tab_element = body?.attributes?.page_body_cmp[0]?.tab_element;
        if (tab_element) {
          let dataset = [];
          tab_element?.map((obj, index) => {
            dataset.push({
              index: index,
              title: obj?.tab_text,
            });
            if (index == 0) {
              setTabBody(obj?.page_body);
            }
          });
          setTabs(dataset);
        }
      }
    }
  }, [body]);

  useEffect(() => {
    activeTab != null
      ? setTabBody(
          body?.attributes?.page_body_cmp[0]?.tab_element[activeTab]?.page_body
        )
      : null;
  }, [activeTab]);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  return (
    <>
      {/* Page Banner */}
      {contents?.data[0]?.attributes?.page_banners && (
        <BannerRetailSolution
          content={contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      <div className="bg-background py-0 md:pt-5 relative">
        {all_tabs && (
          <div className="lg:container">
            <div className="max-w-5xl mx-auto my-0 px-0 md:my-5 md:py-0 md:px-36 lg:px-60  ">
              <Tabs
                items={all_tabs}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        )}
        {tabBody && (
          <div className="main-bg my-1 p-0 flex flex-col items-center text-center">
            {tabBody?.data?.attributes?.page_body_cmp.map((object, index) => (
              <div key={`tab_retail_page_body_${index}`} className="w-full">
                {object?.internal_identifier ==
                  'benefits_of_retail_solution' && (
                  <div key={`content-${index}`} className="content-section  ">
                    <div className=" px-2 pt-5 md:pt-5 md:px-10 ">
                      {object?.marketing_cmp_heading && (
                        <h1 className=" font-bold mt-5 lg:mb-4 md:mb-0 black-h-text text-xl md:text-2xl text-center lg:text-4xl ">
                          {parseString(object?.marketing_cmp_heading)}
                        </h1>
                      )}
                      {object?.marketing_cmp_description && (
                        <h2 className="  text-sm  md:text-base lg:text-lg text-center  mb-0 md:mb-8  mt-2 px-3 md:px-10  font-normal">
                          {parseString(object?.marketing_cmp_description)}
                        </h2>
                      )}
                    </div>
                  </div>
                )}

                {object?.internal_identifier == 'tax_benefits' && (
                  <div key={`content-${index}`} className="content-section  ">
                    <div className=" container px-5 py-5 md:py-5 md:px-10 ">
                      {object?.marketing_extended_heading && (
                        <div className="flex flex-row items-center mt-0 md:mt-5 text-start">
                          <h4 className="text-lg md:text-xl lg:text-2xl font-bold">
                            {parseString(object?.marketing_extended_heading, {
                              class: 'inline',
                            })}
                          </h4>
                        </div>
                      )}
                      {object?.marketing_units_groups?.data?.length && (
                        <div className="grid grid-cols-1 md:grid-cols-2  mt-5  gap-4">
                          {object?.marketing_units_groups?.data?.map(
                            (obj, sindex) => (
                              <CardCopy
                                content={obj}
                                key={`cchbc-${sindex}`}
                                iconHeadStartClass={'pr-2 w-10 h-10'}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {object?.internal_identifier == 'want_more_benefits' && (
                  <div key={`content-${index}`} className="content-section  ">
                    <div className="container mx-auto px-5 py-5 md:py-10 md:px-10 ">
                      {object?.marketing_cmp_heading && (
                        <div className="flex flex-row items-center text-left md:block mt-0 md:mt-5 ">
                          <div className="text-lg md:text-xl lg:text-2xl font-bold">
                            {parseString(object?.marketing_cmp_heading, {
                              class: 'inline',
                            })}
                          </div>
                        </div>
                      )}
                      {object?.marketing_cmp_description && (
                        <div className="flex flex-row items-center text-left md:hidden   mt-5 ">
                          <div className="text-lg md:text-xl lg:text-2xl font-bold">
                            {parseString(object?.marketing_cmp_description)}
                          </div>
                        </div>
                      )}

                      {object?.choose_marketing_blocks?.data?.length && (
                        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-4">
                          {object?.choose_marketing_blocks?.data?.map(
                            (obj, sindex) => (
                              <CardCopy
                                content={obj}
                                key={`cchbc-${sindex}`}
                                iconHeadStartClass={'pr-2 w-10 h-10'}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {object?.internal_identifier == 'eligibility_criteria' && (
                  <div className="w-full bg-site-FFF9F5">
                    <div className="container mx-auto mt-5 px-5 py-5 md:py-5 md:px-10 sm:p-2 flex flex-col lg:flex-col justify-between ">
                      {object?.marketing_extended_heading && (
                        <h1 className="font-bold lg:mb-4 mb-3 md:mb-4 mt-0 md:mt-0 black-h-text text-xl md:text-2xl text-center lg:text-4xl ">
                          {parseString(object?.marketing_extended_heading)}
                        </h1>
                      )}
                      {object?.marketing_extended_description && (
                        <div className="text-sm md:text-base lg:text-lg text-centertext-gray-600 mb-0 md:mb-8 font-normal">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      )}

                      {object?.marketing_blocks?.data?.length && (
                        <div className="grid grid-cols-1 md:grid-cols-3  mt-5 gap-4">
                          {object?.marketing_blocks?.data?.map(
                            (obj, sindex) => (
                              <CardCopy
                                content={obj}
                                key={`cchbc-${sindex}`}
                                iconHeadStartClass={'pr-2 w-10 h-10'}
                              />
                            )
                          )}
                        </div>
                      )}
                      {object?.ctas?.data && (
                        <div className="flex flex-row items-start justify-center  mt-10 ">
                          {/* <StyledButton cta={object?.ctas}  /> */}
                          <ButtonJoinNps />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {tabBody?.data?.attributes?.internal_identifier ==
          'nps_solutions_retail_faq' && (
          <FaqCopy
            bg=""
            contents={
              tabBody?.data?.attributes?.page_body_cmp[0]
                ? tabBody?.data?.attributes?.page_body_cmp[0]
                : tabBody?.data?.attributes?.page_body_cmp
            }
          />
        )}

        {/* <Banner /> */}
        {/* FOOTER BANNER */}
        {body?.attributes?.footer_banner && (
          <FooterBanner contents={body?.attributes?.footer_banner} />
        )}
        {/* FOOTER BANNER */}
      </div>
    </>
  );
};

TabRetail.propTypes = {
  contents: PropTypes.object,
};
export default TabRetail;
