import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '../../common/layout/Menu';
import Footer from '../../common/layout/Footer';

import { blog_filters } from './blog-data';

// images

// redux
import { fetchBlogList } from '../../redux/actions/PageAction';
import {
  resetPageLoader,
  showPageLoader,
} from '../../redux/actions/CommonAction';

// components
import BlogVerticalCard from '../../common/components/Cards/Blog/BlogVerticalCard';
import BlogFilter from '../../common/components/DropDown/BlogFilter';
import BannerBlogsCategory from '../../common/components/Banners/BannerBlogsCategory';

const BlogCategoryList = () => {
  const [body, setBody] = useState({});
  const [blogs, setBlogs] = useState([]);

  const [selectedFilter, setFilter] = useState({});

  // Redux
  const dispatch = useDispatch();
  const { blog_list_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (blog_list_contents == null) {
      dispatch(fetchBlogList({}));
      dispatch(showPageLoader());
    }
  }, [blog_list_contents, body]);

  useEffect(() => {
    if (blog_list_contents) {
      setBody(
        blog_list_contents?.data[0]?.attributes?.page_body_elements?.data
          ? blog_list_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );

      setBlogs(
        blog_list_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          .attributes?.page_body_cmp?.[1]?.blogs?.data
      );
      dispatch(resetPageLoader());
    }
  }, [blog_list_contents, blogs]);

  //Filter

  return (
    <>
      {/* NAVBAR SECTION */}
      <Menu key="blogs-category-menu" />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {blog_list_contents?.data[0]?.attributes?.page_banners && (
        <BannerBlogsCategory
          content={blog_list_contents?.data[0]?.attributes?.page_banners}
        />
      )}

      {/* Page Banner */}

      {/* BODY SECTION */}
      {body?.attributes?.page_body_cmp &&
        body?.attributes?.page_body_cmp?.map((object, index) => (
          <div key={`blogs_category_page_body_${index}`}>
            {object?.internal_identifier == 'all_blogs_listing' && (
              <div
                key={`${Date.now()}-all-blog-${index}`}
                className=" lg:container mx-auto mt-5 md:mt-10  px-5 md:px-10  pb-10 lg:pb-20 "
              >
                {blogs?.length > 0 && (
                  <div className="w-full  flex-row flex justify-between   md:justify-end  items-center gap-4 mb-5 md:mb-8">
                    <div className="  md:mb-0">
                      <p className="text-xs md:text-base text-neutral-GRAY-300 font-medium">
                        Showing: {blogs?.length} results
                      </p>
                    </div>

                    <BlogFilter
                      options={blog_filters}
                      selected={selectedFilter}
                      setSelection={setFilter}
                    />
                  </div>
                )}

                {blogs.length > 0 && (
                  <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5">
                    {blogs?.map((item, index) => (
                      <BlogVerticalCard
                        key={`${Date.now()}-blog-card-${index}`}
                        content={item}
                      />
                    ))}
                  </div>
                )}

                {/* pagination */}
                {/* <div className="flex items-center justify-center mt-8 px-4 py-3 sm:px-6">
                  <div className="flex flex-1 justify-center sm:hidden">
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md"
                        aria-label="Pagination"
                      >
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400   hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <a
                          href="#"
                          aria-current="page"
                          className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 rounded-full text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          1
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 rounded-full hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          2
                        </a>
                        <a
                          href="#"
                          className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 rounded-full hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                        >
                          3
                        </a>
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-full focus:outline-offset-0">
                          ...
                        </span>
                        <a
                          href="#"
                          className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 rounded-full hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                        >
                          8
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 rounded-full  hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          9
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 rounded-full hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          10
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-full hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        ))}
      {/* BODY SECTION */}

      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default BlogCategoryList;
