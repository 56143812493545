import React from 'react';
import Menu from '../common/layout/Menu';
import Footer from '../common/layout/Footer';

import coming_soon_art from '../assets/images/coming-soon-art.svg';

const ComingSoon = () => {
  return (
    <>
      {/* NAVBAR SECTION */}
      <Menu />
      {/* NAVBAR SECTION */}
      <div className="container mx-auto mt-32 lg:mt-48 mb-24 px-4 sm:px-5 md:px-6 lg:px-10 flex flex-col  items-center">
        <div className="flex justify-center items-center mb-10">
          <img src={coming_soon_art} />
        </div>
        <div>
          <h1 className="text-xl md:text-2xl lg:text-4xl font-bold mb-2 md:mb-4 text-BLACK text-center">
            <span className="text-primary text-xl md:text-2xl lg:text-4xl font-bold">
              Building{' '}
            </span>
            the best pension fund experience{' '}
          </h1>
          <p className="text-sm md:text-lg text-center px-3">
            The site is under maintenance at the moment, come back soon!
          </p>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default ComingSoon;
