import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AgeFilter } from '../DropDown';

// import { rows } from './tab-data';
import { parseString } from '../../AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutNPSTable } from '../../../redux/actions/PageAction';
//import { parseString } from "../../AppConstants";

const AbnpsTable = ({ contents }) => {
  const [tabHeader, setHeader] = useState(contents?.attributes?.table_headers || {});
  const [tabRows, setRows] = useState({});
  const [tabColSpan, setColSpan] = useState([]);
  const [tabRowsAll, setRowsAll] = useState({});
  const [isRows, setRowsUpdate] = useState(true);

  const [defaultAge] = useState(contents?.attributes?.default_value);
  const [minAge] = useState(contents?.attributes?.min_value || 18);
  const [maxAge] = useState(contents?.attributes?.max_value || 70);

  const [selectedAge, setAge] = useState(defaultAge);
  // const [isAgeChange, setAgeSelected] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { aboutnps_table } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (contents) {
      setHeader(contents?.attributes?.table_headers);
      setRows(contents?.attributes?.table_dynamic_data);
      setColSpan(contents?.attributes?.colspan_text);
      setRowsAll(contents?.attributes?.table_dynamic_data);
    }
  }, [contents]);


  useEffect(() => {
    if (selectedAge > 0) {
      dispatch(fetchAboutNPSTable(selectedAge));
    }
    else setRows(tabRowsAll);
  }, [selectedAge]);

  useEffect(() => {
    if (tabHeader || tabRows || tabColSpan) {
      if (tabRows != null && tabRows != undefined && Object.values(tabRows)?.length) {
        setRowsUpdate(true);
      }
    }
  }, [tabHeader, tabRows, tabColSpan]);

  useEffect(() => {
    if (aboutnps_table) {
      setRows(aboutnps_table?.data)
    }
  }, [aboutnps_table])

  return (
    <div className="px-0  p-3 mt-5 bg-gray-100">
      {
        contents?.attributes?.table_heading && (<div className="text-left pt-3 text-sm text-black pl-2">{parseString(contents?.attributes?.table_heading)}</div>)
      }
      <div>
        <div className="container mx-auto mt-4 text sm:p-2 flex flex-col lg:flex-col justify-between ">
          <div className=" bg-white text-white rounded-lg  border border-grey-neutral">
            <div className="flex flex-col lg:flex-row justify-between ">
              <div className="bg-primitive-BLUE-100 p-3 w-full  lg:w-6/12">
                <>
                  <h5 className="text-primary-BLACK text-left text-base font-semibold	 whitespace-nowrap">
                    Enter your age
                  </h5>
                  <AgeFilter minAge={minAge} maxAge={maxAge} selected={selectedAge} setSelection={setAge} />
                  <p className="text-left pt-3 text-sm text-neutral-GRAY font-normal">
                    The distribution percentage of money is mentioned below
                    based on your age.
                  </p>
                </>
              </div>

              <div className=" w-full lg:w-11/12">
                {isRows && (
                  <table className="w-full">
                    <React.Fragment>
                      {tabHeader && (
                        <>
                          <thead>
                            <tr className="text-blue-700 horizontal-b ">
                              {tabHeader?.map((obj, oindex) => (
                                <th
                                  key={`header-${oindex}`}
                                  className={`px-3 py-4 text-primitive-BLUE-400 font-medium text-xs ${(oindex + 1) % 2 == 0
                                      ? 'horizontal-l horizontal-r'
                                      : ''
                                    }`}>
                                  {parseString(obj?.header_title)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                        </>
                      )}
                      
                      {tabRows != undefined && tabRows != null && Object.values(tabRows) && (
                        <tbody>
                          {Object.values(tabRows).map((obj, rowIndex) => (
                            <React.Fragment
                              key={`col-${Date.now()}-${rowIndex}`}
                            >
                              {tabColSpan?.length &&
                                tabColSpan[rowIndex]?.colspan_text && (
                                  <tr className="w-full">
                                    <td
                                      colSpan={tabColSpan?.length}
                                      className="text-xs text-center lg:text-sm text-primitive-OLIVE-40 bg-primitive-OLIVE-100 w-full p-0 lg:p-2"
                                    >
                                      {parseString(
                                        tabColSpan[rowIndex]?.colspan_text
                                      )}
                                    </td>
                                  </tr>
                                )}

                              {Object.values(obj)?.length && (
                                <tr className="text-black font-medium text-sm horizontal-b">
                                  {Object.values(obj)?.map((object, col) => (
                                    <React.Fragment
                                      key={`col-${Date.now()}-${col}`}
                                    >
                                      {object && (
                                        <td
                                          className={`px-3 py-4 text-black font-mediam 
                                                                                        ${(col +
                                              1) %
                                              2 ==
                                              0
                                              ? 'horizontal-l horizontal-r'
                                              : ''
                                            } `}
                                        >
                                          {parseString(
                                            object
                                          )}
                                        </td>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      )}
                    </React.Fragment>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {contents?.attributes?.table_description && (
        <div className="bg-white md:rounded-b-lg overflow-hidden">
          <div className="container mx-auto mt-4 bg-white p-6 flex flex-col lg:flex-col justify-between ">
            <div className=" text-base text-GRAY-DEFAULT font-normal text-left">
              {parseString(contents?.attributes?.table_description)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AbnpsTable.propTypes = {
  contents: PropTypes.object || undefined,
};
export default AbnpsTable;
