import axios from 'axios';
import { decrypt } from '../../common/AppConstants';

//const { protocol, hostname } = window.location;
//const { protocol } = window.location;

const { REACT_APP_STRAPI_API_URL, REACT_APP_AUTH_TOKEN } = process.env;

//const API = `${protocol}//${hostname}:${REACT_APP_SERVER_PORT}/api/${REACT_APP_API_VER}`;

const API = `${REACT_APP_STRAPI_API_URL}/`;


axios.defaults.validateStatus = () => {
  return true;
};

// GET
export const GET_METHOD = async (url = '', params = {}) => {
  //const session_data = decrypt(sessionStorage.getItem('user'));
  // const access_token =  getAuthToken();
  const access_token = REACT_APP_AUTH_TOKEN;

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${access_token}`
  };

  const result = await axios.get(API + url, { headers, params });
  return result;
};

// POST
export const POST_METHOD = async (url, params, customHeaders) => {
  const session_data = decrypt(sessionStorage.getItem('user'));
  const access_token = session_data?.accessToken || '';

  const headers = {
    ...customHeaders,
    'Content-Type': 'application/json',
    'x-access-token': access_token
  };

  const result = await axios.post(API + url, params, { headers });
  return result;
};

// DELETE
export const DELETE_METHOD = async (url, params) => {
  const session_data = decrypt(sessionStorage.getItem('user'));
  const access_token = session_data?.accessToken || '';

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': access_token
  };

  const result = await axios.delete(API + url, { params, headers });
  return result;
};

// PUT
export const PUT_METHOD = async (url, params, customHeaders) => {
  const session_data = decrypt(sessionStorage.getItem('user'));
  const access_token = session_data?.accessToken || '';

  const headers = {
    ...customHeaders,
    'Content-Type': 'application/json',
    'x-access-token': access_token
  };

  const result = await axios.put(API + url, params, { headers });
  return result;
};
