
import React, { useEffect, useState } from 'react';
import DownloadCardDisclosure from "../Downloaders/DownloadCardViewDisclosure";
import dm_icon from '../../../assets/images/downlaodicon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHalfYear } from '../../../redux/actions/PageAction';
import { parseString } from '../../AppConstants';
import SelectNormal from '../DropDown/SelectNormal';


const FinancialReportTable = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { half_year_contents } = useSelector((state) => state.pageState);

  const [files, setFiles] = useState();
  const [tableHeaders, setTableHeaders] = useState();
  const [tableCols, setTableCols] = useState();
  const [fileCaption, setCaption] = useState();

  const [options, setOptions] = useState();
  const [selected, setSelection] = useState();
  const [defaultVal, setDefault] = useState();

  useEffect(() => {
    if (contents) {
      let ds = contents?.data?.attributes?.page_body_cmp[0];
      if (ds) {
        // setFiles(ds?.files_included);
        // setTableHeaders(ds?.dynamic_tables?.data[0]?.attributes?.table_headers);
        // setTableCols(ds?.dynamic_tables?.data[0]?.attributes?.colspan_text);
        setCaption(ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.filename);
        let max = ds?.dynamic_tables?.data[0]?.attributes?.max_value;
        let min = ds?.dynamic_tables?.data[0]?.attributes?.min_value;
        let default_value = ds?.dynamic_tables?.data[0]?.attributes?.default_value || null;

        if (max > 0 && min > 0) {
          let dataSet = [];
          for (let r = min; r <= max; r++) {

            dataSet.push({
              key: `${r} H1`,
              cors: 'H1',
              year: r,
              value: `${r} H1`
            })
            dataSet.push({
              key: `${r} H2`,
              cors: 'H2',
              year: r,
              value: `${r} H2`
            })
          }
          setOptions(dataSet);
          // setDefault(dataSet[dataSet?.length - 1]);

          if (default_value) {
            dataSet?.forEach((obj) => {
              if (obj?.value == default_value || obj?.year == default_value) {
                setDefault(obj);
              }
            })
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (selected) {
      if (selected?.year && selected?.cors) {
        let param = `${selected?.year}/${selected?.cors}`;

        dispatch(fetchHalfYear(param));
      }
    }
  }, [selected])

  const getColumnVal = (row, column) => {
    let data = Object.entries(half_year_contents);
    let out = '-';
    data?.forEach(([key, value]) => {
      if (key == row) {
        Object.entries(value)?.forEach(([col, rows]) => {
          if (col == `col_${column}`) {
            out = rows?.data ? <DownloadCardDisclosure
              text=""
              contents={rows}
              icon={dm_icon} /> : '-';
          }
        });
      }
    });
    return out;
  };

  useEffect(() => {
    if (half_year_contents) {
      if (tableHeaders == null || tableHeaders == undefined)
        setTableHeaders(half_year_contents?.table_details?.table_headers);
      if (tableCols == null || tableCols == undefined)
      setTableCols(half_year_contents?.table_details?.colspan_text);

      setFiles(half_year_contents?.balance_sheet);
    }
  }, [half_year_contents])

  return (
    <div className='p-[15px] bg-primary-LIGHT '>
      <div className=" flex flex-col lg:flex-col justify-between">

        <div className="bg-white text-white rounded-lg lg:card-shadow-custom lg:border lg:border-grey-neutral">
          <div className="flex flex-col lg:flex-row justify-between ">
            <div className="bg-primitive-BLUE-100   py-5 lg:py-5 px-5 w-full lg:w-80 flex flex-col md:flex-row lg:flex-col  justify-between lg:rounded-l-lg rounded-t-lg lg:rounded-[0px]">
              <div className='flex flex-col'>
                <h5 className="text-[16px] lg:text-[18px] text-primary-BLACK  md:text-center lg:text-left whitespace-nowrap font-semibold">
                  Select Financial Year
                </h5>

                <div className="flex items-start justify-start  mt-5 p-5 ">
                  {options && <SelectNormal selected={selected} setSelection={setSelection} options={options} defaultVal={defaultVal} />}
                </div>
              </div>

              {
                files && (
                  <div className='mt-0 lg:mt-5 '>
                    <DownloadCardDisclosure
                      text={fileCaption}
                      contents={files}
                      icon={dm_icon} />
                  </div>
                )
              }
            </div>

            <div className="w-full overflow-x-auto">
              {half_year_contents && (
                <table className="w-full">
                  {
                    tableHeaders && (
                      <thead>
                        <tr className="text-blue-700 border-black border-b">
                          {
                            tableHeaders?.map((obj, index) => (
                              <th key={`thead-${index}`}
                                className="px-2 py-2 text-primitive-BLUE-400 text-[12px] md:text-[14px] font-medium horizontal-l">
                                {obj?.header_title}
                              </th>
                            ))
                          }
                        </tr>
                      </thead>
                    )
                  }
                  <tbody>
                    {
                      tableCols?.map((obj, index) => (
                        <React.Fragment key={index}>
                          <tr key={`cols-${index}`} className='bg-primitive-OLIVE-100'>
                            <td colSpan={tableHeaders?.length || 10}
                              className=" text-center text-[12px] md:text-[14px] bg-primitive-OLIVE-100 font-medium text-primitive-OLIVE-400 w-full  px-2 py-4">
                              {parseString(obj?.colspan_text)}
                            </td>
                          </tr>
                          <tr key={`cols-${index}${new Date().now}`} className="text-black font-medium text-sm horizontal-b">
                            {
                              tableHeaders?.map((objC, indexC) => (
                                <td key={`col-${indexC}`}
                                  className=" text-[12px] md:text-[14px] lg:text-[18px] px-2 py-2 lg:px-3 lg:py-4 font-medium horizontal-l">
                                  {getColumnVal(`tier${index + 1}`, objC?.col_index)}
                                </td>
                              ))
                            }
                          </tr>
                        </React.Fragment>
                      ))
                    }
                  </tbody>
                </table>)
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FinancialReportTable;