import React, { useEffect, useState } from "react";
import DownloadCardDisclosureSecond from "../Downloaders/DownloadCardDisclosureSecond";
import { useDispatch, useSelector } from "react-redux";
import { resetCommFile } from "../../../redux/actions/CommonAction";
import { fetchAGMFiles, fetchAGMOFiles } from "../../../redux/actions/PageAction";

const FileDownloadAccordion = ({ contents }) => {
    // Redux
    const dispatch = useDispatch();
    const { fragm_files, fragm_other_files } = useSelector(
        (state) => state.pageState
    );

    const [dataState, setDataState] = useState(null);

    useEffect(() => {
        dispatch(resetCommFile());
        if (contents?.data?.attributes?.internal_identifier == "agm_notice_public_disclosure") {
            dispatch(fetchAGMFiles("agm-notice"));
        }
        if (contents?.data?.attributes?.internal_identifier == "others_public_disclosure") {
            dispatch(fetchAGMOFiles("agm-others"));
        }
    }, [contents]);


    useEffect(() => {
        setDataState(null);
        if (fragm_files != null && contents?.data?.attributes?.internal_identifier == "agm_notice_public_disclosure") {
            setDataState(fragm_files);
        }
        if (fragm_other_files != null && contents?.data?.attributes?.internal_identifier == "others_public_disclosure") {
            setDataState(fragm_other_files);
        }
    }, [fragm_files, fragm_other_files])

    return (
        <div className="px-[8px] py-[8px] bg-primary-LIGHT ">
            <div className="bg-primary-LIGHT grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  
            lg:grid-cols-3  md:gap-3 max-h-72 overflow-y-scroll">
                {dataState?.data?.data?.map((obj, index) => (
                    <DownloadCardDisclosureSecond
                        key={`dlc-${index}`}
                        heading={obj?.attributes?.file_display_name}
                        contents={obj}
                    />
                ))}
            </div>
        </div>
    );
};

export default FileDownloadAccordion;
