import React, { useEffect } from "react";
import DownloadCardDisclosureThird from "../Downloaders/DownloadCardDisclosureThird";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicyFileComp } from "../../../redux/actions/PageAction";
import { resetCommFile } from "../../../redux/actions/CommonAction";


const ComplianceStewardshipPolicy = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { policy_comp_files } = useSelector((state) => state.pageState);

  useEffect(() => {
    dispatch(resetCommFile());
  }, [])

  useEffect(() => {
    dispatch(resetCommFile());
    dispatch(fetchPolicyFileComp('compliance-of-stewardship'));
  }, [contents])

  return (
    <div className=' p-[5px] md:p-[24px] bg-primary-LIGHT '>
      <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2  max-h-72 overflow-y-scroll ">
        {
          policy_comp_files?.data?.data?.map((obj, index) => (
            <DownloadCardDisclosureThird key={`dlc-${index}`}
              heading={obj?.attributes?.file_display_name}
              heading2={obj?.attributes?.tag || ''}
              contents={obj} />
          ))
        }
      </div>
    </div>
  )
}

export default ComplianceStewardshipPolicy;