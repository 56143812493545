import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchHomeCalulator } from '../redux/actions/PageAction';
import { resetPageLoader, showPageLoader } from "../redux/actions/CommonAction";
import Menu from "../common/layout/Menu";
import Footer from "../common/layout/Footer";
import HomeCalculator from "../common/components/Calculator/HomeCalculator";
import { toLower } from "lodash";

import { Helmet } from 'react-helmet';

const Calculator = () => {
    const [body, setBody] = useState({});
    const [content, setContent] = useState({});

    // Redux
    const dispatch = useDispatch();
    const { home_calculator } = useSelector((state) => state.pageState);

    useEffect(() => {
        if (home_calculator == null) {
            dispatch(fetchHomeCalulator({}));
            dispatch(showPageLoader());
        }
    }, [home_calculator, body]);

    useEffect(() => {
        if (home_calculator) {
            setContent(home_calculator?.data ? home_calculator?.data[0] : {});
            setBody(
                home_calculator?.data[0]?.attributes?.page_body_elements?.data
                    ? home_calculator?.data[0]?.attributes?.page_body_elements?.data[0]
                    : {}
            );

            dispatch(resetPageLoader());
        }
    }, [home_calculator]);

    //Calculator
    const [calcAPI, setCalcAPI] = useState(false);

    const [activeStep, setStep] = useState(1);
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(0);
    const [retrMinAge, setRetMinAge] = useState(0);
    const [retrMaxAge, setRetMaxAge] = useState(0);

    const [currentAge, setCurrentAge] = useState('');
    const [retirementAge, setRetirementAge] = useState('');

    const [currentAgeDefault, setCurrentAgeDefault] = useState('');
    const [retirementAgeDefault, setRetirementAgeDefault] = useState('');

    const [gender, setGender] = useState(true);//Female;
    const [calculationType, setCalculationType] = useState('');

    //Step 2
    const [iwanttoinvest, setIwantToInvest] = useState('');
    const [iwanttoinvestMin, setIwantToInvestMin] = useState('');

    const [iwanttoinvestMode, setIwantToInvestMode] = useState('month');
    const [annualincrementof, setAnnualIncrementOf] = useState('');

    const [investmentstrategy, setInvestmentStrategy] = useState('');
    const [expectedrateofreturn, setExpectedRateofReturn] = useState('');
    const [expectedrateofreturnEdit, setExpectedRateofReturnEdit] = useState(false);

    const [iwanttotalfundsof, setIwanttotalFundsOf] = useState('500');
    const [pensionof, setPensionOf] = useState('500');
    const [pensionofMode, setPensionOfMode] = useState('month');

    const [anyotherschemes, setAnyOtherSchemes] = useState(false); //NO

    const [any_other_MutualFunds, setAnyOtherMutualFunds] = useState('');
    const [any_other_epf, setAnyOtherEpf] = useState('');
    const [any_other_schemes, setAnyOtherSchemesInput] = useState('');

    const [currentImage, setCurrentImage] = useState('');
    const [retireImage, setRetireImage] = useState('');

    const [annutyPercentage, setAnnutyPercentage] = useState(40);
    const [lampsumPercentage, setLampsumPercentage] = useState('');
    const [rateofannuty, setRateofAnnuty] = useState(4);

    const setImageSrc = () => {
        if (body?.attributes?.page_body_cmp) {
            body?.attributes?.page_body_cmp.map((object) => {
                if (object?.internal_identifier === 'retirement_planner') {
                    let contents = object || {};

                    if (gender == true) {
                        if (contents?.retirement_planners) {
                            contents?.retirement_planners?.data?.map((obj) => {
                                if (toLower(obj?.attributes?.Gender) == "female") {
                                    if (obj?.attributes?.current_age_image) {
                                        setCurrentImage(obj?.attributes?.current_age_image?.data?.attributes?.url);
                                    }
                                    if (obj?.attributes?.retire_age_image) {
                                        setRetireImage(obj?.attributes?.retire_age_image?.data?.attributes?.url);
                                    }
                                }
                            });
                        }
                    }

                    if (gender == false) {
                        if (contents?.retirement_planners) {
                            contents?.retirement_planners?.data?.map((obj) => {
                                if (toLower(obj?.attributes?.Gender) == "male") {
                                    if (obj?.attributes?.current_age_image) {
                                        setCurrentImage(obj?.attributes?.current_age_image?.data?.attributes?.url);
                                    }
                                    if (obj?.attributes?.retire_age_image) {
                                        setRetireImage(obj?.attributes?.retire_age_image?.data?.attributes?.url);
                                    }
                                }
                            });
                        }
                    }
                }
            }
            )
        }
    };

    useEffect(() => {
        setImageSrc();
    }, [gender])

    useEffect(() => {
        if (body?.attributes?.page_body_cmp) {
            body?.attributes?.page_body_cmp.map((object) => {
                if (object?.internal_identifier === 'retirement_planner') {
                    let contents = object || {};

                    if (contents) {
                        if (contents?.retirement_planners?.data) {
                            contents?.retirement_planners?.data?.map((obj) => {
                                if (toLower(obj?.attributes?.Gender) == "female" && gender == true) {

                                    if (obj?.attributes?.current_age_detail) {
                                        setMinAge(obj?.attributes?.current_age_detail?.min);
                                        setMaxAge(obj?.attributes?.current_age_detail?.max);
                                        setCurrentAge(obj?.attributes?.current_age_detail?.default)
                                        setCurrentAgeDefault(obj?.attributes?.current_age_detail?.default)
                                    }

                                    if (obj?.attributes?.retire_age) {
                                        setRetMinAge(obj?.attributes?.retire_age[0]?.min);
                                        setRetMaxAge(obj?.attributes?.retire_age[0]?.max);
                                        setRetirementAge(obj?.attributes?.retire_age[0]?.default)
                                        setRetirementAgeDefault(obj?.attributes?.retire_age[0]?.default)
                                    }
                                }

                                if (toLower(obj?.attributes?.Gender) == "male" && gender == false) {

                                    if (obj?.attributes?.current_age_detail) {
                                        setMinAge(obj?.attributes?.current_age_detail?.min);
                                        setMaxAge(obj?.attributes?.current_age_detail?.max);
                                        setCurrentAge(obj?.attributes?.current_age_detail?.default)
                                        setCurrentAgeDefault(obj?.attributes?.current_age_detail?.default)
                                    }

                                    if (obj?.attributes?.retire_age) {
                                        setRetMinAge(obj?.attributes?.retire_age[0]?.min);
                                        setRetMaxAge(obj?.attributes?.retire_age[0]?.max);
                                        setRetirementAge(obj?.attributes?.retire_age[0]?.default);
                                        setRetirementAgeDefault(obj?.attributes?.retire_age[0]?.default);
                                    }
                                }
                            });
                        }

                        if (contents?.pension_after_retirement) {
                            if (contents?.pension_after_retirement?.invest_amt) {
                                setIwantToInvest(contents?.pension_after_retirement?.invest_amt || '')
                                setIwantToInvestMin(contents?.pension_after_retirement?.invest_amt || 500);
                            }
                            if (contents?.pension_after_retirement?.annuity_rate)
                                setAnnualIncrementOf(contents?.pension_after_retirement?.annuity_rate || 5);
                        }

                        if (contents?.how_to_invest) {
                            if (contents?.how_to_invest?.lumpsum_amt) {
                                setPensionOf(contents?.how_to_invest?.lumpsum_amt || '500');
                            }
                        }
                    }
                }
            });

            setImageSrc();
        }
    }, [body?.attributes?.page_body_cmp])

    useEffect(() => {
        if (investmentstrategy) {
            if (investmentstrategy == 'equity') {
                setExpectedRateofReturn('15.26');
                setExpectedRateofReturnEdit(false);
            }
            if (investmentstrategy == 'corporate') {
                setExpectedRateofReturn('9.19');
                setExpectedRateofReturnEdit(false);
            }
            if (investmentstrategy == 'gov') {
                setExpectedRateofReturn('9.46');
                setExpectedRateofReturnEdit(false);
            }
            if (investmentstrategy == 'custom') {
                setExpectedRateofReturnEdit(true);
            }
        }
    }, [investmentstrategy])

    useEffect(() => {
        if (anyotherschemes == false) {
            setAnyOtherMutualFunds('');
            setAnyOtherEpf('');
            setAnyOtherSchemesInput('');
        }
    }, [anyotherschemes])

    const resetAll = () => {

        setCurrentAge(currentAgeDefault);
        setRetirementAge(retirementAgeDefault);

        setGender(true);//Female;
        setCalculationType('');

        setIwantToInvest('');
        setIwantToInvestMin('');
        setIwantToInvestMode('month');
        setAnnualIncrementOf('');
        setInvestmentStrategy('');
        setIwanttotalFundsOf('');
        setPensionOf('');
        setPensionOfMode('month');
        setAnyOtherSchemes(false);
        setExpectedRateofReturn('');
        setExpectedRateofReturnEdit(false);
        setAnyOtherMutualFunds('');
        setAnyOtherEpf('');
        setAnyOtherSchemesInput('');
        setStep(1);
        setAnnutyPercentage(40);
        setLampsumPercentage('');
        setRateofAnnuty(4);
    };
    //Calculator

    return (
        <>
            {content?.attributes?.meta_title ? (
                <>
                    <Helmet>
                        <title>{content?.attributes?.meta_title}</title>
                        <meta name="description" content={content?.attributes?.meta_description} />
                        <meta name="keywords" content={content?.attributes?.meta_keywords} />
                    </Helmet>
                </>) : null}

            <div className="bg-site-FFF4EC">
                {/* NAVBAR SECTION */}
                <Menu key="home-menu" showScroller={false} />
                {/* NAVBAR SECTION */}
                <div className="mt-28"></div>
                {
                    body?.attributes?.page_body_cmp &&
                    body?.attributes?.page_body_cmp.map((object, index) => (
                        <React.Fragment key={`hc-${index}`}>
                            {object?.internal_identifier === 'retirement_planner' && (
                                <HomeCalculator
                                    key={index}
                                    contents={object}
                                    apiCall={calcAPI}
                                    setApiCall={setCalcAPI}
                                    activeStep={activeStep} setStep={setStep}
                                    minAge={minAge} setMinAge={setMinAge}
                                    maxAge={maxAge} setMaxAge={setMaxAge}
                                    retrMinAge={retrMinAge} setRetMinAge={setRetMinAge}
                                    retrMaxAge={retrMaxAge} setRetMaxAge={setRetMaxAge}
                                    currentAge={currentAge} setCurrentAge={setCurrentAge}
                                    retirementAge={retirementAge} setRetirementAge={setRetirementAge}
                                    gender={gender} setGender={setGender}
                                    calculationType={calculationType} setCalculationType={setCalculationType}
                                    iwanttoinvest={iwanttoinvest} setIwantToInvest={setIwantToInvest}
                                    iwanttoinvestMin={iwanttoinvestMin} setIwantToInvestMin={setIwantToInvestMin}
                                    iwanttoinvestMode={iwanttoinvestMode} setIwantToInvestMode={setIwantToInvestMode}
                                    annualincrementof={annualincrementof} setAnnualIncrementOf={setAnnualIncrementOf}
                                    investmentstrategy={investmentstrategy} setInvestmentStrategy={setInvestmentStrategy}
                                    expectedrateofreturn={expectedrateofreturn} setExpectedRateofReturn={setExpectedRateofReturn}
                                    expectedrateofreturnEdit={expectedrateofreturnEdit} setExpectedRateofReturnEdit={setExpectedRateofReturnEdit}
                                    iwanttotalfundsof={iwanttotalfundsof} setIwanttotalFundsOf={setIwanttotalFundsOf}
                                    pensionof={pensionof} setPensionOf={setPensionOf}
                                    pensionofMode={pensionofMode} setPensionOfMode={setPensionOfMode}
                                    anyotherschemes={anyotherschemes} setAnyOtherSchemes={setAnyOtherSchemes}
                                    any_other_MutualFunds={any_other_MutualFunds} setAnyOtherMutualFunds={setAnyOtherMutualFunds}
                                    any_other_epf={any_other_epf} setAnyOtherEpf={setAnyOtherEpf}
                                    any_other_schemes={any_other_schemes} setAnyOtherSchemesInput={setAnyOtherSchemesInput}
                                    resetAll={resetAll}
                                    currentImage={currentImage}
                                    retireImage={retireImage}
                                    annutyPercentage={annutyPercentage}
                                    lampsumPercentage={lampsumPercentage}
                                    setAnnutyPercentage={setAnnutyPercentage}
                                    setLampsumPercentage={setLampsumPercentage}
                                    rateofannuty={rateofannuty}
                                    setRateofAnnuty={setRateofAnnuty}
                                />)
                            }
                        </React.Fragment>))
                }
                {/* FOOTER */}
                < Footer key="home-footer" />
                {/* FOOTER */}

            </div>
        </>
    )
};

export default Calculator;