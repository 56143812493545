import React from "react";

const ToggleButton = ({ isToggle, setToggle = () => { }, bg = '' }) => {
    const toggle = () => {
        setToggle(isToggle => !isToggle);
    }

    return (
        <button className={`tg-button ${isToggle ? 'on' : 'off'} ${bg != '' ? bg : ' bg-primary '} `} onClick={toggle}>
            <span className="pin" />
        </button>
    );

}

export default ToggleButton;
