import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IsEmpty, getFileExt, getFilenameUrl, parseString } from '../../AppConstants';

const AccordionDownload = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    
    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const handleFileClick = (item) => {
        if (item) {
            let fileName = getFilenameUrl(item?.attributes?.redirect_to) || '';
            let ext = getFileExt(item?.attributes?.redirect_to) || 'pdf';
            fileName = `${fileName}.${ext}`;
            fileName = IsEmpty(fileName) ? `${Date.now()}.${ext}` : fileName;

            fetch(item?.attributes?.redirect_to)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName || 'downloaded-file';
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error fetching the file:', error);
                });
        }
    };


    return (
        <div className="mb-4 px-0 py-2">
            {items?.map((item) => (
                <div
                    key={item?.index}
                    className="mb-4 focus:outline-none transition ease-in-out delay-150"
                >
                    <div
                        className="cursor-pointer"
                        onClick={() => handleClick(item?.index)}
                    >
                        <div className="flex justify-between items-center gap-4">
                            <h2
                                className={`font-normal text-base cursor-pointer ${activeIndex === item?.index ? 'text-primary' : 'text-neutral-GRAY-100'
                                    }`}
                            >
                                {parseString(item.title, { class: 'hover:text-white' })}
                            </h2>
                            <div className="w-6 h-6 transition-transform flex justify-center items-center">
                                {activeIndex === item?.index ? (
                                    <span className="font-material-icons material-symbols-outlined text-lg text-gray-800">
                                        remove
                                    </span>
                                ) : (
                                    <span className="font-material-icons material-symbols-outlined text-lg text-gray-400">
                                        add
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {activeIndex === item?.index && (
                        <div>
                            {
                                item?.content?.attributes?.link_set?.data &&
                                item?.content?.attributes?.link_set?.data?.map((item, index) => (
                                    <div key={index} className="text-neutral-GRAY-100 hover:text-white font-normal text-base cursor-pointer px-0 py-2"
                                        onClick={() => handleFileClick(item)}>
                                        {parseString(item?.attributes?.link_text, { class: 'hover:text-white break-words' })}
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

AccordionDownload.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.any,
        })
    ).isRequired,
};

export default AccordionDownload;
