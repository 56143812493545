import React from 'react';
import PropTypes from 'prop-types';
import { parseString } from '../../AppConstants';

const CardCopyStatistics = ({ contents }) => {
  return (
    <>
      {contents?.data?.map((object, index) => (
        <div
          key={`hinw-${index}`}
          className="bg-white p-4 lg:p-6 rounded-lg card-shadow-News-Blogs"
        >
          <div className="mb-2 text-neutral-BLACK-DARKER text-2xl lg:text-4xl font-extrabold">
            {parseString(object?.attributes?.statistics_data)}
          </div>
          <div className="text-neutral-BLACK-DARK-400 text-sm lg:text-1xl">
            {parseString(object?.attributes?.statistic_heading)}
          </div>
        </div>
      ))}
    </>
  );
};
CardCopyStatistics.propTypes = {
  contents: PropTypes.object,
};
export default CardCopyStatistics;
