
import React from 'react';
import { parseString } from '../../AppConstants';

const TableDisclosure = ({ headers, colspan, contents }) => {
  
  const getColumnVal = (row, column) => {
    let data = Object.entries(contents);
    let out = '-';
    data?.forEach(([key, value]) => {
      if (key == row) {
        Object.entries(value)?.forEach(([col, rows]) => {
          if (col == column) {
            out = rows;
          }
        });
      }
    });
    return out;
  };

  return (<table className="w-full">
    {
      headers && (
        <thead>
          <tr className="text-blue-700 border-black border-b">
            {
              headers?.map((obj, index) => (
                <th key={`thead-${index}`} className="  px-2 py-2 text-primitive-BLUE-400 text-[12px] md:text-[14px] font-medium horizontal-l">
                  {obj?.header_title}
                </th>
              ))
            }
          </tr>
        </thead>
      )
    }
    <tbody>
      {
        colspan?.map((obj, index) => (
          <React.Fragment key={index}>
            <tr key={`cols-${index}`} className='bg-primitive-OLIVE-100'>
              <td colSpan={headers?.length || 10}
                className=" text-center text-[12px] md:text-[14px] bg-primitive-OLIVE-100 
              font-medium text-primitive-OLIVE-400 w-full  px-2 py-4">
                {parseString(obj?.colspan_text)}
              </td>
            </tr>
            <tr key={`cols-${index}${new Date().now}`} className="text-black font-medium text-sm horizontal-b">
              {
                headers?.map((objC, indexC) => (
                  <td key={`col-${indexC}`}
                    className=" text-[12px] md:text-[14px] lg:text-[18px] 
                     px-2 py-2 lg:px-3 lg:py-4 font-medium horizontal-l">
                    {getColumnVal(`row${index + 1}`, objC?.col_index)}
                  </td>
                ))
              }
            </tr>
          </React.Fragment>
        ))
      }
    </tbody>
  </table>)
}

export default TableDisclosure;