import React from 'react';
import PropTypes from 'prop-types';

import { parseString } from '../../AppConstants';
import CardCopy from './CardCopy';

const HomeInvestNpsCopy = ({ contents }) => {
  return (
    <div
      key={`${Date.now()}-hinc${contents?.id}`}
      className="container mx-auto px-5 md:px-10 py-6 md:py-10 lg:py-12 flex flex-col items-center"
    >
      <div>
        {contents?.marketing_cmp_heading && (
          <h2 className="text-center text-darker text-xl md:text-2xl lg:text-4xl  font-bold">
            {parseString(contents?.marketing_cmp_heading)}
          </h2>
        )}
        {contents?.marketing_cmp_description && (
          <div className="text-neutral-GRAY text-center mt-4 text-sm md:text-lg">
            {parseString(contents?.marketing_cmp_description)}
          </div>
        )}
      </div>

      {contents?.choose_marketing_blocks?.data?.length && (
        <>
          <div className="grid grid-cols-1 mt-6 md:mt-8 lg:mt-10 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            {contents?.choose_marketing_blocks?.data?.map((object, index) => (
              <CardCopy key={`cc-${index}`} content={object} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

HomeInvestNpsCopy.propTypes = {
  contents: PropTypes.object || undefined,
};
export default HomeInvestNpsCopy;
