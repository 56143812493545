import React, { useCallback, useEffect, useState } from 'react';

import toparrow_icon from '../../../assets/images/toparrowicon.svg';
import bottomarrow_icon from '../../../assets/images/bottomarrowicon.svg';

import { IsEmpty, setData } from '../../AppConstants';

const UpDownInput = ({ valueInput,
    setInput = () => { }, name,
    placeholder = '',
    max, min,
    validation = 'decimal', maxLength = null, isEdit = true }) => {

    const [value, setValuePage] = useState(valueInput || '');

    useEffect(() => {
        if (!IsEmpty(valueInput)) {
            setValuePage(valueInput);
        }
    }, [])

    useEffect(() => {
        if (valueInput != value) {
            setValuePage(valueInput);
        }
    }, [valueInput])

    const changePlus = () => {
        if (isEdit) {
            if (max > 0) {
                if (parseFloat(value) >= 0 && parseFloat(value) < max)
                    setValuePage(parseFloat(value) + 1);
            }
            else {
                if (value >= 0)
                    setValuePage(parseFloat(value) + 1);
            }
        }
    };

    const changeMinus = () => {
        if (isEdit) {
            if (min > 0) {
                if (parseFloat(value) > min)
                    setValuePage(parseFloat(value) - 1);
            }
            else {
                if (value > 1)
                    setValuePage(parseFloat(value) - 1);
                else
                    setValuePage('0');
            }
        }
    };

    const handleChange = useCallback((event) => {
        const { value } = event.target;
        if (validation == 'decimal') {
            var validRegex = /^\d*\.?\d*$/;
            if (value && validRegex.test(value)) {
                if (max > 0) {
                    if (parseFloat(value) <= max) {
                        setValuePage(value);
                    }
                }
                else
                    setValuePage(value);
            }
            else if (IsEmpty(value))
                setValuePage('');
        }
        else
            setValuePage(value);
    }, []);

    useEffect(() => {
        setData(name, value)
        setInput(value);
    }, [value])

    return (<div className="flex items-center justify-center border-b-[3px]  py-2 ml-5 md:ml-0">
        <input
            type='text'
            className="font-semibold text-[14px] md:text-[16px] lg:text-[20px] w-[90px] md:w-[120px] text-center"
            value={value || ''}
            placeholder={placeholder || ''}
            onChange={handleChange}
            maxLength={maxLength || null}
            readOnly={!isEdit}
            name={name}
        />
        <div className=" ml-2 md:ml-10 flex flex-col">
            <span className='cursor-pointer' onClick={() => changePlus()}><img className="w-[17px] h-[17px] p-0 m-0" src={toparrow_icon} /></span>
            <span className='cursor-pointer' onClick={() => changeMinus()}><img className="w-[17px] h-[17px] p-0 m-0" src={bottomarrow_icon} /></span>
        </div>
    </div >
    )
}

export default UpDownInput;