import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  NotFound,
  Home,
  AboutUs,
  AboutNps,
  NpsSolution,
  Faq,
  Blog,
  Sitemap,
  ComingSoon,
  BlogCategoryList,
  PubDisclosure,
  Calculator,
  Privacy,
} from '../../pages';

const AppRoutes = () => {
  return (
    <Router>
      <Routes path="/">
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/nps" element={<AboutNps />} />
        <Route path="/solution/:tab" element={<NpsSolution />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:category" element={<BlogCategoryList />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/soon" element={<ComingSoon />} />
        <Route path="/error" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/public_disclosure" element={<PubDisclosure />} />
        <Route path="/public_disclosure/:item" element={<PubDisclosure />} />
        <Route path="/privacy/" element={<Privacy />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
