import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import { toLower } from '../../AppConstants';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

const StyledButton = ({ cta = {}, url = '', extraClass = '', title = '' }) => {
  const [typeButton, setType] = useState('primary');
  const [cleanCta, setCta] = useState(null);
  useEffect(() => {
    if (cta) {
      if (cta?.attributes) {
        if (cta?.attributes?.cta_behaviour) {
          if (cta?.attributes?.cta_behaviour == 'LinkSecondary') {
            setType('secondary');
          } else setType('primary');
        } else setType(cta?.attributes?.cta_type);

        setCta(cta);
      } else if (cta?.data?.attributes) {
        if (cta?.data?.attributes?.cta_behaviour) {
          if (cta?.data?.attributes?.cta_behaviour == 'LinkSecondary') {
            setType('secondary');
          } else setType('primary');
        } else setType(cta?.data?.attributes?.cta_type);

        setCta(cta?.data);
      } else if (cta?.data[0]?.attributes) {
        if (cta?.data[0]?.attributes?.cta_behaviour) {
          if (cta?.data[0]?.attributes?.cta_behaviour == 'LinkSecondary') {
            setType('secondary');
          } else setType('primary');
        } else setType(cta?.data[0]?.attributes?.cta_type);

        setCta(cta?.data[0]);
      }
    }
  }, [cta, url, title]);

  return (
    <>
      {toLower(typeButton) == 'link' && (
        <LinkButton cta={cta} url={url} extraClass={extraClass} title={title} />
      )}
      {toLower(typeButton) == 'primary' && (
        <PrimaryButton
          cta={cleanCta}
          url={url}
          extraClass={extraClass}
          title={title}
        />
      )}
      {toLower(typeButton) == 'secondary' && (
        <SecondaryButton
          cta={cleanCta}
          url={url}
          extraClass={extraClass}
          title={title}
        />
      )}
      {toLower(typeButton) == 'button' && (
        <PrimaryButton
          cta={cleanCta}
          url={url}
          extraClass={extraClass}
          title={title}
        />
      )}
    </>
  );
};

StyledButton.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  openTab: PropTypes.bool,
  onClick: PropTypes.func,
  cta: PropTypes.object,
  extraClass: PropTypes.string,
};

export default StyledButton;
