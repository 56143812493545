import React from "react";
import DownloadCardDisclosureSecond from "../Downloaders/DownloadCardDisclosureSecond";

const ProxyYearEnd = ({ contents }) => {
    return (
        <div className='p-[8px] bg-primary-LIGHT '>
            <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-1 max-h-72 overflow-y-scroll">
                {
                    contents?.map((obj, index) => (
                        <DownloadCardDisclosureSecond key={`dlc-${index}`}
                            heading={obj?.attributes?.file_display_name}
                            contents={obj} />
                    ))
                }
            </div>
        </div>
    )
}

export default ProxyYearEnd;
