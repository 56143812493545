import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_nps_solution_government } from '../../layout/breadcrumb-data';

// images
import vector_nps from '../../../assets/images/vectornps.svg';

const BannerGovernmentSolution = ({ content }) => {
  return (
    <div className="bg-primary-light-orange mt-20 xl:mt-28 relative">
      <img src={vector_nps} className="absolute right-0  hidden  md:block" />
      <div className="container mx-auto pt-6 self-start pl-5">
        <BreadCrumb
          items={bc_nps_solution_government}
          key="nps-solution-retail-breadcrumb"
        />
      </div>
      <div className="px-5 py-6 md:py-24  mx-auto container   banner-border-custom text    flex flex-col lg:flex-row justify-between ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-3  md:gap-20">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_gov_content_${index}`}>
                {toLower(item?.position) == 'right' && (
                  <div
                    key={`img_right_${index}`}
                    className="w-full block md:hidden  order-first lg:overflow-y-auto mt-5 md:mt-0"
                  >
                    <img
                      className="w-full max-w-[340px] mx-auto"
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
                {toLower(item?.position) == 'left' && (
                  <div className="w-full  flex flex-col lg:flex-row justify-center    ">
                    <div className="  flex items-center justify-center flex-col md:items-start md:justify-center">
                      {item?.banner_heading && (
                        <h1 className=" text-center md:text-left lg:text-left  text-2xl md:text-4xl  lg:text-5xl font-bold ">
                          {parseString(item?.banner_heading)}
                        </h1>
                      )}
                      {item?.banner_description && (
                        <div className="text-sm md:text-lg text-center md:text-justify  text-gray-600 mb-8 font-normal   mt-3 md:mt-6  ">
                          {parseString(item?.banner_description)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {toLower(item?.position) == 'right' && (
                  <div className="w-full   md:flex items-center justify-center  hidden p-0  lg:overflow-y-auto">
                    <img
                      className="  w-full max-w-[540px] mx-auto	"
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerGovernmentSolution;
