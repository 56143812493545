import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// images
import vector_abtnps from '../../../assets/images/abtnpsvector.svg';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_about_nps } from '../../layout/breadcrumb-data';
import StyledButton from '../StyledButton/Index';

const BannerAboutNPS = ({ content }) => {
  return (
    <div className="bg-site-FFF0E3  relative mt-20 xl:mt-28 ">
      <img
        src={vector_abtnps}
        className=" absolute right-0 bottom-0 w-20 hidden md:block"
        alt="Image"
      />
      <div className="container mx-auto w-full align-start p-4 pt-8">
        <div className="">
          <BreadCrumb items={bc_about_nps} />
        </div>
      </div>

      <div className="container mx-auto xl:px-4  banner-border-custom text  pb-2 md:pb-8 flex flex-col lg:flex-row justify-between  overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_about_nps_content_${index}`}>
                {toLower(item?.position) == 'left' && (
                  <div className="w-full flex flex-col lg:flex-row justify-center items-start  p-4 md:py-0 lg:p-4 xl:pl-0">
                    <div>
                      {item?.banner_heading && (
                        <h1 className="text-left  font-bold text-2xl md:text-4xl  lg:text-[54px] lg:leading-[1.3em]	 ">
                          {parseString(item?.banner_heading)}
                        </h1>
                      )}

                      {item?.banner_description && (
                        <div className=" text-sm md:text-lg  font-normal  text-left text-primary-BLACK  mt-6  ">
                          {parseString(item?.banner_description)}
                        </div>
                      )}
                      {item?.ctas && (
                        <div className=" hidden  md:flex flex-row  items-center gap-4 justify-start mt-10 ">
                          {item?.ctas?.data?.map((cta, index) => (
                            <StyledButton
                              cta={cta}
                              key={`about_nps_banner_web_cta_${index}`}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {toLower(item?.position) == 'right' && (
                  <div className="w-full  h-100  p-0 md:p-0 lg:p-4  lg:overflow-y-auto">
                    <marquee
                      className="hidden md:block"
                      width="100%"
                      direction="up"
                    >
                      {item?.marketing_units_group?.data?.attributes
                        ?.marketing_units &&
                        item?.marketing_units_group?.data?.attributes?.marketing_units.map(
                          (unit, index) => (
                            <div
                              key={`slider_mktg_unit_${index}`}
                              className="flex flex-row items-center odd:mr-20 even:ml-20 mb-10"
                            >
                              <div className="bg-white py-2 px-4 mb-2 md:mb-4  card-shadow-custom   rounded-full   ">
                                <div className=" flex flex-row items-center ">
                                  {unit?.unit_media?.data?.attributes?.url && (
                                    <img
                                      src={
                                        unit?.unit_media?.data?.attributes?.url
                                      }
                                      className="pr-2 w-16 h-16 "
                                      alt={
                                        unit?.unit_media?.data?.attributes
                                          ?.alternativeText
                                      }
                                    />
                                  )}
                                  {unit?.unit_heading && (
                                    <div className="font-normal  pl-2  md:text-xl">
                                      {parseString(unit?.unit_heading)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </marquee>

                    <marquee
                      className=" block md:hidden "
                      width="100%"
                      height="100%"
                      direction="left"
                    >
                      <div className="flex flex-row items-start justify-star">
                        {item?.marketing_units_group?.data?.attributes
                          ?.marketing_units &&
                          item?.marketing_units_group?.data?.attributes?.marketing_units.map(
                            (unit, index) => (
                              <div
                                key={`slider_mktg_unit_${index}`}
                                className="flex flex-row  flex-shrink-0 items-center ml-10 mb-2"
                              >
                                <div className="py-2 px-4 mb-4 bg-white  md:mb-4  card-shadow-custom   rounded-full">
                                  <div className=" flex flex-row items-center ">
                                    {unit?.unit_media?.data?.attributes
                                      ?.url && (
                                      <img
                                        src={
                                          unit?.unit_media?.data?.attributes
                                            ?.url
                                        }
                                        className="pr-2 w-16 h-16 "
                                        alt={
                                          unit?.unit_media?.data?.attributes
                                            ?.alternativeText
                                        }
                                      />
                                    )}
                                    {unit?.unit_heading && (
                                      <div className="font-normal  pl-2  md:text-xl">
                                        {parseString(unit?.unit_heading)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>

                      <div className="flex flex-row items-start justify-start  pl-10 ">
                        {item?.marketing_units_group?.data?.attributes
                          ?.marketing_units &&
                          item?.marketing_units_group?.data?.attributes?.marketing_units
                            .map((unit, index) => (
                              <div
                                key={`slider_mktg_unit_${index}`}
                                className="flex flex-row  flex-shrink-0 items-center ml-10 mb-2"
                              >
                                <div className="py-2 px-4 mb-4 bg-white  md:mb-4  card-shadow-custom   rounded-full">
                                  <div className=" flex flex-row items-center ">
                                    {unit?.unit_media?.data?.attributes
                                      ?.url && (
                                      <img
                                        src={
                                          unit?.unit_media?.data?.attributes
                                            ?.url
                                        }
                                        className="pr-2 w-16 h-16 "
                                        alt={
                                          unit?.unit_media?.data?.attributes
                                            ?.alternativeText
                                        }
                                      />
                                    )}
                                    {unit?.unit_heading && (
                                      <div className="font-normal  pl-2  md:text-xl">
                                        {parseString(unit?.unit_heading)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                            .reverse()}
                      </div>
                    </marquee>
                  </div>
                )}

                {toLower(item?.position) == 'left' && (
                  <div className="px-4 flex  md:hidden flex-row flex-wrap justify-center items-center gap-4  mb-5 mt-10  order-last">
                    {item?.ctas &&
                      item?.ctas?.data?.map((cta, index) => (
                        <StyledButton
                          cta={cta}
                          key={`about_nps_banner_mobile_cta_${index}`}
                        />
                      ))}
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerAboutNPS;
