import React from "react";
import { parseString, parseTable } from "../../AppConstants";

const InvestmentManagementFee = ({ contents }) => {
  return (
    <div className='p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      <div className="bg-neutral-50  p-[24px]  rounded-[12px] ">
        <h5 className="text-neutral-GRAY font-semibold text-[14px]	lg:text-[16px]">
          {parseString(contents?.page_body?.data?.attributes?.page_body_cmp[0]?.table?.data?.attributes?.table_description)}
        </h5>
        <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2  gap-3  md:gap-5 mt-5">
          <div className="overflow-x-auto rounded-xl md:border md:border-neutral-GRAY-300 ">
            {parseTable(contents?.page_body?.data?.attributes?.
              page_body_cmp[0]?.table?.data?.attributes?.table_data)}
          </div>
          <div className="font-normal text-neutral-GRAY  text-[12px] lg:text-[14px] max-w-[500px]">
            {parseString(contents?.page_body?.data?.attributes?.page_body_cmp[0]?.marketing_extended_description)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestmentManagementFee;