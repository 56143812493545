import React, { useEffect } from 'react';
import DownloadCardDisclosureSecond from '../Downloaders/DownloadCardDisclosureSecond';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegularCircularsFiles } from '../../../redux/actions/PageAction';

const RegularCirculars = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { regular_circulars_files } = useSelector((state) => state.pageState);

  useEffect(() => {
    dispatch(fetchRegularCircularsFiles('regulatory-circulars'));
  }, [contents])


  return (
    <div className='p-[8px] md:p-[15px] lg:p-[24px]  border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-1 max-h-72 overflow-y-scroll">
        {
          regular_circulars_files?.data?.data?.map((obj, index) => (
            <DownloadCardDisclosureSecond key={`dlc-${index}`}
              heading={obj?.attributes?.file_display_name}
              contents={obj} />
          ))
        }
      </div>
    </div>
  );
};

export default RegularCirculars;





