import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '../common/layout/Menu';
import Footer from '../common/layout/Footer';

import { fetchAboutUs } from '../redux/actions/PageAction';
import { resetPageLoader, showPageLoader } from '../redux/actions/CommonAction';
// import BannerTop from '../common/layout/BannerTop';
import { parseString } from '../common/AppConstants';
import Thumbnail from '../common/components/Imager/Thumbnail';
import CardCopyUser from '../common/components/Cards/CardCopyUser';
import CardCopyStatistics from '../common/components/Cards/CardCopyStatistics';
import ContenSliderCopy from '../common/components/Sliders/ContenSliderCopy';
import FooterBanner from '../common/components/Banners/FooterBanner';
import ModalAbout from '../common/components/Modals/ModalAbout';
import ModalSponsers from '../common/components/Modals/ModalSponsers';
import BannerAboutUs from '../common/components/Banners/BannerAboutUs';

const AboutUs = () => {
  const [body, setBody] = useState({});
  const [openModal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [openModalSponsers, setModalSponsers] = useState(false);
  const [modalContentSponsers, setModalContentSpon] = useState(null);
  // Redux
  const dispatch = useDispatch();
  const { aboutus_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (aboutus_contents == null) {
      dispatch(fetchAboutUs({}));
      dispatch(showPageLoader());
    }
  }, [aboutus_contents, body]);

  useEffect(() => {
    if (aboutus_contents) {
      setBody(
        aboutus_contents?.data[0]?.attributes?.page_body_elements?.data
          ? aboutus_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
      dispatch(resetPageLoader());
    }
  }, [aboutus_contents]);

  useEffect(() => {
    if (!openModal) {
      setModalContent(null);
    }
    if (!openModalSponsers) {
      setModalContentSpon(null);
    }
  }, [openModal, openModalSponsers]);

  return (
    <>
      <div className="bg-primary-light-blue">
        {/* NAVBAR SECTION */}
        <Menu key="about-menu" />
        {/* NAVBAR SECTION */}

        {/* <BannerTop page="aboutus" key="aboutus-banner" /> */}

        {aboutus_contents?.data[0]?.attributes?.page_banners && (
          <BannerAboutUs
            content={aboutus_contents?.data[0]?.attributes?.page_banners}
          />
        )}

        <div className="container mx-auto mt-10 lg:mt-20 mb-8 px-4 md:px-6 lg:px-10 flex flex-col  items-center">
          {body?.attributes?.page_body_cmp &&
            body?.attributes?.page_body_cmp.map((object, index) => (
              <div key={`aboutus_page_body_${index}`} className="w-full">
                {object?.internal_identifier == 'board_of_directors' ||
                  object?.internal_identifier == 'management_team' ? (
                  <div>
                    {object?.heading && (
                      <h1 className="text-2xl text-center sm:text-2xl md:text-2xl lg:text-4xl font-bold mb-2 md:mb-4 text-BLACK">
                        {parseString(object?.heading)}
                      </h1>
                    )}
                    {object?.description && (
                      <div className="text-sm md:text-lg text-center px-3">
                        {parseString(object?.description)}
                      </div>
                    )}
                    <CardCopyUser
                      contents={object}
                      key={`cuc-abt-${index}`}
                      isModalOpen={openModal}
                      onImageClick={setModal}
                      setModalContent={setModalContent}
                    />
                  </div>
                ) : null}

                {object?.internal_identifier == 'sponsors_and_partners' && (
                  <div className="mt-10 md:mt-20">
                    {object?.heading && (
                      <h3 className="text-primary text-2xl sm:text-2xl md:text-2xl lg:text-4xl font-bold mb-4 md:mb-8 text-center">
                        {parseString(object?.heading)}
                      </h3>
                    )}
                    {object?.description && (
                      <div className="text-sm md:text-lg text-center px-3">
                        {parseString(object?.description)}
                      </div>
                    )}
                    {object?.sponsors_and_partners?.data.length && (
                      <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-5 md:gap-5">
                        <Thumbnail
                          contents={object?.sponsors_and_partners}
                          figContainerClass="h-full flex max-w-[180px] mx-2.5 sm:mx-0 md:mx-0 lg:mx-0 p-0 md:p-4"
                          isModalOpen={openModalSponsers}
                          onImageClick={setModalSponsers}
                          setModalContent={setModalContentSpon}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="">
          {body?.attributes?.page_body_cmp &&
            body?.attributes?.page_body_cmp.map((object, index) => (
              <div key={`about_page_body_cmp_${index}`}>
                {object?.internal_identifier ==
                  'invest_with_icici_pru_pension' && (
                    <div className="bg-site-FFF4EC w-full">
                      <div className="container mx-auto px-2 md:px-10 pt-10 lg:pt-20 pb-10 lg:pb-24">
                        <div className="mx-auto mb-6 md:mb-14 text-center">
                          {object?.statistics_cmp_heading && (
                            <h1 className="text-BLACK font-bold  text-xl md:text-2xl lg:text-4xl mb-2 md:mb-4 ">
                              {parseString(object?.statistics_cmp_heading)}
                            </h1>
                          )}
                          {object?.statistics_cmp_description && (
                            <div className="text-sm md:text-lg text-center px-3">
                              {parseString(object?.statistics_cmp_description)}
                            </div>
                          )}
                        </div>
                        {object?.statistics?.data?.length && (
                          <div className="grid grid-cols-2  md:grid-cols-2 lg:grid-cols-4  gap-3">
                            <CardCopyStatistics contents={object?.statistics} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {object?.internal_identifier == 'awards_and_recognition' && (
                  <div className="bg-site-FFF4EC w-full">
                    <div className="container mx-auto text-center pb-6 sm:pb-10 lg:pb-20 ">
                      <div className="mx-auto mb-6 md:mb-14 text-center">
                        {object?.heading && (
                          <h3 className="text-primary text-2xl sm:text-2xl md:text-2xl lg:text-4xl font-bold mb-2 md:mb-4  text-center">
                            {parseString(object?.heading)}
                          </h3>
                        )}
                        {object?.description && (
                          <div className="text-sm md:text-lg text-center px-3">
                            {parseString(object?.description)}
                          </div>
                        )}
                      </div>
                      {object?.awards_and_recognitions?.data.length && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex flex-wrap justify-center  gap-8 ">
                          <Thumbnail
                            contents={object?.awards_and_recognitions}
                            figContainerClass="h-full p-2"
                            parentContainerClass="lg:w-auto max-lg:mx-auto"
                            disableModal={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {object?.internal_identifier == 'sales_and_success_stories' && (
                  <div className="container mx-auto">
                    <ContenSliderCopy contents={object} />
                  </div>
                )}
              </div>
            ))}
        </div>

        {/* FOOTER BANNER */}
        {body?.attributes?.footer_banner && (
          <FooterBanner contents={body?.attributes?.footer_banner} />
        )}
        {/* FOOTER BANNER */}

        {openModal && modalContent && (
          <ModalAbout
            openModal={openModal}
            contents={modalContent}
            onCloseClick={setModal}
          />
        )}

        {openModalSponsers && modalContentSponsers && (
          <ModalSponsers
            openModal={openModalSponsers}
            contents={modalContentSponsers}
            onCloseClick={setModalSponsers}
          />
        )}

        {/* FOOTER */}
        <Footer />
        {/* FOOTER */}
      </div>
    </>
  );
};
export default AboutUs;
