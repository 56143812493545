import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Accordion from './Accordion';
// import { fdata } from './faq-data';

import faqhomeicon from '../../../assets/images/faqhomeicon.svg';
import { parseString } from '../../AppConstants';
import StyledButton from '../StyledButton/Index';

const FaqCopy = ({ contents = {}, bg = 'bg-site-FFF4EC' }) => {
  const [accData, setData] = useState([]);

  useEffect(() => {
    if (contents) {
      if (contents?.list_out_faqs?.data) {
        let dataset = [];
        contents?.list_out_faqs?.data.map((obj, idx) => {
          dataset.push({
            index: idx,
            title: obj?.attributes?.faq_question,
            content: obj?.attributes?.faq_answer?.faq_answer_text,
          });
        });

        setData(dataset);
      }
    }
  }, [contents]);

  return (
    <div
      className={`${
        bg != null ? bg : ''
      }   mx-auto p-0 flex flex-col items-center text-center w-full mt-5 md:mt-0`}
    >
      <img
        className="absolute top-0 left-0 hidden md:block w-20 md:w-40"
        src={faqhomeicon}
      />
      <div className="container mx-auto px-5 md:px-10 md:py-5 ">
        <div className="container-fluid  flex flex-col items-center text-center">
          {contents?.faq_cmp_heading && (
            <h1 className="font-bold  text-xl md:text-2xl lg:text-4xl mb-2 md:mb-4 mt-5 text-center">
              {parseString(contents?.faq_cmp_heading)}
            </h1>
          )}
          {contents?.faq_cmp_description && (
            <div className="  text-sm md:text-lg text-neutral-GRAY  px-10 md:px-20 lg:px-36 text-center">
              {parseString(contents?.faq_cmp_description)}
            </div>
          )}

          <div className="w-full mt-5  md:mt-14">
            <Accordion items={accData} />
          </div>
          {contents?.faq_ctas?.data?.length ? (
            <div className="my-5 md:my-10">
              <StyledButton
                cta={contents?.faq_ctas}
                url="/faq"
                title="View all FAQs"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

FaqCopy.propTypes = {
  contents: PropTypes.object,
  bg: PropTypes.string || undefined,
};
export default FaqCopy;
