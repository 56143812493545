import React from 'react';
import { parseString, toLower } from '../../common/AppConstants';

import stock_img from '../../assets/images/stocks.svg';
import return_img from '../../assets/images/return-icon.svg';

const AssetCopy = ({ contents }) => {
  const getMedia = (data) => {
    return data?.data?.attributes?.url || '';
  };

  const getTextColor = (val) => {
    if (toLower(val) == 'high') return 'text-primitive-BLUE-300';
    if (toLower(val) == 'moderate') return 'text-primary';
    if (toLower(val) == 'very high') return 'text-VERYHIGH';
    if (toLower(val) == 'Low') return 'text-SUCCESS-GREEN';
  };

  return (
    <div className="w-full bg-white text-white rounded-lg border border-grey-neutral mt-3 md:mt-0">
      <div className="p-4">
        <div className="flex items-center mb-4">
          {getMedia(contents?.attributes?.asset_class_icon) && (
            <div className="w-10 h-10 mr-3">
              <img
                src={getMedia(contents?.attributes?.asset_class_icon)}
                alt="Icon"
              />
            </div>
          )}
          <div className=" text-left">
            {contents?.attributes?.asset_class_name && (
              <h2 className="text-lg text-black font-semibold">
                {parseString(contents?.attributes?.asset_class_name)}
              </h2>
            )}

            {contents?.attributes?.primary_asset_classes && (
              <div className="text-sm text-gray-600">
                {parseString(contents?.attributes?.primary_asset_classes)}
              </div>
            )}
          </div>
        </div>

        {contents?.attributes?.asset_class_description && (
          <div className="mb-4 text-left">
            <div className="text-gray-800 text-sm">
              {parseString(contents?.attributes?.asset_class_description)}
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2  ">
          {/* Risk */}
          <div className="pr-2">
            {contents?.attributes?.risk?.data?.attributes?.risk_level && (
              <div className="">
                <h4
                  className={`${getTextColor(
                    contents?.attributes?.risk?.data?.attributes?.risk_level
                  )} text-sm mb-2 text-left`}
                >
                  {parseString(
                    contents?.attributes?.risk?.data?.attributes?.risk_level
                  )}
                </h4>
                {getProgress(
                  toLower(
                    contents?.attributes?.risk?.data?.attributes?.risk_level
                  )
                )}
              </div>
            )}

            <div className="mt-2 border-t-2 mb-2 ">
              <div className="pt-2 flex justify-between items-end">
                <p className="text-black text-sm">Risk</p>
                <div>
                  <img src={stock_img} />
                </div>
              </div>
            </div>
          </div>

          {/* Retun */}
          <div>
            {contents?.attributes?.return?.data?.attributes?.returns_level && (
              <div className="">
                <h4
                  className={`${getTextColor(
                    contents?.attributes?.return?.data?.attributes
                      ?.returns_level
                  )} text-sm mb-2 text-left`}
                >
                  {parseString(
                    contents?.attributes?.return?.data?.attributes
                      ?.returns_level
                  )}
                </h4>
                {getProgress(
                  toLower(
                    contents?.attributes?.return?.data?.attributes
                      ?.returns_level
                  )
                )}
              </div>
            )}

            <div className="mt-2 border-t-2 mb-2">
              <div className="pt-2 flex justify-between items-end">
                <p className="text-black text-sm">Return</p>
                <div>
                  <img src={return_img} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {contents?.attributes?.asset_class_footer_text && (
        <div className="bg-primitive-olive-100 rounded-b-lg p-4">
          <div className="text-gray-600 text-sm text-left">
            {parseString(contents?.attributes?.asset_class_footer_text)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetCopy;

export const getProgress = (level) => {
  return (
    <>
      {level == 'high' ? (
        <div className="flex">
          <span className="flex w-5 h-1.5 me-3 bg-primitive-BLUE-300 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-primitive-BLUE-300 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-primitive-BLUE-300 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200  rounded-full mr-1"></span>
        </div>
      ) : level == 'moderate' ? (
        <div className="flex">
          <span className="flex w-5 h-1.5 me-3 bg-primary rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-primary  rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200 rounded-full mr-1"></span>
        </div>
      ) : level == 'very high' ? (
        <div className="flex">
          <span className="flex w-5 h-1.5 me-3 bg-VERYHIGH  rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-VERYHIGH  rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-VERYHIGH  rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-VERYHIGH rounded-full mr-1"></span>
        </div>
      ) : (
        <div className="flex">
          <span className="flex w-5 h-1.5 me-3 bg-SUCCESS-GREEN  rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200 rounded-full mr-1"></span>
          <span className="flex w-5 h-1.5 me-3 bg-gray-200 rounded-full mr-1"></span>
        </div>
      )}
    </>
  );
};
