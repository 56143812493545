import React, { useEffect, useState } from "react";
import AnnualReportScheme from "./AnnualReportScheme";
import AnnualReportComapany from "./AnnualReportCompany";
import FinancialReportTable from "../Tables/FinancialReportTable";
import AnnualReturnsOthers from "./AnnualReturnsOthers";
import AccordionCopy from "../Accordions/AccordionCopy";
import FileDownloadAccordion from "./FileDownloadAccordion";

const FinancialReports = ({ contents }) => {
  const [dataItem, setDataItem] = useState();
  
  const getContent = (key, data) => {
    if (key == "annual_report_company")
      return <AnnualReportComapany contents={data} />;
    if (key == "annual_report_schemes")
      return <AnnualReportScheme contents={data} />;
    if (key == "half_yearly_unaudited_scheme_financials")
      return <FinancialReportTable contents={data} />;
    if (key == "annual_returns_and_others")
      return <AnnualReturnsOthers contents={data} />;
    if (key == "agm_notice" || key=="others")
      return <FileDownloadAccordion contents={data} />;
  };

  useEffect(() => {
    if (contents) {
      if (
        contents?.page_body?.data?.attributes?.page_body_cmp[0]?.tab_element
      ) {
        let dataset = [];
        contents?.page_body?.data?.attributes?.page_body_cmp[0]?.tab_element?.map(
          (object, index) => {
            dataset.push({
              index: index,
              title: `<div className="text-[12px] md:text-[16px] lg:text-[18px] font-medium">${object?.tab_text}</div>`,
              content: getContent(
                object?.internal_identifier,
                object?.page_body
              ),
              key: object?.internal_identifier,
            });
          }
        );
        setDataItem(dataset);
      }
    }
  }, []);

  return (
    <div className="p-[12px] border-primitive-BLUE-100 Border-2 rounded-b-xl">
      <AccordionCopy items={dataItem} />
    </div>
  );
};

export default FinancialReports;
