import React, { useEffect, useState } from 'react';
import { IsEmpty } from '../../AppConstants';

const DisclosureDrop = ({ selected, setSelection = () => { }, options, title = '', titleAppend = false }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [titleInit, setTitle] = useState(title);

  useEffect(() => {
    if (selected?.key) {
      if (titleAppend && !IsEmpty(title))
        setTitle(`${title}: ${selected?.value}`);
      else
        setTitle(`${selected?.value}`);
    }
  }, [selected]);


  useEffect(() => {
    if (options) {
      options?.map((obj) => {
        if (IsEmpty(selected?.key)) {
          if (obj?.default == 'true' || obj?.default == true) {
            setSelection(obj);

            if (titleAppend && !IsEmpty(title))
              setTitle(`${title}: ${obj?.value}`);
            else
              setTitle(`${obj?.value}`);
          }
        }
      });
    }
  }, [options]);


  const toggleFilterMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelectClick = (index) => {
    if (index != null && options[index]) {
      setSelection(options[index]);
      if (titleAppend && !IsEmpty(title))
        setTitle(`${title}: ${options[index].value}`);
      else
        setTitle(`${options[index].value}`);

      setShowDropdown(false);
    }
  };

  return (
    <div className="relative">
      <button
        className="text-black w-full md:w-[fit-content] bg-white dropd-shadow font-medium rounded-full text-[12px] 
         lg:text-[14px]	  px-2 md:px-5 py-1.5 text-center inline-flex items-center
         min-w-28"
        type="button"
        onClick={() => toggleFilterMenu()}
      >
        <div className="flex justify-between items-center w-full">{`${titleInit}`}</div>
        <svg
          className={`${showDropdown ? 'rotate-180' : ''
            } w-2.5 h-2.5 ms-3 ml-3 transition-transform`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {showDropdown && (
        <div className="z-10 absolute w-full bg-white divide-y divide-gray-100 rounded-lg shadow mt-1">
          <ul className="py-2 px-0 text-sm text-gray-700 dark:text-gray-200">
            {options?.map((option, index) => (
              <li
                key={option?.key}
                onClick={() => handleSelectClick(index)}
                className="block px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600
                 dark:hover:text-white cursor-pointer"
              >
                {option?.value || ''}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DisclosureDrop;
