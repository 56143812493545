import CryptoJS from 'crypto-js';
import parser from 'html-react-parser';

// social media icons
import socialmedia from '../assets/images/socialmedia.svg';
// import socialmedia1 from '../assets/images/socialmedia-1.svg';
import socialmedia2 from '../assets/images/socialmedia-2.svg';
import socialmedia3 from '../assets/images/socialmedia-3.svg';

export const openPage = (url) => {
  window.open(url);
};
export const openInTab = (url) => {
  window.open(url, '_blank');
};

export const parseString = (string, config) => {
  if (typeof string === 'string') {
    return parser(string || '', {
      replace: ({ attribs }) => {
        if (attribs) {
          attribs.class = 'custom-area' + ` ${config?.class || ''}`;
        }
      },
    });
  }
  return '';
};

export const parseStringFaq = (string, config) => {
  if (typeof string === 'string') {
    return parser(string || '', {
      replace: ({ attribs }) => {
        if (attribs) {
          if (attribs?.classname)
            attribs.class =
              attribs.classname + ' custom-area' + ` ${config?.class || ''}`;
        }
      },
    });
  }
  return '';
};

export const parseTable = (string, config) => {
  if (typeof string === 'string') {
    return parser(string || '', {
      replace: ({ attribs }) => {
        if (attribs) {
          if (attribs?.classname)
            attribs.class =
              attribs.classname + ' custom-table' + ` ${config?.class || ''}`;
        }
      },
    });
  }
  return '';
};

export const toLower = (val) => {
  return val.toLowerCase();
};

export const toUpper = (val) => {
  return val.toUpperCase();
};

//Encrypt-Decrypt
var Sha256 = CryptoJS.SHA256;
var Hex = CryptoJS.enc.Hex;
var Utf8 = CryptoJS.enc.Utf8;
var Base64 = CryptoJS.enc.Base64;
var AES = CryptoJS.AES;

var secret_key = process.env.REACT_APP_ENCRYPTION_KEY;
var secret_iv = process.env.REACT_APP_ENCRYPTION_IV;

var key = Sha256(secret_key).toString(Hex).substr(0, 32);
var iv = Sha256(secret_iv).toString(Hex).substr(0, 16);

export const encrypt = (data) => {
  try {
    let text;
    if (typeof data === 'object') {
      text = JSON.stringify(data);
    } else {
      text = data;
    }

    var output = AES.encrypt(text, Utf8.parse(key), {
      iv: Utf8.parse(iv),
    }).toString();
    var output2ndB64 = Utf8.parse(output).toString(Base64);
    return output2ndB64;
  } catch (error) {
    return error;
  }
};

export const decrypt = (data) => {
  try {
    if (data !== null) {
      let buff = Buffer.from(data, 'base64');
      let base64ToStringNew = buff.toString('ascii');
      var decrypted = AES.decrypt(base64ToStringNew, Utf8.parse(key), {
        iv: Utf8.parse(iv),
      }).toString(Utf8);
      var outputText = JSON.parse(decrypted);
      return outputText;
    }
  } catch (error) {
    return '';
  }
};

export const IsEmpty = (value) => {
  if (value === null) return true;
  else if (value === undefined) return true;
  else if (value !== null && typeof value === 'string' && value.trim() === '')
    return true;
  else return false;
};

export const formatDate = (dateString) => {
  try {
    if (IsEmpty(dateString)) return '';
    let splitter = dateString.search('/') <= 0 ? '-' : '/';

    var dateArray = dateString?.split(splitter);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${dateArray[2]} ${months[Number(dateArray[1] - 1)]} ${dateArray[0]
      }`;
  } catch (error) {
    return '';
  }
};

export const dateCustom = (date, format = 'YYYY-MM-DD') => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  if (format == 'YYYY-MM-DD')
    return year + '-' + month + '-' + day;
  if (format == 'DD-MM-YYYY')
    return day + '-' + month + '-' + year;
}

//Authtoken

export const getAuthToken = () => {
  const tokenstring = process.env.REACT_APP_AUTH_TOKEN_KEY || 'Centelon';
  //const currentDate = new Date();
  const currentDate = new Date(new Date().toLocaleString('en', { timeZone: 'Asia/Kolkata' }))
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  const randomSeed = parseInt(`${year}${month}${day}`); // Combine year, month, and day
  const randomNumber = (randomSeed * 9301 + 49297) % 233280; // Generate a pseudo-random number
  const expectedToken = tokenstring + randomNumber;
  return expectedToken;
};

//Authtoken


// social media links
export const socialMediaLinks = [
  {
    id: '1',
    icon: socialmedia,
    name: 'Facebook',
    url: 'https://www.facebook.com/iciciprupension',
  },
  // {
  //   id: '2',
  //   icon: socialmedia1,
  //   name: 'Instagram',
  //   url: 'https://www.google.com/',
  // },
  {
    id: '3',
    icon: socialmedia2,
    name: 'Twitter',
    url: 'https://twitter.com/iprupension',
  },
  {
    id: '4',
    icon: socialmedia3,
    name: 'LinkedIN',
    url: 'https://www.linkedin.com/company/iciciprupension/',
  },
];

// external links
export const externalURLs = [
  {
    id: '1',
    linkText: 'Latest NAV',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/latest-nav',
  },
  {
    id: '2',
    linkText: 'NAV History',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/nav-history',
  },
  {
    id: '3',
    linkText: 'Portfolio Details',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/portfolio-details',
  },
  {
    id: '4',
    linkText: 'Financial Reports',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/financial-reports',
  },
  {
    id: '5',
    linkText: 'Policy',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/policy',
  },
  {
    id: '6',
    linkText: 'Investment Management Fee',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/investment-management-fee',
  },
  {
    id: '7',
    linkText: 'Proxy Voting Details',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/proxy-voting-details',
  },
  {
    id: '8',
    linkText: 'Regulatory Circulars',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/regulatory-circulars',
  },
  {
    id: '9',
    linkText: 'Group Companies List',
    url: '',
    redirect_to:
      'https://s3-icici.s3.ap-south-1.amazonaws.com/List_of_Group_Companies_V1_1_1_b532900649.pdf',
    link_mode: 'Download',
  },
  {
    id: '10',
    linkText: 'Risk-o-Meter',
    url: 'https://www.iciciprupensionfund.com/NPS/#/public-disclosure/Risk-o-Meter',
  },
];

export const getCardType = (object) => {
  if (object) {
    if (
      object?.data[0]?.attributes?.unit_style?.data?.attributes?.category_name
    )
      return object?.data[0]?.attributes?.unit_style?.data?.attributes
        ?.category_name;
    else
      return object?.data?.attributes?.unit_style?.data?.attributes
        ?.category_name;
  }
  return '';
};


//--------------------- Amount

// export const toInrFormat = (val) => {
//   return val > 0 ? `₹ ${val}` : '';
// };

export const toLakhCrText = (value) => {
  if (IsEmpty(value))
    return '';

  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Crores only';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lakh only';
  }
  else
    val = val + ' Only';

  return val;
}

export const toLakhCrShort = (value, trimEnd = 2) => {
  if (IsEmpty(value))
    return '';

  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(trimEnd) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(trimEnd) + ' L';
  }
  else if (val >= 1000) {
    val = (val / 1000).toFixed(trimEnd) + ' K';
  }
  return val;
}

export const toLakhCr = (value, trimEnd = 2) => {
  if (IsEmpty(value))
    return '';

  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(trimEnd);
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(trimEnd);
  }
  else if (val >= 1000) {
    val = (val / 1000).toFixed(trimEnd);
  }
  return val;
}

export const toInrFormat = (value) => {
  if (IsEmpty(value))
    return '';

  let parsed = parseFloat(value);

  var val = parsed.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR'
  });

  return val;
}


export const trailZero = (value, decimal = 2) => {
  var r = (+value).toFixed(decimal);
  if (r.match(/\./)) {
    r = r.replace(/\.?0+$/, '');
  }

  return r;
}


export const getData = (key) => {
  let data = localStorage.getItem(key);
  return !IsEmpty(data) ? data : '';
}

export const setData = (key, value) => {
  localStorage.setItem(key, value);
}


export const toSpecialDate = (value, format = '') => {
  if (IsEmpty(value))
    return '';

  let val = '';
  const date = new Date(value);
  if (!IsEmpty(format) && format == 'DD-MMM-YY') {
    const day = String(date.getDate()).padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = String(date.getFullYear()).slice(-2); // Take only last two digits
    val = `${day}-${toUpper(month)}-${year}`;
  }
  else {
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    val = date.toLocaleDateString('en-US', options);
  }

  return val;
}

export const IsNumber = (input) => {
  return /^\d*\.?\d+$/.test(input) ? true : /^\d+$/.test(input) ? true : false;
}

export const getFileExt = (filename) => {
  return filename.split('.').pop();
};

export const getFilenameUrl = (url) => {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return "";
}

export const roundTo = (value, decimalPlaces = 0) => {
  value = Math.round(value + "e" + decimalPlaces);
  return Number(value + "e" + -decimalPlaces);
}

export const getMonthName = (month) => {

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months[Number(month)];
}

export const IsUL = (htmlString) => {
  return /<ul>/i.test(htmlString);
};

export const cleanUL = (htmlString, customClass) => {
  return htmlString.replace(/<ul>/gi, `<ul class="${customClass}">`);
}