import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';

import Marquee from 'react-fast-marquee';

// import logo from '../../assets/images/icici-logo.svg';
import search from '../../assets/images/icons.svg';
// import icon4 from '../../assets/images/icons-4.svg';
// import icon3 from '../../assets/images/icons-3.svg';
// import PrimaryButton from '../components/StyledButton/PrimaryButton';
// import SecondaryButton from '../components/StyledButton/SecondaryButton';

// import { URI_JOIN_NPS, URI_SIGN_IN } from '../NavigationUri';
import { useSelector } from 'react-redux';
import { parseString } from '../AppConstants';
import StyledButton from '../components/StyledButton/Index';

const Menu = ({ showScroller = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuMainOpen, setSubmenuMainOpen] = useState(false);
  const [headerContents, setHeaderContents] = useState({});

  const { header_footer_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (header_footer_contents) {
      setHeaderContents(header_footer_contents ? header_footer_contents : {});
    }
  }, [header_footer_contents]);

  // const [isHomePage, setIsHomePage] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname == '/' || location.pathname == '/home') {
  //     setIsHomePage(true);
  //   } else {
  //     setIsHomePage(false);
  //   }
  // }, [location.pathname]);

  const clickMobileMenu = () => {
    setIsOpen(!isOpen);
  };

  const clickSubmenuMenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const clickSubmenuMainMenu = () => {
    setSubmenuMainOpen(!submenuMainOpen);
  };

  return (
    <nav className="bg-white mr-13 ml-13 py-0 bottom-border mx-auto fixed top-0 w-full z-[1000]">
      {headerContents?.greyheaderdata && (
        <div className="px-5 py-2 bg-secondary-GRAY">
          <div className="container mx-auto xl:px-5 flex justify-end items-center">
            {headerContents?.greyheaderdata?.data?.attributes?.gray_header_items
              ?.data?.length &&
              headerContents?.greyheaderdata?.data?.attributes?.gray_header_items?.data?.map(
                (item, index) => (
                  <div
                    className={`text-xs pr-2 ${item?.attributes?.internal_identifier ==
                        'public_disclosure_grey_header'
                        ? 'pl-2  border-left-cp'
                        : ''
                      }`}
                    key={`grey_header_item_${index}`}
                  >
                    {item?.attributes?.link_text && (
                      <a
                        href={item?.attributes?.url}
                        className="cursor-pointer"
                      >
                        {parseString(item?.attributes?.link_text)}
                      </a>
                    )}
                  </div>
                )
              )}
          </div>
        </div>
      )}
      {headerContents?.headerdata && (
        <div className="container mx-auto flex justify-between items-center bg-white px-5">
          <div className="flex items-center">
            {/* Mobile */}
            <div className="lg:hidden xl:hidden flex items-center ">
              <div
                onClick={clickMobileMenu}
                id="menu-icon"
                className={`z-10 cursor-pointer ${isOpen ? 'open' : ''
                  } flex items-center `}
              >
                <button
                  onClick={clickMobileMenu}
                  className="mobile-menu-button"
                >
                  {!isOpen ? (
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  )}
                </button>
              </div>
              <div
                className={`fixed top-24 z-50 left-0 bottom-0 overflow-y-auto h-calc(100vh - 80px) w-full md:w-1/2 bg-white text-primary-DARK transform transition-transform duration-300 ease-in-out ${!isOpen ? '-translate-x-full' : ''
                  } `}
              >
                {headerContents?.headerdata?.data?.attributes?.header_elements?.map(
                  (item, index) => (
                    <React.Fragment key={`header_element_${index}`}>
                      {item?.internal_identifier == 'links' && (
                        <React.Fragment key={'slfd'}>
                          <ul className="p-6">
                            {item?.link_groups?.data?.length &&
                              item?.link_groups?.data?.map((link, idx) => (
                                <React.Fragment key={`nav_link_mobile_${idx}`}>
                                  {link?.attributes?.link_mode ==
                                    'single_link' && (
                                      <li className="text-base py-3 horizontal-b font-low navbar-text-color leading-6 hover:text-blue-500 transform hover:translate-y-1 transition-transform duration-300 cursor-pointer">
                                        <a
                                          href={
                                            link?.attributes?.link_set?.data?.[0]
                                              ?.attributes?.url
                                          }
                                          className="no-underline text-primary-DARK"
                                        >
                                          {parseString(
                                            link?.attributes?.link_heading
                                          )}
                                        </a>
                                      </li>
                                    )}

                                  {link?.attributes?.link_mode ==
                                    'dropdown' && (
                                      <li
                                        onClick={clickSubmenuMenu}
                                        className="text-base py-3 horizontal-b font-low navbar-text-color leading-6 hover:text-blue-500 transform hover:translate-y-1 cursor-pointer"
                                        type="button"
                                      >
                                        <a className="flex items-center justify-between no-underline text-primary-DARK">
                                          {parseString(
                                            link?.attributes?.link_heading
                                          )}{' '}
                                          {!submenuOpen ? (
                                            <span className="font-material-icons text-xl no-underline">
                                              expand_more
                                            </span>
                                          ) : (
                                            <span className="font-material-icons text-xl no-underline">
                                              expand_less
                                            </span>
                                          )}
                                        </a>

                                        {submenuOpen && (
                                          <div
                                            className={`z-10 bg-white divide-y divide-gray-100 rounded-lgdark:bg-gray-700 ease-out `}
                                          >
                                            <ul className="text-sm text-gray-700 dark:text-gray-200">
                                              {link?.attributes?.link_set?.data?.map(
                                                (item, idx) => (
                                                  <li
                                                    className="py-3"
                                                    key={`nav_link_mob_drop_item_${idx}`}
                                                  >
                                                    <a
                                                      href={item?.attributes?.url}
                                                      className="block hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline"
                                                    >
                                                      {parseString(
                                                        item?.attributes
                                                          ?.link_text
                                                      )}
                                                    </a>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </li>
                                    )}
                                </React.Fragment>
                              ))}
                          </ul>
                        </React.Fragment>
                      )}

                      {item?.internal_identifier == 'buttons' && (
                        <div className="grid grid-cols-2 gap-4 py-8 px-6">
                          {item?.ctas?.data
                            ?.map((cta, idx) => (
                              <StyledButton
                                cta={cta}
                                key={`header_cta_mobile_${idx}`}
                              />
                            ))
                            .reverse()}
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            {/* Mobile */}

            {/* logo */}
            {headerContents?.headerdata?.data?.attributes?.header_logo?.data
              ?.attributes?.media?.data?.attributes?.url && (
                <div className="logo">
                  <a
                    href="/"
                    className="flex items-center py-4 px-2 text-gray-700 hover:text-gray-900"
                  >
                    <img
                      src={
                        headerContents?.headerdata?.data?.attributes?.header_logo
                          ?.data?.attributes?.media?.data?.attributes?.url
                      }
                      className="max-w-[140px] xl:max-w-[260px]"
                      alt="Logo"
                    />
                  </a>
                </div>
              )}
            {/* logo */}
          </div>

          {/* mobile search */}
          {headerContents?.headerdata?.data?.attributes?.header_elements?.map(
            (item, index) => (
              <React.Fragment key={`header_element_${index}`}>
                {item?.internal_identifier == 'search_button' && (
                  <div className="flex items-center justify-center lg:hidden xl:hidden">
                    <button className="w-8 h-8 flex items-center justify-center bg-transparent border border-custom-color rounded-full search-btn-hover ml-4">
                      <img
                        src={search}
                        className="w-[20px] h-[20px]"
                        alt="Icon"
                      />
                    </button>
                  </div>
                )}
              </React.Fragment>
            )
          )}
          {/* mobile search */}

          {/* Web */}
          <div className="hidden lg:flex xl:flex items-center gap-4">
            {headerContents?.headerdata?.data?.attributes?.header_elements?.map(
              (item, index) => (
                <React.Fragment key={`header_element_${index}`}>
                  {item?.internal_identifier == 'search_button' && (
                    <div className="flex items-center justify-center">
                      <button className="w-10 h-10 flex items-center justify-center bg-transparent border border-custom-color rounded-full hover:bg-primary">
                        <img
                          src={search}
                          className="w-[20px] h-[20px]"
                          alt="Icon"
                        />
                      </button>
                    </div>
                  )}

                  {item?.internal_identifier == 'links' && (
                    <ul className="flex justify-between items-center text-primary-DARK">
                      {item?.link_groups?.data?.length &&
                        item?.link_groups?.data?.map((link, idx) => (
                          <React.Fragment key={`nav_link_web_${idx}`}>
                            {link?.attributes?.link_mode == 'single_link' && (
                              <li className="px-4">
                                <a
                                  href={
                                    link?.attributes?.link_set?.data?.[0]
                                      ?.attributes?.url
                                  }
                                  className="text-primary-DARK no-underline leading-6 cursor-pointer"
                                >
                                  {parseString(
                                    link?.attributes?.link_heading
                                  )}
                                </a>
                              </li>
                            )}
                            {link?.attributes?.link_mode == 'dropdown' && (
                              <li className="relative">
                                <a
                                  className="text-primary-DARK no-underline leading-6 px-4 cursor-pointer flex items-center"
                                  onClick={clickSubmenuMainMenu}
                                >
                                  {parseString(link?.attributes?.link_heading)}
                                  {!submenuMainOpen ? (
                                    <span className="ml-1 font-material-icons text-xl no-underline text-center">
                                      expand_more
                                    </span>
                                  ) : (
                                    <span className="ml-1 font-material-icons text-xl no-underline text-center">
                                      expand_less
                                    </span>
                                  )}
                                </a>
                                {submenuMainOpen && (
                                  <ul className="absolute left-0 mt-2 py-2 px-4 bg-white rounded-lg z-50 shadow">
                                    {link?.attributes?.link_set?.data?.map(
                                      (item, idx) => (
                                        <li
                                          className="whitespace-nowrap"
                                          key={`nav_link_drop_item_${idx}`}
                                        >
                                          <a
                                            href={item?.attributes?.url}
                                            className="text-primary-DARK no-underline whitespace-nowrap"
                                          >
                                            {parseString(
                                              item?.attributes?.link_text
                                            )}
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </li>
                            )}
                          </React.Fragment>
                        ))}
                    </ul>
                  )}

                  {item?.internal_identifier == 'buttons' &&
                    item?.ctas?.data?.map((cta, idx) => (
                      <StyledButton cta={cta} key={`header_cta_web_${idx}`} />
                    ))}
                </React.Fragment>
              )
            )}
          </div>
          {/* Web */}
        </div>
      )}
      {headerContents?.scrollheaderdata?.data?.length && showScroller && (
        <div className="horizontal-t horizontal-b">
          <Marquee
            play={true}
            pauseOnHover={true}
            pauseOnClick={true}
            direction="left"
          >
            {headerContents?.scrollheaderdata?.data?.map(
              (scrollItem, index) => (
                <div
                  className="text-xs my-2 mx-2"
                  key={`scroll_header_item_${index}`}
                >
                  {parseString(scrollItem?.attributes?.scroll_header_items)}
                </div>
              )
            )}
          </Marquee>
        </div>
      )}
    </nav>
  );
};

Menu.propTypes = {
  contents: PropTypes.object,
  showScroller: PropTypes.bool || undefined,
};

export default Menu;
