import React, { useEffect, useState } from 'react';
import Menu from '../../common/layout/Menu';
import arrow_icon_mobile from '../../assets/images/arrow-illustration-mobile.svg';

import filter1_icon from '../../assets/images/filter1.svg';
import filter2_icon from '../../assets/images/filter2.svg';
import filter3_icon from '../../assets/images/filter3.svg';
import filter4_icon from '../../assets/images/filter4.svg';

import avatar_1 from '../../assets/images/avatar.svg';
import avatar_arrow from '../../assets/images/avatar-bg.svg';

import connecter from '../../assets/images/connector.svg';

import Footer from '../../common/layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutNPS } from '../../redux/actions/PageAction';
import {
  showPageLoader,
  resetPageLoader,
} from '../../redux/actions/CommonAction';
import Tabs from '../../common/components/Tabs';
import { parseString, parseTable } from '../../common/AppConstants';
import CardCopy from '../../common/components/Cards/CardCopy';

// import StyledButton from '../../common/components/StyledButton/Index';
import DownloadCardView from '../../common/components/Downloaders/DownloadCardView';
import AbnpsTable from '../../common/components/Tables/AbnpsTable';
import FooterBanner from '../../common/components/Banners/FooterBanner';

import ButtonPrimary from '../../common/components/StyledButton/ButtonPrimary';
import AssetCopy from './AssetCopy';
import BannerAboutNPS from '../../common/components/Banners/BannerAboutNPS';

import { Helmet } from 'react-helmet';

const AboutNps = () => {
  const [body, setBody] = useState({});
  const [contents, setContent] = useState({});
  const [tabBody, setTabBody] = useState({});
  const [all_tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const { aboutnps_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (aboutnps_contents == null) {
      dispatch(fetchAboutNPS({}));
      dispatch(showPageLoader());
    }
  }, [aboutnps_contents]);

  useEffect(() => {
    if (aboutnps_contents) {
      setContent(aboutnps_contents?.data ? aboutnps_contents?.data[0] : {});
      setBody(
        aboutnps_contents?.data[0]?.attributes?.page_body_elements?.data
          ? aboutnps_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );

      dispatch(resetPageLoader());
    }
  }, [aboutnps_contents]);

  useEffect(() => {
    if (body) {
      if (body?.attributes?.page_body_cmp) {
        let tab_element = body?.attributes?.page_body_cmp[0]?.tab_element;
        if (tab_element) {
          let dataset = [];
          tab_element?.map((obj, index) => {
            dataset.push({
              index: index,
              title: obj?.tab_text,
            });
            if (index == 0) {
              setTabBody(obj?.page_body);
            }
          });
          setTabs(dataset);
        }
      }
    }
  }, [body]);

  useEffect(() => {
    activeTab != null
      ? setTabBody(
        body?.attributes?.page_body_cmp[0]?.tab_element[activeTab]?.page_body
      )
      : null;
  }, [activeTab]);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  const getMedia = (data) => {
    return data?.data?.attributes?.url || '';
  };

  return (
    <>
      {contents?.attributes?.meta_title ? (
        <>
          <Helmet>
            <title>{contents?.attributes?.meta_title}</title>
            {contents?.attributes?.meta_description && <meta name="description" content={contents?.attributes?.meta_description} />}
            {contents?.attributes?.meta_keywords && <meta name="keywords" content={contents?.attributes?.meta_keywords} />}
          </Helmet>
        </>) : null}
      {/* NAVBAR SECTION */}
      <Menu />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {aboutnps_contents?.data[0]?.attributes?.page_banners && (
        <BannerAboutNPS
          content={aboutnps_contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      <div className="bg-background py-0 md:py-5 md:px-10 relative">
        <div className="md:container mx-auto my-0 px-0 mb-6 md:mt-4 md:mb-12">
          {all_tabs && (
            <Tabs
              items={all_tabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              setActiveTab={setActiveTab}
            />
          )}
        </div>

        <div className="main-bg container mx-auto flex flex-col items-center text-center">
          {tabBody &&
            tabBody?.data?.attributes?.page_body_cmp.map((object, tindex) => (
              <div
                key={`441_tindex-${tindex}`}
                className="content-section container mx-auto py-5 px-5 md:py-0 md:px-0"
              >
                {/* Tab 1 */}
                {object?.internal_identifier == 'types_of_nps_account' && (
                  <div
                    key="types_of_nps_account"
                    className="bg-white p-6 rounded-lg py-6 md:py-10"
                  >
                    {object?.marketing_cmp_heading && (
                      <h1 className="black-h-text text-xl md:text-2xl lg:text-4xl font-bold lg:mb-4 md:mb-0">
                        {parseString(object?.marketing_cmp_heading)}
                      </h1>
                    )}
                    {object?.marketing_cmp_description && (
                      <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal py-3 px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_cmp_description)}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'are_you_starting_new_nps' && (
                  <div
                    key="are_you_starting_new_nps"
                    className="bg-primary-BLUE"
                  >
                    <div className="mx-auto text flex flex-col lg:flex-row  md:flex-row justify-between mt-0 md:mt-10 mb-6 md:mb-14 lg:mb-40">
                      {getMedia(
                        object?.marketing_units_groups?.data[0]?.attributes
                          ?.unit_media
                      ) && (
                          <div className="w-full lg:w-1/2 hidden  md:flex items-center justify-center  ">
                            <img
                              src={getMedia(
                                object?.marketing_units_groups?.data[0]
                                  ?.attributes?.unit_media
                              )}
                              className="w-full max-w-[500px]"
                            />
                          </div>
                        )}

                      <div className="w-full lg:w-1/2 pl-0 md:pl-14">
                        {object?.marketing_units_groups?.data[0]?.attributes
                          ?.group_badge && (
                            <button className="text-sm border border-custom-color block text-white rounded-full px-2 py-1 mb-2.5 orange-custom-button ">
                              {parseString(
                                object?.marketing_units_groups?.data[0]
                                  ?.attributes?.group_badge
                              )}
                            </button>
                          )}

                        {object?.marketing_extended_heading && (
                          <h4 className="text-left text-primary-BLACK font-bold text-xl md:text-lg lg:text-2xl">
                            {parseString(object?.marketing_extended_heading)}
                          </h4>
                        )}
                        {object?.marketing_extended_description && (
                          <div className=" text-sm sm:text-lg lg:text-lgtext-neutral-GRAY  text-left font-normal  mb-8 mt-2 ">
                            {parseString(
                              object?.marketing_extended_description
                            )}
                          </div>
                        )}

                        {getMedia(
                          object?.marketing_units_groups?.data[0]?.attributes
                            ?.unit_media
                        ) && (
                            <div className="w-full lg:w-1/2 md:hidden mb-10  flex items-center justify-center">
                              <img
                                src={getMedia(
                                  object?.marketing_units_groups?.data[0]
                                    ?.attributes?.unit_media
                                )}
                                className="w-full max-w-[500px]"
                              />
                            </div>
                          )}
                        {object?.marketing_units_groups?.data[0]?.attributes
                          ?.marketing_units?.length && (
                            <div className="flex-col items-left justify-center">
                              {object?.marketing_units_groups?.data[0]?.attributes?.marketing_units?.map(
                                (obj, sindex) => (
                                  <CardCopy
                                    content={obj}
                                    key={`cchbc-${sindex}`}
                                    iconHeadStartClass={'pr-2 w-10 h-10'}
                                    extraBoxClass="mb-4 md:mb-4"
                                  />
                                )
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}

                {object?.internal_identifier == 'already_have_tier1' && (
                  <div key="already_have_tier1" className="bg-primary-BLUE">
                    <div className="mx-auto text flex flex-col lg:flex-row  md:flex-row justify-between mt-0 md:mt-10 mb-12 md:mb-24">
                      <div className="w-full lg:w-1/2 pl-0 md:pr-14">
                        {object?.marketing_units_groups?.data[0]?.attributes
                          ?.group_badge && (
                            <button className="text-sm border border-custom-color block text-white rounded-full px-2 py-1 mb-2.5 orange-custom-button ">
                              {parseString(
                                object?.marketing_units_groups?.data[0]
                                  ?.attributes?.group_badge
                              )}
                            </button>
                          )}
                        {object?.marketing_extended_heading && (
                          <h4 className="text-left text-primary-BLACK font-bold text-xl md:text-lg lg:text-2xl">
                            {parseString(object?.marketing_extended_heading)}
                          </h4>
                        )}
                        {object?.marketing_extended_description && (
                          <div className="text-sm sm:text-lg lg:text-lg text-neutral-GRAY font-normal text-left mb-8 mt-2">
                            {parseString(
                              object?.marketing_extended_description
                            )}
                          </div>
                        )}

                        {getMedia(
                          object?.marketing_units_groups?.data[0]?.attributes
                            ?.unit_media
                        ) && (
                            <div className="w-full lg:w-1/2 mb-10 md:hidden  flex items-center justify-center">
                              <img
                                className="w-full  max-w-[500px]"
                                src={getMedia(
                                  object?.marketing_units_groups?.data[0]
                                    ?.attributes?.unit_media
                                )}
                              />
                            </div>
                          )}

                        {object?.marketing_units_groups?.data[0]?.attributes
                          ?.marketing_units?.length && (
                            <div className="flex-col items-left justify-center">
                              {object?.marketing_units_groups?.data[0]?.attributes?.marketing_units?.map(
                                (obj, sindex) => (
                                  <CardCopy
                                    content={obj}
                                    key={`cchbc-${sindex}`}
                                    iconHeadStartClass={'pr-2 w-10 h-10'}
                                    extraBoxClass="mb-4 md:mb-4"
                                  />
                                )
                              )}
                            </div>
                          )}
                      </div>
                      {getMedia(
                        object?.marketing_units_groups?.data[0]?.attributes
                          ?.unit_media
                      ) && (
                          <div className="w-full lg:w-1/2 hidden  md:flex items-center  justify-center">
                            <img
                              src={getMedia(
                                object?.marketing_units_groups?.data[0]
                                  ?.attributes?.unit_media
                              )}
                              className="w-full max-w-[500px]"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {/* Tab 2 */}
                {object?.internal_identifier == 'nps_ecosystem' && (
                  <div
                    key="nps_ecosystem_tab_init"
                    className="mx-auto bg-white p-6 rounded-lg py-6 md:py-10"
                  >
                    {object?.marketing_cmp_heading && (
                      <h1 className="text-primary-BLACK font-bold text-xl md:text-2xl lg:text-4xl">
                        {parseString(object?.marketing_cmp_heading)}
                      </h1>
                    )}
                    {object?.marketing_cmp_description && (
                      <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal py-3 px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_cmp_description)}
                      </div>
                    )}
                  </div>
                )}

                {/* nps ecosystem dotted line illustrations */}
                {object?.internal_identifier == 'nps_ecosystem' && (
                  <div key="nps_ecosystem">
                    <img
                      src={filter1_icon}
                      className=" absolute top-1/4	left-0 hidden  lg:block"
                    />
                    <img
                      src={filter2_icon}
                      className=" absolute top-1/3	 right-0 hidden lg:block "
                    />
                    <img
                      src={filter3_icon}
                      className=" absolute top-3/4	left-0  hidden lg:block "
                    />
                    <img
                      src={filter4_icon}
                      className=" absolute bottom-0 right-0 hidden lg:block"
                    />
                  </div>
                )}

                {object?.internal_identifier == 'nps_ecosystem_timeline' && (
                  <div
                    className="container mx-auto md:px-6 lg:px-10 mt-0 md:mt-20"
                    key="abnps_650"
                  >
                    {object?.choose_marketing_blocks?.data?.length && (
                      <div
                        key="abnps_652"
                        className="flex flex-col md:grid grid-cols-9 mx-auto p-2 mt-0 md:mt-12 relative  md:pt-60 "
                      >
                        <div
                          key="abnps_654"
                          className="hidden md:block absolute top-8 md:left-[47%] lg:left-[49%] xl:left-[50%]  2xl:left-[51%] -translate-x-1/2 z-10"
                        >
                          <div className="relative">
                            <img
                              src={avatar_arrow}
                              className="absolute -left-16"
                            />
                            <img
                              src={avatar_1}
                              className="custom-avatar shadow-lg"
                            />
                          </div>
                        </div>

                        <div
                          key="abnps_667"
                          className="flex md:content  md:hidden"
                        >
                          <div className="col-start-5 col-end-6 mr-4 md:mr-0 md:mx-auto relative">
                            <div className="h-[70%] mt-28 md:mt-0 md:h-full w-10 flex items-center justify-center">
                              <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                            </div>
                            <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-orange-500 border-8 border-orange-400"></div>
                          </div>

                          <div className="col-start-5 col-end-6 flex items-center justify-center border-full mb-8 relative">
                            <img
                              src={arrow_icon_mobile}
                              className="absolute w-full max-w-[100px] -right-12 -top-6"
                            />
                            <img
                              src={avatar_1}
                              className="custom-avatar shadow-lg border-full"
                            />
                          </div>
                        </div>

                        {object?.choose_marketing_blocks?.data?.map(
                          (obj, sindex) => (
                            <React.Fragment key={`abnps_691_${sindex}`}>
                              {sindex % 2 == 0 ? (
                                <div className="flex md:contents">
                                  <div className="col-start-5 col-end-6 mr-4 md:mr-10 md:mx-auto relative">
                                    <div className="h-full w-10 flex items-center justify-center">
                                      <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                    </div>
                                    <div className="w-10 h-10 absolute top-1/2 -mt-3 rounded-full bg-orange-500 border-8 border-orange-400"></div>
                                    <div className=" hidden md:block absolute top-1/2 left-full connector">
                                      <img src={connecter} />
                                    </div>
                                  </div>
                                  <div className=" relative bg-white md:bg-transparent col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto">
                                    {getMedia(
                                      obj?.attributes?.marketing_image
                                    ) && (
                                        <img
                                          src={getMedia(
                                            obj?.attributes?.marketing_image
                                          )}
                                        />
                                      )}
                                    {obj?.attributes?.marketing_heading && (
                                      <h3 className="font-semibold text-left  text-base  md:text-xl lg:text-2xl mb-1 text-primary-BLACK mt-4">
                                        {parseString(
                                          obj?.attributes?.marketing_heading
                                        )}
                                      </h3>
                                    )}
                                    {obj?.attributes?.marketing_description && (
                                      <div className="text-left text-neutral-GRAY font-normal text-sm md:text-base">
                                        {parseString(
                                          obj?.attributes?.marketing_description
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex flex-row-reverse md:contents">
                                  <div className="bg-white md:bg-transparent col-start-1 col-end-5 p-4 rounded-xl  my-4 ml-auto ">
                                    {getMedia(
                                      obj?.attributes?.marketing_image
                                    ) && (
                                        <img
                                          src={getMedia(
                                            obj?.attributes?.marketing_image
                                          )}
                                        />
                                      )}
                                    {obj?.attributes?.marketing_heading && (
                                      <h3 className="font-semibold text-left  text-base  md:text-xl lg:text-2xl mb-1 text-primary-BLACK mt-4">
                                        {parseString(
                                          obj?.attributes?.marketing_heading
                                        )}
                                      </h3>
                                    )}
                                    {obj?.attributes?.marketing_description && (
                                      <div className="leading-tight text-left text-neutral-GRAY font-normal text-sm md:text-base">
                                        {parseString(
                                          obj?.attributes?.marketing_description
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-start-5 col-end-6 md:mx-auto relative mr-4 md:mr-10">
                                    {sindex + 1 ==
                                      object?.choose_marketing_blocks?.data
                                        ?.length ? (
                                      <div className="h-[33%] w-10 flex items-center justify-center">
                                        <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                      </div>
                                    ) : (
                                      <div className="h-full w-10 flex items-center justify-center">
                                        <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                      </div>
                                    )}
                                    <div className="w-10 h-10 absolute top-1/3 -mt-3 rounded-full bg-orange-500 border-8 border-orange-400"></div>
                                    <div className="hidden md:block absolute top-1/3 right-full connector transform rotate-180">
                                      <img src={connecter} />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Tab 3 */}
                {object?.internal_identifier == 'charges_under_nps' && (
                  <div
                    key="charges_under_nps"
                    className=" mx-auto bg-white p-6 rounded-lg py-6 md:py-10 w-full"
                  >
                    {object?.marketing_extended_heading && (
                      <h1 className=" font-bold lg:mb-4 md:mb-0 text-xl md:text-2xl lg:text-4xl">
                        {parseString(object?.marketing_extended_heading)}
                      </h1>
                    )}
                    {object?.marketing_extended_description && (
                      <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal py-3 px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_extended_description)}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'point_of_presence' && (
                  <div
                    key="point_of_presence"
                    className="bg-white  py-6 md:py-8 lg:py-8 rounded-lg  mt-0 md:mt-10 p-4"
                  >
                    <div className="flex border-b-2 horizontal-b pb-3">
                      {getMedia(
                        object?.marketing_extendeds?.data[0]?.attributes
                          ?.marketing_blocks?.data[0]?.attributes
                          ?.marketing_image
                      ) && (
                          <div className="">
                            <img
                              src={getMedia(
                                object?.marketing_extendeds?.data[0]?.attributes
                                  ?.marketing_blocks?.data[0]?.attributes
                                  ?.marketing_image
                              )}
                            />
                          </div>
                        )}

                      <div className="text-left pl-10  flex flex-col lg:flex-col justify-center  ">
                        {object?.marketing_extended_heading && (
                          <h4 className=" text-primary-BLACK text-lg md:text-2xl font-bold">
                            {parseString(object?.marketing_extended_heading)}
                          </h4>
                        )}
                        {object?.marketing_extended_description && (
                          <div className=" text-neutral-GRAY  font-normal text-base">
                            {parseString(
                              object?.marketing_extended_description
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {object?.marketing_extendeds?.data[0]?.attributes?.tables
                      ?.data[0]?.attributes?.table_data && (
                        <div>
                          {parseTable(
                            object?.marketing_extendeds?.data[0]?.attributes
                              ?.tables?.data[0]?.attributes?.table_data
                          )}
                          {/* dummy table added to fix the tailwind css issue -- this will not render on the screen */}
                          <div className="hidden">
                            <table className="table-auto w-full mt-4 border-line">
                              <thead>
                                <tr className="bg-primitive-BLUE-50 horizontal-b">
                                  <th className="width-50-100 p-4 font-bold  bg-light-blue text-lg text-black text-left border  hidden lg:table-cell">
                                    Charge Head
                                  </th>
                                  <th className="width-50-100 p-4 font-bold  bg-light-blue text-lg text-black text-left border   hidden lg:table-cell">
                                    Charges **
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap horizontal-t">
                                  <td className="width-50-100     p-4 text-primary-BLACK font-normal text-base  text-left   block lg:table-cell relative lg:static">
                                    Investment Management Fee
                                  </td>
                                  <td className="width-50-100    p-4 text-primary-BLACK font-normal text-base  text-left   block lg:table-cell relative lg:static">
                                    <span className="font-bold text-base">
                                      0.0467% - 0.09%
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                  </div>
                )}

                {object?.internal_identifier == 'pension_fund_manager' && (
                  <div
                    key="pension_fund_manager"
                    className="bg-white container mx-auto py-6 md:py-8 lg:py-8 rounded-lg  mt-0 md:mt-10 p-4"
                  >
                    <div className="flex border-b-2 horizontal-b  pb-3">
                      {getMedia(
                        object?.marketing_extendeds?.data[0]?.attributes
                          ?.marketing_blocks?.data[0]?.attributes
                          ?.marketing_image
                      ) && (
                          <div className="">
                            <img
                              src={getMedia(
                                object?.marketing_extendeds?.data[0]?.attributes
                                  ?.marketing_blocks?.data[0]?.attributes
                                  ?.marketing_image
                              )}
                            />
                          </div>
                        )}

                      <div className="text-left pl-10  flex flex-col lg:flex-col justify-center  ">
                        {object?.marketing_extended_heading && (
                          <h4 className=" text-primary-BLACK text-lg md:text-2xl font-bold">
                            {parseString(object?.marketing_extended_heading)}
                          </h4>
                        )}
                        {object?.marketing_extended_description && (
                          <div className=" text-neutral-GRAY  font-normal text-base">
                            {parseString(
                              object?.marketing_extended_description
                            )}{' '}
                          </div>
                        )}
                      </div>
                    </div>

                    {object?.marketing_extendeds?.data[0]?.attributes?.tables
                      ?.data[0]?.attributes?.table_data && (
                        <div>
                          {parseTable(
                            object?.marketing_extendeds?.data[0]?.attributes
                              ?.tables?.data[0]?.attributes?.table_data
                          )}
                        </div>
                      )}
                  </div>
                )}

                {object?.internal_identifier == 'payment_gateway_charges' && (
                  <div
                    key="payment_gateway_charges"
                    className="bg-white container mx-auto py-6 md:py-8 lg:py-8 rounded-lg  mt-0 md:mt-10 p-4"
                  >
                    <div className="flex border-b-2 horizontal-b pb-3">
                      {getMedia(
                        object?.marketing_extendeds?.data[0]?.attributes
                          ?.marketing_blocks?.data[0]?.attributes
                          ?.marketing_image
                      ) && (
                          <div className="">
                            <img
                              src={getMedia(
                                object?.marketing_extendeds?.data[0]?.attributes
                                  ?.marketing_blocks?.data[0]?.attributes
                                  ?.marketing_image
                              )}
                            />
                          </div>
                        )}

                      <div className="text-left pl-10  flex flex-col lg:flex-col justify-center  ">
                        {object?.marketing_extended_heading && (
                          <h4 className=" text-primary-BLACK text-lg md:text-2xl font-bold">
                            {parseString(object?.marketing_extended_heading)}
                          </h4>
                        )}
                        {object?.marketing_extended_description && (
                          <div className=" text-neutral-GRAY  font-normal text-base">
                            {parseString(
                              object?.marketing_extended_description
                            )}{' '}
                          </div>
                        )}
                      </div>
                    </div>

                    {object?.marketing_extendeds?.data[0]?.attributes?.tables
                      ?.data[0]?.attributes?.table_data && (
                        <div>
                          {parseTable(
                            object?.marketing_extendeds?.data[0]?.attributes
                              ?.tables?.data[0]?.attributes?.table_data
                          )}
                        </div>
                      )}
                  </div>
                )}
                {object?.internal_identifier == 'other_charges' && (
                  <>
                    <div className="bg-white container mx-auto py-6 md:py-8 lg:py-8 rounded-lg  mt-0 md:mt-10 p-4 mb-12 md:mb-12">
                      <div className="w-full">
                        <div className="text-left">
                          {object?.marketing_extended_heading && (
                            <h4 className=" text-primary-BLACK text-lg md:text-2xl font-bold">
                              {parseString(object?.marketing_extended_heading)}
                            </h4>
                          )}

                          {object?.marketing_extendeds?.data?.length && (
                            // <div className="flex flex-col md:flex-row pt-4 gap-2">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-4 gap-2">
                              {object?.marketing_extendeds?.data?.map(
                                (obj, sindex) => (
                                  <DownloadCardView
                                    key={sindex}
                                    contents={obj}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {object?.internal_identifier == 'disclaimer' && (
                  <>
                    {object?.marketing_cmp_description && (
                      <div className="w-full text-left mt-3 mb-24">
                        <div className="text-sm font-normal text-neutral-GRAY">
                          {parseString(object?.marketing_cmp_description)}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {object?.internal_identifier == 'investment_choices' && (
                  <div className="bg-white  p-6 rounded-lg py-6 md:py-10 md:mb-8 lg:mb-0 ">
                    {object?.marketing_cmp_heading && (
                      <h1 className=" font-bold text-xl md:text-2xl lg:text-4xl lg:mb-4 md:mb-0">
                        {parseString(object?.marketing_cmp_heading)}
                      </h1>
                    )}
                    {object?.marketing_cmp_description && (
                      <div className="text-sm md:text-base lg:text-lg  text-neutral-GRAY py-3 px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_cmp_description)}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier ==
                  'portfolio_details_investment' && (
                    <div
                      key="portfolio_details_investment"
                      className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 mt-0 lg:mt-10 gap-1 lg:gap-8"
                    >
                      {object?.portfolios?.data?.length &&
                        object?.portfolios?.data?.map((item, idx) => (
                          <div
                            key={`portfolio-${idx}`}
                            className="bg-white rounded-lg h-fit"
                          >
                            <div
                              style={{
                                backgroundColor: `${item?.attributes?.user_bg_color_code}`,
                              }}
                              className="pt-2 rounded-lg relative "
                            >
                              <div className="grid grid-cols-12 gap-4 min-h-[220px]">
                                <div className="relative flex col-span-6 md:col-span-5 lg:col-span-6 xl:col-span-5 flex-col h-full justify-end">
                                  <img
                                    src={getMedia(item?.attributes?.user_image)}
                                    className="pr-2 max-w-[220px] max-h-[200px]  w-3/4 lg:w-full	 md:60 pl-2"
                                    alt="Image"
                                  />
                                  <img
                                    src={getMedia(item?.attributes?.user_intro)}
                                    className="pr-2  w-36    absolute  top-0 left-28  sm:left-36 	 "
                                    alt="Image"
                                  />
                                </div>

                                <div className="relative flex col-span-6 md:col-span-7 lg:col-span-6 xl:col-span-7 flex-col h-full pb-12">
                                  <div className="text-left  pr-5  mt-auto">
                                    {item?.attributes?.user_display_name && (
                                      <h4 className="text-sm  md:text-xl	 text-black font-bold">
                                        {parseString(
                                          item?.attributes?.user_display_name
                                        )}
                                      </h4>
                                    )}
                                    {item?.attributes?.user_description && (
                                      <div className="font-normal text-xs text-black md:text-base pt-5 line-clamp-custom1 ">
                                        {parseString(
                                          item?.attributes?.user_description
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  {item?.attributes?.user_dialogue && (
                                    <div
                                      style={{
                                        backgroundColor: `${item?.attributes?.dialogue_bg_color_code}`,
                                      }}
                                      className={`py-5 px-4 bg-primary absolute top-[180px] left-0 -right-3 chat-border card-shadow-custom`}
                                    >
                                      {/* <div className=" flex flex-row items-center "> */}
                                      <div className="text-white text-left text-sm max-w-[200px]">
                                        {parseString(
                                          item?.attributes?.user_dialogue
                                        )}
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="rounded-lg">
                              <div className="container mx-auto  px-5 pt-20 py-10 flex flex-col lg:flex-col justify-between ">
                                <div className="  flex flex-row pb-3 lg:flex-row justify-between items-center	 w-full  horizontal-b   ">
                                  {item?.attributes?.portfolio_heading && (
                                    <h2 className=" text-lg md:text-2xl lg:text-4xl text-black  font-semibold	  lg:font-bold">
                                      {parseString(
                                        item?.attributes?.portfolio_heading
                                      )}
                                    </h2>
                                  )}
                                  {item?.attributes?.portfolio_badge && (
                                    <button className="text-primitive-BLUE-700 bg-site-F0F5FF px-3 py-1  text-sm font-extrabold rounded-full h-10  flex   justify-center items-center	 ">
                                      {parseString(
                                        item?.attributes?.portfolio_badge
                                      )}
                                    </button>
                                  )}
                                </div>

                                {item?.attributes?.portfolio_description && (
                                  <div className="text-left pt-5 text-neutral-GRAY text-sm ">
                                    {parseString(
                                      item?.attributes?.portfolio_description
                                    )}
                                  </div>
                                )}

                                {item?.attributes?.asset_classes?.data
                                  ?.length && (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
                                      {item?.attributes?.asset_classes?.data?.map(
                                        (asobj, asindex) => (
                                          <AssetCopy
                                            key={asindex}
                                            contents={asobj}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}

                                {item?.attributes?.portfolio_footer && (
                                  <div className="text-left mt-4">
                                    {parseString(
                                      item?.attributes?.portfolio_footer
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* Age Calculator */}

                            {item?.attributes?.dynamic_tables?.data[0] != null && item?.attributes?.dynamic_tables?.data[0] != undefined && (
                              <AbnpsTable
                                key={`abnps_table_${idx}`}
                                contents={
                                  item?.attributes?.dynamic_tables?.data[0]
                                }
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  )}

                {/* Tab 4 */}
                {object?.internal_identifier ==
                  'about_nps_partial_withdrawal' && (
                    <div
                      key="about_nps_partial_withdrawal"
                      className="bg-white p-6 rounded-lg py-6 md:py-10"
                    >
                      {object?.marketing_cmp_heading && (
                        <h1 className="black-h-text text-xl md:text-2xl lg:text-4xl font-bold lg:mb-4 md:mb-0">
                          {parseString(object?.marketing_cmp_heading)}
                        </h1>
                      )}
                      {object?.marketing_cmp_description && (
                        <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal py-3 px-4 md:px-16 lg:px-36">
                          {parseString(object?.marketing_cmp_description)}
                        </div>
                      )}
                    </div>
                  )}

                {object?.internal_identifier ==
                  'you_can_withdraw_in_any_case_of_emergency' && (
                    <div
                      key="you_can_withdraw_in_any_case_of_emergency"
                      className="main-bg"
                    >
                      <div className="mx-auto text flex flex-col lg:flex-row  md:flex-row justify-between mt-5 md:mt-10 mb-6 md:mb-14 lg:mb-28">
                        {getMedia(
                          object?.marketing_units_groups?.data[0]?.attributes
                            ?.unit_media
                        ) && (
                            <div className="w-full lg:w-1/2 hidden mb-10 md:flex items-center justify-center">
                              <img
                                src={getMedia(
                                  object?.marketing_units_groups?.data[0]
                                    ?.attributes?.unit_media
                                )}
                                className="w-full max-w-[500px]"
                              />
                            </div>
                          )}

                        <div className="w-full lg:w-1/2 pl-0 md:pl-14">
                          {object?.marketing_extended_heading && (
                            <h4 className="text-left text-primary-BLACK font-bold text-xl md:text-lg lg:text-2xl">
                              {parseString(object?.marketing_extended_heading)}
                            </h4>
                          )}
                          {object?.marketing_extended_description && (
                            <div className="text-sm sm:text-lg lg:text-lg text-neutral-GRAY text-left font-normal  mb-8 mt-2 ">
                              {parseString(
                                object?.marketing_extended_description
                              )}
                            </div>
                          )}

                          {getMedia(
                            object?.marketing_units_groups?.data[0]?.attributes
                              ?.unit_media
                          ) && (
                              <div className="w-full lg:w-1/2 md:hidden  flex items-center justify-center ">
                                <img
                                  src={getMedia(
                                    object?.marketing_units_groups?.data[0]
                                      ?.attributes?.unit_media
                                  )}
                                  className="w-full max-w-[500px]"
                                />
                              </div>
                            )}

                          {object?.marketing_units_groups?.data[0]?.attributes
                            ?.marketing_units?.length && (
                              <div className="flex-col items-left justify-center mt-5 md:mt-0">
                                {object?.marketing_units_groups?.data[0]?.attributes?.marketing_units?.map(
                                  (obj, sindex) => (
                                    <CardCopy
                                      content={obj}
                                      key={`cchbc-${sindex}`}
                                      iconHeadStartClass={'pr-2 w-10 h-10'}
                                      extraBoxClass="mb-4 md:mb-4"
                                    />
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                {object?.internal_identifier == 'about_us_exit_from_nps' && (
                  <div
                    key="about_us_exit_from_nps"
                    className="bg-white p-6 rounded-lg py-6 md:py-10"
                  >
                    {object?.marketing_cmp_heading && (
                      <h1 className="black-h-text text-xl md:text-2xl lg:text-4xl font-bold mb-3 lg:mb-4 ">
                        {parseString(object?.marketing_cmp_heading)}
                      </h1>
                    )}
                    {object?.marketing_cmp_description && (
                      <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal  px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_cmp_description)}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier ==
                  'about_nps_withdraw_variants' && (
                    <>
                      {object?.marketing_units_groups?.data?.length && (
                        <div
                          key="about_nps_withdraw_variants"
                          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  mt-10  gap-4"
                        >
                          {object?.marketing_units_groups?.data?.map(
                            (obj, sindex) => (
                              <CardCopy
                                content={obj}
                                key={`cchbc-${sindex}`}
                                iconHeadStartClass={'pr-2 w-10 h-10'}
                              />
                            )
                          )}
                        </div>
                      )}
                    </>
                  )}

                {/* Tab 5 */}

                {object?.internal_identifier == 'income_post_retirement' && (
                  <div
                    key="income_post_retirement"
                    className="bg-white p-6 rounded-lg py-6 md:py-10"
                  >
                    {object?.marketing_cmp_heading && (
                      <h1 className="black-h-text text-xl md:text-2xl lg:text-4xl font-bold lg:mb-4 md:mb-0">
                        {parseString(object?.marketing_cmp_heading)}
                      </h1>
                    )}
                    {object?.marketing_cmp_description && (
                      <div className="text-sm md:text-base lg:text-lg text-neutral-GRAY font-normal py-3 px-4 md:px-16 lg:px-36">
                        {parseString(object?.marketing_cmp_description)}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier ==
                  'income_post_retirement_details' && (
                    <div key="income_post_retirement_details" className="main-bg">
                      <div className="  mx-auto    text   flex flex-col lg:flex-row  md:flex-row justify-between mt-0 md:mt-10">
                        {getMedia(
                          object?.marketing_units_groups?.data[0]?.attributes
                            ?.unit_media
                        ) && (
                            <div className="w-full lg:w-1/2 hidden md:flex items-center justify-center">
                              <img
                                src={getMedia(
                                  object?.marketing_units_groups?.data[0]
                                    ?.attributes?.unit_media
                                )}
                                className="w-full max-w-[500px]"
                              />
                            </div>
                          )}
                        <div className=" w-full lg:w-1/2 pl-0 md:pl-14    ">
                          {getMedia(
                            object?.marketing_units_groups?.data[0]?.attributes
                              ?.unit_media
                          ) && (
                              <div className="w-full  lg:w-1/2 md:hidden  flex items-center  justify-center  ">
                                <img
                                  src={getMedia(
                                    object?.marketing_units_groups?.data[0]
                                      ?.attributes?.unit_media
                                  )}
                                  className="w-10/12	"
                                />
                              </div>
                            )}
                          {object?.marketing_units_groups?.data[0]?.attributes
                            ?.marketing_units?.length && (
                              <div className="flex-col items-left justify-center mt-5 md:mt-0">
                                {object?.marketing_units_groups?.data[0]?.attributes?.marketing_units?.map(
                                  (obj, sindex) => (
                                    <CardCopy
                                      content={obj}
                                      key={`cchbc-${sindex}`}
                                      iconHeadStartClass={'pr-2 w-10 h-10'}
                                      extraBoxClass="mb-4 md:mb-4"
                                    />
                                  )
                                )}
                                {object?.ctas?.data?.length ? (
                                  <div className="mt-8 lg:mt-10 text-left">
                                    {/* <StyledButton cta={object?.ctas} title='Know more' />{' '} */}
                                    <ButtonPrimary
                                      title={
                                        object?.ctas?.data[0]?.attributes?.cta_title
                                      }
                                      url={
                                        object?.ctas?.data[0]?.attributes
                                          ?.cta_external_link
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ))}
        </div>
      </div>

      {/* FOOTER BANNER */}
      {tabBody?.data?.attributes?.footer_banner?.data && (
        <FooterBanner contents={tabBody?.data?.attributes?.footer_banner} />
      )}
      {/* FOOTER BANNER */}

      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default AboutNps;
