import React, { useEffect, useState } from 'react';

import Menu from '../common/layout/Menu';
import Footer from '../common/layout/Footer';

import AccordionPub from '../common/components/Accordions/AccordionPub';
import { useDispatch, useSelector } from 'react-redux';
import { showPageLoader, resetPageLoader } from '../redux/actions/CommonAction';
import { fetchPubDiscData } from '../redux/actions/PageAction';
import BannerDisclosure from '../common/components/Banners/BannerDisclosure';
import { useParams } from 'react-router-dom';

const PubDisclosure = () => {
  // const [content, setContent] = useState({});
  const [body, setBody] = useState({});

  // Redux
  const dispatch = useDispatch();
  const { pubdisc_content } = useSelector((state) => state.pageState);
  const { item } = useParams();

  useEffect(() => {
    if (pubdisc_content == null) {
      dispatch(fetchPubDiscData({}));
      dispatch(showPageLoader());
    }
  }, [pubdisc_content]);

  useEffect(() => {
    if (pubdisc_content) {
      // setContent(pubdisc_content?.data ? pubdisc_content?.data[0] : {});
      setBody(
        pubdisc_content?.data[0]?.attributes?.page_body_elements?.data
          ? pubdisc_content?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
      dispatch(resetPageLoader());
    }
  }, [pubdisc_content]);


  return (
    <>
      <div className="bg-secondary">
        {/* NAVBAR SECTION */}
        <Menu key={`pub-menu-disclosure${new Date().now}`} />
        {/* NAVBAR SECTION */}

        <div className=" bg-primary-LIGHT mt-[8rem] md:mt-32 px-4 lg:px-16 md:px-10 py-6  md:py-16 flex flex-col lg:flex-column relative">
          {body?.attributes?.page_body_cmp &&
            body?.attributes?.page_body_cmp.map((object, index) => (
              <React.Fragment key={index}>
                {
                  object?.internal_identifier == 'public_disclosure' && object?.__component == "marketing-block.marketing-block-cmp" && <BannerDisclosure contents={object || {}} key={`banner_${index}`} />
                }
                {
                  object?.internal_identifier == 'public_disclosure' && object?.__component == 'tabs.page-tabs' && <AccordionPub contents={object || {}}
                    key={`acco_${index}`} activeItem={item || ''} />
                }
              </React.Fragment>
            ))}
        </div>
        {/* FOOTER */}
        <Footer key="pub-footer-disclosure" />
        {/* FOOTER */}
      </div>
    </>
  );
};

export default PubDisclosure;
