import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import mmes2 from '../../../assets/images/making-money-easily-2.svg';
import vct13 from '../../../assets/images/vector-13.svg';
import vct14 from '../../../assets/images/vector-14.svg';
import { parseString } from '../../AppConstants';
import CardCopy from './CardCopy';

const HomeNpsWorksCopy = ({ contents }) => {
  const [cardBody, setCardBody] = useState(null);

  useEffect(() => {
    if (
      contents &&
      contents?.marketing_units_groups?.data[0]?.attributes?.marketing_units &&
      cardBody == null
    ) {
      setCardBody(
        contents?.marketing_units_groups?.data[0]?.attributes?.marketing_units
      );
    }
  }, [contents]);

  return (
    <div className="bg-site-FFF4EC px-4 lg:px-16 md:px-10 py-6  md:py-16 flex flex-col lg:flex-column justify-center">
      <div className="text-center">
        {contents?.marketing_extended_heading && (
          <h2 className="font-bold text-xl md:text-2xl lg:text-4xl mb-1 md:mb-4">
            {parseString(contents?.marketing_extended_heading)}
          </h2>
        )}
        {contents?.marketing_extended_description && (
          <div className="text-neutral-GRAY text-center mt-2 md:mt-4 text-sm md:text-lg">
            {parseString(contents?.marketing_extended_description)}
          </div>
        )}
      </div>

      <div className="mx-auto mt-2 md:mt-14 container flex flex-col lg:flex-row justify-between">
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-0 md:gap-5">
          <div className="flex items-center justify-center mb-5 md:mb-0">
            <div>
              <img
                className="max-w-[380px] md:w-full md:max-w-[500px]"
                src={mmes2}
              />
            </div>
          </div>
          <div className="overflow-x-hidden !px-15   md:!px-5  !py-2   md:!py-5   table-scrollable lg:overflow-y-auto no-scrollbar">
            {cardBody &&
              cardBody.map((obj, index) => (
                <div
                  key={`block-${index}`}
                  className={`flex flex-row relative items-center
                                    ${
                                      (index + 1) % 2 == 0
                                        ? 'md:mr-20 lg:mr-20'
                                        : 'md:ml-20 lg:ml-20'
                                    } `}
                >
                  {index + 1 != cardBody?.length && (
                    <>
                      {(index + 1) % 2 == 0 ? (
                        <img
                          className="absolute move-arrow-right w-[2.5rem]     md:w-[5rem] z-10  top-[90px] left-[-32px]   md:top-[90px] md:left-[-20px]   "
                          src={vct14}
                        />
                      ) : (
                        <img
                          className="absolute move-arrow-top-hgh w-[2.5rem] md:w-[5rem] top-[107px] right-[-28px]   md:top-[90px] md:right-[-20px] z-10"
                          src={vct13}
                        />
                      )}
                    </>
                  )}

                  <CardCopy
                    content={obj}
                    key={`cchbc-${index}`}
                    iconHeadStartClass="pr-2 w-6 h-6"
                    extraBoxClass="mb-5 md:mb-14"
                    cardType="plain"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

HomeNpsWorksCopy.propTypes = {
  contents: PropTypes.object,
};
export default HomeNpsWorksCopy;
