import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// constants
import { parseString } from '../../common/AppConstants';

// images
import plus_icon from '../../assets/images/plusicon.svg';
import minus_icon from '../../assets/images/minusicon.svg';
import toparrow_icon from '../../assets/images/toparrowicon.svg';
import bottomarrow_icon from '../../assets/images/bottomarrowicon.svg';

// components
import Tabs from '../../common/components/Tabs';
import CardCopy from '../../common/components/Cards/CardCopy';
import TabStepperComponent from './TabStepperComponent';
import TabFaqCopy from './TabFaqCopy';
import BannerGovernmentSolution from '../../common/components/Banners/BannerGovernmentSolution';

const TabGov = ({ contents }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabBody, setTabBody] = useState({});
  const [body, setBody] = useState({});
  const [all_tabs, setTabs] = useState([]);

  useEffect(() => {
    if (contents) {
      setBody(
        contents?.data[0]?.attributes?.page_body_elements?.data
          ? contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
    }
  }, [contents]);

  useEffect(() => {
    if (body) {
      if (body?.attributes?.page_body_cmp) {
        let tab_element = body?.attributes?.page_body_cmp[0]?.tab_element;
        if (tab_element) {
          let dataset = [];
          tab_element?.map((obj, index) => {
            dataset.push({
              index: index,
              title: obj?.tab_text,
            });
            if (index == 0) {
              setTabBody(obj?.page_body);
            }
          });
          setTabs(dataset);
        }
      }
    }
  }, [body]);

  useEffect(() => {
    activeTab != null
      ? setTabBody(
          body?.attributes?.page_body_cmp[0]?.tab_element[activeTab]?.page_body
        )
      : null;
  }, [activeTab]);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  return (
    <>
      {/* Page Banner */}
      {contents?.data[0]?.attributes?.page_banners && (
        <BannerGovernmentSolution
          content={contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      <div className="bg-background pt-0 md:pt-5">
        <div className="lg:container mx-auto  my-0 px-0 md:my-5 md:py-0 md:px-36 lg:px-60  ">
          {all_tabs && (
            <Tabs
              items={all_tabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
        {tabBody &&
          tabBody?.data?.attributes?.page_body_cmp.map((object, index) => (
            <div key={`government_page_body_${index}`}>
              {object?.internal_identifier ==
                'governemnt_national_pension_schemes' && (
                <div className="container mx-auto px-5  md:px-10 pt-6 lg:pt-10 pb-10 lg:pb-20 ">
                  <div>
                    {object?.marketing_extended_heading && (
                      <h1 className="  mb-2 md:mb-4 text-xl md:text-2xl lg:text-4xl text-center font-bold">
                        {parseString(object?.marketing_extended_heading)}
                      </h1>
                    )}
                    {object?.marketing_extended_description && (
                      <div className="text-sm  md:text-base lg:text-lg text-center font-normaltext-gray-600 mb-0 md:mb-8 px-10">
                        {parseString(object?.marketing_extended_description)}
                      </div>
                    )}
                  </div>

                  {object?.marketing_units_groups?.data?.length && (
                    <div className="grid grid-cols-1  md:grid-cols-2 mt-5 gap-4">
                      {object?.marketing_units_groups?.data?.map(
                        (obj, sindex) => (
                          <CardCopy
                            content={obj}
                            key={`cchbc-${sindex}`}
                            iconHeadStartClass={'pr-2 w-10 h-10'}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              )}

              {object?.internal_identifier == 'investment_options' && (
                <div className="bg-primary-light-orange">
                  <div className=" container mx-auto px-2 md:px-10  pt-10 lg:pt-10 pb-10 lg:pb-20 flex flex-col lg:flex-col justify-between ">
                    <div className=" px-4 mx-auto ">
                      {object?.marketing_extended_heading && (
                        <h1 className="text-center font-bold mb-2 md:mb-4 text-xl md:text-2xl lg:text-4xl">
                          {parseString(object?.marketing_extended_heading)}
                        </h1>
                      )}
                      {object?.marketing_extended_description && (
                        <div className="text-sm  md:text-base lg:text-lg text-center  text-gray-600 mb-8 font-normal">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      )}
                    </div>

                    {object?.marketing_units_groups?.data?.length && (
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-4">
                        {object?.marketing_units_groups?.data?.map(
                          (obj, sindex) => (
                            <CardCopy
                              content={obj}
                              key={`cchbc-${sindex}`}
                              iconHeadStartClass={'pr-2 w-10 h-10'}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {object?.internal_identifier == 'how_does_lc50_and_lc25' && (
                <div className="main-bg">   
                  <div className="container mx-auto px-2 md:px-10  pt-10 lg:pt-10 pb-10 lg:pb-20 flex flex-col lg:flex-col justify-between ">
                    <div className="px-4 mx-auto">
                      {object?.marketing_extended_heading && (
                        <h1 className="font-bold text-center text-xl md:text-2xl lg:text-4xl mb-2 md:mb-4">
                          {parseString(object?.marketing_extended_heading)}
                        </h1>
                      )}
                      {object?.marketing_extended_description && (
                        <div className="  text-sm  md:text-base lg:text-lg text-center  text-gray-600 mb-8 font-normal  px-5 lg:px-10">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      )}
                    </div>

                    <div>
                      <div className=" lg:bg-white rounded-lg  p-0 lg:p-5">
                        <div className="flex flex-col md:flex-row gap-3 justify-between">
                          {object?.marketing_blocks?.data?.length && (
                            <div className="flex flex-col lg:flex-col md:justify-between gap-3 ">
                              {object?.marketing_blocks?.data?.map(
                                (sobj, soindex) => (
                                  <div
                                    key={`block244-${soindex}`}
                                    className="bg-white p-5 rounded-lg  border-purple"
                                  >
                                    {sobj?.attributes?.marketing_heading && (
                                      <h2 className="text-base md:text-xl text-primary-DARK  font-bold text-left">
                                        {parseString(
                                          sobj?.attributes?.marketing_heading
                                        )}
                                      </h2>
                                    )}
                                    {sobj?.attributes
                                      ?.marketing_description && (
                                      <div className="  text-sm  lg:text-base  text-left font-normal  text-primary-DARK mt-2">
                                        {parseString(
                                          sobj?.attributes
                                            ?.marketing_description
                                        )}
                                      </div>
                                    )}{' '}
                                  </div>
                                )
                              )}
                            </div>
                          )}

                          <div className="container mx-auto bg-neutral-50  px-0 md:px-0 lg:px-5  rounded-lg  ">
                            <h6 className=" text-lg  hidden lg:block   text-left   py-5 text-neutral-GRAY ">
                              Chart giving details of investment pattern in LC
                              50 and LC 25.
                            </h6>
                            <div className=" bg-white text-white rounded-lg card-shadow-custom border border-grey-neutral">
                              <div className="flex flex-col lg:flex-row justify-between ">
                                <div className="bg-sky-blue  rounded-lg  border-purple py-5 lg:py-10 px-5  w-full lg:w-80">
                                  <h5 className=" text-base lg:text-lg text-primary-BLACK text-center whitespace-nowrap">
                                    Age
                                  </h5>
                                  <div className="flex justify-start lg:justify-around  items-center px-0 py-4">
                                    <h6 className=" text-xl lg:text-2xl	 text-secondary-DARK  ">
                                      {' '}
                                      35 Years
                                    </h6>
                                  </div>
                                  <h5 className="  text-sm  lg:text-base  text-neutral-GRAY text-left   pt-2 pb-0 lg-pt-5 lg-pb-5    ">
                                    The distribution percentage of money is
                                    mentioned below based on your age.
                                  </h5>
                                </div>

                                <div className=" w-full  ">
                                  <table className="w-full">
                                    <tbody>
                                      <tr className="text-blue-700 horizontal-b   ">
                                        <th className=" text-xs lg:text-base   px-2 py-2 lg:px-3 lg:py-4  text-primary-BLUE-500  font-medium  ">
                                          LC 50 ( Moderate)
                                        </th>
                                        <th className=" text-xs lg:text-base  px-2 py-2 lg:px-3 lg:py-4  text-primary-BLUE-500   font-medium	   horizontal-l horizontal-r">
                                          LC 25 (Conservative)
                                        </th>
                                        <th className="text-xs lg:text-base   px-2 py-2 lg:px-3 lg:py-4  text-primary-BLUE-500    font-medium	    ">
                                          Scheme G
                                        </th>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <h6 className="  text-xs text-center  lg:text-sm  text-black bg-light-brown w-full   p-0 lg:p-2  ">
                                            Asset Allocation under E
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr className="text-black  font-medium	 text-sm    horizontal-b ">
                                        <th className="text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4   font-medium	 ">
                                          50%
                                        </th>
                                        <th className=" text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4  font-medium	  horizontal-l horizontal-r">
                                          25%
                                        </th>
                                        <th className="text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4  font-medium	 ">
                                          0%
                                        </th>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <h6 className=" text-xs  lg:text-sm   text-black bg-light-brown  w-full   p-0 lg:p-2  text-center  ">
                                            Asset Allocation under C
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr className="text-black  font-medium	 text-sm   horizontal-b  ">
                                        <th className="  text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4   font-medium	  ">
                                          {' '}
                                          30%
                                        </th>
                                        <th className="text-sm lg:text-lg   px-2 py-2 lg:px-3 lg:py-4  font-medium	 horizontal-l horizontal-r">
                                          45%
                                        </th>
                                        <th className=" text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4 font-medium	 ">
                                          0%
                                        </th>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <h6 className=" text-xs  lg:text-sm   text-black bg-light-brown  w-full   p-0 lg:p-2  text-center ">
                                            Asset Allocation under G
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr className="text-black  font-medium	 text-sm   horizontal-b   ">
                                        <th className=" text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4   font-medium	  ">
                                          {' '}
                                          20%
                                        </th>
                                        <th className=" text-sm lg:text-lg   px-2 py-2 lg:px-3 lg:py-4  font-medium	 horizontal-l horizontal-r">
                                          30%
                                        </th>
                                        <th className=" text-sm lg:text-lg  px-2 py-2 lg:px-3 lg:py-4  font-medium	 ">
                                          100%
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {object?.internal_identifier == 'how_to_change_pfm' && (
                <div className="bg-primary-light-orange">
                  <div className=" container mx-auto   px-2 md:px-10  pt-10 lg:pt-10 pb-10 lg:pb-20 flex flex-col lg:flex-col justify-between ">
                    <div className="  max-w-3xl	 mx-auto  ">
                      {object?.marketing_extended_heading && (
                        <h1 className="text-center text-neutral-GRAY font-bold mb-2 md:mb-4 text-xl md:text-2xl lg:text-4xl ">
                          {parseString(object?.marketing_extended_heading)}
                        </h1>
                      )}
                      {object?.marketing_extended_description && (
                        <div className="text-sm md:text-base lg:text-lg text-center text-gray-600 mb-8 font-normal">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      )}
                    </div>

                    {/* Stepper  */}
                    <TabStepperComponent contents={object} />
                    {/* Stepper  */}

                    <div className=" mt-20 max-w-3xl	 mx-auto  ">
                      <h1 className=" text-center max-w-xl lg:max-w-3xl mx-auto font-bold text-xl md:text-2xl lg:text-4xl  mb-2 md:mb-4  ">
                        {' '}
                        <span className="text-primary text-xl md:text-2xl lg:text-4xl  font-bold">
                          Fund performance
                        </span>{' '}
                        <span className="black-h-text text-xl md:text-2xl lg:text-4xl font-bold ">
                          of Moderate Scheme 50 & conservative Scheme LC 25
                        </span>{' '}
                      </h1>
                      <h2 className="  text-sm  md:text-base lg:text-lg text-center px-5 md:px-16  text-gray-600 mb-8  font-normal ">
                        Under the NPS product 3 Portfolio available under Auto
                        choice, subscribers can choose between Aggressive,
                        Moderate, and Conservative portfolios based on their
                        risk appetite.
                      </h2>
                    </div>

                    <div className="px-5 mt-5">
                      <div className=" lg:bg-white rounded-lg  p-0  ">
                        <div className="flex flex-col md:flex-row gap-3 justify-between">
                          <div className="container mx-auto    bg-grey-color  pb-4 px-4 md:py-5 md:px-10 lg:px-5  lg:pb-4 rounded-xl		 ">
                            <div className="  items-center hidden md:flex">
                              <span className=" text-lg    text-left   py-5">
                                {' '}
                                ICICI Prudential Pension Funds{' '}
                              </span>
                              <button className="border bg-primary border-custom-color text-sm  ml-3 block text-white rounded-full uppercase px-2 py-1 orange-custom-button">
                                Tier I Fund Performance
                              </button>
                            </div>
                            <div className=" bg-white text-white rounded-lg card-shadow-custom border border-grey-neutral">
                              <div className="flex flex-col lg:flex-row justify-between ">
                                <div className="bg-sky-blue  py-5 lg:py-10 px-5  w-full lg:w-80">
                                  <h5 className=" text-base  lg:text-lg  text-black text-left whitespace-nowrap">
                                    Enter your age
                                  </h5>
                                  <div className="flex justify-start lg:justify-around  items-center border-b-2 px-0 py-4 border-black">
                                    <a
                                      href=""
                                      className="mb-1  hidden lg:block"
                                    >
                                      {' '}
                                      <img src={minus_icon} />
                                    </a>

                                    <h6 className=" text-xl lg:text-2xl	 text-black font-bold ">
                                      {' '}
                                      50
                                    </h6>
                                    <div className=" block lg:hidden pl-20 ">
                                      <a href="" className="mb-1">
                                        {' '}
                                        <img src={toparrow_icon} />
                                      </a>
                                      <a href="">
                                        {' '}
                                        <img
                                          src={bottomarrow_icon}
                                          className="pt-1"
                                        />
                                      </a>
                                    </div>
                                    <a className="hidden lg:block" href="">
                                      {' '}
                                      <img src={plus_icon} />
                                    </a>
                                  </div>
                                  <h5 className="  text-sm  lg:text-base  text-black text-left   pt-2 pb-0 lg-pt-5 lg-pb-5    ">
                                    The distribution percentage of money is
                                    mentioned below based on your age.
                                  </h5>
                                </div>
                                <div className=" w-full overflow-auto   ">
                                  <table className="table-auto border-collapse w-full text-black ">
                                    <thead>
                                      <tr>
                                        <th className="border font-semibold bg-dullgreen  p-3 text-sm lg:text-lg">
                                          Tier I
                                        </th>
                                        <th className="border p-3 text-blue-600 font-medium  text-sm lg:text-lg ">
                                          3 Years
                                        </th>
                                        <th className="border p-3 text-blue-600 font-medium  text-sm lg:text-lg ">
                                          5 Years
                                        </th>
                                        <th className="border p-3 text-blue-600 font-medium  text-sm lg:text-lg ">
                                          7 Years
                                        </th>
                                        <th className="border p-3 text-blue-600 font-medium  text-sm lg:text-lg">
                                          10 Years
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="border bg-dullgreen p-6 text-primary-BLACK  text-sm lg:text-lg">
                                          Aggressive - LC 75
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          12.64%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          10.40%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          12.61%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          11.66%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="border bg-dullgreen text-primary-BLACK  p-6 text-sm lg:text-lg">
                                          Moderate - LC 50
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          10.58%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          9.56%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          11.21%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          10.69%
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="border bg-dullgreen text-primary-BLACK p-6 text-sm lg:text-lg">
                                          Conservative - LC 25
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          8.47%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg ">
                                          8.73%
                                        </td>
                                        <td className="border p-3  text-BLACK text-center text-sm lg:text-lg">
                                          9.82%
                                        </td>
                                        <td className="border p-3 text-BLACK text-center text-sm lg:text-lg">
                                          9.69%
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-10 md:mb-20">
                {object?.internal_identifier == 'faq' && (
                  <TabFaqCopy bg="" contents={object} />
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
TabGov.propTypes = {
  contents: PropTypes.object,
};
export default TabGov;
