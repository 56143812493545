import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// components
// import AutoComplete from '../Search/AutoComplete';
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_nps_solution_corporate } from '../../layout/breadcrumb-data';

// images
import vector_btm_nps from '../../../assets/images/bottom-vectors.svg';
// import base_icon from '../../../assets/images/baseicons.svg';

const BannerCorporateSolution = ({ content }) => {
  return (
    <div className="bg-site-FFF0E3 mt-20 xl:mt-28 pb-5 md:pb-0 relative">
      <img
        src={vector_btm_nps}
        className="absolute right-0 bottom-0  hidden  md:block"
      />
      <div className="container mx-auto pt-6 self-start pl-5">
        <BreadCrumb
          items={bc_nps_solution_corporate}
          key="nps-solution-retail-breadcrumb"
        />
      </div>

      <div className="px-5 py-2  mx-auto container banner-border-custom text flex flex-col lg:flex-row justify-between ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_corporate_content_${index}`}>
                {toLower(item?.position) == 'right' && (
                  <div
                    key={`img_right_${index}`}
                    className="w-full order-first block md:hidden mb-5"
                  >
                    <img
                      className="w-full max-w-[340px] mx-auto"
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
                {toLower(item?.position) == 'left' && (
                  <div
                    key={`content_left_${index}`}
                    className="w-full  flex flex-col lg:flex-row justify-center    "
                  >
                    <div className="  flex items-center justify-center flex-col md:items-start md:justify-center">
                      {item?.banner_heading && (
                        <h1 className=" text-center text-2xl md:text-4xl  lg:text-5xl md:text-left font-bold">
                          {parseString(item?.banner_heading)}
                        </h1>
                      )}
                      {item?.banner_description && (
                        <div className=" text-sm md:text-lg   text-center md:text-justify  text-primary-BLACK mb-8   mt-2 md:mt-6  font-normal ">
                          {parseString(item?.banner_description)}
                        </div>
                      )}
                      {/* <div className=" w-full  ">
                        <div className="max-w-md mx-auto md:mx-0">
                          <AutoComplete page="corporate" />
                          <img
                            src={base_icon}
                            className=" w-60  md:w-80 mx-auto"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                )}
                {toLower(item?.position) == 'right' && (
                  <div className="w-full md:mb-8  hidden  md:block  p-0  lg:overflow-y-auto">
                    <img
                      className=" w-full  max-w-[540px]"
                      src={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url
                      }
                      alt={
                        item?.media?.data?.attributes?.media?.data?.attributes
                          ?.alternative_text || 'banner image'
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerCorporateSolution;
