import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_about_us } from '../../layout/breadcrumb-data';

const BannerAboutUs = ({ content }) => {
  return (
    <div className="bg-white  mx-auto p-4 pb-8 lg:pb-24 lg:p-8 flex flex-col items-center mt-20 xl:mt-28">
      <div className="container mx-auto  self-start px-4 pl-0 xl:pl-6 ">
        <div className="mb-4">
          <BreadCrumb items={bc_about_us} />
        </div>
      </div>

      <div className="container mx-auto mt-8 px-0 sm:px-5 md:px-6 lg:px-10">
        <div className="flex flex-wrap -mx-4">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_about_content_${index}`}>
                {toLower(item?.position) == 'center' && (
                  <div
                    key={`content_center_${index}`}
                    className="w-full mb-6 order-first"
                  >
                    {item?.banner_heading && (
                      <h1 className="text-2xl text-center md:text-4xl lg:text-5xl font-bold mb-2 lg:mb-4 text-black">
                        {parseString(item?.banner_heading)}
                      </h1>
                    )}
                    {item?.banner_description && (
                      <div className="text-center text-sm md:text-base text-black">
                        {parseString(item?.banner_description)}
                      </div>
                    )}
                  </div>
                )}

                {toLower(item?.position) == 'left' && (
                  <div className="w-full sm:w-full md:w-1/3 px-4 mb-4">
                    <div className="bg-white p-1 ">
                      {item?.marketing_units_group?.data?.attributes?.marketing_units.map(
                        (unit, index) => (
                          <React.Fragment key={`left_mktg_unit_${index}`}>
                            {unit?.internal_identifier == 'left_top' && (
                              <div className="flex items-start">
                                {unit?.unit_media?.data?.attributes?.url && (
                                  <img
                                    className="align-top"
                                    src={
                                      unit?.unit_media?.data?.attributes?.url
                                    }
                                    alt={
                                      unit?.unit_media?.data?.attributes
                                        ?.alternativeText
                                    }
                                  />
                                )}
                                {unit?.unit_heading && (
                                  <div className="pl-3 text-sm md:text-lg  text-primary-DARK">
                                    {parseString(unit?.unit_heading)}
                                  </div>
                                )}
                              </div>
                            )}

                            {unit?.internal_identifier == 'left_bottom' && (
                              <div className="flex items-start pl-20 md:pl-2.5 mt-3 xs:mt-3 sm:mt-8 md:mt-28 lg:mt-28 xl:mt-40">
                                <img
                                  className="align-top"
                                  src={unit?.unit_media?.data?.attributes?.url}
                                  alt={
                                    unit?.unit_media?.data?.attributes
                                      ?.alternativeText
                                  }
                                />
                                {unit?.unit_heading && (
                                  <div className="pl-3 text-sm md:text-lg ">
                                    {parseString(unit?.unit_heading)}
                                  </div>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                )}

                {toLower(item?.position) == 'center' &&
                  item?.internal_identifier == 'center_web' && (
                    <div className="hidden md:flex items-center justify-center w-full sm:w-full md:w-1/3 px-0 mb-4 mt-3">
                      <div className="bg-white p-0 flex justify-center ">
                        {item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url && (
                          <img
                            className="hidden md:block"
                            src={
                              item?.media?.data?.attributes?.media?.data
                                ?.attributes?.url
                            }
                            alt={
                              item?.media?.data?.attributes?.media?.data
                                ?.attributes?.alternativeText
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}

                {toLower(item?.position) == 'center' &&
                  item?.internal_identifier == 'center_mobile' && (
                    <div className="flex md:hidden items-center justify-center w-full sm:w-full md:w-1/3 px-0 mb-4 mt-3">
                      <div className="bg-white p-0 flex justify-center ">
                        {item?.media?.data?.attributes?.media?.data?.attributes
                          ?.url && (
                          <img
                            className="px-5 block md:hidden max-w-[300px]"
                            src={
                              item?.media?.data?.attributes?.media?.data
                                ?.attributes?.url
                            }
                            alt={
                              item?.media?.data?.attributes?.media?.data
                                ?.attributes?.alternativeText
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}

                {toLower(item?.position) == 'right' && (
                  <div className="w-full sm:w-full md:w-1/3 px-4 mb-4">
                    <div className="bg-white p-1 ">
                      {item?.marketing_units_group?.data?.attributes?.marketing_units.map(
                        (unit, index) => (
                          <React.Fragment key={`right_mktg_unit_${index}`}>
                            {unit?.internal_identifier == 'right_top' && (
                              <div className="flex items-start">
                                <img
                                  className="align-top"
                                  src={unit?.unit_media?.data?.attributes?.url}
                                  alt={
                                    unit?.unit_media?.data?.attributes
                                      ?.alternativeText
                                  }
                                />
                                {unit?.unit_heading && (
                                  <div className="pl-3 text-sm md:text-lg  text-primary-DARK">
                                    {parseString(unit?.unit_heading)}
                                  </div>
                                )}
                              </div>
                            )}

                            {unit?.internal_identifier == 'right_bottom' && (
                              <div className="flex items-start pl-20 md:pl-2.5 mt-3 xs:mt-3 sm:mt-8 md:mt-28 lg:mt-28 xl:mt-40	">
                                <img
                                  className="align-top"
                                  src={unit?.unit_media?.data?.attributes?.url}
                                  alt={
                                    unit?.unit_media?.data?.attributes
                                      ?.alternativeText
                                  }
                                />
                                {unit?.unit_heading && (
                                  <div className="pl-3 text-sm md:text-lg  text-primary-DARK">
                                    {parseString(unit?.unit_heading)}
                                  </div>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerAboutUs;
