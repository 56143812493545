import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// images
import blog_bg_light from '../../../assets/images/blog-bg-light.png';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_blog } from '../../layout/breadcrumb-data';

const BannerBlogs = ({ content }) => {
  return (
    <div className="bg-primary-light-orange  mx-auto p-8 h-90 md:h-96 flex flex-col items-center mt-20 xl:mt-28 relative">
      <div className="absolute top-0 right-0">
        <img src={blog_bg_light} />
      </div>

      <div className="container mx-auto pt-6 sm:px-2 md:px-8 self-start">
        <div className="mb-4">
          <BreadCrumb items={bc_blog} />
        </div>
      </div>

      <div className="container mx-auto lg:pt-0 lg:px-8 text md:px-8 sm:p-2 flex flex-col ">
        <div className="grid grid-cols-1">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_blogs_content_${index}`}>
                {toLower(item?.position) == 'center' && (
                  <div key={`content_center_${index}`} className="w-full ">
                    {item?.banner_heading && (
                      <h1 className="text-2xl md:text-4xl font-bold lg:mb-4 text-primary text-center ">
                        {parseString(item?.banner_heading)}
                      </h1>
                    )}
                    {item?.banner_description && (
                      <div className="text-center text-base md:text-lg">
                        {parseString(item?.banner_description)}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerBlogs;
