import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// images
import dash_line_vector_faq from '../../../assets/images/dash-line-vector-faq.svg';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_privacy } from '../../layout/breadcrumb-data';

const BannerCommon = ({ content }) => {
    return (
        <div className="p-5 xl:pt-5 pb-0  relative">
            <div className="absolute top-24 right-0">
                <img className="w-20 md:w-40" src={dash_line_vector_faq} />
            </div>
            <div className="container mx-auto pt-6 sm:px-2 md:px-8 self-start mt-20 xl:mt-20">
                <div className="mb-4">
                    <BreadCrumb items={bc_privacy} key="faq-breadcrumb" />
                </div>
            </div>
            <div className="container mx-auto lg:pt-0 lg:px-8 text md:px-8 sm:p-2 flex flex-col ">
                <div className="grid grid-cols-1 ">
                    {content?.data?.[0]?.attributes?.banner_elements?.map(
                        (item, index) => (
                            <React.Fragment key={`banner_faq_content_${index}`}>
                                {toLower(item?.position) == 'center' && (
                                    <div key={`content_center_${index}`} className="w-full ">
                                        {item?.banner_heading && (
                                            <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-0 text-BLACK text-center">
                                                {parseString(item?.banner_heading)}
                                            </h1>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default BannerCommon;
