import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '../common/layout/Menu';
import Footer from '../common/layout/Footer';

import { showPageLoader, resetPageLoader } from '../redux/actions/CommonAction';
import { fetchFaq } from '../redux/actions/PageAction';

// import { parseString } from '../common/AppConstants';

// images
// import dash_line_vector_faq from '../assets/images/dash-line-vector-faq.svg';
import search_icon_dark from '../assets/images/search-icon-dark.svg';
// import BreadCrumb from '../common/components/Navigations/BreadCrumb';

import Tabs from '../common/components/Tabs';

// import { bc_faq } from '../common/layout/breadcrumb-data';
import Accordion from '../common/components/Accordions/Accordion';
import BannerFaq from '../common/components/Banners/BannerFaq';

const Faq = () => {
  const [body, setBody] = useState({});
  const [all_tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabBody, setTabBody] = useState({});
  const [accData, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  // Redux
  const dispatch = useDispatch();
  const { faq_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (faq_contents == null) {
      dispatch(fetchFaq({}));
      dispatch(showPageLoader());
    }
  }, [faq_contents, body]);

  useEffect(() => {
    if (faq_contents) {
      setBody(
        faq_contents?.data[0]?.attributes?.page_body_elements?.data
          ? faq_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );

      dispatch(resetPageLoader());
    }
  }, [faq_contents]);

  useEffect(() => {
    if (body) {
      if (body?.attributes?.page_body_cmp) {
        let tab_element = body?.attributes?.page_body_cmp[1]?.tab_element;
        if (tab_element) {
          let dataset = [];
          tab_element?.map((obj, index) => {
            dataset.push({
              index: index,
              title: obj?.tab_text,
            });
            if (index == 0) {
              setTabBody(obj?.page_body);
            }
          });
          setTabs(dataset);
        }
      }
    }
  }, [body]);

  useEffect(() => {
    activeTab != null
      ? setTabBody(
          body?.attributes?.page_body_cmp[1]?.tab_element[activeTab]?.page_body
        )
      : null;
  }, [activeTab]);

  useEffect(() => {
    if (tabBody) {
      if (tabBody?.data?.attributes?.page_body_cmp[0]?.list_out_faqs?.data) {
        let dataset = [];
        tabBody?.data?.attributes?.page_body_cmp[0]?.list_out_faqs?.data.map(
          (obj, idx) => {
            dataset.push({
              index: `${Math.floor(Math.random() * 9999)}_${idx}`,
              title: obj?.attributes?.faq_question,
              content: obj?.attributes?.faq_answer?.faq_answer_text,
            });
          }
        );

        setData(dataset);
        setAllData(dataset);
      }
    }
  }, [tabBody]);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
    if (index != activeTab) {
      setSearchQuery('');
    }
  };

  //Search list of objects
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const searchList = allData.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setData(searchList);
  };

  return (
    <>
      {/* NAVBAR SECTION */}
      <Menu key="faq-menu" />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {faq_contents?.data[0]?.attributes?.page_banners && (
        <BannerFaq content={faq_contents?.data[0]?.attributes?.page_banners} />
      )}
      {/* Page Banner */}

      <div className="mx-auto p-0 md:p-8 md:pt-0 flex flex-col items-center">
        {body?.attributes?.page_body_cmp &&
          body?.attributes?.page_body_cmp.map((object, index) => (
            <div key={`faq_page_body_${index}`} className="w-full">
              {object?.internal_identifier == 'search_bar' && (
                <div className="w-full">
                  {/* search */}
                  <div className="mx-10 lg:mx-60 ">
                    <div className="container mx-auto ">
                      <div className="max-w-[860px] mx-auto mt-5  w-full    flex   items-center px-2 mb-0  h-12 rounded-full border border-gray-200 focus-within:shadow-lg bg-white overflow-hidden">
                        <div className="flex items-center justify-center">
                          <button className="">
                            <img
                              src={search_icon_dark}
                              className="w-6 h-6 ml-2"
                              alt="Icon"
                            />
                          </button>
                        </div>
                        <input
                          className="h-full w-full outline-none text-sm text-gray-700 pl-2 "
                          type="text"
                          id="search-box"
                          placeholder="Eg. How to Invest in NPS?"
                          autoComplete="off"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>

                  {/* tab navs */}
                  {all_tabs && (
                    <div className="lg:container lg:mx-auto px-0  md:px-10 py-6 md:py-5 lg:py-12">
                      <Tabs
                        items={all_tabs}
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                  )}

                  {/* tab content */}
                  {tabBody && (
                    <div className="lg:container mx-auto px-5 md:px-10 py-6 md:py-8 lg:py-1 mb-20">
                      <div>{accData && <Accordion items={accData} />}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>

      {/* FOOTER */}
      <Footer key="faq-footer" />
      {/* FOOTER */}
    </>
  );
};
export default Faq;
