import React, { useState } from 'react';
import { parseString } from '../../AppConstants';

const AccordionCopy = ({ items }) => {

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const UpArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );

  const DownArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );

  return (
    <div className="mt-4 container">
      {items?.map((item, index) => (
        <div
          key={`acc-copy-${index}`}
          className="bg-primary-LIGHT rounded-lg w-500 mb-4 border border-gray-300 focus:outline-none transition ease-in-out delay-150 m-2"
        >
          <div className="cursor-pointer" onClick={() => handleClick(index)}>
            <div
              className={`sector flex justify-between items-center gap-4 px-[16px] py-[12px] rounded-t-xl ${activeIndex === index ? 'bg-primary-LIGHT' : ''
                }`}>
              <h2
                className={`  text-left text-[12px] md:text-[16px] lg:text-[18px] font-bold ${activeIndex === index ? 'text-BLACK' : ''
                  }`}>
                {parseString(item?.title)}
              </h2>
              <div className="w-4 h-3 transition-transform flex justify-center items-center">
                {activeIndex === index ? (
                  <UpArrowIcon />
                ) : (
                  <DownArrowIcon />
                )}
              </div>
            </div>
          </div>
          {activeIndex === index && (
            <div className={`accordion-content ${activeIndex === index ? 'active-acc' : ''}`}>
              {item?.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AccordionCopy;
