import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles/global.css';

import PageLoader from './common/components/Loader';
import chat_icon from './assets/images/chatbot.svg';

import AppRoutes from './common/routes';
import { fetchHeaderFooterData } from './redux/actions/PageAction';

function App() {
  // Redux
  const dispatch = useDispatch();
  const { header_footer_contents } = useSelector((state) => state.pageState);
  //const { msgSnackbar, pageLoader } = useSelector((state) => state.commonState);
  const { pageLoader } = useSelector((state) => state.commonState);

  // Redux

  useEffect(() => {
    if (header_footer_contents == null) {
      dispatch(fetchHeaderFooterData({}));
      // dispatch(showPageLoader());
    }
  }, [header_footer_contents]);


  return (
    <main>
      <div className="App">
        <AppRoutes />
        {/* Toast Message handler */}
        {/* <Snackbar
              key={msgSnackbar?.message}
              open={msgSnackbar?.open || false}
              onClose={handleSnackbarChanged}
              autoHideDuration={msgSnackbar?.duration}
              severity={msgSnackbar?.severity}
              message={msgSnackbar?.message}
            /> */}
        {/* Page Loader Handler */}
        {pageLoader ? <PageLoader /> : null}
        {/* <PageLoader /> */}
        <div className="fixed bottom-0 right-0 zIn-max">
          <a href="/soon">
            <img
              src={chat_icon}
              className="w-20 h-20 md:w-28 md:h-28 xl:w-28 xl:h-28"
            />
          </a>
        </div>
      </div>
    </main>
  );
}

export default App;
