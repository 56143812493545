import React, { useEffect } from 'react';
import DownloadCardDisclosureSecond from '../Downloaders/DownloadCardDisclosureSecond';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchPortFolioFiles } from '../../../redux/actions/PageAction';

const TierSchemePortfolio = ({ contents }) => {
  // Redux
  // const dispatch = useDispatch();
  // const { portfolio_files } = useSelector((state) => state.pageState);

  useEffect(() => {
    // dispatch(fetchPortFolioFiles());
  }, [])

  return (
    <div className='p-[8px] md:p-[15px] lg:p-[20px] bg-primary-LIGHT '>
      <div className='grid grid-cols-1   sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4 max-h-72 overflow-y-scroll'>

        {contents?.map((obj, index) => (
          <DownloadCardDisclosureSecond key={`dlc-${index}`}
            heading={obj?.data.attributes?.file_display_name}
            contents={obj?.data} />
        ))}

        {/* {contents?.data?.attributes?.internal_identifier == 'tier2_scheme_portfolio' && (
          <>
            {portfolio_files?.tier2?.data?.map((obj, index) => (
              <DownloadCardDisclosureSecond key={`dlc-${index}`}
                heading={obj?.attributes?.file_display_name}
                contents={obj} />
            ))}
          </>
        )} */}
      </div>
    </div>
  );
};

export default TierSchemePortfolio;





