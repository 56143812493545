import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// constants
import { parseString } from '../../common/AppConstants';

// components
import Tabs from '../../common/components/Tabs';
import FaqCopy from '../../common/components/Accordions/FaqCopy';
import CardCopy from '../../common/components/Cards/CardCopy';
import FooterBanner from '../../common/components/Banners/FooterBanner';
import TabStepperComponent from './TabStepperComponent';
import BannerCorporateSolution from '../../common/components/Banners/BannerCorporateSolution';

const TabCorp = ({ contents }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabBody, setTabBody] = useState({});
  const [body, setBody] = useState({});
  const [all_tabs, setTabs] = useState([]);

  useEffect(() => {
    if (contents) {
      setBody(
        contents?.data[0]?.attributes?.page_body_elements?.data
          ? contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );
    }
  }, [contents]);

  useEffect(() => {
    if (body) {
      if (body?.attributes?.page_body_cmp) {
        let tab_element = body?.attributes?.page_body_cmp[0]?.tab_element;
        if (tab_element) {
          let dataset = [];
          tab_element?.map((obj, index) => {
            dataset.push({
              index: index,
              title: obj?.tab_text,
            });
            if (index == 0) {
              setTabBody(obj?.page_body);
            }
          });
          setTabs(dataset);
        }
      }
    }
  }, [body]);

  useEffect(() => {
    activeTab != null
      ? setTabBody(
          body?.attributes?.page_body_cmp[0]?.tab_element[activeTab]?.page_body
        )
      : null;
  }, [activeTab]);

  const handleTabClick = (index) => {
    setActiveTab(index === activeTab ? null : index);
  };

  return (
    <>
      {/* Page Banner */}
      {contents?.data[0]?.attributes?.page_banners && (
        <BannerCorporateSolution
          content={contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      <div className="bg-background py-0 md:pt-5 ">
        <div className="lg:container mx-auto my-0 px-0 md:my-5 md:py-0 md:px-36 lg:px-60  ">
          {all_tabs && (
            <Tabs
              items={all_tabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
        {tabBody && (
          <div className="main-bg mx-auto my-1 p-0 flex flex-col items-center text-center">
            {tabBody?.data?.attributes?.page_body_cmp.map((object, index) => (
              <div key={`corporate_page_body_${index}`} className="w-full">
                {object?.internal_identifier ==
                  'benefits_of_corporate_solutions' && (
                  <div className=" px-5 py-5 md:pt-10 md:px-10 ">
                    <div className="">
                      {object?.marketing_cmp_heading && (
                        <h1 className="font-bold lg:mb-4 md:mb-0 text-neutral-GRAY text-xl md:text-2xl text-center lg:text-4xl ">
                          {parseString(object?.marketing_cmp_heading)}
                        </h1>
                      )}
                      {object?.marketing_cmp_description && (
                        <div className="  text-sm  md:text-base lg:text-lg text-center text-gray-600 mb-0 md:mb-4 px-2 md:px-28 mt-2 font-normal">
                          {parseString(object?.marketing_cmp_description)}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {object?.internal_identifier ==
                  'corporate_solution_benefits' && (
                  <div className="container mx-auto px-5 py-5 md:py-5 md:px-10 ">
                    {object?.marketing_cmp_heading && (
                      <div className="bg-primitive-OLIVE-200 p-6 mt-0 md:mt-0  rounded-lg text-left  flex flex-col  justify-start   ">
                        {object?.marketing_cmp_heading && (
                          <h4 className="text-black font-bold text-xl md:text-2xl lg:text-4xl">
                            {parseString(object?.marketing_cmp_heading)}
                          </h4>
                        )}
                        {object?.marketing_cmp_description && (
                          <div className="text-sm md:text-base lg:text-lg text-left mt-2 font-normaltext-black">
                            {parseString(object?.marketing_cmp_description)}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'tax_benefits' && (
                  <div className=" container mx-auto px-5 py-2 md:py-5 md:px-10 ">
                    {object?.marketing_extended_heading && (
                      <div className="flex flex-row items-center mt-0 md:mt-5 text-start">
                        <h4 className="text-lg md:text-xl lg:text-2xl font-bold">
                          {parseString(object?.marketing_extended_heading, {
                            class: 'inline',
                          })}
                        </h4>
                      </div>
                    )}
                    {object?.marketing_units_groups?.data?.length && (
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-4">
                        {object?.marketing_units_groups?.data?.map(
                          (obj, sindex) => (
                            <CardCopy
                              content={obj}
                              key={`cchbc-${sindex}`}
                              iconHeadStartClass={'pr-2 w-10 h-10'}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'want_more_benefits' && (
                  <div className="container mx-auto px-5 py-5 md:py-5 md:px-10">
                    {object?.marketing_extended_heading && (
                      <div className="flex flex-row items-center text-left md:block  mt-0 md:mt-5 ">
                        <div className="text-lg md:text-xl lg:text-2xl font-bold">
                          {parseString(object?.marketing_extended_heading, {
                            class: 'inline',
                          })}
                        </div>
                      </div>
                    )}
                    {object?.marketing_extended_description && (
                      <div className="flex flex-row items-center text-left md:hidden   mt-5 ">
                        <div className="text-lg md:text-xl lg:text-2xl font-bold">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      </div>
                    )}
                    {object?.marketing_blocks?.data?.length && (
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-4">
                        {object?.marketing_blocks?.data?.map((obj, sindex) => (
                          <CardCopy
                            content={obj}
                            key={`cchbc-${sindex}`}
                            iconHeadStartClass={'pr-2 w-10 h-10'}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'employer_benefits' && (
                  <div className="container mx-auto px-5 py-0 md:py-5 md:px-10 ">
                    {object?.marketing_cmp_heading && (
                      <div className="bg-primitive-OLIVE-200 p-6 mt-0 md:mt-5  rounded-lg text-left  flex flex-col  justify-start   ">
                        {object?.marketing_cmp_heading && (
                          <h4 className="text-black font-bold text-xl md:text-2xl lg:text-4xl">
                            {parseString(object?.marketing_cmp_heading)}
                          </h4>
                        )}
                        {object?.marketing_cmp_description && (
                          <div className="text-sm md:text-base lg:text-lg text-left mt-2 font-normaltext-black">
                            {parseString(object?.marketing_cmp_description)}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'benefits_details' && (
                  <div className="container mx-auto px-5 py-5 md:py-5 md:px-10">
                    {object?.marketing_units_groups?.data?.length && (
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-0 md:mt-5 gap-3 md:gap-4">
                        {object?.marketing_units_groups?.data?.map(
                          (obj, sindex) => (
                            <CardCopy
                              content={obj}
                              key={`cchbc-${sindex}`}
                              iconHeadStartClass={'pr-2 w-10 h-10'}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}

                {object?.internal_identifier == 'eligibility_criteria' && (
                  <div className="bg-site-FFF9F5 w-full">
                    <div className="container mx-auto px-5 py-5 md:py-10 md:px-10 ">
                      <div className="">
                        {object?.marketing_extended_heading && (
                          <h1 className="font-bold lg:mb-4 md:mb-0 black-h-text text-xl  md:text-2xl text-center lg:text-4xl ">
                            {parseString(object?.marketing_extended_heading)}
                          </h1>
                        )}
                        {object?.marketing_extended_description && (
                          <div className="  text-sm  md:text-base lg:text-lg text-center text-gray-600 mb-8 ">
                            {parseString(
                              object?.marketing_extended_description
                            )}
                          </div>
                        )}
                      </div>

                      {object?.marketing_units_groups?.data?.length && (
                        <div className="grid grid-cols-1 md:grid-cols-3 mt-5 gap-4">
                          {object?.marketing_units_groups?.data[0]?.attributes?.marketing_units?.map(
                            (obj, sindex) => (
                              <CardCopy
                                content={obj}
                                key={`cchbc-${sindex}`}
                                iconHeadStartClass={'pr-2 w-10 h-10'}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {object?.internal_identifier == 'how_to_register' && (
                  <div className="container mx-auto px-5 py-5 md:py-10 md:px-10">
                    <div className="">
                      {object?.marketing_extended_heading && (
                        <h1 className=" font-bold lg:mb-4 md:mb-0 black-h-text  text-xl md:text-2xl text-center lg:text-4xl ">
                          {parseString(object?.marketing_extended_heading)}
                        </h1>
                      )}
                      {object?.marketing_extended_description && (
                        <div className="  text-sm  md:text-base lg:text-lg text-center  text-gray-600 font-normal mt-2">
                          {parseString(object?.marketing_extended_description)}
                        </div>
                      )}
                    </div>

                    <TabStepperComponent contents={object} />
                  </div>
                )}

                {object?.internal_identifier == 'faq' && (
                  <FaqCopy contents={object} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* <Banner /> */}
      {/* FOOTER BANNER */}
      {body?.attributes?.footer_banner && (
        <FooterBanner contents={body?.attributes?.footer_banner} />
      )}
      {/* FOOTER BANNER */}
    </>
  );
};

TabCorp.propTypes = {
  contents: PropTypes.object,
};
export default TabCorp;
