import React from 'react';
import PropTypes from 'prop-types';

import gold_coin from '../../../assets/images/gold-coin.svg';
import bottom_coin_bg_left from '../../../assets/images/bottom-coin-bg-left.svg';
import bottom_coin_bg_right from '../../../assets/images/bottom-coin-bg-right.svg';

import icons_4 from '../../../assets/images/icons-4.svg';
import icons_3 from '../../../assets/images/icons-3.svg';

import { URI_JOIN_NPS, URI_CONTRIBUTE_NOW } from '../../NavigationUri';

const FooterBanner = ({ customClass = '' }) => {
  return (
    <div
      className={`relative mt-36 md:mt-24 bg-primitive-BLUE-600  pt-4 md:pt-12 lg:pt-16 pb-4 md:pb-12 lg:pb-16 ${customClass} `}
    >
      <div>
        <img
          className=" absolute w-52 md:w-96 left-0 bottom-0 z-0 "
          src={bottom_coin_bg_left}
        />
        <img
          className="absolute w-52 md:w-96  right-0 bottom-0 z-0 "
          src={bottom_coin_bg_right}
        />
      </div>

      <div className="container mx-auto relative">
        <div className="w-full lg:flex">
          <div className="w-full flex items-center justify-center lg:w-1/2 ">
            <div className="custom-minus w-80 md:w-96 lg:w-full p-1.5 z-10">
              <img src={gold_coin} />
            </div>
          </div>

          <div className="w-full lg:w-1/2 p-4 z-10">
            <div className="pb-2 md:pb-6">
              <h2 className="text-center lg:text-left text-base md:text-4xl leading-6 md:leading-54 font-bold mb-4 text-white line-clamp-custom1">
                Plan for your retirement with NPS & Save taxes
              </h2>
            </div>
            <div className="text-center lg:text-left flex gap-6 justify-center lg:justify-start">
              <a
                href={URI_JOIN_NPS}
                className=" flex flex-row items-center justify-center border border-custom-color text-white rounded-full font-bold text-sm md:text-lg leading-6 px-4 md:px-8 py-3 bg-primary  "
              >
                Join NPS <img src={icons_4} className="w-4-important" />{' '}
              </a>

              <a
                href={URI_CONTRIBUTE_NOW}
                className=" flex flex-row items-center justify-center border border-custom-color text-black rounded-full font-bold text-sm md:text-lg leading-6 px-4 md:px-8 py-3 bg-white  "
              >
                Contribute Now <img src={icons_3} className="w-4-important" />{' '}
              </a>
            </div>
            <div className="text-center lg:text-left">
              <p className="text-white text-base md:text-lg mt-4">
                *All NPS subscribers can make contribution here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterBanner.propTypes = {
  customClass: PropTypes.string || undefined,
};

export default FooterBanner;
