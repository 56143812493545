import React from "react";

const DownloadCardDisclosure = ({ text, contents, icon }) => {

  const handleFileClick = (item) => {
    if (item) {
      let fileName = item?.name;
      fetch(item?.url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "downloaded-file";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    }
  };

  return (
    <div className="flex flex-row items-center justify-between   border border-custom-color bg-white p-2 rounded-lg">
      {text && (<span className="text-[12px] md:text-[14px] text-neutral-BLACK font-normal">{text}</span>)}
      <img src={icon} className="ml-11 md:w-8 md:h-5 cursor-pointer" alt="Download Icon"
        onClick={() => handleFileClick(contents?.data[0]?.attributes || contents?.data?.attributes)} />
    </div>
  );
};

export default DownloadCardDisclosure;
