import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IsEmpty, toLower } from '../../AppConstants';

const SecondaryButton = ({
  cta = {},
  title = '',
  variant = 'button',
  url = '',
  openTab = false,
  onClick = () => {},
  extraClass = '',
}) => {
  const [btnText, setBtnText] = useState(title || '');
  const [type, setButtonType] = useState(variant);
  const [link, setRedirect] = useState(url);

  useEffect(() => {
    if (cta) {
      if (IsEmpty(title)) {
        if (cta?.data?.attributes) {
          setBtnText(cta?.data?.attributes?.cta_title);
        } else if (cta?.attributes) {
          setBtnText(cta?.attributes?.cta_title);
        }
      }

      if (url == '') {
        if (cta?.attributes) {
          setButtonType(toLower(cta?.attributes?.cta_type));
          if (cta?.attributes?.cta_external_link) {
            setButtonType('link');
            setRedirect(cta?.attributes?.cta_external_link);
          }
        }
      } else setButtonType('link');
    }
  }, [cta, url, title]);

  return (
    <>
      {type == 'button' && (
        <button
          onClick={onClick}
          className={`border 
                                    text-primary-DARK 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-white 
                                    hover:bg-neutral-50
                                    hover:text-primary-DARK ${
                                      extraClass ? extraClass : ''
                                    } `}
        >
          {btnText || ''}
        </button>
      )}
      {type == 'link' && (
        <>
          {openTab ? (
            <a
              href={link ? link : '#'}
              target="_blank"
              rel="noreferrer"
              className={`border 
                                    text-primary-DARK 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-white 
                                    hover:bg-neutral-50
                                    hover:text-primary-DARK
                                    flex justify-center items-center 
                                    ${extraClass ? extraClass : ''}`}
            >
              {btnText || ''}
              {cta?.attributes?.cta_icon?.data?.attributes?.url && (
                <img
                  className=""
                  src={cta?.attributes?.cta_icon?.data?.attributes?.url}
                />
              )}
            </a>
          ) : (
            <a
              href={link ? link : '#'}
              className={`border 
                                    text-primary-DARK 
                                    rounded-full 
                                    font-bold 
                                    text-sm
                                    px-4 py-2
                                    bg-white 
                                    hover:bg-neutral-50
                                    hover:text-primary-DARK
                                    flex justify-center items-center 
                                     ${extraClass ? extraClass : ''}`}
            >
              {btnText || ''}
              {cta?.attributes?.cta_icon?.data?.attributes?.url && (
                <img
                  className=""
                  src={cta?.attributes?.cta_icon?.data?.attributes?.url}
                />
              )}
            </a>
          )}
        </>
      )}
    </>
  );
};

SecondaryButton.propTypes = {
  cta: PropTypes.object || undefined,
  title: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  openTab: PropTypes.bool,
  onClick: PropTypes.func,
  extraClass: PropTypes.string,
};

export default SecondaryButton;
