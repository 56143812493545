import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import ContentSlider from './ContentSlider';
import { parseString } from '../../AppConstants';
import SalesStorySlider from './SalesStorySlider';

const ContenSliderCopy = ({ contents }) => {
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    if (contents) {
      let dataset = [];
      contents?.sales_and_successstories?.data.map((object, index) => {
        dataset.push({
          key: index,
          title: object?.attributes?.author_name,
          description: object?.attributes?.story_description,
          image: object?.attributes?.author_image?.data?.attributes?.url,
        });
      });
      setSlides(dataset);
    }
  }, [contents]);

  return (
    <>
      <div className="container mx-auto max-w-screen-xl pl-4">
        <div className="mt-10 lg:mt-20">
          {contents?.sales_cmp_heading && (
            <h3 className="text-2xl sm:text-2xl md:text-2xl lg:text-4xl font-bold mb-2 md:mb-4  text-center text-primary">
              {parseString(contents?.sales_cmp_heading)}
            </h3>
          )}
          {contents?.sales_cmp_description && (
            <div className="text-sm md:text-lg text-center px-3">
              {parseString(contents?.sales_cmp_description)}
            </div>
          )}
        </div>
      </div>

      {slides?.length && (
        <div className="mx-auto max-w-full px-4 py-6 sm:pl-6 lg:pl-8 lg:py-12">
          {/* <ContentSlider slides={slides} /> */}
          <SalesStorySlider slides={slides} />
        </div>
      )}
    </>
  );
};
ContenSliderCopy.propTypes = {
  contents: PropTypes.object,
};
export default ContenSliderCopy;
