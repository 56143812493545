//import { getAllAccessLevel } from 'redux/apis/CommonApi';

export const handleSnackbar = ({ open, severity, message }) => dispatch => {
    try {
        dispatch({ type: 'SET_SNACKBAR', payload: { open, severity, message } });
    }
    catch (error) {
        return error;
    }
};

export const ResetSnackbar = () => dispatch => {
    try {
        dispatch({ type: "RESET_SNACKBAR", payload: false });
    }
    catch (error) {
        return error;
    }
};

export const showPageLoader = () => dispatch => {
    try {
        dispatch({ type: 'SET_PAGE_LOADER', payload: true });
    }
    catch (error) {
        return error;
    }
};

export const resetPageLoader = () => dispatch => {
    try {
        dispatch({ type: "RESET_PAGE_LOADER", payload: false });
    }
    catch (error) {
        return error;
    }
};

// export const fetchStateList = () => async dispatch => {
//     try {
//         const getResult = await getStateList();
//         const token_status = getResult?.tokenExpired || getResult.data?.tokenExpired;
//         if (token_status) {
//             dispatch({ type: 'ACCESS_TOKEN_EXPIRED', payload: token_status });
//         }
//         dispatch({ type: 'STATE_LIST', payload: getResult.data });
//     } catch (error) {
//         return (error);
//     }
// }

export const resetCommFile = () => dispatch => {
    try {
        dispatch({ type: "RESET_DFILE", payload: false });
    }
    catch (error) {
        return error;
    }
};