import React from 'react';
import PropTypes from 'prop-types';

import { parseString } from '../../AppConstants';
import CardCopy from './CardCopy';
import StyledButton from '../StyledButton/Index';

const HomeBenefitsCopy = ({ contents }) => {
  return (
    <div className="container main-bg  mx-auto mb-6 md:mb-10 lg:mb-20 px-5 md:px-8  flex flex-col  text-center">
      {contents?.marketing_extended_heading && (
        <h1 className="font-bold  text-xl md:text-2xl lg:text-4xl mb-1 md:mb-4">
          {parseString(contents?.marketing_extended_heading)}
        </h1>
      )}
      {contents?.marketing_extended_description && (
        <div className="text-neutral-GRAY text-center mt-2 text-sm md:text-lg">
          {parseString(contents?.marketing_extended_description)}
        </div>
      )}

      {contents?.marketing_blocks?.data?.length && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6 md:mt-8 lg:mt-14 ">
          {contents?.marketing_blocks?.data?.map((object, index) => (
            <CardCopy
              content={object}
              key={`cchbc-${index}`}
              iconHeadStartClass={'pr-2 w-10 h-10'}
            />
          ))}
        </div>
      )}

      {contents?.ctas?.data?.length ? (
        <div className="flex justify-center items-center mt-10">
          <StyledButton cta={contents?.ctas} extraClass="text-sm" />
        </div>
      ) : null}
    </div>
  );
};

HomeBenefitsCopy.propTypes = {
  contents: PropTypes.object,
};
export default HomeBenefitsCopy;
