import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Menu from '../../common/layout/Menu';
import Footer from '../../common/layout/Footer';
import TabGov from './TabGov';
import TabCorp from './TabCorp';
import TabRetail from './TabRetail';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNPSSolutionCoporate,
  fetchNPSSolutionGov,
  fetchNPSSolutionRetail,
} from '../../redux/actions/PageAction';
import {
  resetPageLoader,
  showPageLoader,
} from '../../redux/actions/CommonAction';

import { Helmet } from 'react-helmet';


const NpsSolution = () => {
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState({});
  const { tab } = useParams();

  // Redux
  const dispatch = useDispatch();
  const { nps_retail_contents, nps_corp_contents, nps_gov_contents } =
    useSelector((state) => state.pageState);

  useEffect(() => {
    if (loader) dispatch(showPageLoader());
    else dispatch(resetPageLoader());
  }, [loader]);

  useEffect(() => {
    if (tab == 'retail' && nps_retail_contents == null) {
      dispatch(fetchNPSSolutionRetail({}));
      setLoader(true);
    }
    if (tab == 'corporate' && nps_corp_contents == null) {
      dispatch(fetchNPSSolutionCoporate({}));
      setLoader(true);
    }
    if (tab == 'gov' && nps_gov_contents == null) {
      dispatch(fetchNPSSolutionGov({}));
      setLoader(true);
    }
  }, [tab]);

  useEffect(() => {
    if (nps_retail_contents != null) {
      setLoader(false);
      setContent(nps_retail_contents?.data ? nps_retail_contents?.data[0] : {});
    }
    if (nps_corp_contents != null) {
      setLoader(false);
      setContent(nps_corp_contents?.data ? nps_corp_contents?.data[0] : {});
    }
    if (nps_gov_contents != null) {
      setLoader(false);
      setContent(nps_gov_contents?.data ? nps_gov_contents?.data[0] : {});
    }
  }, [nps_retail_contents, nps_corp_contents, nps_gov_contents]);

  return (
    <>
      {content?.attributes?.meta_title ? (
        <>
          <Helmet>
            <title>{content?.attributes?.meta_title}</title>
            {content?.attributes?.meta_description && <meta name="description" content={content?.attributes?.meta_description} />}
            {content?.attributes?.meta_keywords && <meta name="keywords" content={content?.attributes?.meta_keywords} />}
          </Helmet>
        </>) : null}

      {/* NAVBAR SECTION */}
      <Menu />
      <div className="mt-20 xl:mt-28"></div>
      {/* NAVBAR SECTION */}

      {tab == 'retail' && <TabRetail contents={nps_retail_contents} />}

      {tab == 'corporate' && <TabCorp contents={nps_corp_contents} />}

      {tab == 'gov' && <TabGov contents={nps_gov_contents} />}

      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default NpsSolution;
