import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css';

import { parseString } from '../../AppConstants';
import ModalStories from '../Modals/ModalStories';

const SalesStorySlider = ({ slides }) => {
  const [openModal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleImageClick = (index) => {
    if (index != null) {
      setModal(!openModal);
      setModalContent(slides[index]);
    }
  };

  return (
    <>
      <div className="relative sales-success-slider">
        <div
          className={`hidden md:flex navigation-btn-wrapper gradient-custom-left absolute z-10 -left-1 bottom-0 top-0 justify-center items-center`}
        >
          <button className="sss-button-prev swiper-navigation-disabled swiper-button-prev bg-primary rounded-full !flex !justify-center !items-center !w-12 !h-12 after:hidden">
            <span className="font-material-icons material-symbols-outlined text-white">
              arrow_back_ios
            </span>
          </button>
        </div>

        <div
          className={`hidden md:flex navigation-btn-wrapper gradient-custom absolute z-10 -right-1 bottom-0 top-0 justify-center items-center`}
        >
          <button className="sss-button-next swiper-button-next bg-primary rounded-full !flex !justify-center !items-center !w-12 !h-12 after:hidden">
            <span className="font-material-icons material-symbols-outlined text-white">
              arrow_forward_ios
            </span>
          </button>
        </div>

        <div className="flex flex-nowrap gap-4 overflow-x-auto relative no-scrollbar">
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: '.sss-button-next',
              prevEl: '.sss-button-prev',
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          // onSlideChange={handleSlideChange}
          >
            {slides?.map((obj, index) => (
              <SwiperSlide key={`cs-${index}`}>
                <div className="rounded-lg bg-white p-6 card-shadow-custom sm:p-8 h-full  flex-shrink-0">
                  <div className="flex flex-col h-full">
                    <div className="mb-6">
                      <div className="mt-4 text-BLACK text-base line-clamp-custom6">
                        {parseString(obj?.description)}
                      </div>

                      {obj?.description?.length > 200 && <span onClick={() => handleImageClick(index)} className='text-blue-500 cursor-pointer'>Read more</span>}

                    </div>
                    <div className="flex items-center gap-4 mt-auto">
                      <img
                        className="h-14 w-14 rounded-full object-cover border-blue-500 border-4"
                        src={obj?.image}
                      />
                      <div>
                        <div className="mt-0.5 text-lg font-bold text-gray-900">
                          {parseString(obj?.title)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <ModalStories
        openModal={openModal}
        contents={modalContent}
        onCloseClick={setModal}
      />
    </>
  );
};

SalesStorySlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string || undefined,
      description: PropTypes.string || undefined,
      image: PropTypes.string || undefined,
      key: PropTypes.number,
    })
  ).isRequired,
  max: PropTypes.number,
};
export default SalesStorySlider;
