import React from 'react';
import PropTypes from 'prop-types';
import { parseString } from '../../AppConstants';

const Thumbnail = ({
  contents,
  figContainerClass,
  parentContainerClass = '',
  isModalOpen,
  onImageClick = () => { },
  setModalContent = () => { },
  disableModal = false
}) => {

  const handleImageClick = (index) => {
    if (index != null) {
      onImageClick(!isModalOpen);
      setModalContent(contents?.data[index]);
    }
  };


  return (
    <>
      {contents?.data?.map((object, index) => (
        <div
          key={`thumb-${index}`}
          className={`w-full flex flex-col ${parentContainerClass}`}
        >
          <div className="flex-1 flex justify-center">
            <div className={figContainerClass}>
              <figure className="flex justify-center items-center">

                <div className="relative overflow-hidden"
                  onClick={() => handleImageClick(index)}>
                  {disableModal == false && (
                    <div className="absolute flex items-center justify-center
                       left-0 top-0 w-full h-full bg-BLACK bg-opacity-60 
                       cursor-pointer 
                       opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out">
                      <span className="font-material-icons no-underline text-white text-4xl cursor-pointer">
                      visibility
                    </span>
                    </div>)}

                  {object?.attributes?.images?.data[0]?.attributes?.url && (
                    <img
                      className="text-center object-contain max-w-[10 rem] aspect-[3/2] object-contain"
                      src={object?.attributes?.images?.data[0]?.attributes?.url}
                    />
                  )}
                  {object?.attributes?.award_image?.data?.attributes?.url && (
                    <img
                      className="text-center"
                      src={object?.attributes?.award_image?.data?.attributes?.url}
                    />
                  )}

                </div>
              </figure>


              {object?.attributes?.award_name ||
                object?.attributes?.award_description ? (
                <div className="text-center mt-4">
                  <h4 className="text-sm font-bold text-primary-BLACK">
                    {parseString(object?.attributes?.award_name)}
                  </h4>
                  <div className="text-xs text-TERTIARY">
                    {parseString(object?.attributes?.award_description)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

Thumbnail.propTypes = {
  contents: PropTypes.object || undefined,
  figContainerClass: PropTypes.string || undefined,
  disableModal: PropTypes.bool || undefined
};
export default Thumbnail;
