import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cleanUL, IsUL, parseString, parseStringFaq } from '../../AppConstants';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="mb-4 ">
      {items?.map((item) => (
        <div
          key={item?.index}
          className="bg-white rounded-md w-500 p-6 mb-4 border border-gray-300 focus:outline-none transition ease-in-out delay-150"
        >
          <div
            className="cursor-pointer"
            onClick={() => handleClick(item?.index)}
          >
            <div className="flex justify-between items-center gap-4">
              <h2
                className={`text-sm text-left md:text-base lg:text-lg font-medium ${activeIndex === item?.index ? 'text-primary' : ''
                  }`}
              >
                {parseString(item.title)}
              </h2>
              <div className="w-6 h-6 transition-transform flex justify-center items-center">
                {activeIndex === item?.index ? (
                  <span className="font-material-icons material-symbols-outlined text-lg text-gray-800">
                    remove
                  </span>
                ) : (
                  <span className="font-material-icons material-symbols-outlined text-lg text-gray-400">
                    add
                  </span>
                )}
              </div>
            </div>
          </div>
          {activeIndex === item?.index && (
            <div>
              <div className="border-acc-faq  h-[0.0625rem] my-4"></div>
              <div className="text-primary-DARK text-left text-xs md:text-sm lg:text-base">
                {
                  IsUL(item.content) ? parseStringFaq(cleanUL(item.content,'list-disc')) : parseStringFaq(item.content)
                }

              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Accordion;
