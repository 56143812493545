import React from "react";
import PropTypes from 'prop-types';
// import AWS from 'aws-sdk';

import pdf_icon from '../../../assets/images/pdficon.svg';
import dm_icon from '../../../assets/images/downlaodicon.svg';
import { parseString } from "../../AppConstants";

const DownloadCardView = ({ contents }) => {
    // AWS.config.update({
    //     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    //     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    //   });
    
    const handleFileClick = (item) => {
        if (item) {
            let fileName=item?.name;
            fetch(item?.url)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = fileName || "downloaded-file";
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error("Error fetching the file:", error);
                });
        }
    };

    return (
        <>
            {contents?.attributes?.files?.data && (
                <div className="flex flex-col lg:flex-row justify-between h-14 mt-2 md:mt-0 border border-custom-color  bg-white p-2 rounded-lg">
                    <div className=" flex flex-row items-center  justify-between">
                        <img src={pdf_icon} className="pr-2  w-10 h-7   " alt="Image" />
                        {contents?.attributes?.marketing_extended_heading && (<span className="text-sm text-neutral-BLACK font-normal ">{parseString(contents?.attributes?.marketing_extended_heading)}</span>)}
                        {/* <a href={contents?.attributes?.files?.data[0]?.attributes?.url}> */}
                        <img src={dm_icon} className="ml-11 w-10 h-7 cursor-pointer"
                            onClick={() => handleFileClick(contents?.attributes?.files?.data[0]?.attributes|| contents?.attributes?.files?.data?.attributes)}
                        />
                        {/* </a> */}
                    </div>
                </div>)
            }
        </>
    )
};

DownloadCardView.propTypes = {
    contents: PropTypes.object || undefined,
    iconHeadStartClass: PropTypes.string || undefined,
    extraBoxClass: PropTypes.string || undefined,
    cardType: PropTypes.string || undefined
};

export default DownloadCardView;

