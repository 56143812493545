import React, { useEffect } from 'react';
import DownloadCardDisclosureThird from '../Downloaders/DownloadCardDisclosureThird';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRiskMeterFiles } from '../../../redux/actions/PageAction';

const RiskMeter = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { risk_meter_files } = useSelector((state) => state.pageState);

  useEffect(() => {
    dispatch(fetchRiskMeterFiles('risk-o-meter'));
  }, [contents])

  return (
    <div className='p-[8px] md:p-[15px] lg:p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  lg:grid-cols-2 max-h-72 overflow-y-scroll">
        {
          risk_meter_files?.data?.data?.map((obj, index) => (
            <DownloadCardDisclosureThird key={`dlc-${index}`}
              heading={obj?.attributes?.file_display_name}
              heading2={obj?.attributes?.tag || ''}
              contents={obj} />
          ))
        }
      </div>
    </div>
  );
};

export default RiskMeter;





