import React from 'react';
import mncharecter from '../../../assets/images/main-character.svg';

import PropTypes from 'prop-types';
import { parseString } from '../../AppConstants';
import CardCopy from './CardCopy';

const HomeNpsCopy = ({ contents }) => {
  return (
    <div
      key={`${Date.now()}-hinc${contents?.id}`}
      className="container main-bg  mx-auto px-5 md:px-10 py-6 md:py-10   flex flex-col items-center text-center"
    >
      {contents?.marketing_extended_heading && (
        <h1 className="font-bold  text-xl md:text-2xl lg:text-4xl mb-2 md:mb-4 ">
          {parseString(contents?.marketing_extended_heading)}
        </h1>
      )}
      {contents?.marketing_extended_description && (
        <div className=" mt-4 text-sm md:text-lg text-neutral-GRAY  px-2 md:px-20 lg:px-36">
          {parseString(contents?.marketing_extended_description)}
        </div>
      )}

      <div className="container mx-auto flex flex-col lg:flex-row justify-between pt-8 lg:pt-20">
        {/* <!-- Top Section (Mobile Screen) --> */}
        <div className="w-full lg:w-1/2 pl-0 lg:pl-4 p-0 lg:p-4 flex flex-col items-center justify-center">
          <div className="lg:hidden">
            <img
              className="mb-10 max-w-[380px]"
              src={mncharecter}
              alt="Mobile Screen Image"
            />
          </div>

          {contents?.marketing_units_groups?.data[0]?.attributes
            ?.marketing_units &&
            contents?.marketing_units_groups?.data[0]?.attributes?.marketing_units.map(
              (obj, index) => (
                <CardCopy
                  content={obj}
                  key={`cchbc-${index}`}
                  iconHeadStartClass="pr-2 w-6 h-6"
                  extraBoxClass="mb-4 md:mb-4  "
                />
              )
            )}
        </div>

        {/* <!-- Bottom Section (Laptop Screen) --> */}
        <div className="w-full lg:w-1/2 p-4 hidden lg:flex items-center justify-center">
          <div>
            <img
              className="max-w-[380px] md:w-full md:max-w-[500px]"
              src={mncharecter}
              alt="Laptop Screen Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HomeNpsCopy.propTypes = {
  contents: PropTypes.object,
};
export default HomeNpsCopy;
