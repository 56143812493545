import { GET_METHOD } from './ApiMethods';

export const getHomeContent = async (data) => {
  try {
    let response = await GET_METHOD('home', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAboutNpsContent = async (data) => {
  try {
    let response = await GET_METHOD('about_nps', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFaqContent = async (data) => {
  try {
    let response = await GET_METHOD('faq', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNPSSolutionRetailContent = async (data) => {
  try {
    let response = await GET_METHOD('retail', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNPSSolutionCorpContent = async (data) => {
  try {
    let response = await GET_METHOD('corporate', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNPSSolutionGovContent = async (data) => {
  try {
    let response = await GET_METHOD('gov', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSiteMapContent = async (data) => {
  try {
    let response = await GET_METHOD('sitemap', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAboutUsContent = async (data) => {
  try {
    let response = await GET_METHOD('about_us', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getBlogContent = async (data) => {
  try {
    let response = await GET_METHOD('blogs', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getBlogListContent = async (data) => {
  try {
    let response = await GET_METHOD('allblogs', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAboutNpsTable = async (data) => {
  try {
    let response = await GET_METHOD(
      `table/about_nps_investment_choices/${data}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getHeaderFooter = async (data) => {
  try {
    let response = await GET_METHOD('template/combined', data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCalcInvest = async (data) => {
  try {
    let response = await GET_METHOD(`reverse/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCalcForward = async (data) => {
  try {
    let response = await GET_METHOD(`forward/${data}`,);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPubDisc = async (data) => {
  try {
    let response = await GET_METHOD(`public_disclosure`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNav = async () => {
  try {
    let response = await GET_METHOD(`latestnav`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getNavHistory = async (data) => {
  try {
    let response = await GET_METHOD(`nav/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getProxyVoting = async (data) => {
  try {
    let response = await GET_METHOD(`proxyvoting/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getHalfYear = async (data) => {
  try {
    let response = await GET_METHOD(`unaudited_half_yearly/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};


export const getHomeCalculator = async (data) => {
  try {
    let response = await GET_METHOD(`calculator`, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getPrivacy = async (data) => {
  try {
    let response = await GET_METHOD(`privacy`, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getPubFiles = async (data) => {
  try {
    let response = await GET_METHOD(`public_disclosure_files/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getLatestNavFiles = async (data) => {
  try {
    let response = await GET_METHOD(`download`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesPortFolio = async () => {
  try {
    let response = await GET_METHOD(`get_check/portfolio`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesAnnRepComp = async () => {
  try {
    let response = await GET_METHOD(`get_check/company-report`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesAnnRepSche = async () => {
  try {
    let response = await GET_METHOD(`get_check/schemes-report`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesAnnRetOth = async () => {
  try {
    let response = await GET_METHOD(`get_check/return-and-others`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesGrpCmpLst = async () => {
  try {
    let response = await GET_METHOD(`get_check/group-companies`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesPolicy = async () => {
  try {
    let response = await GET_METHOD(`get_check/policy`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesRegCirc = async () => {
  try {
    let response = await GET_METHOD(`get_check/regulatory-circulars`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesRiskMete = async () => {
  try {
    let response = await GET_METHOD(`get_check/risk-o-meter`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilesCompStew = async () => {
  try {
    let response = await GET_METHOD(`get_check/compliance-of-stewardship`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCheck = async (data) => {
  try {
    let response = await GET_METHOD(`get_check/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};


export const getPortFolio = async (data) => {
  try {
    let response = await GET_METHOD(`get_check/portfolio/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};
