import React, { useEffect, useState } from "react";
import AccordionDownload from "../components/Accordions/AccordionDownload";

const FooterDaForms = ({ contents }) => {    
    const [accData, setData] = useState([]);

    useEffect(() => {
        if (contents) {
            let dataset = [];
            contents?.map((object, index) => {
                dataset.push({
                    index: `${Math.floor(Math.random() * 9999)}_${index}`,
                    title: object?.attributes?.link_heading,
                    content: object,
                });
            });
            setData(dataset);
        }
    }, [])

    return <>
        {accData && <AccordionDownload items={accData} />}
    </>
};

export default FooterDaForms;