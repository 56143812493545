
import React from 'react';

import vector_nps from '../../../assets/images/sitemap-bg-vector.svg';
import { parseString } from '../../AppConstants';

const BannerDisclosure = ({ contents }) => {
    return (<>
        <img src={vector_nps} className="absolute right-0 top-0  hidden  md:block" />

        <h1 className="text-4xl md:text-2xl lg:text-4xl font-bold lg:mb-4 md:mb-0 black-h-text text-center ">
            {parseString(contents?.marketing_cmp_heading || '')}
        </h1>
        <div className="text-sm md:text-lg mt-2  text-gray-600  lg:mb-2 md:mb-2 font-normal mb-5 text-center max-w-[700px] mx-auto">{parseString(contents?.marketing_cmp_description)}</div>
    </>)
};

export default BannerDisclosure;