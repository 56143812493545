import React, { useCallback, useEffect, useState } from "react";
import { IsEmpty, IsNumber, parseString, roundTo, toInrFormat, trailZero } from "../../AppConstants";
import ToggleButton from "../ToggleButtons/ToggleButton";
import IncrementalInput from "./IncrementalInput";

// import RetirementPlanner from "../common/components/Plain/RetirementPlanner";
// import retireImage from '../../../assets/images/image 46.svg';
// import currentImage from '../../../assets/images/image 47.svg';
// import plus from '../../../assets/images/plusicon.svg';
// import minus from '../../../assets/images/minusicon.svg';
import arrow_text_1 from '../../../assets/images/calculator-img-2.svg';
import arrow_text_2 from '../../../assets/images/calculator-img-1.svg';
import arrow_text_5 from '../../../assets/images/calculator-img-4.svg';
import arrow_text_4 from '../../../assets/images/calculator-img-5.svg';

import { useDispatch, useSelector } from "react-redux";
import Toast from "../Toast";
import arrow_text_3 from '../../../assets/images/calculator-img-3.svg';
import arrow_text_6 from '../../../assets/images/calculator-img-6.svg';
import arrow_text_7 from '../../../assets/images/calculator-img-7.svg';
import IncrementalInputCopy from "./IncrementalInputCopy";
import ToastLoader from "../Toast/ToastLoader";

import info_icon from '../../../assets/images/information.svg';
import circlearrow_icon from '../../../assets/images/circle_arrow.svg';
// import retireImage from '../../../assets/images/coolg_women.svg';
// import toparrow_icon from '../../../assets/images/toparrowicon.svg';
// import bottomarrow_icon from '../../../assets/images/bottomarrowicon.svg';
import savings_icon from '../../../assets/images/savings.svg';
// import ToggleButton from "../ToggleButtons/ToggleButton";
// import { IsEmpty, toInrFormat, toLakhCrText } from "../../AppConstants";

import { fetchCalcForwardData, fetchCalcInvestData } from "../../../redux/actions/PageAction";
import CalculatorChart from "./CalculatorChart";
import UpDownInput from "./UpDownInput";
import Annuity from "./Annuity";
import StyledButton from "../StyledButton/Index";
import RangeSlider from "./RangeSlider";


const HomeCalculator = ({ contents,
    apiCall, setApiCall = () => { },
    activeStep, setStep = () => { },
    minAge,
    maxAge,
    retrMinAge,
    retrMaxAge,
    currentAge, setCurrentAge = () => { },
    retirementAge, setRetirementAge = () => { },
    gender, setGender = () => { },
    calculationType, setCalculationType = () => { },
    iwanttoinvest, setIwantToInvest = () => { },
    iwanttoinvestMin,
    iwanttoinvestMode, setIwantToInvestMode = () => { },
    annualincrementof, setAnnualIncrementOf = () => { },
    investmentstrategy, setInvestmentStrategy = () => { },
    expectedrateofreturn, setExpectedRateofReturn = () => { },
    expectedrateofreturnEdit,
    iwanttotalfundsof, setIwanttotalFundsOf = () => { },
    pensionof, setPensionOf = () => { },
    pensionofMode, setPensionOfMode = () => { },
    anyotherschemes, setAnyOtherSchemes = () => { },
    any_other_MutualFunds, setAnyOtherMutualFunds = () => { },
    any_other_epf, setAnyOtherEpf = () => { },
    any_other_schemes, setAnyOtherSchemesInput = () => { },
    resetAll = () => { },
    currentImage, retireImage,
    annutyPercentage, setAnnutyPercentage = () => { },
    lampsumPercentage, setLampsumPercentage = () => { },
    rateofannuty, setRateofAnnuty = () => { }
}) => {

    const [showToast, onToastClose] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showLoader, onLoaderClose] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState('');

    const [ageDiff, setAgeDiff] = useState('');
    // Redux
    const dispatch = useDispatch();
    const { calculator_data_contents } = useSelector((state) => state.pageState);

    useEffect(() => {
        if (showToast == false)
            setToastMessage('');
    }, [showToast])

    //Step 2

    const changePensionMode = useCallback((args) => {
        setPensionOfMode(args);
    }, []);

    const changeCalculation = useCallback((args) => {
        setCalculationType(args);
    }, []);

    const changeInvestMode = useCallback((args) => {
        setIwantToInvestMode(args);
    }, []);

    const changeInvestmentStrategy = ((args) => {
        if (args == 'custom') {
            if (IsEmpty(expectedrateofreturn)) {
                setExpectedRateofReturn('0')
            }
        }
        setInvestmentStrategy(args);
    });

    //Next Click 
    const NextStepOne = () => {
        setToastMessage('');
        onToastClose(false);

        let error = "";
        if (IsEmpty(currentAge) || IsEmpty(retirementAge) || IsEmpty(calculationType)) {
            if (IsEmpty(currentAge)) {
                error = 'Please enter your age';
            }
            else if (IsEmpty(retirementAge)) {
                error = 'Please enter your retirement age';
            }
            else if (IsEmpty(calculationType)) {
                error = 'Please select calculation mode';
            }
            else if (!IsEmpty(currentAge)) {
                if (parseInt(currentAge) <= 0) {
                    error = "Please check your age.";
                }
                else if (parseInt(currentAge) >= 60) {
                    error = "Please check your age, it's over 60 or equeal.";
                }
            }
            else if (!IsEmpty(retirementAge)) {
                if (parseInt(retirementAge) <= 0) {
                    error = "Please check your retirement age.";
                }
                else if (parseInt(currentAge) > parseInt(retirementAge)) {
                    error = "Please check your age and retirement age.";
                }
            }
        }
        else if (!IsEmpty(currentAge) && !IsEmpty(calculationType) && !IsEmpty(currentAge)) {
            if (parseInt(currentAge) <= 0) {
                error = "Please check your age.";
            }
            else if (parseInt(currentAge) >= 60) {
                error = "Please check your age, it's over 60 or equeal.";
            }
            else if (parseInt(retirementAge) <= 0) {
                error = "Please check your retirement age.";
            }
            else if (parseInt(currentAge) >= parseInt(retirementAge)) {
                error = "Please check your age and retirement age.";
            }
        }

        if (IsEmpty(error)) {
            if (calculationType == 'reverse') {
                setStep(3);
            }
            if (calculationType == 'forward') {
                setStep(2);
            }
        }
        else {
            setToastMessage(error);
            onToastClose(true);
        }
    };

    const backStep = (step) => {
        if (activeStep > 1) {
            if (IsEmpty(step))
                setStep(activeStep - 1);
            else
                setStep(step);
        }
    };

    const fetchData = () => {
        if (!IsEmpty(calculationType)) {
            if (calculationType == 'reverse') {
                //currentage/retirementage/lumpsum/rateofreturn/pensionmonthly/annuityrate
                let pensionofVal = pensionofMode == 'year' ? roundTo(pensionof / 12, 2) : pensionof;
                let param = `${currentAge}/${retirementAge}/${iwanttotalfundsof}/${expectedrateofreturn}/${pensionofVal}/${rateofannuty}`
                setApiCall(true);
                dispatch(fetchCalcInvestData(param));
            }
            if (calculationType == 'forward') {
                let iwanttoinvestVal = iwanttoinvestMode == 'year' ? roundTo(iwanttoinvest / 12, 2) : iwanttoinvest;
                //currentage/retirementage/monthlyinvest/expectedreturn/annuityrate/annuitypercentage(optional)
                let param = `${currentAge}/${retirementAge}/${iwanttoinvestVal}/${expectedrateofreturn}/${annutyPercentage}/${rateofannuty}/${annualincrementof}/`;
                //forward/curr age/ retire age/ invest amt /rate of return/annuity percent / annuity rate / annual increment
                setApiCall(true);
                dispatch(fetchCalcForwardData(param));
            }
        }
    };

    const caculateForward = () => {
        setLoaderMessage('Please wait.....');
        onLoaderClose(false);
        onToastClose(false);

        let error = "";

        if (IsEmpty(iwanttoinvest)) {
            error = 'Please enter invest amount';
        }
        else if (!IsEmpty(iwanttoinvest) && parseFloat(iwanttoinvest) <= 0) {
            error = 'Enter valid invest amount ';
        }
        else if (IsEmpty(iwanttoinvestMode)) {
            error = 'Please select invest period';
        }
        else if (IsEmpty(annualincrementof)) {
            error = 'Please enter annual increment';
        }
        // else if (!IsEmpty(annualincrementof) && parseFloat(annualincrementof) <= 0) {
        //     error = 'Enter valid annual increment ';
        // }
        else if (!IsNumber(annualincrementof)) {
            error = 'Enter valid annual increment ';
        }
        else if (IsEmpty(expectedrateofreturn)) {
            error = 'Please enter rate of return';
        }
        // else if (!IsEmpty(expectedrateofreturn) && parseFloat(expectedrateofreturn) <= 0) {
        //     error = 'Enter valid rate of return ';
        // }
        else if (!IsNumber(expectedrateofreturn)) {
            error = 'Enter valid rate of return ';
        }

        if (!IsEmpty(error)) {
            setToastMessage(error);
            onToastClose(true);
        }
        else if (IsEmpty(error)) {
            setApiCall(true);
            onLoaderClose(true);
            fetchData();
        }
    };

    const caculateReverse = () => {
        setLoaderMessage('Please wait.....');
        onLoaderClose(false);
        onToastClose(false);

        let error = "";
        if (IsEmpty(iwanttotalfundsof)) {
            error = 'Please enter total funds of';
        }
        else if (!IsEmpty(iwanttotalfundsof) && parseFloat(iwanttotalfundsof) <= 0) {
            error = 'Enter valid total funds amount ';
        }
        else if (IsEmpty(pensionof)) {
            error = 'Please enter pension of';
        }
        else if (!IsEmpty(pensionof) && parseFloat(pensionof) <= 0) {
            error = 'Enter valid pension of amount ';
        }
        else if (IsEmpty(pensionofMode)) {
            error = 'Please select pension period';
        }
        else if (IsEmpty(expectedrateofreturn)) {
            error = 'Please enter rate of return';
        }
        else if (!IsEmpty(expectedrateofreturn) && parseFloat(expectedrateofreturn) <= 0) {
            error = 'Enter valid rate of return amount';
        }

        if (anyotherschemes && IsEmpty(error)) {
            if (!IsEmpty(any_other_MutualFunds) && parseFloat(any_other_MutualFunds) <= 0) {
                error = 'Enter valid Mutual Funds amount ';
            }

            if (!IsEmpty(any_other_epf) && parseFloat(any_other_epf) <= 0) {
                error = 'Enter valid EPF amount ';
            }

            if (!IsEmpty(any_other_schemes) && parseFloat(any_other_schemes) <= 0) {
                error = 'Enter valid Other schemes amount ';
            }
        }

        if (!IsEmpty(error)) {
            setToastMessage(error);
            onToastClose(true);
        }
        else if (IsEmpty(error)) {
            setApiCall(true);
            onLoaderClose(true);
            fetchData();
        }
    };

    useEffect(() => {
        if (calculator_data_contents) {
            if (apiCall) {
                setStep(4);
                setApiCall(false);
                onLoaderClose(false);

                let age = parseInt(retirementAge) - parseInt(currentAge);
                setAgeDiff(age);
                if (calculationType != 'forward') {
                    setAnnutyPercentage(trailZero(calculator_data_contents?.data?.annuity_percent));
                }
                setLampsumPercentage(trailZero(calculator_data_contents?.data?.lumpsum_percent));
            }
        }
    }, [calculator_data_contents]);

    useEffect(() => {
        if (activeStep) {
            if (activeStep == 4 && apiCall == false) {
                setLoaderMessage('Please wait.....');
                onLoaderClose(false);
                onToastClose(false);

                setApiCall(true);
                onLoaderClose(true);
                fetchData();
            }
        }
    }, [activeStep, currentAge, retirementAge, iwanttotalfundsof,
        expectedrateofreturn, pensionof, iwanttoinvest,
        expectedrateofreturn, annualincrementof, annutyPercentage,
        rateofannuty, iwanttoinvestMode, pensionofMode])

    return (
        <div className="bg-site-FFF4EC px-4 lg:px-16 md:px-10 py-6  md:py-16 flex flex-col lg:flex-column justify-center items-center">
            <h1 className="lg:mb-4 md:mb-0 black-h-text font-bold md:text-2xl text-xl lg:text-4xl"> {parseString(contents?.planner_title || "")}</h1>
            <div className="text-sm md:text-lg mt-2  text-gray-600  lg:mb-2 md:mb-2 font-normal mb-5">
                {parseString(contents?.planner_description || "")}
            </div>

            {/* Stage 1 */}
            {activeStep == 1 && (
                <div className="container bg-white    mx-auto my-8 p-8 flex flex-col items-center rounded-[12px]  md:rounded-[40px] ">
                    <h5 className="text-[18px]	md:text-xl text-neutral-GRAY-200  lg:text-neutral-DARK font-bold lg:font-medium		">Tell us about yourself!</h5>
                    <div className="flex items-center justify-center mt-[16px] md:mt-5 lg:mt-10">
                        <h6 className={`check-male text-[14px]	md:text-2xl	 lg:text-[32px]  ${gender == false ? 'text-primary' : 'text-neutral-GRAY-200'} `}>Male</h6>
                        <ToggleButton isToggle={gender} setToggle={setGender} />
                        <h6 className={`check-female text-base	md:text-2xl	 lg:text-[32px]	${gender == true ? 'text-primary' : 'text-neutral-GRAY-200'} `}>Female</h6>
                    </div>
                    <div className="flex items-center justify-evenly mt-[16px]  md:mt-5 lg:mt-16 w-full flex-col lg:flex-row ">
                        <div className="flex items-center justify-center flex-col  ">
                            <img className="w-[80px] lg:w-[120px] " src={currentImage} alt="Mobile Screen Image" />
                            <div className="flex items-center justify-center mt-[16px]  md:mt-10">
                                <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	">My Current Age is</h6>
                                <IncrementalInput
                                    maxLength={3}
                                    name="currentAge"
                                    valueInput={currentAge}
                                    setInput={setCurrentAge}
                                    min={minAge}
                                    max={maxAge} />
                            </div>
                        </div>
                        <div className="flex items-center justify-center flex-col  mt-[16px]  md:mt-5 lg:mt-0 ">
                            <img className=" w-[80px] lg:w-[120px] " src={retireImage} alt="Mobile Screen Image" />
                            <div className="flex items-center justify-center mt-[16px]  md:mt-10">
                                <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	">I Want to Retire by</h6>
                                <IncrementalInput
                                    maxLength={3}
                                    name="retireAge"
                                    valueInput={retirementAge}
                                    setInput={setRetirementAge}
                                    min={retrMinAge} maxAge={retrMaxAge} />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-10 w-full   flex-col lg:flex-row  ">
                        <h5 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">I want to calculate</h5>
                        <div className="mt-3 lg:mt-0 flex relative ">
                            <button
                                onClick={() => changeCalculation('forward')}
                                className={`bg-white border text-neutral-GRAY-200 font-medium flex items-center justify-center	 
                            text-[11px] md:text-[14px]	lg:text-[18px] leading-6 rounded-full py-[6px] px-[16px] mr-2 lg:mr-2  
                            w-[fit-content] h-[29px] md:h-[46px]   whitespace-nowrap ${calculationType == 'forward' ? ' text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 `}>Pension after retirement</button>
                            <img className=" hidden md:block  absolute left-[-33%] lg:left-[-30%]  top-[90%]   md:w-[170px]  lg:w-[218px] " src={arrow_text_1} alt="Mobile Screen Image" />
                            <img className=" md:hidden absolute  left-[0%]  lg:left-[21%] top-[90%]   " src={arrow_text_4} alt="Mobile Screen Image" />
                            <button
                                onClick={() => changeCalculation('reverse')}
                                className={`bg-white border  text-neutral-GRAY-200 font-medium flex items-center justify-center	 
                            text-[11px] md:text-[14px]	lg:text-[18px] leading-6 rounded-full py-[6px] px-[16px]  lg:mr-2 
                             w-[fit-content] h-[29px] md:h-[46px] whitespace-nowrap ${calculationType == 'reverse' ? ' text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 `}>How much to invest ?</button>
                            <img className=" hidden md:block absolute right-[-33%] lg:right-[-30%]   top-[90%]  md:w-[170px]  lg:w-[218px]" src={arrow_text_2} alt="Mobile Screen Image" />
                            <img className=" md:hidden absolute right-[0%] top-[100%]    " src={arrow_text_5} alt="Mobile Screen Image" />
                        </div>
                        {/* mobile arrows */}
                    </div>
                    <button onClick={NextStepOne}
                        className="flex flex-row items-center justify-center border 
                        text-white 
                        rounded-full 
                        font-bold 
                        text-[16px]
                        lg:text-[18px]
                        px-4 py-2
                        md:h-[56px] md:w-[126px]
                        lg:h-[64px] lg:w-[121px]
                        bg-primary 
                        hover:bg-primitive-ORANGE-400
                        hover:text-white mt-24">Next</button>
                </div>)}
            {/* Stage 1 */}

            {/* Stage 2 */}
            {activeStep == 2 && (
                <div className="container bg-white   mx-auto my-8 p-8 flex flex-col items-center rounded-[12px]  md:rounded-[40px] ">
                    <h5 className="text-lg hidden	md:text-xl md:flex items-center justify-center font-bold lg:font-medium	 text-neutral-GRAY-200  lg:text-neutral-DARK 	">Let’s talk money!</h5>
                    {/* mobile screen replace this text */}
                    <h5 className="  md:hidden text-[18px]	md:text-xl text-neutral-GRAY-200  lg:text-neutral-DARK font-bold lg:font-medium	 text-center	">Tell us about yourself!</h5>
                    <div className="md:hidden flex items-center justify-center mt-[16px] md:mt-5 lg:mt-10">
                        <h6 className="check-male text-[14px]	md:text-2xl	 lg:text-[32px]	">Male</h6>
                        <ToggleButton isToggle={gender} setToggle={setGender} />
                        <h6 className="check-female text-base	md:text-2xl	 lg:text-[32px]	">Female</h6>
                    </div>
                    <div className="flex items-center justify-center flex-col lg:flex-row mt-[16px] md:mt-[35px] ">
                        <div className="flex items-center justify-center mt-0   flex-col lg:flex-row">
                            <img className=" w-[80px] lg:w-[120px]  " src={currentImage} alt="Mobile Screen Image" />
                            <div className="flex items-center justify-center mt-5 ml-0 lg:ml-5 lg:mt-0 flex-row ">
                                <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	">I want to invest</h6>
                                <IncrementalInput
                                    maxLength={8}
                                    name="iwanttoinvest"
                                    valueInput={iwanttoinvest}
                                    setInput={setIwantToInvest}
                                    min={iwanttoinvestMin} />
                            </div>
                        </div>
                        <div className=" d-flex lg:d-block text-center items-center justify-center mt-5 lg:mt-0">
                            <button onClick={() => changeInvestMode('month')} className={`bg-white ${iwanttoinvestMode == 'month' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px] rounded-full mr-5 ml-0 lg:ml-5 leading-[1] w-[115px] h-[40px] `}>Monthly</button>
                            <button onClick={() => changeInvestMode('year')} className={`bg-white ${iwanttoinvestMode == 'year' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''}  border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full  leading-[1] w-[115px] h-[40px]`}>Yearly</button>
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col lg:flex-row md:mt-5 lg:mt-10">
                        <div className="flex items-center justify-center mt-5 lg:mt-0 flex-row ">
                            <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] md:ml-0 lg:ml-10 whitespace-nowrap text-primary-DARK	">with an annual increment of</span>

                            <IncrementalInput
                                maxLength={2}
                                name="annualincrementof"
                                valueInput={annualincrementof}
                                setInput={setAnnualIncrementOf}
                                min={0} max={99} />
                        </div>
                        <h6 className="text-xs md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] md:ml-0 lg:ml-5 text-center mt-10 lg:mt-0  	">and my investment strategy will be </h6>
                    </div>
                    <div className="flex items-center justify-center md:mt-0 lg:mt-10 w-full relative  flex-col lg:flex-row     ">
                        <div className=" d-flex lg:d-block text-center items-center justify-center mt-3 lg:mt-0 whitespace-nowrap">
                            <button
                                onClick={() => changeInvestmentStrategy('equity')}
                                className={`bg-white border ${investmentstrategy == 'equity' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} hover:text-primary-BLUE-500 hover:border-primary-BLUE-500    text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   lg:px-[10px]  leading-[1] w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Allocation to Equity </button>
                            <button
                                onClick={() => changeInvestmentStrategy('corporate')}
                                className={`bg-white ${investmentstrategy == 'corporate' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   ml-3 md:ml-0 lg:px-[10px]  leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Corporate bonds</button>
                            <br className="md:hidden " />
                            <button
                                onClick={() => changeInvestmentStrategy('gov')}
                                className={`bg-white ${investmentstrategy == 'gov' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500    text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   mt-3 md:mt-0 lg:px-[10px]  leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Government securities</button>
                            <button
                                onClick={() => changeInvestmentStrategy('custom')}
                                className={`bg-white ${investmentstrategy == 'custom' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px] lg:text-[18px]   rounded-full   mt-3  md:mt-0  ml-3 md:ml-0  lg:px-[10px] leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px] `}>Custom</button>
                        </div>
                        <div className="flex items-center justify-center  mr-0 lg:ml-3 mt-5 lg:mt-0 flex-row ">
                            <h6 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">with expected rate of return</h6>
                            <IncrementalInput
                                maxLength={6}
                                name="expectedrateofreturn"
                                valueInput={expectedrateofreturn}
                                setInput={setExpectedRateofReturn}
                                min={0} max={30}
                                isEdit={expectedrateofreturnEdit} />
                        </div>
                    </div>
                    <div className="flex items-center justify-center md:mt-0 lg:mt-10 w-full relative  flex-col lg:flex-row     ">
                        <h6 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">and rate of annuity is</h6>
                        <IncrementalInput
                            maxLength={6}
                            name="rateofannuty"
                            valueInput={rateofannuty}
                            setInput={setRateofAnnuty}
                            min={4} max={10} />
                    </div>

                    <div className=" flex items-center justify-center mt-36">
                        <button
                            onClick={() => backStep(1)}
                            className="bg-white border-custom-color  text-[16px]
                            lg:text-[18px]
                            px-4 py-2
                            md:h-[56px] md:w-[126px]
                            lg:h-[64px] lg:w-[121px]  
                            flex justify-center items-center   
                           border text-black font-bold rounded-full mr-5">Back</button>
                        <button
                            onClick={() => caculateForward()}
                            className="mr-4 bg-primary-ORANGE-500 border-custom-color  text-[16px]
                            lg:text-[18px]
                            px-4 py-2
                            md:h-[56px] md:w-[126px]
                            lg:h-[64px] lg:w-[121px]  
                            flex justify-center items-center  
                            border text-white font-bold rounded-full">Calculate</button>
                    </div>
                </div>)}

            {/* Step 2 */}
            {activeStep == 3 && (
                <div className="container bg-white    mx-auto my-8 p-8 flex flex-col items-center  custom-radius  ">
                    <h5 className="text-lg hidden	md:text-xl md:flex items-center justify-center font-bold lg:font-medium	 text-neutral-GRAY-200  lg:text-neutral-DARK 	">Let’s talk money!</h5>
                    {/* mobile screen replace this text */}
                    <h5 className="  md:hidden text-[18px]	md:text-xl text-neutral-GRAY-200  lg:text-neutral-DARK font-bold lg:font-medium	 text-center	">Let’s talk money!</h5>
                    <div className="md:hidden flex items-center justify-center mt-[16px] md:mt-5 lg:mt-10">
                        <h6 className="check-male text-[14px]	md:text-2xl	 lg:text-[32px]	">Male</h6>
                        <ToggleButton isToggle={gender} setToggle={setGender} />
                        <h6 className="check-female text-base	md:text-2xl	 lg:text-[32px]	">Female</h6>
                    </div>
                    <div className="flex items-center justify-center flex-col lg:flex-row mt-[16px] md:mt-[35px]  ">
                        <div className="flex items-center justify-center mt-0   flex-col lg:flex-row">
                            <img className=" w-[80px] lg:w-[120px]  " src={retireImage} alt="Mobile Screen Image" />
                            <div className="flex items-center justify-center mt-5 lg:mt-0  ml-0 lg:ml-5  flex-row ">
                                <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] text-primary-DARK	"> when I retire, I want total funds of</span>
                                <IncrementalInput
                                    maxLength={20}
                                    name="iwanttotalfundsof"
                                    valueInput={iwanttotalfundsof}
                                    setInput={setIwanttotalFundsOf}
                                    min={0} />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col lg:flex-row md:mt-5 lg:mt-10">
                        <div className="flex items-center justify-center mt-5 lg:mt-0 flex-row ">
                            <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] md:ml-0 lg:ml-10 text-primary-DARK ">and pension of</span>
                            <IncrementalInput
                                maxLength={20}
                                name="pensionof"
                                valueInput={pensionof}
                                setInput={setPensionOf}
                                min={1} />
                        </div>
                        <div className=" d-flex lg:d-block text-center items-center justify-center mt-5 lg:mt-0">
                            <button onClick={() => changePensionMode('month')} className={`bg-white ${pensionofMode == 'month' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px] rounded-full mr-5 ml-0 lg:ml-5 leading-[1] w-[115px] h-[40px] `}>Monthly</button>
                            <button onClick={() => changePensionMode('year')} className={`bg-white ${pensionofMode == 'year' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''}  border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full  leading-[1] w-[115px] h-[40px]`}>Yearly</button>
                        </div>
                        <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] md:ml-0 lg:ml-5 text-center mt-5 md:mt-7 lg:mt-0 	">and my investment strategy will be  </h6>
                    </div>
                    <div className="flex items-center justify-center md:mt-0 lg:mt-10 w-full relative  flex-col lg:flex-row     ">
                        <div className=" d-flex lg:d-block text-center items-center justify-center mt-3 lg:mt-0 whitespace-nowrap">
                            <button
                                onClick={() => changeInvestmentStrategy('equity')}
                                className={`bg-white border ${investmentstrategy == 'equity' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} hover:text-primary-BLUE-500 hover:border-primary-BLUE-500    text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   lg:px-[10px]  leading-[1] w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Allocation to Equity </button>
                            <button
                                onClick={() => changeInvestmentStrategy('corporate')}
                                className={`bg-white ${investmentstrategy == 'corporate' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   ml-3 md:ml-0 lg:px-[10px]  leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Corporate bonds</button>
                            <br className="md:hidden " />
                            <button
                                onClick={() => changeInvestmentStrategy('gov')}
                                className={`bg-white ${investmentstrategy == 'gov' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500    text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full   mr-0 md:mr-[4px]   mt-3 md:mt-0 lg:px-[10px]  leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px]`}>Government securities</button>
                            <button
                                onClick={() => changeInvestmentStrategy('custom')}
                                className={`bg-white ${investmentstrategy == 'custom' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px] lg:text-[18px]   rounded-full   mt-3  md:mt-0  ml-3 md:ml-0  lg:px-[10px] leading-[1]  w-[140px] md:w-[170px] lg:w-[auto] h-[40px] md:h-[50px] `}>Custom</button>
                        </div>
                        <div className="flex items-center justify-center  mr-0 lg:ml-3 mt-5 lg:mt-0 flex-row ">
                            <h6 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">with expected rate of return</h6>
                            <IncrementalInput
                                maxLength={6}
                                name="expectedrateofreturn"
                                valueInput={expectedrateofreturn}
                                setInput={setExpectedRateofReturn}
                                min={0} max={30}
                                isEdit={expectedrateofreturnEdit} />
                        </div>
                    </div>
                    <div className="flex items-center justify-center md:mt-0 lg:mt-10 w-full relative  flex-col lg:flex-row     ">
                        <h6 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">and rate of annuity is</h6>
                        <IncrementalInput
                            maxLength={6}
                            name="rateofannuty"
                            valueInput={rateofannuty}
                            setInput={setRateofAnnuty}
                            min={4} max={10} />
                    </div>

                    {/* Any Other  */}
                    <div>
                        <div className=" relative bg-light-blend rounded-xl	 px-[16px]	py-[8px] flex items-center justify-center mt-8 lg:mt-16">
                            <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	 mr-5">Have you invested money in any other schemes? </h6>
                            <h6 className="  text-[10px] md:text-[13px] lg:text-[18px] text-neutral-GRAY-300	">NO</h6>
                            <ToggleButton isToggle={anyotherschemes} setToggle={setAnyOtherSchemes} bg="bg-neutral-GRAY-300" />
                            <h6 className="  text-[10px]  md:text-[13px] lg:text-[18px]	text-neutral-GRAY-300	">YES</h6>
                            <img className=" hidden lg:block absolute      left-[-33%]   top-[90%]    " src={arrow_text_3} alt="Mobile Screen Image" />
                            <img className=" hidden md:block lg:hidden absolute    left-[-17%]    top-[90%]    " src={arrow_text_7} alt="Mobile Screen Image" />
                            <img className=" md:hidden absolute  left-[1%]   md:left-[-10%] lg:left-[-30%]   top-[90%]  " src={arrow_text_6} alt="Mobile Screen Image" />
                        </div>
                    </div>

                    {anyotherschemes && (
                        <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4  gap-3 mt-[4rem]   ">
                            <div className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]  text-primary-DARK md:text-center my-auto">I have invested</div>

                            <div className="flex items-center justify-center mt-5 lg:mt-0 flex-row ">
                                <IncrementalInputCopy
                                    maxLength={20}
                                    name="any_other_MutualFunds"
                                    valueInput={any_other_MutualFunds}
                                    setInput={setAnyOtherMutualFunds}
                                />
                                <span className="text-xs md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2 md:ml-0 lg:ml-2  text-primary-DARK	w-[130px] md:w-full	">in Mutual Funds </span>
                            </div>

                            <div className="flex items-center justify-center mt-5 lg:mt-0 flex-row ">
                                <IncrementalInputCopy
                                    maxLength={20}
                                    name="any_other_epf"
                                    valueInput={any_other_epf}
                                    setInput={setAnyOtherEpf}
                                />
                                <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2 md:ml-0 lg:ml-2  text-primary-DARK w-[130px] md:w-full	">in Employeess Provident Fund EPF</span>
                            </div>

                            <div className="flex items-center justify-center mt-5 lg:mt-0 flex-row ">
                                <IncrementalInputCopy
                                    maxLength={20}
                                    name="any_other_schemes"
                                    valueInput={any_other_schemes}
                                    setInput={setAnyOtherSchemesInput}
                                />
                                <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2 md:ml-0 lg:ml-2 text-primary-DARK w-[130px] md:w-full">in Other schemes</span>
                            </div>
                        </div>)}


                    <div className=" flex items-center justify-center mt-[70px]">
                        <button
                            onClick={() => backStep(1)}
                            className="bg-white border-custom-color  text-[16px]
                            lg:text-[18px]
                            px-4 py-2
                            md:h-[56px] md:w-[126px]
                            lg:h-[64px] lg:w-[121px]  
                            flex justify-center items-center   
                           border text-black font-bold rounded-full mr-5">Back</button>
                        <button
                            onClick={() => caculateReverse()}
                            className="mr-4 bg-primary-ORANGE-500 border-custom-color  text-[16px]
                            lg:text-[18px]
                            px-4 py-2
                            md:h-[56px] md:w-[126px]
                            lg:h-[64px] lg:w-[121px]  
                            flex justify-center items-center  
                            border text-white font-bold rounded-full">Calculate</button>
                    </div>
                </div>)}
            {/* Step 2 */}
            {/* Stage 2 */}

            {activeStep == 4 && (
                <div className=" container bg-white   mx-auto my-8   px-3  py-10  md:px-8 md:py-10 flex flex-col items-center   rounded-[24px] md:rounded-[40px] relative  ">
                    <div className="w-full mx-10 grid grid-cols-1   md:grid-cols-1 lg:grid-cols-2 gap-0">

                        {/* mobile screen replace this text */}

                        <h5 className="  md:hidden text-[18px]	md:text-xl text-neutral-GRAY-200  lg:text-neutral-DARK font-bold lg:font-medium	 text-center	">Tell us about yourself!</h5>

                        <div className="md:hidden flex items-center justify-center mt-[16px] md:mt-5 lg:mt-10">
                            <h6 className="check-male text-[14px]	md:text-2xl	 lg:text-[32px]	">Male</h6>
                            <ToggleButton isToggle={gender} setToggle={setGender} />
                            <h6 className="check-female text-base	md:text-2xl	 lg:text-[32px]	">Female</h6>
                        </div>

                        <div>
                            <h5 className=" hidden md:block text-xs md:text-[16px] lg:text-[18px]	 font-bold text-center lg:text-left tracking-[0.16px] text-primary-DARK">Calculate my  <span className="blue-text text-xs md:md:text-[16px] lg:text-[18px]  font-bold"> Investment </span></h5>
                            <div className="flex items-center lg:items-start xl:items-center  md:justify-center lg:justify-start mt-[24px]  w-full flex-col md:flex-row lg:flex-col xl:flex-row ">
                                <div className="flex items-center justify-center flex-row  ">
                                    <img className=" w-[60px]   md:w-[64px] " src={currentImage} alt="Mobile Screen Image" />
                                    <div className="flex items-center justify-centerflex-row md:flex-col mt-0 ml-3">
                                        <h6 className="text-xs	md:text-base lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	">My Current Age is</h6>
                                        <UpDownInput
                                            maxLength={3}
                                            name="currentAge"
                                            valueInput={currentAge}
                                            setInput={setCurrentAge}
                                            min={minAge}
                                            max={maxAge} />
                                    </div>
                                </div>

                                <div className="flex items-center justify-center flex-row mt-[16px]   md:mt-0 lg:mt-[16px] xl:mt-0 ml-0   md:ml-7 lg:ml-0 xl:ml-7 ">
                                    <img className=" w-[60px]   md:w-[64px]  " src={retireImage} alt="Mobile Screen Image" />
                                    <div className="flex items-center justify-center  flex-row md:flex-col mt-0 ml-3">
                                        <h6 className="text-xs	md:text-base lg:text-[16px]  font-semibold tracking-[0.16px] text-primary-DARK		">I Want to Retire by</h6>

                                        <UpDownInput
                                            maxLength={3}
                                            name="retireAge"
                                            valueInput={retirementAge}
                                            setInput={setRetirementAge}
                                            min={retrMinAge} maxAge={retrMaxAge} />
                                    </div>
                                </div>
                            </div>

                            {calculationType == 'forward' && (
                                <>
                                    <div className="flex items-center lg:items-start xl:items-center md:justify-center lg:justify-start mt-[24px] w-full relative  flex-col md:flex-row  lg:flex-col xl:flex-row ">
                                        <div className="flex items-center justify-center">
                                            <h5 className="mr-0   text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK whitespace-nowrap">I want to invest</h5>
                                            <UpDownInput
                                                maxLength={8}
                                                name="iwanttoinvest"
                                                valueInput={iwanttoinvest}
                                                setInput={setIwantToInvest}
                                                min={iwanttoinvestMin} />
                                        </div>
                                        <div className="mt-[24px] md:mt-0  xl:mt-0 md:ml-5 lg:ml-0 lg:mt-0 flex">
                                            <button onClick={() => changeInvestMode('month')} className={`bg-white ${iwanttoinvestMode == 'month' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px] rounded-full mr-5 ml-0 lg:ml-5 leading-[1] w-[115px] h-[40px] `}>Monthly</button>
                                            <button onClick={() => changeInvestMode('year')} className={`bg-white ${iwanttoinvestMode == 'year' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''}  border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full  leading-[1] w-[115px] h-[40px]`}>Yearly</button>
                                        </div>
                                    </div>

                                    <div className="flex items-center md:justify-center lg:justify-start mt-[24px] w-full relative  flex-col md:flex-row  ">
                                        <div className="flex items-center justify-center">
                                            <h5 className="mr-0   text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK">with annual increment </h5>
                                            <UpDownInput
                                                maxLength={2}
                                                name="annualincrementof"
                                                valueInput={annualincrementof}
                                                setInput={setAnnualIncrementOf}
                                                min={0} max={99} />
                                        </div>
                                        <div className="  ml-5 lg:mt-0">
                                            <h5 className="mr-0 lg:mr-10 text-xs lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK hidden md:block">and my</h5>
                                        </div>
                                    </div>
                                </>
                            )}

                            {calculationType == 'reverse' && (
                                <>
                                    <div className="flex items-center lg:items-start xl:items-center md:justify-center lg:justify-start mt-[24px] w-full relative  flex-col md:flex-row  lg:flex-col xl:flex-row ">
                                        <div className="flex items-center justify-center">
                                            <h5 className="mr-0 text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK whitespace-nowrap">i want total funds of</h5>
                                            <UpDownInput
                                                maxLength={20}
                                                name="iwanttotalfundsof"
                                                valueInput={iwanttotalfundsof}
                                                setInput={setIwanttotalFundsOf}
                                                min={0} />
                                        </div>
                                        <div className="mt-[24px] md:mt-0  xl:mt-0 md:ml-5 lg:ml-0 lg:mt-0 flex">
                                            <p className="mr-0 text-xs	lg:text-base font-semibold tracking-[0.16px]
                                text-primary-DARK whitespace-nowrap"> , and</p>
                                        </div>
                                    </div>

                                    <div className="flex items-center lg:items-start xl:items-center md:justify-center lg:justify-start mt-[24px] w-full relative  flex-col md:flex-row  lg:flex-col xl:flex-row ">
                                        <div className="flex items-center justify-center">
                                            <h5 className="mr-0 text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK whitespace-nowrap">pension of </h5>
                                            <UpDownInput
                                                maxLength={20}
                                                name="pensionof"
                                                valueInput={pensionof}
                                                setInput={setPensionOf}
                                                min={0} />
                                        </div>
                                        <div className="mt-[24px] md:mt-0  xl:mt-0 md:ml-5 lg:ml-0 lg:mt-0 flex">
                                            <button onClick={() => changePensionMode('month')} className={`bg-white ${pensionofMode == 'month' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500  text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px] rounded-full mr-5 ml-0 lg:ml-5 leading-[1] w-[115px] h-[40px] `}>Monthly</button>
                                            <button onClick={() => changePensionMode('year')} className={`bg-white ${pensionofMode == 'year' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''}  border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500 text-neutral-GRAY-200 font-normal text-xs md:text-[14px]	lg:text-[18px]   rounded-full  leading-[1] w-[115px] h-[40px]`}>Yearly</button>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className=" d-flex lg:d-block text-center lg:text-left   items-center justify-center lg:justify-start  mt-[24px] whitespace-nowrap">
                                <h5 className="mr-0 lg:mr-10 text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK text-center lg:text-left mb-[16px]	 ">My investment strategy will be </h5>
                                <button
                                    onClick={() => changeInvestmentStrategy('equity')}
                                    className={`bg-white border ${investmentstrategy == 'equity' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500   text-neutral-GRAY-200 font-normal  text-xs md:text-[14px]    rounded-full   mr-0 md:mr-[15px]  lg:px-[10px]  leading-[1] w-[170px]   h-[40px] md:h-[50px]`}>Allocation to Equity </button>
                                <button
                                    onClick={() => changeInvestmentStrategy('corporate')}
                                    className={`bg-white ${investmentstrategy == 'corporate' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500   text-neutral-GRAY-200 font-normal  text-xs md:text-[14px]  rounded-full   mr-0 md:mr-[15px]  ml-3 md:ml-0 lg:px-[10px]  leading-[1] w-[170px]   h-[40px] md:h-[50px]`}>Corporate bonds</button>
                                <br className=" "></br>
                                <button
                                    onClick={() => changeInvestmentStrategy('gov')}
                                    className={`bg-white ${investmentstrategy == 'gov' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500   text-neutral-GRAY-200 font-normal text-xs md:text-[14px]  rounded-full   mr-0 md:mr-[15px]  mt-3  lg:px-[10px]  leading-[1] w-[170px]   h-[40px] md:h-[50px] `}>Government securities</button>
                                <button
                                    onClick={() => changeInvestmentStrategy('custom')}
                                    className={`bg-white ${investmentstrategy == 'custom' ? 'text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} border hover:text-primary-BLUE-500 hover:border-primary-BLUE-500   text-neutral-GRAY-200 font-normal text-xs md:text-[14px]  rounded-full   mt-3  md:mt-0  ml-3 md:ml-0  lg:px-[10px] leading-[1] w-[170px]   h-[40px] md:h-[50px]`}>Custom</button>
                            </div>

                            <div className="flex items-center md:justify-center lg:justify-start  mt-[24px] w-full relative  flex-col md:flex-row  ">
                                <div className="flex items-center justify-center">
                                    <h5 className="mr-0  text-xs	lg:text-base font-semibold tracking-[0.16px]	text-primary-DARK">with expected rate of return </h5>
                                    <UpDownInput
                                        maxLength={6}
                                        name="expectedrateofreturn"
                                        valueInput={expectedrateofreturn}
                                        setInput={setExpectedRateofReturn}
                                        min={0} max={30}
                                        isEdit={expectedrateofreturnEdit} />
                                </div>
                            </div>

                            <div className="flex items-center md:justify-center lg:justify-start  mt-[24px] w-full relative  flex-col md:flex-row  ">
                                <h6 className="mr-0 lg:mr-5 text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK">and rate of annuity is</h6>
                                <IncrementalInput
                                    maxLength={6}
                                    name="rateofannuty"
                                    valueInput={rateofannuty}
                                    setInput={setRateofAnnuty}
                                    min={4} max={10} />
                            </div>

                            {/* Any Other schemes */}
                            {calculationType == 'reverse' && (
                                <>
                                    <div className=" relative bg-light-blend rounded-xl	 px-[16px]	py-[8px] flex items-center justify-start mt-8 lg:mt-10 w-[fit-content] mx-auto lg:mx-0 lg:flex-col xl:flex-row">
                                        <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]	text-primary-DARK	 mr-5"> Investment in any other schemes? </h6>
                                        <div className="flex items-center justify-center">
                                            <h6 className="  text-[10px] md:text-[13px] lg:text-[18px] text-neutral-GRAY-300	">NO</h6>
                                            <ToggleButton isToggle={anyotherschemes} setToggle={setAnyOtherSchemes} bg="bg-neutral-GRAY-300" />
                                            <h6 className="  text-[10px]  md:text-[13px] lg:text-[18px]	text-neutral-GRAY-300	">YES</h6>
                                        </div>
                                    </div>

                                    {anyotherschemes && (
                                        <div className="grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  gap-3 mt-8 mb-5   ">
                                            <div className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]  text-primary-DARK md:text-center   mx-auto lg:ml-0  ">I have invested</div>

                                            <div className="flex items-center justify-center lg:justify-start mt-5 lg:mt-0 flex-row ">
                                                <UpDownInput
                                                    maxLength={20}
                                                    name="any_other_MutualFunds"
                                                    valueInput={any_other_MutualFunds}
                                                    setInput={setAnyOtherMutualFunds}
                                                />
                                                <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2   lg:ml-2  text-primary-DARK	w-[fit-content]	">in Mutual Funds </span>

                                            </div>

                                            <div className="flex items-center justify-center lg:justify-start mt-5 lg:mt-0 flex-row ">
                                                <UpDownInput
                                                    maxLength={20}
                                                    name="any_other_epf"
                                                    valueInput={any_other_epf}
                                                    setInput={setAnyOtherEpf}
                                                />
                                                <span className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2   lg:ml-2  text-primary-DARK w-[fit-content]	">in Employeess Provident Fund EPF</span>
                                            </div>

                                            <div className="flex items-center justify-center lg:justify-start mt-5 lg:mt-0 flex-row ">
                                                <UpDownInput
                                                    maxLength={20}
                                                    name="any_other_schemes"
                                                    valueInput={any_other_schemes}
                                                    setInput={setAnyOtherSchemesInput}
                                                />
                                                <span className="text-xs md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px] ml-2 lg:ml-2 text-primary-DARK w-[fit-content]">in Other schemes</span>
                                            </div>
                                        </div>)}
                                </>)}
                            {/* Any Other schemes */}

                        </div>

                        {/* Right Side */}
                        <div className=" mt-[24px]   ">

                            {calculationType == 'reverse' && (
                                <div className="flex items-center justify-between flex-row bg-shade-BLUE-100  px-[16px] mb-5	py-[8px] rounded-xl  lg:flex-col xl:flex-row mx-auto lg:mx-0">
                                    <div className="flex items-center justify-center   ">
                                        <img className="  w-[60px] md:w-[80px] lg:w-[90px] "
                                            src={currentImage} alt="Mobile Screen Image" />
                                        <div className="flex  justify-start flex-col ml-5 ">
                                            <h6 className="  text-[14px] md:text-[16px]	lg:text-base tracking-[0.16px]	">You should invest </h6>

                                            <div className="flex items-start md:items-center justify-center flex-col md:flex-row">
                                                <h5 className=" text-[18px] mt-2 md:text-[22px] font-bold border-b-[3px] tracking-[0.16px] lg:text-xl text-primary-BLACK whitespace-nowrap">
                                                    {pensionofMode == 'month' ? toInrFormat(calculator_data_contents?.data?.monthly_invest) : toInrFormat((calculator_data_contents?.data?.monthly_invest * 12) || 0)}</h5>

                                                <span className="mt-2 ml-0 md:ml-12   flex">
                                                    <button className={` ${pensionofMode == 'month' ? ' text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} bg-white border hover:text-primitive-BLUE-700 hover:border-primitive-BLUE-700   text-neutral-GRAY-300 font-medium flex items-center justify-center	 text-xs	lg:text-[14px] leading-6 rounded-full py-[6px] px-[16px] mr-2 lg:mr-[3px]  w-[85px] h-[29px] `}>Monthly</button>
                                                    <button className={`${pensionofMode == 'year' ? ' text-primary-BLUE-500 border-primary-BLUE-500 ' : ''} bg-white border hover:text-primitive-BLUE-700 hover:border-primitive-BLUE-700 text-neutral-GRAY-300 font-medium	 flex items-center justify-center text-xs	lg:text-[14px] leading-6 rounded-full py-[6px] px-[16px] w-[85px] h-[29px] `}>Yearly</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center ">
                                        {contents?.result?.join_nps && (<StyledButton cta={contents?.result?.join_nps} />)}
                                    </div>
                                </div>
                            )}

                            {calculator_data_contents?.data?.invest_msg && (
                                <div className="bg-primary-LIGHT flex items-center justify-start px-[12px] py-[8px] rounded-[4px] mt-2 mb-5  ">
                                    <p className="text-[10px] ml-3 tracking-[0.16px] text-primary">{parseString(calculator_data_contents?.data?.invest_msg)}</p>
                                </div>)
                            }

                            <h5 className="tracking-[0.16px] text-[16px] md:text-lg font-bold text-left text-primary-DARK">For  <span className="blue-text text-[16px] md:text-lg	 font-bold"> {parseInt(ageDiff) > 0 ? ageDiff : '1'} Years </span>of Contribution</h5>

                            <div className="w-ful mt-[24px]  grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 gap-5 cell-border-bottom pb-3">
                                <div>
                                    <CalculatorChart />
                                </div>

                                <div className="mx-auto flex md:block">
                                    <div className=" relative bg-light-blend rounded-xl max-w-[220px]  px-[8px]	py-[8px]  md:px-[16px]	md:py-[8px]  flex items-start flex-col justify-center ">
                                        <div className="flex items-center justify-center cell-border-bottom pb-2">
                                            <div className="  w-[24px] h-[24px]  md:w-[32px] md:h-[32px] cell-blue rounded-[4px] md:rounded-lg mr-2 md:mr-3	"></div>
                                            <div>
                                                <h4 className="  text-sm	   md:text-base	lg:text-lg  font-bold tracking-[0.16px] text-primary-BLACK ">{toInrFormat(calculator_data_contents?.data?.corpus || 0)}</h4>
                                                <h6 className=" text-[10px] 	   md:text-[12px]  lg:text-sm  tracking-[0.16px] text-neutral-GRAY-300">Wealth Generated</h6>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center pt-2">
                                            <div className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px] cell-lightblue  rounded-[4px] md:rounded-lg mr-2 md:mr-3	"></div>
                                            <div>
                                                <h4 className="text-sm	   md:text-base	lg:text-lg  font-bold tracking-[0.16px] text-primary-BLACK">{toInrFormat(calculator_data_contents?.data?.total_invested || 0)}</h4>
                                                <h6 className="text-[10px] 	   md:text-[12px]  lg:text-sm  tracking-[0.16px] text-neutral-GRAY-300">Total Investment</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center flex-col md:flex-row justify-center mt-0 md:mt-5 ml-3 md:ml-0 px-[8px]	py-[8px]  md:px-[16px]	md:py-[8px] max-w-[220px]	 cell-box-shadow rounded-xl ">
                                        <img className="w-[46px] " src={savings_icon} alt="Mobile Screen Image" />
                                        <div className="ml-2">
                                            <h6 className=" text-[12px] md:text-xs	 lg:text-sm whitespace-nowrap tracking-[0.16px] ">Tax Saving Over Years</h6>
                                            <h4 className="text-sm	   md:text-base	lg:text-[16px]  font-bold tracking-[0.16px]  ">{toInrFormat(calculator_data_contents?.data?.tax_deduction || 0)}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Annuity retirementAge={retirementAge} currentAge={currentAge}
                                annutyPercentage={annutyPercentage}
                                lampsumPercentage={lampsumPercentage} />

                            {calculationType == 'forward' && (
                                <div className="relative slider-shell bg-primary-LIGHT shadow-inner rounded-xl	 px-[16px]	
                                py-[8px] flex items-center justify-start 
                                   w-[fit-content] mx-auto   lg:flex-col xl:flex-row">
                                    <h6 className="text-xs	md:text-[12px] lg:text-[16px] font-semibold tracking-[0.16px]
                                    	text-primary-DARK	 mr-5"> % Invested in Annuity? </h6>
                                    <div className="flex items-center justify-center">
                                        <span className="text-[10px] md:text-[13px] lg:text-[18px]
                                         text-neutral-GRAY-300 mr-1"> </span>
                                        <RangeSlider min={40}
                                            max={100}
                                            inputVal={annutyPercentage}
                                            setInput={setAnnutyPercentage}
                                            extraClass="w-full" />
                                        <h6 className="text-[10px]  md:text-[13px] lg:text-[18px]
                                        	text-neutral-GRAY-300 ml-1"> {annutyPercentage} </h6>
                                    </div>
                                </div>)}

                            {calculationType == 'forward' && (
                                <div className="flex items-center justify-between flex-row mt-5 bg-blend-light-blue  px-[16px]	py-[8px] rounded-xl mx-auto lg:mx-0  ">
                                    <div className="flex items-center justify-center  ">
                                        <img className="  w-[60px] md:w-[80px] lg:w-[90px] " src={retireImage} alt="Mobile Screen Image" />
                                        <div className="flex  justify-start flex-col ml-5 ">
                                            <h6 className="  text-[14px] md:text-[16px]	lg:text-base tracking-[0.16px]	">Your pension per month will be </h6>
                                            <h5 className=" text-[18px]	md:text-[22px] font-bold border-b-[3px]	tracking-[0.16px] lg:text-xl text-primary-BLACK">{toInrFormat(calculator_data_contents?.data?.monthly_annuity)}</h5>
                                        </div>
                                    </div>
                                    {contents?.result?.join_nps && (<StyledButton cta={contents?.result?.join_nps} />)}
                                </div>
                            )}


                            {calculator_data_contents?.data?.disclaimer && (
                                <div className="bg-primary-LIGHT flex items-center justify-start px-[12px]	py-[8px] rounded-[4px] mt-5 mb-5  ">
                                    <img className="w-4 h-4" src={info_icon} alt="Mobile Screen Image" />
                                    <p className="text-[10px] ml-3 tracking-[0.16px] text-neutral-GRAY-300">{parseString(calculator_data_contents?.data?.disclaimer)}</p>
                                </div>)}
                            {calculator_data_contents?.data?.message && (
                                <div className="bg-primary flex items-center justify-start px-[12px] py-[8px] rounded-[4px] mt-2 mb-5  ">
                                    <p className="animate-pulse text-[10px] ml-3 tracking-[0.16px] text-white">{parseString(calculator_data_contents?.data?.message)}</p>
                                </div>)
                            }
                            {calculator_data_contents?.data?.annuity_rate && (
                                <div className="bg-primary-LIGHT flex items-center justify-start px-[12px] py-[8px] rounded-[4px] mt-2 mb-20">
                                    <p className="text-[10px] ml-3 tracking-[0.16px] text-primary">Rate of Annuity {calculator_data_contents?.data?.annuity_rate} % </p>
                                </div>)
                            }
                        </div>
                    </div>

                    <div onClick={() => resetAll()} className="flex items-center justify-center mb-5 mt-[20px] absolute bottom-0 left-5 cursor-pointer">
                        <button className=" w-5 h-5  bg-transparent border border-custom-color rounded-full flex items-center justify-center	">
                            <img className="  w-[12px]  " src={circlearrow_icon} alt="Mobile Screen Image" />
                        </button>
                        <h6 className="text-primary ml-1 md:text-[12px] lg:text-[16px]">Reset</h6>
                    </div>
                </div>
            )}

            <Toast message={toastMessage} isOpen={showToast} onClose={onToastClose} />
            <ToastLoader message={loaderMessage} isOpen={showLoader} />

        </div >
    )
}

export default HomeCalculator;