import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../common/layout/Footer';
import Menu from '../common/layout/Menu';
// import BreadCrumb from '../common/components/Navigations/BreadCrumb';
// import { bc_sitemap } from '../common/layout/breadcrumb-data';

// images
// import sitemap_top_bg from '../assets/images/sitemap-bg-top.svg';
// import sitemap_bg_vector from '../assets/images/sitemap-bg-vector.svg';

import { fetchSiteMap } from '../redux/actions/PageAction';
import { showPageLoader, resetPageLoader } from '../redux/actions/CommonAction';

// import { parseString } from '../common/AppConstants';
import SitemapLinkGroup from '../common/components/Plain/SitemapLinkGroup';
import BannerSitemap from '../common/components/Banners/BannerSitemap';

const Sitemap = () => {
  const [content, setContent] = useState({});
  const [body, setBody] = useState({});
  // Redux
  const dispatch = useDispatch();
  const { sitemap_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (sitemap_contents == null) {
      dispatch(fetchSiteMap({}));
      dispatch(showPageLoader());
    }
  }, [sitemap_contents, body]);

  useEffect(() => {
    if (sitemap_contents) {
      setContent(sitemap_contents?.data ? sitemap_contents?.data[0] : {});
      setBody(
        sitemap_contents?.data[0]?.attributes?.page_body_elements?.data
          ? sitemap_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );

      dispatch(resetPageLoader());
    }
  }, [sitemap_contents]);

  return (
    <>
      {/* NAVBAR SECTION */}
      <Menu key="sitemap-menu" contents={content || null} />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {sitemap_contents?.data[0]?.attributes?.page_banners && (
        <BannerSitemap
          content={sitemap_contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      {body?.attributes?.page_body_cmp &&
        body?.attributes?.page_body_cmp.map((object, index) => (
          <div key={`sitemap_page_body_${index}`}>
            {/* SITEMAP CONTENT */}
            {object?.internal_identifier == 'site_map_content' && (
              <SitemapLinkGroup
                contents={object}
                key={`${Date.now()}-smc-${index}`}
              />
            )}
            {/* SITEMAP CONTENT */}
          </div>
        ))}
      {/* FOOTER */}
      <Footer />
      {/* FOOTER */}
    </>
  );
};
export default Sitemap;
