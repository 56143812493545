import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IsEmpty } from '../../AppConstants';

const LinkButton = ({ openTab = false, cta = {}, url = '', title = null }) => {
  const [link, setRedirect] = useState(url || '#');
  const [btnText, setBtnText] = useState(title || '');

  useState(() => {
    if (cta) {
      if (IsEmpty(title)) {
        if (cta?.data?.attributes) {
          setBtnText(cta?.data?.attributes?.cta_title);
        } else if (cta?.attributes) {
          setBtnText(cta?.attributes?.cta_title);
        }
      }

      if (url == '') {
        if (cta?.data?.attributes) {
          setRedirect(cta?.data?.attributes?.cta_external_link);
        } else if (cta?.attributes) {
          setRedirect(cta?.attributes?.cta_external_link);
        }
      }
    }
  }, [cta, url, title]);

  return (
    <>
      {openTab ? (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-base md:text-lg lg:font-22 font-bold"
        >
          {btnText || ''}
        </a>
      ) : cta?.data?.attributes?.cta_icon?.data?.attributes?.url ? (
        <p className="flex flex-row items-center">
          <a href={link} className="text-base md:text-lg lg:font-22 font-bold">
            {btnText || ''}
          </a>
          <img
            className="mt-1"
            src={cta?.data?.attributes?.cta_icon?.data?.attributes?.url}
          />
        </p>
      ) : (
        <a href={link} className="text-base md:text-lg lg:font-22 font-bold">
          {btnText || ''}
        </a>
      )}
    </>
  );
};

LinkButton.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  openTab: PropTypes.bool || false,
  onClick: PropTypes.func,
  cta: PropTypes.object,
};

export default LinkButton;
