import React, { useEffect, useState } from "react";

// import meter_icon from '../../../assets/images/meter.svg';
import { toInrFormat, toLakhCrText, trailZero } from "../../AppConstants";
import { useSelector } from "react-redux";

const Annuity = ({ currentAge, retirementAge }) => {
    const { calculator_data_contents } = useSelector((state) => state.pageState);
    const [sixtyDate, setSixtyDate] = useState('');

    const [annuityWidth, setAnnuityWidth] = useState(40);
    const [lampsumWidth, setLampsumWidth] = useState(60);

    const addDays = (param) => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getFullYear() + param, currentDate.getMonth(), currentDate.getDate());
        return futureDate;
    }

    useEffect(() => {
        if (calculator_data_contents) {
            if (parseInt(currentAge) > 0 && currentAge < retirementAge) {
                let diff = retirementAge - currentAge;
                let dates = addDays(diff);

                setSixtyDate(dates.getFullYear());
            }
            else {
                setSixtyDate(new Date().getFullYear());
            }

            let annuity = trailZero(calculator_data_contents?.data?.annuity_percent) || 40;
            let lampsum = trailZero(calculator_data_contents?.data?.lumpsum_percent) || 60;

            if (annuity >= 60) {
                setAnnuityWidth(60)
                setLampsumWidth(40);
            }
            else {
                setAnnuityWidth(annuity)
                setLampsumWidth(lampsum);
            }
        }
    }, [currentAge, calculator_data_contents, retirementAge]);



    return (
        <div>
            <h5 className=" tracking-[0.16px] text-[14px] md:text-[16px]  lg:text-[18px]  mt-[24px]	 font-bold text-left 
             	text-primary-DARK  ">At the age of <span className="blue-text text-[14px] md:text-[16px] font-bold"> {retirementAge || '60'} (in {sixtyDate || ''})</span></h5>

            <div className="flex justify-between items-center mt-1">
                <div>
                    <h4 className="text-primary text-[12px] md:text-[14px] lg:text-base tracking-[0.16px]  font-semibold">Annuity investment</h4>
                    <h6 className="text-[10px] md:text-[12px]  tracking-[0.16px] text-neutral-GRAY-300 font-normal	 ">for monthly pension after retirement</h6>
                </div>
                <div>
                    <h4 className="text-primitive-BLUE-400 text-[12px] md:text-[14px]	 lg:text-base tracking-[0.16px] text-right font-semibold	 ">Lump sum withdrawal</h4>
                    <h6 className="text-[10px] md:text-[12px]  text-right tracking-[0.16px] text-neutral-GRAY-300 font-normal	  ">At the age of {retirementAge || '60'}</h6>
                </div>
            </div>

            <div className="w-full rounded-xl mt-3 container">
                <div className="flex max-w-[1000px] section ">
                    <div className={`bg-primary-ORANGE-B2 w-[${annuityWidth}%]  px-[16px]	py-[8px] rounded-l-xl `}
                        style={{ width: `${annuityWidth}%` }}>

                        <div className="flex justify-between">
                            <div className="border-b-2 border-white-300">
                                <h4 className="  text-[11px]  md:text-[14px]  lg:text-[15px] font-bold text-white tracking-[0.16px] ">{toInrFormat(calculator_data_contents?.data?.total_annuity || 0)}</h4>
                            </div>
                            <div className="border-b-2 border-white-300">
                                <h6 className=" text-[12px]  md:text-sm lg:text-base font-bold	text-white tracking-[0.16px] ">{trailZero(calculator_data_contents?.data?.annuity_percent) || 0}%</h6>
                            </div>
                        </div>
                        <h6 className="  text-[13px]  md:text-base  lg:text-[16px]	font-bold cavet_font text-white mt-2 tracking-[0.16px]  ">{toLakhCrText(calculator_data_contents?.data?.total_annuity || 0)}</h6>
                    </div>

                    <div className={`bg-primitive-BLUE-30 relative overflow-x-hidden w-[${lampsumWidth}%]  px-[16px]	py-[8px] rounded-r-xl `}
                        style={{ width: `${lampsumWidth}%` }}>
                        <div className="flex justify-between ">
                            <div className="border-b-2 border-white-300">
                                <h6 className="text-[12px]  md:text-sm lg:text-base font-bold	 text-white   tracking-[0.16px] ">{trailZero(calculator_data_contents?.data?.lumpsum_percent) || 0}%</h6>
                            </div>
                            <div className="border-b-2 border-white-300">
                                <h4 className="  text-[11px]  md:text-[14px]  lg:text-[15px] font-bold text-white   tracking-[0.16px] ">{toInrFormat(calculator_data_contents?.data?.lumpsum || 0)}</h4>
                            </div>
                        </div>
                        <h6 className="text-[13px]  md:text-base  lg:text-[15px] whitespace-nowrap		font-bold text-right text-white cavet_font mt-2 tracking-[0.16px]  ">{toLakhCrText(calculator_data_contents?.data?.lumpsum || 0)}</h6>
                    </div>
                </div>
            </div >
        </div>
    )
};

export default Annuity;