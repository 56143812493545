import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BlogFilter = ({ options, selected, setSelection = () => {} }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [title, setTitle] = useState('All');

  const toggleFilterMenu = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (selected?.key) {
      setTitle(selected?.title);
    }
  }, [selected]);

  const handleSelectClick = (index) => {
    if (index != null) {
      if (options[index]) {
        setSelection(options[index]);
        setShowDropdown(false);
      }
    }
  };

  return (
    <>
      <div className="relative">
        <button
          className="text-black w-full md:w-72 bg-white  dropd-shadow   font-medium rounded-full text-xs md:text-sm px-2 md:px-5 py-2.5 text-center inline-flex items-center "
          type="button"
          onClick={toggleFilterMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            className="mr-2"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3 7H6M6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4C7.34315 4 6 5.34315 6 7ZM3 17H9M18 17H21M18 17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17C12 15.3431 13.3431 14 15 14C16.6569 14 18 15.3431 18 17ZM15 7H21"
              stroke="#101C2D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          <div className="flex justify-between items-center w-full">
            {`Filter: ${title}`}
            <svg
              className={`${
                showDropdown ? 'rotate-180' : ''
              } w-2.5 h-2.5 ms-3 ml-3 transition-transform`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </div>
        </button>

        <div
          className={`${
            showDropdown
              ? 'max-h-auto ease-in delay-300'
              : 'max-h-0 overflow-hidden ease-out delay-200'
          } z-10 absolute w-full transition  bg-white divide-y divide-gray-100 rounded-lg shadow   `}
        >
          {options?.length && (
            <ul className="py-2 px-0 text-sm text-gray-700 dark:text-gray-200">
              {options?.map((object, index) => (
                <li
                  key={`options-${index}`}
                  onClick={() => handleSelectClick(index)}
                  className="block px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                >
                  {object?.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

BlogFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
};
export default BlogFilter;
