import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css';

// import home_hero_web from '../../../assets/images/grouppic.svg';
// import banner2 from '../../../assets/images/banner2.svg';
// import banner32 from '../../../assets/images/banner32.svg';
// import banner33 from '../../../assets/images/banner33.svg';
// import banner34 from '../../../assets/images/guywithfile.svg';
// import banner35 from '../../../assets/images/girlwithmbe.svg';
// import icon3 from '../../../assets/images/icons-3.svg';
// import icon4 from '../../../assets/images/icons-4.svg';

import { parseString, toLower } from '../../AppConstants';
import StyledButton from '../StyledButton/Index';

const BannerHome = ({ content }) => {
  const pagination = {
    clickable: true,
    bulletClass: 'bg-neutral-GRAY-200 w-3 h-3 rounded-full m-1',
    bulletActiveClass: '!bg-neutral-BLACK-DARK-400 w-8',
    bulletElement: 'button',
    wrapperClass: 'container',
  };

  return (
    <div className="relative w-full mt-[8rem] md:mt-32">
      <div className="relative h-[600px] md:h-[650px] rounded-lg bg-white shrink-0 banner-slider">
        <Swiper
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={1000}
          pagination={pagination}
        >
          {content?.data &&
            content?.data?.map((slide, index) => (
              <React.Fragment key={`home_banner_slide_${index}`}>
                {slide?.attributes?.internal_identifier ==
                  'nps_for_all_professions-home' && (
                  <SwiperSlide>
                    {slide?.attributes?.banner_elements?.map((item, idx) => (
                      <React.Fragment
                        key={`banner_home_slide_1_content_${idx}`}
                      >
                        {toLower(item?.position) == 'center' && (
                          <div
                            className={`flex items-center flex-col h-full  justify-center md:justify-start duration-700 px-5 py-20    lg:px-36 ease-in-out w-full bg-site-FFF0E3`}
                          >
                            <div className="pb-[45px]  pt-0 md:pb-0 ">
                              <div className="mt-8 mb-4 flex flex-col md:flex-row md:items-start justify-center text-v-slider h-36 ">
                                {item?.banner_heading && (
                                  <h1 className="text-neutral-BLACK-DARKER  main-slide-fontsize">
                                    {parseString(item?.banner_heading)}
                                  </h1>
                                )}
                                {item?.banner_slide_text && (
                                  <div className="sliders  ml-0 md:ml-3 h-12 md:h-auto overflow-hidden md:overflow-visible	">
                                    {item?.banner_slide_text?.map(
                                      (slide_text, index) => (
                                        <h1
                                          key={`slide_text_${index}`}
                                          className={`main-slide-fontsize slider-text-${
                                            index + 1
                                          } font-black lg:text-left md:text-center md:mr-2`}
                                        >
                                          {parseString(slide_text?.text)}
                                        </h1>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            {item?.media && (
                              <img
                                src={
                                  item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url
                                }
                                className="w-8/12 lg:w-[45rem] h-auto hidden md:block"
                                alt={
                                  item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.alternative_text
                                }
                              />
                            )}

                            {item?.ctas && (
                              <div className="flex flex-row items-center justify-center flex-wrap mt-5 gap-4">
                                {item?.ctas?.data?.map((cta, idx) => (
                                  <StyledButton
                                    cta={cta}
                                    key={`banner_slide_cta_${idx}`}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </SwiperSlide>
                )}

                {slide?.attributes?.internal_identifier ==
                  'plan_your_all_retirement_home' && (
                  <SwiperSlide>
                    <div
                      className={` px-2 md:px-20  pb-20 pt-3 md:pt-20 h-full flex items-center justify-center  bg-site-F8F8FF`}
                    >
                      <div className="grid grid-cols-1  container mx-auto sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
                        {slide?.attributes?.banner_elements?.map(
                          (item, idx) => (
                            <React.Fragment
                              key={`banner_home_slide_2_content_${idx}`}
                            >
                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex md:hidden justify-center px-5 py-2 lg:overflow-y-auto order-first">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-56"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}

                              {toLower(item?.position) == 'left' && (
                                <div className="w-full md:w-[500px] xl:w-full  flex flex-col lg:flex-row justify-center items-center  px-5 py-2  ">
                                  <div className="flex items-center justify-center flex-col md:items-start md:justify-start">
                                    {item?.banner_heading && (
                                      <h1 className=" text-2xl md:text-4xl font-52 text-left leading-[1em]	 lg:leading-[1em] ">
                                        {parseString(item?.banner_heading)}
                                      </h1>
                                    )}
                                    {item?.banner_description && (
                                      <div className=" text-xs	 md:text-lg border-l-4 pl-2 border-orange-500 font-normal  text-left text-gray-600 mb-8 mt-3">
                                        {parseString(item?.banner_description)}
                                      </div>
                                    )}

                                    {item?.ctas && (
                                      <div className="flex flex-row items-center flex-wrap justify-center mt-5 gap-4">
                                        {item?.ctas?.data?.map((cta, idx) => (
                                          <StyledButton
                                            cta={cta}
                                            key={`banner_slide_2_cta_${idx}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex-col items-end justify-center    hidden  md:flex     p-0  lg:overflow-y-auto">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-8/12 lg:w-[25rem]"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                )}

                {slide?.attributes?.internal_identifier ==
                  'secure_your_future_home' && (
                  <SwiperSlide>
                    <div
                      className={` px-2 md:px-20  pb-20 pt-3 md:pt-20 h-full flex items-center justify-center bg-site-F5F5DA `}
                    >
                      <div className="grid grid-cols-1  container mx-auto sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
                        {slide?.attributes?.banner_elements?.map(
                          (item, idx) => (
                            <React.Fragment
                              key={`banner_home_slide_3_content_${idx}`}
                            >
                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex md:hidden justify-center px-5 py-2 lg:overflow-y-auto order-first">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-56"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}

                              {toLower(item?.position) == 'left' && (
                                <div className="w-full md:w-[500px] xl:w-full  flex flex-col lg:flex-row justify-center items-center   px-5 py-2  ">
                                  <div className="lg:pt-10 pt-sm-9   flex items-center justify-center flex-col md:items-start md:justify-start">
                                    {item?.banner_heading && (
                                      <h1 className=" text-2xl md:text-4xl font-52  font-extrabold text-left md:text-left lg:text-left leading-[1em] lg:leading-[1em]">
                                        {parseString(item?.banner_heading)}
                                      </h1>
                                    )}
                                    {item?.banner_description && (
                                      <div className=" text-xs	 md:text-lg  text-left md:text-left lg:text-left font-normal  text-gray-600 mb-8   mt-6  ">
                                        {parseString(item?.banner_description)}
                                      </div>
                                    )}
                                    {item?.ctas && (
                                      <div className="flex flex-row items-center justify-center flex-wrap mt-5 gap-4">
                                        {item?.ctas?.data?.map((cta, idx) => (
                                          <StyledButton
                                            cta={cta}
                                            key={`banner_slide_3_cta_${idx}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex-col items-end justify-center    hidden  md:flex  p-0 lg:overflow-y-auto">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-8/12 lg:w-[25rem]"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                )}

                {slide?.attributes?.internal_identifier ==
                  'invest_in_your_future_home' && (
                  <SwiperSlide>
                    <div
                      className={` px-2  md:px-20 pb-20 pt-3 md:pt-20 h-full flex items-center justify-center bg-site-E8EBFF`}
                    >
                      <div className="grid grid-cols-1  container mx-auto sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
                        {slide?.attributes?.banner_elements?.map(
                          (item, idx) => (
                            <React.Fragment
                              key={`banner_home_slide_4_content_${idx}`}
                            >
                              {toLower(item?.position) == 'right' && (
                                <div className="w-full    flex md:hidden   justify-center        px-5 py-2 lg:overflow-y-auto order-first">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-56"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}

                              {toLower(item?.position) == 'left' && (
                                <div className="w-full md:w-[500px] xl:w-full  flex flex-col lg:flex-row justify-center items-center   px-5 py-2  ">
                                  <div className="lg:pt-10 pt-sm-9   flex items-center justify-center flex-col md:items-start md:justify-start">
                                    {item?.banner_heading && (
                                      <h1 className="text-2xl	md:text-4xl  font-52  font-extrabold text-left   leading-[1em] lg:leading-[1em] ">
                                        {parseString(item?.banner_heading)}
                                      </h1>
                                    )}

                                    {item?.banner_description && (
                                      <div className=" text-xs	 md:text-lg border-l-4 pl-2 border-red-500  font-normal   text-left   text-gray-600 mb-8   mt-6  ">
                                        {parseString(item?.banner_description)}
                                      </div>
                                    )}

                                    {item?.ctas && (
                                      <div className="flex flex-row items-center justify-center flex-wrap mt-5 gap-4">
                                        {item?.ctas?.data?.map((cta, idx) => (
                                          <StyledButton
                                            cta={cta}
                                            key={`banner_slide_4_cta_${idx}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex-col items-end justify-center    hidden  md:flex    p-0  lg:overflow-y-auto">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-8/12 lg:w-[25rem] "
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                )}

                {slide?.attributes?.internal_identifier ==
                  'focus_on_your_present_home' && (
                  <SwiperSlide>
                    <div
                      className={`px-2 md:px-20  pb-20 pt-3 md:pt-20 h-full flex items-center justify-center bg-site-F4F4F4`}
                    >
                      <div className="grid grid-cols-1  container mx-auto sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
                        {slide?.attributes?.banner_elements?.map(
                          (item, idx) => (
                            <React.Fragment
                              key={`banner_home_slide_4_content_${idx}`}
                            >
                              {toLower(item?.position) == 'right' && (
                                <div className="w-full    flex md:hidden   justify-center        px-5 py-2 lg:overflow-y-auto order-first">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-56"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}

                              {toLower(item?.position) == 'left' && (
                                <div className="w-full md:w-[500px] xl:w-full  flex flex-col lg:flex-row justify-center items-center   px-5 py-2  ">
                                  <div className="lg:pt-10 pt-sm-9   flex items-center justify-center flex-col md:items-start md:justify-start">
                                    {item?.banner_heading && (
                                      <h1 className="text-2xl	md:text-4xl  font-52  font-extrabold text-left   leading-[1em] lg:leading-[1em] ">
                                        {parseString(item?.banner_heading)}
                                      </h1>
                                    )}

                                    {item?.banner_description && (
                                      <div className=" text-xs	 md:text-lg  font-normal  text-left  text-gray-600 mb-8 mt-6">
                                        {parseString(item?.banner_description)}
                                      </div>
                                    )}

                                    {item?.ctas && (
                                      <div className="flex flex-row items-center justify-center flex-wrap mt-5 gap-4">
                                        {item?.ctas?.data?.map((cta, idx) => (
                                          <StyledButton
                                            cta={cta}
                                            key={`banner_slide_4_cta_${idx}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex-col items-end justify-center    hidden  md:flex    p-0  lg:overflow-y-auto">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-8/12 lg:w-[25rem] "
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                )}

                {slide?.attributes?.internal_identifier ==
                  'lead_your_followers_home' && (
                  <SwiperSlide>
                    <div
                      className={`bg-site-FFEAEA px-2  md:px-20 pb-20 pt-3 md:pt-20 h-full flex items-center justify-center `}
                    >
                      <div className="grid grid-cols-1  container mx-auto sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  gap-0  md:gap-20">
                        {slide?.attributes?.banner_elements?.map(
                          (item, idx) => (
                            <React.Fragment
                              key={`banner_home_slide_4_content_${idx}`}
                            >
                              {toLower(item?.position) == 'right' && (
                                <div className="w-full    flex md:hidden   justify-center        px-5 py-2 lg:overflow-y-auto order-first">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-56"
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}

                              {toLower(item?.position) == 'left' && (
                                <div className="w-full md:w-[500px] xl:w-full  flex flex-col lg:flex-row justify-center items-center   px-5 py-2  ">
                                  <div className="lg:pt-10 pt-sm-9   flex items-center justify-center flex-col md:items-start md:justify-start">
                                    {item?.banner_heading && (
                                      <h1 className="text-2xl	md:text-4xl  font-52  font-extrabold text-left   leading-[1em] lg:leading-[1em] ">
                                        {parseString(item?.banner_heading)}
                                      </h1>
                                    )}

                                    {item?.banner_description && (
                                      <div className=" text-xs	 md:text-lg  font-normal  text-left  text-gray-600 mb-8 mt-6">
                                        {parseString(item?.banner_description)}
                                      </div>
                                    )}

                                    {item?.ctas && (
                                      <div className="flex flex-row items-center justify-center flex-wrap mt-5 gap-4">
                                        {item?.ctas?.data?.map((cta, idx) => (
                                          <StyledButton
                                            cta={cta}
                                            key={`banner_slide_4_cta_${idx}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {toLower(item?.position) == 'right' && (
                                <div className="w-full flex-col items-end justify-center    hidden  md:flex    p-0  lg:overflow-y-auto">
                                  {item?.media?.data?.attributes?.media?.data
                                    ?.attributes?.url && (
                                    <img
                                      className="w-8/12 lg:w-[25rem] "
                                      src={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.url
                                      }
                                      alt={
                                        item?.media?.data?.attributes?.media
                                          ?.data?.attributes?.alternative_text
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </React.Fragment>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BannerHome;
