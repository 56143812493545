import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '../common/layout/Menu';
import Footer from '../common/layout/Footer';
// import MenuDynamic from '../common/layout/MenuDynamic';
// import FooterDynamic from '../common/layout/FooterDynamic';

// VIEWS
import HomeInvestNpsCopy from '../common/components/Cards/HomeInvestNpsCopy';
import HomeNpsCopy from '../common/components/Cards/HomeNpsCopy';
import HomeBenefitsCopy from '../common/components/Cards/HomeBenefitsCopy';
import HomeNpsWorksCopy from '../common/components/Cards/HomeNpsWorksCopy';
import ArticleNewsCopy from '../common/components/Cards/ArticleNewsCopy';
import FaqCopy from '../common/components/Accordions/FaqCopy';

// import AutoSlider from '../common/components/Sliders/AutoSlider';
import { fetchHome } from '../redux/actions/PageAction';
import { showPageLoader, resetPageLoader } from '../redux/actions/CommonAction';
import HomeInvestWhy from '../common/components/Cards/HomeInvestWhy';
// import HomeSlider from '../common/components/Sliders/HomeSlider';
import BannerHome from '../common/components/Banners/BannerHome';
import HomeCalculator from '../common/components/Calculator/HomeCalculator';
import { toLower } from '../common/AppConstants';

// import RetirementPlanner from "../common/components/Plain/RetirementPlanner";
import { Helmet } from 'react-helmet';

const Home = () => {
  const [content, setContent] = useState({});
  const [body, setBody] = useState({});
  // Redux
  const dispatch = useDispatch();
  const { home_contents } = useSelector((state) => state.pageState);

  useEffect(() => {
    if (home_contents == null) {
      dispatch(fetchHome({}));
      dispatch(showPageLoader());
    }
  }, [home_contents, body]);

  useEffect(() => {
    if (home_contents) {
      setContent(home_contents?.data ? home_contents?.data[0] : {});
      setBody(
        home_contents?.data[0]?.attributes?.page_body_elements?.data
          ? home_contents?.data[0]?.attributes?.page_body_elements?.data[0]
          : {}
      );

      dispatch(resetPageLoader());
    }
  }, [home_contents]);


  //Calculator
  const [calcAPI, setCalcAPI] = useState(false);

  const [activeStep, setStep] = useState(1);
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(0);
  const [retrMinAge, setRetMinAge] = useState(0);
  const [retrMaxAge, setRetMaxAge] = useState(0);

  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');

  const [currentAgeDefault, setCurrentAgeDefault] = useState('');
  const [retirementAgeDefault, setRetirementAgeDefault] = useState('');

  const [gender, setGender] = useState(true);//Female;
  const [calculationType, setCalculationType] = useState('');

  //Step 2
  const [iwanttoinvest, setIwantToInvest] = useState('');
  const [iwanttoinvestMin, setIwantToInvestMin] = useState('');

  const [iwanttoinvestMode, setIwantToInvestMode] = useState('month');
  const [annualincrementof, setAnnualIncrementOf] = useState('');

  const [investmentstrategy, setInvestmentStrategy] = useState('');
  const [expectedrateofreturn, setExpectedRateofReturn] = useState('');
  const [expectedrateofreturnEdit, setExpectedRateofReturnEdit] = useState(false);

  const [iwanttotalfundsof, setIwanttotalFundsOf] = useState('500');
  const [pensionof, setPensionOf] = useState('500');
  const [pensionofMode, setPensionOfMode] = useState('month');

  const [anyotherschemes, setAnyOtherSchemes] = useState(false); //NO

  const [any_other_MutualFunds, setAnyOtherMutualFunds] = useState('');
  const [any_other_epf, setAnyOtherEpf] = useState('');
  const [any_other_schemes, setAnyOtherSchemesInput] = useState('');

  const [currentImage, setCurrentImage] = useState('');
  const [retireImage, setRetireImage] = useState('');

  const [rateofannuty, setRateofAnnuty] = useState(4);

  const [annutyPercentage, setAnnutyPercentage] = useState(40);
  const [lampsumPercentage, setLampsumPercentage] = useState('');

  const setImageSrc = () => {
    if (body?.attributes?.page_body_cmp) {
      body?.attributes?.page_body_cmp.map((object) => {
        if (object?.internal_identifier === 'retirement_planner') {
          let contents = object || {};

          if (gender == true) {
            if (contents?.retirement_planners) {
              contents?.retirement_planners?.data?.map((obj) => {
                if (toLower(obj?.attributes?.Gender) == "female") {
                  if (obj?.attributes?.current_age_image) {
                    setCurrentImage(obj?.attributes?.current_age_image?.data?.attributes?.url);
                  }
                  if (obj?.attributes?.retire_age_image) {
                    setRetireImage(obj?.attributes?.retire_age_image?.data?.attributes?.url);
                  }
                }
              });
            }
          }

          if (gender == false) {
            if (contents?.retirement_planners) {
              contents?.retirement_planners?.data?.map((obj) => {
                if (toLower(obj?.attributes?.Gender) == "male") {
                  if (obj?.attributes?.current_age_image) {
                    setCurrentImage(obj?.attributes?.current_age_image?.data?.attributes?.url);
                  }
                  if (obj?.attributes?.retire_age_image) {
                    setRetireImage(obj?.attributes?.retire_age_image?.data?.attributes?.url);
                  }
                }
              });
            }
          }
        }
      }
      )
    }
  };

  useEffect(() => {
    setImageSrc();
  }, [gender])

  useEffect(() => {
    if (body?.attributes?.page_body_cmp) {
      body?.attributes?.page_body_cmp.map((object) => {
        if (object?.internal_identifier === 'retirement_planner') {
          let contents = object || {};

          if (contents) {
            if (contents?.retirement_planners?.data) {
              contents?.retirement_planners?.data?.map((obj) => {
                if (toLower(obj?.attributes?.Gender) == "female" && gender == true) {

                  if (obj?.attributes?.current_age_detail) {
                    setMinAge(obj?.attributes?.current_age_detail?.min);
                    setMaxAge(obj?.attributes?.current_age_detail?.max);
                    setCurrentAge(obj?.attributes?.current_age_detail?.default)
                    setCurrentAgeDefault(obj?.attributes?.current_age_detail?.default)
                  }

                  if (obj?.attributes?.retire_age) {
                    setRetMinAge(obj?.attributes?.retire_age[0]?.min);
                    setRetMaxAge(obj?.attributes?.retire_age[0]?.max);
                    setRetirementAge(obj?.attributes?.retire_age[0]?.default)
                    setRetirementAgeDefault(obj?.attributes?.retire_age[0]?.default)
                  }
                }

                if (toLower(obj?.attributes?.Gender) == "male" && gender == false) {

                  if (obj?.attributes?.current_age_detail) {
                    setMinAge(obj?.attributes?.current_age_detail?.min);
                    setMaxAge(obj?.attributes?.current_age_detail?.max);
                    setCurrentAge(obj?.attributes?.current_age_detail?.default)
                    setCurrentAgeDefault(obj?.attributes?.current_age_detail?.default)
                  }

                  if (obj?.attributes?.retire_age) {
                    setRetMinAge(obj?.attributes?.retire_age[0]?.min);
                    setRetMaxAge(obj?.attributes?.retire_age[0]?.max);
                    setRetirementAge(obj?.attributes?.retire_age[0]?.default);
                    setRetirementAgeDefault(obj?.attributes?.retire_age[0]?.default);
                  }
                }
              });
            }

            if (contents?.pension_after_retirement) {
              if (contents?.pension_after_retirement?.invest_amt) {
                setIwantToInvest(contents?.pension_after_retirement?.invest_amt || '')
                setIwantToInvestMin(contents?.pension_after_retirement?.invest_amt || 500);
              }
              if (contents?.pension_after_retirement?.annuity_rate)
                setAnnualIncrementOf(contents?.pension_after_retirement?.annuity_rate || 5);
            }

            if (contents?.how_to_invest) {
              if (contents?.how_to_invest?.lumpsum_amt) {
                setPensionOf(contents?.how_to_invest?.lumpsum_amt || '500');
              }
            }
          }
        }
      });

      setImageSrc();
    }
  }, [body?.attributes?.page_body_cmp])

  useEffect(() => {
    if (investmentstrategy) {
      if (investmentstrategy == 'equity') {
        setExpectedRateofReturn('15.26');
        setExpectedRateofReturnEdit(false);
      }
      if (investmentstrategy == 'corporate') {
        setExpectedRateofReturn('9.19');
        setExpectedRateofReturnEdit(false);
      }
      if (investmentstrategy == 'gov') {
        setExpectedRateofReturn('9.46');
        setExpectedRateofReturnEdit(false);
      }
      if (investmentstrategy == 'custom') {
        setExpectedRateofReturnEdit(true);
      }
    }
  }, [investmentstrategy])

  useEffect(() => {
    if (anyotherschemes == false) {
      setAnyOtherMutualFunds('');
      setAnyOtherEpf('');
      setAnyOtherSchemesInput('');
    }
  }, [anyotherschemes])

  const resetAll = () => {

    setCurrentAge(currentAgeDefault);
    setRetirementAge(retirementAgeDefault);

    setGender(true);//Female;
    setCalculationType('');

    setIwantToInvest('');
    setIwantToInvestMin('');
    setIwantToInvestMode('month');
    setAnnualIncrementOf('');
    setInvestmentStrategy('');
    setIwanttotalFundsOf('');
    setPensionOf('');
    setPensionOfMode('month');
    setAnyOtherSchemes(false);
    setExpectedRateofReturn('');
    setExpectedRateofReturnEdit(false);
    setAnyOtherMutualFunds('');
    setAnyOtherEpf('');
    setAnyOtherSchemesInput('');
    setStep(1);
    setAnnutyPercentage(40);
    setLampsumPercentage('');
    setRateofAnnuty(4);
  };
  //Calculator

  return (
    <>
      {content?.attributes?.meta_title ? (
        <>
          <Helmet>
            <title>{content?.attributes?.meta_title}</title>
            {content?.attributes?.meta_description && <meta name="description" content={content?.attributes?.meta_description} />}
            {content?.attributes?.meta_keywords && <meta name="keywords" content={content?.attributes?.meta_keywords} />}
          </Helmet>
        </>) : null}

      {/* NAVBAR SECTION */}
      <Menu key="home-menu" contents={content || null} showScroller={true} />
      {/* NAVBAR SECTION */}

      {/* Page Banner */}
      {home_contents?.data[0]?.attributes?.page_banners && (
        <BannerHome
          content={home_contents?.data[0]?.attributes?.page_banners}
        />
      )}
      {/* Page Banner */}

      {body?.attributes?.page_body_cmp &&
        body?.attributes?.page_body_cmp.map((object, index) => (
          <div key={`home_page_body_${index}`}>
            {object?.internal_identifier === 'how_do_i_invest' && (
              <HomeInvestNpsCopy
                contents={object}
                key={`${Date.now()}-${index}`}
              />
            )}
            {object?.internal_identifier == 'why_invest_with_ICICI' && (
              <HomeInvestWhy contents={object} key={`${Date.now()}-${index}`} />
            )}

            {/* Calculator */}
            {object?.internal_identifier === 'retirement_planner' && (
              <HomeCalculator
                key={index}
                contents={object}
                apiCall={calcAPI}
                setApiCall={setCalcAPI}
                activeStep={activeStep} setStep={setStep}
                minAge={minAge} setMinAge={setMinAge}
                maxAge={maxAge} setMaxAge={setMaxAge}
                retrMinAge={retrMinAge} setRetMinAge={setRetMinAge}
                retrMaxAge={retrMaxAge} setRetMaxAge={setRetMaxAge}
                currentAge={currentAge} setCurrentAge={setCurrentAge}
                retirementAge={retirementAge} setRetirementAge={setRetirementAge}
                gender={gender} setGender={setGender}
                calculationType={calculationType} setCalculationType={setCalculationType}
                iwanttoinvest={iwanttoinvest} setIwantToInvest={setIwantToInvest}
                iwanttoinvestMin={iwanttoinvestMin} setIwantToInvestMin={setIwantToInvestMin}
                iwanttoinvestMode={iwanttoinvestMode} setIwantToInvestMode={setIwantToInvestMode}
                annualincrementof={annualincrementof} setAnnualIncrementOf={setAnnualIncrementOf}
                investmentstrategy={investmentstrategy} setInvestmentStrategy={setInvestmentStrategy}
                expectedrateofreturn={expectedrateofreturn} setExpectedRateofReturn={setExpectedRateofReturn}
                expectedrateofreturnEdit={expectedrateofreturnEdit} setExpectedRateofReturnEdit={setExpectedRateofReturnEdit}
                iwanttotalfundsof={iwanttotalfundsof} setIwanttotalFundsOf={setIwanttotalFundsOf}
                pensionof={pensionof} setPensionOf={setPensionOf}
                pensionofMode={pensionofMode} setPensionOfMode={setPensionOfMode}
                anyotherschemes={anyotherschemes} setAnyOtherSchemes={setAnyOtherSchemes}
                any_other_MutualFunds={any_other_MutualFunds} setAnyOtherMutualFunds={setAnyOtherMutualFunds}
                any_other_epf={any_other_epf} setAnyOtherEpf={setAnyOtherEpf}
                any_other_schemes={any_other_schemes} setAnyOtherSchemesInput={setAnyOtherSchemesInput}
                resetAll={resetAll}
                currentImage={currentImage}
                retireImage={retireImage}
                annutyPercentage={annutyPercentage}
                lampsumPercentage={lampsumPercentage}
                setAnnutyPercentage={setAnnutyPercentage}
                setLampsumPercentage={setLampsumPercentage}
                rateofannuty={rateofannuty}
                setRateofAnnuty={setRateofAnnuty}
              />)
            }

            {object?.internal_identifier == 'what_is_nps' && (
              <HomeNpsCopy contents={object} key={`${Date.now()}-${index}`} />
            )}
            {object?.internal_identifier == 'what_benefits_nps_offers' && (
              <HomeBenefitsCopy
                contents={object}
                key={`${Date.now()}-${index}`}
              />
            )}
            {object?.internal_identifier == 'how_nps_works' && (
              <HomeNpsWorksCopy
                contents={object}
                key={`${Date.now()}-${index}`}
              />
            )}
            {object?.internal_identifier == 'articles' && (
              <ArticleNewsCopy
                contents={object}
                key={`${Date.now()}-${index}`}
              />
            )}
            {object?.internal_identifier == 'faq' && (
              <FaqCopy contents={object} key={`${Date.now()}-${index}`} />
            )}
          </div>
        ))}

      {/* FOOTER */}
      <Footer key="home-footer" />
      {/* FOOTER */}
    </>
  );
};

export default Home;
