import React from 'react';

// constants
import { parseString, toLower } from '../../AppConstants';

// images
import sitemap_top_bg from '../../../assets/images/sitemap-bg-top.svg';
import sitemap_bg_vector from '../../../assets/images/sitemap-bg-vector.svg';

// components
import BreadCrumb from '../Navigations/BreadCrumb';
import { bc_sitemap } from '../../layout/breadcrumb-data';

const BannerSitemap = ({ content }) => {
  return (
    <div className="  bg-site-FFF0E3 relative p-5 xl:p-5 overflow-hidden">
      <img
        className="absolute max-md:w-52 left-0 top-20 lg:top-24 xl:top-28"
        src={sitemap_top_bg}
      />
      <img
        className="absolute w-28 top-20 md:w-93 md:top-20  xl:top-28 right-0 "
        src={sitemap_bg_vector}
      />

      <div className="container mx-auto pt-6 sm:px-2 md:px-8 self-start mt-20 xl:mt-20">
        <div className="mb-4">
          <BreadCrumb items={bc_sitemap} />
        </div>
      </div>

      <div className="container mx-auto lg:pt-0 lg:p-8 banner-border-custom text md:p-8 sm:p-2 flex flex-col ">
        <div className="grid grid-cols-1 sm:grid-cols-1">
          {content?.data?.[0]?.attributes?.banner_elements?.map(
            (item, index) => (
              <React.Fragment key={`banner_sitemap_content_${index}`}>
                {toLower(item?.position) == 'center' && (
                  <div key={`content_center_${index}`} className="w-full ">
                    {item?.banner_heading && (
                      <h1 className="text-primary text-xl  md:text-2xl lg:text-4xl  font-bold lg:mb-4 md:mb-0 text-center">
                        {parseString(item?.banner_heading)}
                      </h1>
                    )}
                    {item?.banner_description && (
                      <div className="text-sm  md:text-base lg:text-lg text-center mb-8 ">
                        {parseString(item?.banner_description)}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerSitemap;
