import React, { useEffect, useState } from 'react';

import dm_icon from '../../../assets/images/downlaodicon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNavLatest } from '../../../redux/actions/PageAction';
import TableDisclosure from '../Tables/TableDisclosure';
import { toSpecialDate } from '../../AppConstants';
import ButtonDownload from '../StyledButton/ButtonDownload';

const LatestNav = ({ contents }) => {
    const { REACT_APP_STRAPI_API_URL } = process.env;

    // Redux
    const dispatch = useDispatch();
    const { latest_nav_contents } = useSelector((state) => state.pageState);
    //
    const [disDate, setDispDate] = useState('');
    const [files, setFiles] = useState();
    const [tableHeaders, setTableHeaders] = useState();
    const [tableCols, setTableCols] = useState();
    const [fileCaption, setCaption] = useState();

    useEffect(() => {
        if (contents) {
            let ds = contents?.page_body?.data?.attributes?.page_body_cmp[0];
            if (ds) {
                setFiles(ds?.files_included);
                setTableHeaders(ds?.dynamic_tables?.data[0]?.attributes?.table_headers);
                setTableCols(ds?.dynamic_tables?.data[0]?.attributes?.colspan_text);
                setCaption(ds?.dynamic_tables?.data[0]?.attributes?.table_dynamic_data?.filename);
            }
        }
        dispatch(fetchNavLatest());
    }, [])

    useEffect(() => {
        if (latest_nav_contents) {
            setDispDate(toSpecialDate(latest_nav_contents?.date, 'DD-MMM-YY'));
        }
    }, [latest_nav_contents])

    const onDownloadClick = () => {
        let file_url = `${REACT_APP_STRAPI_API_URL}/download`;
        let fileName = files?.data[0]?.attributes?.name;

        fetch(file_url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                console.log("Unable to download file");
            });

    }


    return (
        <div className='  p-[8px]  lg:p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
            <div className="main-bg">
                <div className=" flex flex-col lg:flex-col justify-between">
                    <div>
                        <div className="bg-white text-white rounded-lg card-shadow-custom border border-grey-neutral">
                            <div className="flex flex-col lg:flex-row justify-between ">
                                <div className="bg-primitive-BLUE-100   py-5 lg:py-5 px-5 w-full lg:w-80 flex flex-col md:flex-row lg:flex-col  justify-between lg:rounded-l-lg rounded-t-lg lg:rounded-[0px]">
                                    <div className='flex items-center lg:block'>
                                        <h5 className="text-[14px]   lg:text-[20px] text-primary-BLACK text-left whitespace-nowrap font-normal">
                                            Current NAV
                                        </h5>
                                        <h2 className='text-[14px] lg:text-[20px] font-bold mt-0 lg:mt-2 ml-2 lg:ml-0'>({disDate})</h2>
                                    </div>
                                    {
                                        fileCaption && files && <ButtonDownload text={fileCaption} onClickEvent={onDownloadClick} icon={dm_icon} />
                                    }
                                </div>

                                <div className="w-full overflow-x-auto">
                                    {
                                        latest_nav_contents && (
                                            <TableDisclosure
                                                headers={tableHeaders}
                                                colspan={tableCols}
                                                contents={latest_nav_contents} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default LatestNav;