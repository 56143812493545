
import React, { forwardRef } from "react";
// import { dateCustom } from "../../AppConstants";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerView = ({ selectedDate, setSelectedDate = () => { }, maxDate = null }) => {

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const RoundedInput = forwardRef(({ value, onClick }, ref) => (
        <button className="text-black w-[fit-content]  
        bg-white dropd-shadow 
        font-medium rounded-full text-[12px]  lg:text-[14px]  	  
        px-2 md:px-5 py-1.5 text-center inline-flex items-center" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    RoundedInput.displayName = 'MyDatePicker';

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText=""
            maxDate={maxDate || null}
            customInput={<RoundedInput />}
            popperPlacement="bottom"
        />
    )
}

export default DatePickerView;