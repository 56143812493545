import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Area,
    XAxis,
    YAxis,
    ResponsiveContainer,
    AreaChart,
} from "recharts";

import { toLakhCr, toLakhCrShort } from "../../AppConstants";

const CalculatorChart = () => {
    // Redux
    const { calculator_data_contents } = useSelector((state) => state.pageState);
    const [pointData, setPointData] = useState([]);

    const toLabel = (value) => {
        if (value == 'lastmonth')
            return '';
        else if (value <= 0)
            return '';
        else
            return `${value} Y`;
    };

    useEffect(() => {
        if (calculator_data_contents?.graphdata) {
            const chartData = [];
            calculator_data_contents?.graphdata?.map((obj, index) => {
                chartData.push({
                    key: index + 1,
                    xlabel: toLabel(obj?.index),
                    wg: toLakhCrShort(obj?.value?.fund_after_contrib || 0),
                    ti: toLakhCrShort(obj?.value?.invested || 0),
                    wgNc: obj?.value?.fund_after_contrib || 0,
                    tiNc: obj?.value?.invested || 0,
                    wgS: toLakhCr(obj?.value?.fund_after_contrib || 0),
                    tiS: toLakhCr(obj?.value?.invested || 0),
                });
            });
            setPointData(chartData);
        }

    }, [calculator_data_contents])

    // Custom tick formatter for the Y-axis
    const yTickFormatter = (tick) => {
        return toLakhCrShort(tick, 0);
    };

    return (
        <ResponsiveContainer width="100%">
            {/* ComposedChart */}
            <AreaChart
                width={500}
                height={400}
                data={pointData}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                }}
            >
                {/* <XAxis name="xlabel" />
                <YAxis name="ylabel" /> */}
                <XAxis dataKey="xlabel" domain={['dataMin', 'dataMax']} />
                {/* <YAxis tickFormatter={yTickFormatter} domain={['dataMin', 'dataMax']} /> */}
                <YAxis tickFormatter={yTickFormatter} />

                {/* <Tooltip /> */}
                {/* <Legend /> */}
                <Area type="monotone" dataKey="wgNc" fill="#85B0FE" stroke="#85B0FE">
                </Area>
                <Area type="monotone" dataKey="tiNc" fill="#CCDEFF" stroke="#CCDEFF" >
                </Area>
            </AreaChart>
        </ResponsiveContainer >
    )
};

export default CalculatorChart;