
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { parseString, toLower } from '../../AppConstants';
import InvestmentManagementFee from '../DisclosureComponents/InvestmentManagementFee';
import GroupCompanyList from '../DisclosureComponents/GroupCompanyList';
import FinancialReports from '../DisclosureComponents/FinanicialReports';
import Policy from '../DisclosureComponents/Policy';
import ProxyVotingDetails from '../DisclosureComponents/ProxyVotingDetails';
import PortfolioDetails from '../DisclosureComponents/PortfolioDetails';
import RegularCirculars from '../DisclosureComponents/RegularityCirculars';
import RiskMeter from '../DisclosureComponents/RiskMeter';
import NavHistoryDisclosure from '../DisclosureComponents/NavHistoryDisclosure';
import LatestNav from '../DisclosureComponents/LatestNav';
import { useDispatch } from 'react-redux';
import { resetCommFile } from '../../../redux/actions/CommonAction';

const AccordionPub = ({ contents, activeItem }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [dataItem, setDataItem] = useState();
    const autoScrollRef = Array.from({ length: contents?.tab_element?.length || 0 }, () => useRef(null));

    // Redux
    const dispatch = useDispatch();

    useEffect(() => {
        if (contents) {
            if (contents?.tab_element) {
                let dataset = [];
                contents?.tab_element?.map((object, index) => {
                    dataset.push({
                        index: index,
                        title: object?.tab_text,
                        content: object,
                        key: object?.internal_identifier
                    });
                });
                setDataItem(dataset);
            }
        }
    }, [])

    useEffect(() => {
        if (activeItem) {
            if (dataItem) {
                dataItem?.map((object, index) => {
                    if (toLower(object?.key) == toLower(activeItem)) {
                        setActiveIndex(index);
                        autoScrollRef[index].current.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            }
        }
    }, [activeItem, dataItem])

    const handleClick = (index) => {
        dispatch(resetCommFile());
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="mb-4 mt-10 container">
            {dataItem?.map((item, index) => (
                <div
                    key={`acco-${index}`}
                    ref={autoScrollRef[index]}
                    className="bg-white rounded-xl w-500 mb-4 border border-gray-300 focus:outline-none transition ease-in-out delay-150"
                >
                    <div className="cursor-pointer" onClick={() => handleClick(index)}>
                        <div className={`sector flex justify-between items-center gap-4  px-[27px] py-[8px] md:py-[10px] rounded-t-xl ${activeIndex === index ? 'bg-primitive-BLUE-100' : ''
                            }`}>
                            <h2 className={`text-[14px] md:text-[18px] lg:text-[20px] text-left   font-bold ${activeIndex === index ? 'text-BLACK' : ''
                                }`}>
                                {parseString(item.title)}
                            </h2>
                            <div className="w-4 h-3 transition-transform  flex justify-center items-center">
                                {activeIndex === index ? (
                                    <UpArrowIcon />
                                ) : (
                                    <DownArrowIcon />
                                )}
                            </div>
                        </div>
                    </div>
                    {activeIndex === index && item?.key == 'financial_reports' && <FinancialReports contents={item?.content} />}
                    {activeIndex === index && item?.key == 'group_companies_list' && <GroupCompanyList contents={item?.content} />}
                    {activeIndex === index && item?.key == 'investment_management_fee' && <InvestmentManagementFee contents={item?.content} />}
                    {activeIndex === index && item?.key == 'latest_nav' && <LatestNav contents={item?.content} />}
                    {activeIndex === index && item?.key == 'nav_history' && <NavHistoryDisclosure contents={item?.content} />}
                    {activeIndex === index && item?.key == 'policy' && <Policy contents={item?.content} />}
                    {activeIndex === index && item?.key == 'proxy_voting_details' && <ProxyVotingDetails contents={item?.content} />}
                    {activeIndex === index && item?.key == 'portfolio_details' && <PortfolioDetails contents={item?.content} />}
                    {activeIndex === index && item?.key == 'regulatory_circulars' && <RegularCirculars contents={item?.content} />}
                    {activeIndex === index && item?.key == 'risk_o_meter' && <RiskMeter contents={item?.content} />}
                </div>
            ))}
        </div>
    );
};

AccordionPub.propTypes = {
    contents: PropTypes.object,
};

const UpArrowIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 15l7-7 7 7"
        />
    </svg>
);

const DownArrowIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
        />
    </svg>
);

export default AccordionPub;
