import React, { useEffect } from "react";
import DownloadCardDisclosureSecond from "../Downloaders/DownloadCardDisclosureSecond";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupCompanyFiles } from "../../../redux/actions/PageAction";
import { resetCommFile } from "../../../redux/actions/CommonAction";


const GroupCompanyList = ({ contents }) => {
    // Redux
    const dispatch = useDispatch();
    const { group_company_files } = useSelector((state) => state.pageState);

    useEffect(() => {
        dispatch(resetCommFile());
    }, [])

    useEffect(() => {
        dispatch(fetchGroupCompanyFiles('group-companies'));
    }, [contents])

    return (
        <div className='p-[8px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
            <div className="grid grid-cols-1   sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2  md:gap-3 max-h-72 overflow-y-scroll">
                {
                    group_company_files?.data?.data?.map((obj, index) => (
                        <DownloadCardDisclosureSecond key={`dlc-${index}`}
                            heading={obj?.attributes?.file_display_name}
                            contents={obj} />
                    ))
                }
            </div>
        </div>
    )
}

export default GroupCompanyList;