import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const CalenderDisclosure = ({ data }) => {
  const [pointData, setData] = useState([]);

  // // Custom CSS styles
  // const toolbarStyle = {
  //   display: 'flex',
  //   justifyContent: 'flex-start'
  // };

  useEffect(() => {
    if (data) {
      let dataSet = [];
      data?.map((obj) => {
        dataSet.push(
          {
            title: obj?.value ? `₹ ${(obj?.value.toFixed(4))}` : '',
            start: obj?.date,
            end: obj?.date
          }
        )
      });

      setData(dataSet);
    }
  }, [data])

  // Function to determine if the given date is Saturday or Sunday
  // const isWeekend = (date) => {
  //   const day = date.getDay();
  //   return day === 0 || day === 6;
  // };

  //Custom event class names function to add a class for weekends
  // const eventClassNames = ({ event }) => {
  //   if (isWeekend(new Date(event.start))) {
  //     return 'weekend-event';
  //   }
  //   return '';
  // };


  const EventContent = (arg) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="fc-content" style={{ flexGrow: '1' }}>
          {arg.timeText && (
            <div className="fc-time">{arg.timeText}</div>
          )}
          <div className="fc-title text-primary-BLUE-500 
         font-bold align-text-bottom text-base">{arg.event.title}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="border  border-gray-300 p-[24px] mt-5 rounded-[12px] min-h-max">

      <div className="calendar-container relative">
        <div className="text-primary-BLUE-500 bg-site-F0F5FF fund-n-label font-extrabold  
        text-[14px] px-3 py-1 absolute right-0 rounded-[17px] mt-3">Fund NAV(₹)</div>

        {pointData && (<FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={pointData}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'next'
          }}
          firstDay={1} // Monday as the first day of the week
          // eventClassNames={eventClassNames}
          // customViewRender={(view, element) => {
          //   const toolbar = element.querySelector('.fc-header-toolbar');
          //   Object.assign(toolbar.style, toolbarStyle);
          // }}
          className='responsive-calendar' // added class name for targeting in CSS
          eventDisplay='block'
          eventContent={EventContent}
        />)}
      </div>
    </div>
  );
}

export default CalenderDisclosure;
