import React, { useEffect, useState } from "react";
import AccordionCopy from "../Accordions/AccordionCopy";
import ProxyYearEnd from "./ProxyYearEnd";

const ProxyVotingAccordion = ({ year, contents }) => {
    const [dataItem, setDataItem] = useState();
    const getContent = (data) => {
        return <ProxyYearEnd contents={data} />
    }

    useEffect(() => {
        if (contents) {
            let dataset = [];
            dataset.push({
                index: 1,
                title: `Year ended Mar 31, ${year + 1}`,
                content: getContent(contents),
            });
            setDataItem(dataset);
        }
    }, [contents])

    return (<AccordionCopy items={dataItem} />)
};

export default ProxyVotingAccordion;