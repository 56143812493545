import React, { useEffect, useState } from "react";

import DownloadCardDisclosureSecond from "../Downloaders/DownloadCardDisclosureSecond";

import ProxyVotingAccordion from "./ProxyVotingAccordion";
import { fetchProxyVoting } from "../../../redux/actions/PageAction";
import { useDispatch, useSelector } from "react-redux";
import SelectPlusMinus from "../DropDown/SelectPlusMinus";
import { IsEmpty } from "../../AppConstants";

const ProxyVotingDetails = ({ contents }) => {
  // Redux
  const dispatch = useDispatch();
  const { proxy_voting_contents } = useSelector((state) => state.pageState);

  const [quarter, setQuarter] = useState();
  const [yearly, setYearly] = useState();
  const [apiCall, setApi] = useState(false);

  const [options, setOptions] = useState();
  const [selected, setSelection] = useState();
  const [defaultVal, setDefault] = useState();

  const getYear = (arg) => {
    if (IsEmpty(arg))
      return '';

    return arg?.toString().substring(2);
  };

  useEffect(() => {
    if (contents) {
      let ds = contents?.page_body?.data?.attributes?.page_body_cmp[0]?.dynamic_tables?.data[0]?.attributes;

      let max = ds?.max_value || new Date().getFullYear();
      let min = ds?.min_value || new Date().getFullYear();
      let default_value = ds?.default_value || null;

      if (max > 0 && min > 0) {
        let dataSet = [];
        for (let r = min; r <= max; r++) {
          dataSet.push({
            key: `${r} opt`,
            value: r,
            text: `${r}-${getYear(r + 1)}`
          })
        }

        setOptions(dataSet);
        if (default_value) {
          dataSet?.forEach((obj) => {
            if (obj?.value == default_value) {
              setDefault(obj);
              setSelection(obj);
            }
          })
        }
      }

    }
  }, [])

  useEffect(() => {
    if (selected) {
      setApi(true);
      dispatch(fetchProxyVoting(`${selected?.value}`));
    }
  }, [selected])

  useEffect(() => {
    if (proxy_voting_contents) {
      if (proxy_voting_contents?.quarterly) {
        setQuarter(proxy_voting_contents?.quarterly)
      }
      if (proxy_voting_contents?.yearly) {
        setYearly(proxy_voting_contents?.yearly)
      }
      setApi(false);
    }
  }, [proxy_voting_contents, apiCall])
  
  return (
    <div className='p-[8px] md:p-[15px] lg:p-[24px] border-primitive-BLUE-100 Border-2 rounded-b-xl'>
      <div className="bg-white text-white rounded-lg lg:card-shadow-custom md:border md:border-grey-neutral">
        <div className="flex flex-col md:flex-row justify-between ">
          <div className="bg-primitive-BLUE-100   py-5 lg:py-5 px-5 w-full md:w-80 flex flex-col  lg:flex-col  justify-start md:rounded-l-lg rounded-t-lg md:rounded-[0px]">
            <div className='flex flex-col'>
              <h5 className="text-[16px] lg:text-[18px] text-primary-BLACK   lg:text-left whitespace-nowrap font-semibold">
                Select Year
              </h5>
            </div>
            <SelectPlusMinus selected={selected} setSelection={setSelection} options={options} defaultVal={defaultVal} />
          </div>

          <div className="w-full">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 m-2 w-[-webkit-fill-available] max-h-72 overflow-y-scroll">
              {
                quarter?.map((object, index) => (
                  <React.Fragment key={`dlcf-${index}`} >
                    {object?.attributes?.file_display_name &&
                      <DownloadCardDisclosureSecond key={`dlc-${index}`}
                        heading={object?.attributes?.file_display_name}
                        contents={object} />}
                  </React.Fragment>
                ))
              }
            </div>
            {
              yearly?.length > 0 ? <ProxyVotingAccordion year={selected?.value} contents={yearly} /> : null
            }
          </div>

        </div>
      </div>
    </div>

  )
}

export default ProxyVotingDetails;
