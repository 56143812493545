import React from 'react';
import PropTypes from 'prop-types';

const BreadCrumb = ({ items }) => {
  return (
    <>
      <nav className="text-gray-600 relative">
        <ol className="list-none p-0 inline-flex">
          {items?.map((item, index) => (
            <li key={`bc-nav-${index}`} className="flex items-center text-sm lg:text-base">
              <a
                href={item?.url || '#'}
                className={item?.isactive ? 'text-gray-500' : 'text-black-500'}
              >
                {item?.title || ''}
              </a>
              {items.length > 1 && (
                <>
                  {index < items.length - 1 && (
                    <svg
                      className="h-5 w-5 mx-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  )}
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};

BreadCrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      isactive: PropTypes.bool,
      islast: PropTypes.bool,
    })
  ).isRequired,
};

export default BreadCrumb;
